import React, { ChangeEvent, FC, useState } from 'react'
import s from './ChatMessages.module.scss'
import plusInMessage from '../../../images/plusInMessage.svg'
import sendMessage from '../../../images/sendMessage.svg'
import { useSelector } from 'react-redux'
import { chatAPI } from '../../../api'
import { getChatCommandsSelector } from '../../../redux/reducers/chatReducers/chatSelectors'
import { Command } from './Command'

export const ChatFooter: FC<propTypes> = ({ senderID, dialogID }) => {

    const commands = useSelector(getChatCommandsSelector)

    const [inputValue, setInputValue] = useState(''),
            [showCommands, setShowCommands] = useState(false),
            [buttonDisabler, setButtonDisabler] = useState(false)

    const add = () => {
        setShowCommands(prev => !prev)
    }

    const onSendMessage = (commandValue: string = '') => {

        setButtonDisabler(prev => !prev)
        const sendedMessage = commandValue === '' ? inputValue : commandValue
        chatAPI.sendMessages(senderID, dialogID, sendedMessage).then(response => {
            
            setInputValue('')            
            const block = document.getElementById('chatMessagesBlock')
            block && (block.scrollTop = block.scrollHeight)
            setButtonDisabler(prev => !prev)

        })
        
    }
    
    const onChengeInput = (e: ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.currentTarget.value)
    }

    return (
        <div className={s.chatFooterWrapper}>
            <div className={s.comandsWrapper} >
                {showCommands && commands.map(command => <Command key={command.id} command={command} setShowCommands={setShowCommands} onSendMessage={onSendMessage} />)}
            </div>
            <button className={s.footerButton}  onClick={add} disabled={buttonDisabler}>
                <img alt='Добавить' src={plusInMessage} />
            </button>
            <input placeholder='Напишите сообщение' value={inputValue} onChange={onChengeInput} />
            <button className={s.footerButton} disabled={buttonDisabler} >
                <img alt='Отправить сообщение' src={sendMessage} onClick={() => onSendMessage()} />
            </button>
        </div>
    )
}


type propTypes = {
    senderID: string
    dialogID: string
}