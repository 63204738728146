import React, { forwardRef, useRef, useState, useEffect } from "react";

type TProps = {
	id?: string,
	keyId?: string,
	className?: string,
	inputClassName?: string,
	placeholder?: string,
	disabled?: boolean,
	value?: string,
	onBlur?: () => void,
	onChange?: (value: string) => any,
	onSelect?: (suggestion: any) => void
}

const DaData = forwardRef((props: TProps, ref: React.ForwardedRef<HTMLInputElement>) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const dropdownRef = useRef<HTMLDivElement>(null);
	const [open, setOpen] = useState(false);
	const [suggestions, setSuggestions] = useState<any>([]);
	const [focus, setFocus] = useState(-1);

	useEffect(() => {
		function handleClickOutside(e: any) {
			if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
				setOpen(false);
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	});

	useEffect(() => {
		if (inputRef && inputRef.current) inputRef.current.value = props.value || '';
	}, [props.value]);

	return (
		<>
			<div className={props.className || ''}>
				<input id={props.id || ''} key={props.keyId || ''} className={props.inputClassName || ''}
					placeholder={props.placeholder || ''} defaultValue={props.value || ''}
					disabled={props.disabled || false}
					ref={(input: HTMLInputElement) => {
						(inputRef as React.MutableRefObject<HTMLInputElement>).current = input;
						if (typeof ref === 'function') {
							ref(input);
						} else if (ref) {
							(ref as React.MutableRefObject<HTMLInputElement>).current = input;
						}
					}}
					onBlur={() => {
						setOpen(false);
						if (props.onBlur) props.onBlur();
					}}
					onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
						if (props.onChange) {
							let data = await props.onChange(e.target.value);
							setSuggestions(data);
							setOpen(true);
						}
					}}
					onKeyDown={(e: React.KeyboardEvent) => {
						if (e.key === 'ArrowDown') {
							e.preventDefault();
							if (inputRef.current && dropdownRef.current && suggestions.length > 0
								&& focus < suggestions.length - 1) {
								inputRef.current.value = suggestions[focus + 1].value;
								setFocus(focus + 1);
							}
							return;
						}
						if (e.key === 'ArrowUp') {
							e.preventDefault();
							if (inputRef.current && dropdownRef.current && suggestions.length > 0 &&
								focus >= 0) {
								inputRef.current.value = focus > 0 ? suggestions[focus - 1].value : '';
								setFocus(focus - 1);
							}
							return;
						}
						if (e.key === 'Enter') {
							e.preventDefault();
							if (dropdownRef.current && suggestions.length > 0 && focus >= 0) {
								setOpen(false);
								if (props.onSelect) props.onSelect(suggestions[focus]);
							}
						}
						if (e.key === 'Escape') {
							e.preventDefault();
							setOpen(false);
						}
						setFocus(-1);
					}}
				/>
				{open && suggestions.length > 0 &&
					<div ref={dropdownRef} className="react-dadata__suggestions">
						{suggestions.map((suggestion: any, index: number) => (
							<div key={(props.id || '') + '_' + index}
								className="react-dadata__suggestion react-dadata__small" onMouseDown={() => {
								if (inputRef && inputRef.current) inputRef.current.value = suggestion.value;
								setOpen(false);
								if (props.onSelect) props.onSelect(suggestion);
							}}>
								{suggestion.value}
							</div>
						))}
					</div>
				}
			</div>
		</>
	);
});

export default DaData;
