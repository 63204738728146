import React from "react";
import "./Checkbox.css";

interface IProps {
	checked: boolean,
	onClick?: () => void,
	id?: string,
	className?: string,
	parentClassName?: string,
	style?: object
}

const Checkbox: React.FunctionComponent<IProps> = (props) => {
	return (
		<div id={props.id} className={`checkbox ${props.parentClassName}`} style={props.style}
			onClick={props.onClick}>
			<input type="checkbox" className="checkbox_input" checked={props.checked}
				onChange={() => {}} />
			<div className={`checkbox_mark ${props.className}`}></div>
		</div>
	);
}

export default Checkbox;
