import React, { FC } from "react"
import { command } from "../../../redux/reducers/chatReducers/@types"
import s from './ChatMessages.module.scss'

export const Command: FC<propTypes> = ({ command, setShowCommands, onSendMessage }) => {

    const chooseCommand = () => {
        setShowCommands(false)
        onSendMessage(command.name)
    }

    return (
        <div className={s.command} onClick={chooseCommand} >
            {command.name}
        </div>
    )
}


type propTypes = {
    command: command
    setShowCommands:(value: boolean) => void
    onSendMessage: (value: string) => void
}