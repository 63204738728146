import {
    ADD_POINT_ON_MAP,
    AddPointOnMap,
    MapPoint, REMOVE_POINT_FROM_MAP, RemovePointFromMap, RouteInfo,
    SET_POINTS_ON_MAP, SET_ROUTE,
    setPointOnMap, setRouteOnMap,
    UPDATE_POINT_ON_MAP, UpdatePointOnMap
} from "./@types";


export const appendPoint = (point: MapPoint, index: number):
    AddPointOnMap => ({
    type: ADD_POINT_ON_MAP,
    point,
    index
});


export const setPoints =  (points:MapPoint[]):
    setPointOnMap =>  ({
    type: SET_POINTS_ON_MAP,
    points
});

export const setRoutes =  (route: RouteInfo | null): setRouteOnMap =>  ({
    type: SET_ROUTE,
    route
});

export const updatePoint =  (point:MapPoint, index:number):
    UpdatePointOnMap =>  ({
    type: UPDATE_POINT_ON_MAP,
    point,
    index
});

export const removePoint =  ( index:number):
    RemovePointFromMap =>  ({
    type: REMOVE_POINT_FROM_MAP,
    index
});
