import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setOverlay, clearMessage } from "../../../redux/reducers/system/actions";
import { RootState } from "../../../redux/store";
import { BottomWindow, Button } from "../elements";

const SystemMessage: React.FC = () => {
  const dispatch = useDispatch();
  const { system } = useSelector((state: RootState) => ({
    system: state.systemReducer,
  }));

  const [visible, setVisible] = useState(false);

  const handleClose = () => {
    dispatch(setOverlay(false));
    dispatch(clearMessage());
    setVisible(false);
  }

  useEffect(() => {
    if (system.message.id) {
      dispatch(setOverlay(true, 30));
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [system.message.id]);

  return (
    <>
        <BottomWindow
          title={system.message.message}
          visible={visible}
          resizable={false}
          onClose={handleClose}
          style={{ zIndex: 9999 }}
        >
          <div className="bottom_window_content">
            {system.message.statuses.map((status: any) => <Button key={status.status_id} type="primary" size="large" style={{ marginBottom: "0.8rem" }}>{status.status}</Button>)}
            <Button type="grey" size="large" style={{ color: "#5364ff" }}>Открыть чат</Button>
            <div className="bottom_window_problem_link">Сообщить о проблеме</div>
          </div>
        </BottomWindow>
    </>
  );  
}

export default SystemMessage;
