import React, {useEffect, useState} from "react";
import { connect, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { UserState } from "../../redux/reducers/user/@types";
import { FoldableBlock } from "../../components/Elements/elements";
import Screen, { ScreenTab } from "./Screen/Screen";
import { userAPI } from "../../api";
import "./Profile.css";
import ScreenWindow from "./Screen/ScreenWindow";
import {
	toggleProfileWindow,
	setSystemStatus, popSystemStatus
} from "../../redux/reducers/system/actions";
import {
	SystemState,
	ToggleProfileWindowAction,
	SetSystemStatusAction
} from "../../redux/reducers/system/@types";
import { setRegistrationStatus } from "../../redux/reducers/registration/actions";
import {
	RegistrationState,
	SetRegistrationStatusAction
} from "../../redux/reducers/registration/@types";
import {
	FinalizeState,
	SetIsOwnerAction,
	SaveDriverAction,
	SaveVehicleAction,
	SetAssigningDriverAction
} from "../../redux/reducers/finalize/@types";
import {
	setIsOwner,
	saveDriver,
	saveVehicle,
	setAssigningDriver
} from "../../redux/reducers/finalize/actions";
import RowScreenTab from "./Screen/RowScreenTab/RowScreenTab";
import {
	PassportData,
	LicenseData,
	Vehicle
} from "../components";
import {
	VehicleData,
	Driver,
	emptyPassport,
	emptyLicense,
	emptyCertificate, Passport, License
} from "../Elements/Containers/@types";
import {RegistrationStatus, SystemStatus, UserStatus} from "../../@types";
import {generateId} from "../../api/validate";

interface IProps {
  state: UserState;
  registration: RegistrationState;
  finalize: FinalizeState;
  toggleProfileWindow : () => ToggleProfileWindowAction;
  setRegistrationStatus: (status: RegistrationStatus | null) => SetRegistrationStatusAction;
  setSystemStatus: (status: SystemStatus) => SetSystemStatusAction;
  setIsOwner: (is_owner: boolean) => SetIsOwnerAction;
  saveDriver: (driver: Driver) => SaveDriverAction;
  saveVehicle: (vehicle: VehicleData) => SaveVehicleAction;
  setAssigningDriver: (assigning_driver: boolean) => SetAssigningDriverAction;
}

interface ILocalState {
	passport_errors: string,
	transport_errors: string,
	contract_errors: string,
	car_list : any[],
	drivers_list: any[]
}

const Profile: React.FunctionComponent<IProps> = ({
	state,
	registration,
	finalize,
	toggleProfileWindow,
	setRegistrationStatus,
	setSystemStatus,
	setIsOwner,
	saveDriver,
	saveVehicle,
	setAssigningDriver
}) => {

  console.log('user', state);
  const { driver_id, full_name, status } = state;
  const dispatch = useDispatch();

	const [localState, setLocalState] = useState<ILocalState>({
		passport_errors: "",
		transport_errors: "",
		contract_errors: "Реквизиты и подписи",
		car_list : [],
	    drivers_list: []
	});

	// let { passport_errors,transport_errors, contract_errors, car_list, drivers_list } = localState;

	useEffect(() => {
		initData();
    // userAPI.getDriver(driver_id).then((res: any) => {
    //   setLocalState((s) => ({
    //     ...s,
    //     person: res.person,
    //     passport: res.passport,
    //     cars: res.cars
    //   }));
    // }).catch();
  }, [state]);


 // const setStateM = (fieldName: any, value: any) =>{
 // 	setLocalState( (s)=> {
 // 		//@ts-ignore
 // 		s[fieldName] = value; return s;
 // 	});
 // }
	const initData = () => {

		if (finalize.owner) {
			let car_list: any[] = [];
			if (finalize.owner.vehicles && finalize.owner.vehicles.length) {
				car_list = finalize.owner.vehicles.map((car, i) => (
					<ScreenTab key={'car' + i} title={`${car.certificate.brand_model} ${car.certificate.license_plate}`}
							   icon="next" onClick={() => {
						setAssigningDriver(false);
						saveVehicle(car);
						toVehicle();
					}}
					/>
				));
			}
			setLocalState((s) => ({...s, car_list}));

			let drivers_list: any[] = [];
			if (finalize.owner.drivers && finalize.owner.drivers.length) {
				drivers_list = finalize.owner.drivers.map((driver, index) => (
					<ScreenTab key={'drv' + index}
							   title={driver.id === finalize.owner.id ? "Вы" :
								   driver.passport.name} description={"Категории " + driver.license.categories.join(", ")}
							   icon="next" onClick={() => {
						setIsOwner(false);
						saveDriver(driver);
						toPassportData();
					}}
					/>
				));
			}
			setLocalState((s) => ({...s, drivers_list}));
		}



		if (state.data) {
			if (!state.data.passport) setLocalState((s) => ({...s, passport_errors: "Отсутствуют паспортные данные"}));
			else if (!state.data.passport.full_name ||
				!state.data.passport.birth_date ||
				!state.data.passport.series ||
				!state.data.passport.number ||
				!state.data.passport.department_code ||
				!state.data.passport.issue_date ||
				!state.data.passport.adress ||
				!state.data.passport.real_adress) setLocalState((s) => ({
				...s,
				passport_errors: "Паспортные данные заполнены не полностью"
			}));
			else if (!state.data.passport.files.length) setLocalState((s) => ({
				...s,
				passport_errors: "Не прикреплены фото документов"
			}));

		}
	}

	let closeWindow = () => {
		dispatch(popSystemStatus())
	};

	const toPassportData = () => {
		setRegistrationStatus(RegistrationStatus.PassportData);
	}

	const toVehicle = () => {
		setRegistrationStatus(RegistrationStatus.Vehicle);
	}

  return (
  	<>
  		{registration.status === null &&
		    <ScreenWindow title={full_name} status={status === UserStatus.Working ? "Статус: работаю" : "Статус: не работаю"}
		    	onClose={closeWindow}>
		      <div className={'screen_scroll'}>
		        <RowScreenTab title="Договор" warning={localState.contract_errors} icon="next" />
		        {localState.passport_errors && <RowScreenTab title="Паспорт" warning={localState.passport_errors} icon="next"
		        	onClick={() => {
		        		setIsOwner(true);
		        		toPassportData();
		        	}}
		        />}
		        {localState.transport_errors && <RowScreenTab title="Транспортные средства" warning={localState.transport_errors} icon="next" />}
		
		        <FoldableBlock title="Ваши ТС" style={{ marginTop: "3rem" }}>
		          {localState.car_list.length && localState.car_list}
		          <ScreenTab title={<span style={{ color: "#888e99" }}>Добавить ТС</span>} icon="plus"
		          	onClick={() => {
		          		setAssigningDriver(false);
									saveVehicle({
										id: '',
										car_type: '',
										body_option: '',
										characteristics: [],
										car_pass: '',
										hydroboard: '',
										ramp: '',
										length: '',
										width: '',
										height: '',
										address: {
											address: null,
											latitude: '',
											longitude: ''
										},
										photos: [],
										medical_book: false,
										driver_loader: false,
										certificate: emptyCertificate,
										drivers: []
									});
									toVehicle();
		          	}}
		          />
		        </FoldableBlock>
		        <FoldableBlock title="Водители" style={{ margin: "3rem 0" }}>
		          {localState.drivers_list.length && localState.drivers_list}
		          <ScreenTab title={<span style={{ color: "#888e99" }}>Добавить водителя</span>} icon="plus"
		          	onClick={() => {
		          		setIsOwner(false);
		          		saveDriver({
		          			id: '',
		          			is_owner: false,
		          			passport: emptyPassport,
		          			license: emptyLicense
		          		});
									toPassportData();
								}}
							/>
		        </FoldableBlock>
		      </div>
		    </ScreenWindow>
		  }
		  {registration.status === RegistrationStatus.PassportData && <PassportData />}
		  {registration.status === RegistrationStatus.LicenseData && <LicenseData />}
		  {registration.status === RegistrationStatus.Vehicle && <Vehicle />}
	  </>
  )
}

const mapStateToProps = (state: RootState) => ({
  state: state.userReducer,
  registration: state.registrationReducer,
  finalize: state.finalizeReducer
});

export default connect(mapStateToProps, {
	toggleProfileWindow,
	setRegistrationStatus,
	setSystemStatus,
	setIsOwner,
	saveDriver,
	saveVehicle,
	setAssigningDriver
})(Profile);
