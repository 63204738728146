import Button from "../../Elements/Button/Button";
import React from "react";
import {OrderFullInfo} from "../../../redux/reducers/orders/@types";
import "./FinishPlayWindow.css"
import {ReactSVG} from "react-svg";
import CloseSVG from "../../App/svg/close.svg";
import OkSVG from "../../App/svg/ok.svg";

interface ILocalProps {
    order: OrderFullInfo,
    onClose:Function,
}

const FinishPlayWindow: React.FC<ILocalProps> = ({order, onClose}) => {

    return (
        <>
            <div className="play_window small_window finish_window">
                <div className="window_close_btn" onTouchEnd={(e)=> e.stopPropagation()} onClick={(e) =>  onClose()} ><ReactSVG src={CloseSVG} /></div>
                <div className="play_window_row">
                     <ReactSVG src={OkSVG} />
                </div>
                <div className="play_window_row">
                    <div className="order_complete">Заказ выполнен!</div>
                </div>
                <div className="play_window_row">
                    <div className="order_complete_info">
                        <div className="order_complete_info_address">{order.routes[order.routes.length-1].adress}</div>
                        <div className="order_complete_info_points">{order.routes.length + 'точки ∙ 00 км'   } </div>
                        <hr />
                        <div className="order_complete_info_errors">0 ошибок</div>
                    </div>
                </div>
                <div className="play_window_row">
                    <div className="order_complete_info_text">Просмотреть завершенные заказы можно в профиле во вкладке “Журнал заказов”</div>
                </div>
            </div>
        </>
    );
};


export default FinishPlayWindow;
