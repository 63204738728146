import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { message } from '../../../redux/reducers/chatReducers/@types'
import { setChatMessagesIsReadedAction } from '../../../redux/reducers/chatReducers/actions'
import { getChatCommandsSelector } from '../../../redux/reducers/chatReducers/chatSelectors'
import s from './ChatMessages.module.scss'
import { CommandMessage } from './CommandMessage'
import { Message } from './Message'
import { OwnMessage } from './OwnMessage'
import { TemporaryMessage } from './TemporaryMessage'

export const ChatBody: FC<propTypes> = ({ userID, scrollRef, messages, userType }) => {

    const dispatch = useDispatch()

    const commands = useSelector(getChatCommandsSelector)

    useEffect(() => {
        if (messages.some(message => message.read === false)) {
            dispatch(setChatMessagesIsReadedAction(userID))
        }
    })
    
    
    return (
        <div id='chatMessagesBlock' className={messages.length ? s.chatBodyWrapper : s.emptyChatBodyWrapper} ref={scrollRef}>
            {!messages.length && userType === 'client' ? <div>Свяжитесь с получателем в случае возникновения проблем</div> : messages
                .filter(message => message.from_user_id === userID)
                .map(message => message.newMessagesFromHere 
                    ? <TemporaryMessage key={`${message.id}`} /> : message.in_out === 'in' 

                        ? <Message key={`${message.id}`} message={message.message} datetime={message.date} /> 
                        : commands.some(command => command.name === message.message) 
                        
                            ? <CommandMessage key={`${message.id}`} message={message.message} /> 
                            : <OwnMessage key={`${message.id}`} message={message.message} datetime={message.date} />)}
        </div>
    )
}


type propTypes = {
    userID: string
    scrollRef: React.MutableRefObject<null>
    messages: message[]
    userType: string
}