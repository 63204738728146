import {
	SET_LICENSE_NUMBER_ENTERED,
	SET_LICENSE_ISSUE_DATE_ENTERED,
	SET_LICENSE_ISSUED_BY_ENTERED,
	SET_COUNTRY_ENTERED,
	SET_CATEGORY_ENTERED,
	ADD_LICENSE_PHOTO,
	REPLACE_LICENSE_PHOTO,
	CLEAR_LICENSE_PHOTOS,
	SetLicenseNumberEnteredAction,
	SetLicenseIssueDateEnteredAction,
	SetLicenseIssuedByEnteredAction,
	SetCountryEnteredAction,
	SetCategoryEnteredAction,
	AddLicensePhotoAction,
	ReplaceLicensePhotoAction,
	ClearLicensePhotosAction
} from "./@types";

export const setLicenseNumberEntered = (number_entered: boolean, number?: string):
	SetLicenseNumberEnteredAction => ({
  type: SET_LICENSE_NUMBER_ENTERED,
  number_entered,
  number
});

export const setLicenseIssueDateEntered = (issue_date_entered: boolean, issue_date?: Date | null):
	SetLicenseIssueDateEnteredAction => ({
  type: SET_LICENSE_ISSUE_DATE_ENTERED,
  issue_date_entered,
  issue_date
});

export const setLicenseIssuedByEntered = (issued_by_entered: boolean, issued_by?: string):
	SetLicenseIssuedByEnteredAction => ({
  type: SET_LICENSE_ISSUED_BY_ENTERED,
  issued_by_entered,
  issued_by
});

export const setCountryEntered = (country_entered: boolean, country?: string):
	SetCountryEnteredAction => ({
  type: SET_COUNTRY_ENTERED,
  country_entered,
  country
});

export const setCategoryEntered = (category_entered: boolean, categories?: string[]):
	SetCategoryEnteredAction => ({
  type: SET_CATEGORY_ENTERED,
  category_entered,
  categories
});

export const addLicensePhoto = (photo: Blob): AddLicensePhotoAction => ({
  type: ADD_LICENSE_PHOTO,
  photo
});

export const replaceLicensePhoto = (index: number, new_photo: Blob): ReplaceLicensePhotoAction => ({
  type: REPLACE_LICENSE_PHOTO,
  index,
  new_photo
});

export const clearLicensePhotos = (): ClearLicensePhotosAction => ({
  type: CLEAR_LICENSE_PHOTOS
});
