import {
	LOG_OUT,
	LogOutAction,
	SET_AUTHENTICATED,
	SET_CURRENT_COORDINATE,
	SET_STATUS,
	SET_USER_INFO,
	SetAuthenticatedAction,
	SetCurrentCoordinateAction,
	SetStatusAction,
	SetUserInfoAction,
	UserInfo,
	UserState
} from "./@types";
import {saveOwner} from "../finalize/actions";
import {UserStatus} from "../../../@types";
import {sendPhoto} from "../../../api";
import {convertDate} from "../../../api/validate";
import {Driver, VehicleData} from "../../../components/Elements/Containers/@types";

export const setAuthenticated = ({ authenticated, driver_id, full_name, status }: UserState): SetAuthenticatedAction => ({ 
  type: SET_AUTHENTICATED, 
  authenticated,
  driver_id: driver_id,
  full_name: full_name,
  status: status
});

export const setStatus = (status: UserStatus): SetStatusAction => ({
  type: SET_STATUS,
  status
});

export const setLogOut = (): LogOutAction => ({
	type: LOG_OUT
});

export const setUserInfo = (data: UserInfo): SetUserInfoAction => ({
  type: SET_USER_INFO,
  data
});

export const setUserCoordinates = (current_coordinates: {lon:number, lat:number}): SetCurrentCoordinateAction => ({
  type: SET_CURRENT_COORDINATE,
  current_coordinates
});

export const syncData = () => async (dispatch: any, getState: any) => {

	const owner = getState().finalizeReducer.owner;
	if (!owner) return;

	let response: any;
	owner.passport.photo_ids = [];
	if (owner.passport.photos && owner.passport.photos.length > 0) {
		response = await sendPhoto(owner.passport.photos[0],
			owner.id + '-passport-front.jpg');
		if (response) owner.passport.photo_ids.push(response.id);
		response = await sendPhoto(owner.passport.photos[1],
			owner.id + '-passport-registration.jpg');
		if (response) owner.passport.photo_ids.push(response.id);
		response = await sendPhoto(owner.passport.photos[2],
			owner.id + '-passport-selfie.jpg');
		if (response) owner.passport.photo_ids.push(response.id);
	}

	owner.drivers.forEach(async (driver: Driver) => {
		driver.passport.photo_ids = [];
		if (driver.passport.photos && driver.passport.photos.length > 0) {
			response = await sendPhoto(driver.passport.photos[0],
				driver.id + '-front.jpg');
			if (response) driver.passport.photo_ids.push(response.id);
			response = await sendPhoto(driver.passport.photos[1],
				driver.id + '-registration.jpg');
			if (response) driver.passport.photo_ids.push(response.id);
			response = await sendPhoto(driver.passport.photos[2],
				driver.id + '-selfie.jpg');
			if (response) driver.passport.photo_ids.push(response.id);
		}
		driver.license.photo_ids = [];
		if (driver.license.photos && driver.license.photos.length > 0) {
			response = await sendPhoto(driver.license.photos[0],
				driver.id + '-license-front.jpg');
			if (response) driver.license.photo_ids.push(response.id);
			response = await sendPhoto(driver.license.photos[1],
				driver.id + '-license-rear.jpg');
			if (response) driver.license.photo_ids.push(response.id);
		}
	});

	owner.vehicles.forEach(async (vehicle: VehicleData) => {
		vehicle.photo_ids = [];
		if (vehicle.photos && vehicle.photos.length > 0) {
			response = await sendPhoto(vehicle.photos[0],
				vehicle.id + '-inside.jpg');
			if (response) vehicle.photo_ids.push(response.id);
			response = await sendPhoto(vehicle.photos[1],
				vehicle.id + '-front.jpg');
			if (response) vehicle.photo_ids.push(response.id);
			response = await sendPhoto(vehicle.photos[2],
				vehicle.id + '-side.jpg');
			if (response) vehicle.photo_ids.push(response.id);
		}
		vehicle.certificate.photo_ids = [];
		if (vehicle.certificate.photos && vehicle.certificate.photos.length > 0) {
			response = await sendPhoto(vehicle.certificate.photos[0],
				vehicle.id + '-certificate-front.jpg');
			if (response) vehicle.certificate.photo_ids.push(response.id);
			response = await sendPhoto(vehicle.certificate.photos[1],
				vehicle.id + '-certificate-rear.jpg');
			if (response) vehicle.certificate.photo_ids.push(response.id);
		}

		vehicle.characteristics.forEach((characteristic: any) => {
			switch (characteristic.name) {
				case 'Медкнижка':
					characteristic.value = vehicle.medical_book;
					break;
				case 'Водитель грузчик':
					characteristic.value = vehicle.driver_loader;
					break;
				case 'Гидроборт':
					characteristic.value = vehicle.hydroboard;
					break;
				case 'Тип пандуса':
					characteristic.value = vehicle.ramp;
					break;
				default:
					if (characteristic.type === 'Boolean' && !characteristic.value)
						characteristic.value = false;
					if (characteristic.type === 'number' && !characteristic.value)
						characteristic.value = '0';
					break;
			}
		});
	});

	dispatch(saveOwner(owner));

	let car: any;
	let driver: any;
	let cars: any = [];
	let number = getState().userReducer.data.person.phone_number || '';
	let TIN = getState().userReducer.data.TIN || '';
	let email = getState().userReducer.data.person.email || '';
	owner.vehicles.forEach((vehicle: any) => {
		car = {
			car_type_id: vehicle.car_type,
			body_option_id: vehicle.body_option,
			body_option_characteristics: vehicle.characteristics,
			pass: vehicle.car_pass === 'none' ? '' : vehicle.car_pass,
			size: {
				length: vehicle.length?.replace(',', '.') || '',
				width: vehicle.width?.replace(',', '.') || '',
				height: vehicle.height?.replace(',', '.') || ''
			},
			adress: {
				adress: vehicle.address?.address?.value || '',
				longitude: vehicle.address?.longitude || '',
				latitude: vehicle.address?.latitude || ''
			},
			files: vehicle.photo_ids,
			certificate: {
				number: vehicle.certificate.license_plate?.replace('_', '').replace(/ /g, '') || '',
				VIN: vehicle.certificate.vin,
				brand: vehicle.certificate.brand_model?.split(' ')[0] || '',
				model: vehicle.certificate.brand_model?.slice(
					vehicle.certificate.brand_model.indexOf(' ') + 1) || '',
				type: vehicle.certificate.vehicle_type,
				category: vehicle.certificate.category,
				date: vehicle.certificate.build_year,
				class: vehicle.certificate.eco_class,
				passport_serial: vehicle.certificate.pts_series,
				passport_number: vehicle.certificate.pts_number,
				certificate_serial: vehicle.certificate.sts_series,
				certificate_number: vehicle.certificate.sts_number,
				max_weight: vehicle.certificate.max_weight,
				free_weight: vehicle.certificate.load_weight,
				files: vehicle.certificate.photo_ids
			},
			drivers: []
		};

		vehicle.drivers.forEach((dr: any) => {
			driver = {
				phone_number: dr.is_owner ? number : '',
				passport: {
					full_name: dr.passport.name,
					birth_date: convertDate(dr.passport.birthdate),
					series: dr.passport.number.slice(0, 5).replace(' ', ''),
					number: dr.passport.number.slice(6),
					issued_by: dr.passport.issued_by,
					department_code: dr.passport.department,
					issue_date: convertDate(dr.passport.issue_date),
					adress: dr.passport.registration?.value,
					real_adress: (dr.passport.same_address ?
						dr.passport.registration?.value : dr.passport.address?.value),
					files: dr.passport.photo_ids
				},
				driver_certificate: {
					full_name: dr.passport.name,
					serial: dr.license.number.slice(0, 5).replace(' ', ''),
					number: dr.license.number.slice(6),
					issue_date: convertDate(dr.license.issue_date),
					validity: '',
					issued_by: dr.license.issued_by,
					country: dr.license.country,
					category: dr.license.categories,
					files: dr.license.photo_ids
				}
			}
			car.drivers.push(driver);
		});

		cars.push(car);
	});

	let data = {
		TIN,
		banking: {
			account_number: owner.bank.account || '',
			BIK: owner.bank.bic || '',
			correction_number: owner.bank.corr || '',
			bank: {
				BIK: owner.bank.bic || '',
				name: owner.bank.name || '',
				correction_number: owner.bank.corr || '',
				TIN: owner.bank.inn || '',
				KPP: owner.bank.kpp || '',
				OGRN: '',
				adress: owner.bank.address?.value || ''
			}
		},
		person: {
			full_name: owner.passport.name.trim(),
			phone_number: number,
			email
		},
		passport: {
			full_name: owner.passport.name.trim(),
			birth_date: convertDate(owner.passport.birthdate),
			series: owner.passport.number.slice(0, 5).replace(' ', ''),
			number: owner.passport.number.slice(6),
			issued_by: owner.passport.issued_by,
			department_code: owner.passport.department,
			issue_date: convertDate(owner.passport.issue_date),
			adress: owner.passport.registration.value,
			real_adress: (owner.passport.same_address ?
				owner.passport.registration.value : owner.passport.address.value),
			files: owner.passport.photo_ids
		},
		cars,
		recommendations: [],
		accept_agreement: true
	}

	dispatch(setUserInfo(data));
}
