import {SystemStatus} from "../../../@types"
import {
  CLEAR_MESSAGE,
  CLEAR_SYSTEM_STATUS,
  CLOSE_ALL_WINDOWS,
  POP_SYSTEM_STATUS,
  REMOVE_SYSTEM_STATUS,
  SET_CURRENT_ID,
  SET_MESSAGE,
  SET_OVERLAY,
  SET_SYSTEM_STATUS, SET_VISIBILITY_OVERLAY, SET_WINDOW_MESSAGE,
  SystemActionTypes,
  SystemState,
  TOGGLE_DATEPICKER_WINDOW,
  TOGGLE_DRIVER_STATUS_WINDOW,
  TOGGLE_ORDER_BOOK_WINDOW,
  TOGGLE_ORDERS_WINDOW,
  TOGGLE_PARTNERSHIP_WINDOW,
  TOGGLE_PROFILE_WINDOW,
  TOGGLE_SYSTEM_STATUS,
} from "./@types";

const initialState: SystemState = {
  overlay: {
    visible: true,
    zIndex: 20
  },
  menuWindow: false,
  ordersWindow: true,
  datepickerWindow: false,
  driverStatusWindow: false,

  profileWindow:  false,
  partnershipWindow:  false,
  message: {
    id: "",
    statuses: [],
    message: "",
    order_id: "",
  },
  statuses: [SystemStatus.Main],
  currentId: "",
  window_message:{type:0, text: ''}
}

// Перемещаем оверлей на слой ниже
const appendOverlay = (statuses : SystemStatus[]) =>{
  statuses = statuses.filter(x => x !== SystemStatus.Overlay);
  if (statuses.length && statuses[statuses.length-1] !=SystemStatus.Main) {
    let tmp = statuses[statuses.length-1];
    statuses[statuses.length-1] = SystemStatus.Overlay;
    statuses.push(tmp);
  }

  return statuses;
}

const systemReducer = (state = initialState, action: SystemActionTypes) => {
  switch (action.type) {
    case SET_OVERLAY:
      return {
        ...state,
        overlay: {
          visible: action.overlay,
          zIndex: action.zIndex ? action.zIndex : 20
        }
      }
    case TOGGLE_ORDERS_WINDOW:
      return {
        ...state,
        ordersWindow: action.visible,
      }

    case SET_VISIBILITY_OVERLAY:
      return {
        ...state,
        overlay: {
          visible: action.visible,
          zIndex: state.overlay.zIndex
        }
      }
    // case TOGGLE_MENU_WINDOW:
    //   return {
    //     ...state,
    //     menuWindow: !state.menuWindow,
    //     datepickerWindow: false,
    //     driverStatusWindow: false,
    //     overlay: {
    //       visible: !state.menuWindow,
    //       zIndex: 20
    //     }
    //   }
    case TOGGLE_DATEPICKER_WINDOW:
      return {
        ...state,
        overlay: {
          visible: !state.datepickerWindow,
          zIndex: 20
        },
        datepickerWindow: !state.datepickerWindow,
        menuWindow: false,
        driverStatusWindow: false
      }
    case TOGGLE_DRIVER_STATUS_WINDOW:
      return {
        ...state,
        overlay: {
          visible: !state.driverStatusWindow,
          zIndex: 20
        },
        driverStatusWindow: !state.driverStatusWindow,
        menuWindow: false,
        datepickerWindow: false
      }



    case TOGGLE_PROFILE_WINDOW:
      return {
        ...state,
        overlay: {
          visible: state.profileWindow,
          zIndex:  20
        },
        ordersWindow: state.profileWindow,
        menuWindow: state.profileWindow,
        profileWindow: !state.profileWindow ,
      }

      case TOGGLE_PARTNERSHIP_WINDOW:
      return {
        ...state,
        partnershipWindow: !state.partnershipWindow
      }

    case CLOSE_ALL_WINDOWS:
      return {
        ...state,
        overlay: {
          visible: false,
          zIndex: 20
        },
        menuWindow: false,
        driverStatusWindow: false,
        datepickerWindow: false,
        message: {
          id: "",
          statuses: [],
          message: "",
          order_id: "",
        },
      }
    case SET_MESSAGE:
      return {
        ...state,
        message: {
          id: action.id,
          statuses: action.statuses,
          message: action.message,
          order_id: action.order_id,
        }
      }
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: {
          id: action.id,
          statuses: action.statuses,
          message: action.message,
          order_id: action.order_id,
        }
      }
    case SET_SYSTEM_STATUS:

    	return {
    		...state,
            overlay: {zIndex:state.overlay.zIndex, visible:true},
    		statuses: appendOverlay([...state.statuses.filter( x=> x !== action.status), action.status])
    	}

    case CLEAR_SYSTEM_STATUS:
      return {
        ...state,
        statuses: [SystemStatus.Main]
      }
    case POP_SYSTEM_STATUS:
      state.statuses.pop();
      return {
        ...state,
        statuses: appendOverlay(state.statuses)
      }
    case REMOVE_SYSTEM_STATUS:
      return {
        ...state,
        statuses: appendOverlay( state.statuses.filter(x => x !== action.status) )
      }
    case TOGGLE_SYSTEM_STATUS:
      return {
        ...state,
        statuses: state.statuses.some(x => x === action.status) ?
                  appendOverlay( state.statuses.filter(x => x !== action.status) ) :
                  appendOverlay(  [...state.statuses, action.status] )
      }
    case SET_CURRENT_ID:
    	return {
    		...state,
    		currentId: action.id
    	}
    case SET_WINDOW_MESSAGE:
      return {
        ...state,
        window_message:{type:action.message_type, text: action.message_text }
      }
    default:
      return state;
  }
}


export const getLastSystemStatus = (statuses: SystemStatus[]) : SystemStatus =>{
  if (!statuses || ! statuses.length) return SystemStatus.Main;
  return  statuses[statuses.length-1];
}

export const isSystemStatusActive = (status: SystemStatus ,statuses:SystemStatus[]) =>{
  if (!statuses || ! statuses.length) return false;
  return  statuses.some( x=> x === status);
}


export default systemReducer;
