import {
	FinalizeState,
	SET_FINALIZING,
	SET_ASSIGNING_DRIVER,
	SET_IS_OWNER,
	SET_CAMERA_ACCESSED,
	SAVE_OWNER,
	SAVE_DRIVER,
	UPDATE_DRIVER,
	SAVE_VEHICLE,
	UPDATE_VEHICLE,
	REMOVE_VEHICLE,
	FinalizeActionTypes
} from "./@types";

let initialState: FinalizeState = {
	finalizing: false,
	assigning_driver: false,
	is_owner: false,
	camera_accessed: false,
	owner: {
		id: '',
		owner_type: '',
		tin_type: '',
		tin: '',
		agree: false,
		passport: {
			name: '',
			birthdate: null,
			number: '',
			department: '',
			issue_date: null,
			issued_by: '',
			registration: null,
			address: null,
			same_address: false,
			photos: []
		},
		bank: {
			account: '',
			bic: '',
			name: '',
			corr: '',
			inn: '',
			kpp: '',
			address: null
		},
		vehicles: [],
		drivers: []
	},
	current_driver: {
		id: '',
		is_owner: false,
		passport: {
			name: '',
			birthdate: null,
			number: '',
			department: '',
			issue_date: null,
			issued_by: '',
			registration: null,
			address: null,
			same_address: false,
			photos: []
		},
		license: {
			number: '',
			issue_date: null,
			issued_by: '',
			country: '',
			categories: [],
			photos: []
		}
	},
	current_vehicle: {
		id: '',
		car_type: '',
		body_option: '',
		characteristics: [],
		car_pass: '',
		hydroboard: '',
		ramp: '',
		length: '',
		width: '',
		height: '',
		address: {
			address: null,
			latitude: '',
			longitude: ''
		},
		photos: [],
		medical_book: false,
		driver_loader: false,
		certificate: {
			license_plate: '',
			vin: '',
			brand_model: '',
			vehicle_type: '',
			category: '',
			build_year: '',
			eco_class: '',
			pts_series: '',
			pts_number: '',
			sts_series: '',
			sts_number: '',
			max_weight: '',
			load_weight: '',
			photos: []
		},
		drivers: []
	}
}

const finalizeReducer = (state = initialState, action: FinalizeActionTypes) => {
	switch (action.type) {
		case SET_FINALIZING:
			return {
				...state,
				finalizing: action.finalizing
			}
		case SET_ASSIGNING_DRIVER:
			return {
				...state,
				assigning_driver: action.assigning_driver
			}
		case SET_IS_OWNER:
			return {
				...state,
				is_owner: action.is_owner
			}
		case SET_CAMERA_ACCESSED:
			return {
				...state,
				camera_accessed: action.camera_accessed
			}
		case SAVE_OWNER:
			return {
				...state,
				owner: action.owner
			}
		case SAVE_DRIVER:
			return {
				...state,
				current_driver: action.driver
			}
		case UPDATE_DRIVER:
			let driver_owner = state.owner;
			if (driver_owner && driver_owner.drivers && driver_owner.drivers.length > 0) {
				let index = driver_owner.drivers.findIndex((driver) => (
					driver.id === state.current_driver.id
				));
				if (index >= 0) {
					driver_owner.drivers[index] = state.current_driver;
				} else {
					driver_owner.drivers.push(state.current_driver);
				}
			} else {
				driver_owner.drivers = [state.current_driver];
			}
			if (driver_owner && state.current_driver.id === driver_owner.id) {
				driver_owner.passport = state.current_driver.passport;
			}
			return {
				...state,
				owner: driver_owner
			}
		case SAVE_VEHICLE:
			return {
				...state,
				current_vehicle: action.vehicle
			}
		case UPDATE_VEHICLE:
			let vehicle_owner = state.owner;
			if (vehicle_owner && vehicle_owner.vehicles && vehicle_owner.vehicles.length > 0) {
				let index = vehicle_owner.vehicles.findIndex((vehicle) => (
					vehicle.id === state.current_vehicle.id
				));
				if (index >= 0) {
					vehicle_owner.vehicles[index] = state.current_vehicle;
				} else {
					vehicle_owner.vehicles.push(state.current_vehicle);
				}
			} else {
				vehicle_owner.vehicles = [state.current_vehicle];
			}
			return {
				...state,
				owner: vehicle_owner
			}
		case REMOVE_VEHICLE:
			let temp = state.owner;
			if (temp.vehicles && temp.vehicles.length > 0) {
				let index = temp.vehicles.findIndex((vehicle) => (
					vehicle.id === state.current_vehicle.id
				));
				if (index >= 0) {
					temp.vehicles.splice(index, 1);
				}
			}
			return {
				...state,
				owner: temp,
				current_vehicle: {
					id: '',
					car_type: '',
					body_option: '',
					characteristics: [],
					car_pass: '',
					hydroboard: '',
					ramp: '',
					length: '',
					width: '',
					height: '',
					address: {
						address: null,
						latitude: '',
						longitude: ''
					},
					photos: [],
					medical_book: false,
					driver_loader: false,
					certificate: {
						license_plate: '',
						vin: '',
						brand_model: '',
						vehicle_type: '',
						category: '',
						build_year: '',
						eco_class: '',
						pts_series: '',
						pts_number: '',
						sts_series: '',
						sts_number: '',
						max_weight: '',
						load_weight: '',
						photos: []
					},
					drivers: []
				}
			}
		default:
			return state;
	}
}

export default finalizeReducer;
