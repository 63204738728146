import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../../redux/store";
import { OrdersState } from "../../../redux/reducers/orders/@types";
import { Map, YMaps, YMapsApi } from "react-yandex-maps";
import "./YandexMap.css";

interface IProps {
  state: OrdersState;
}

const mapState = {
  center: [55.762222, 37.606765],
  zoom: 14,
  controls: []
}

const MapComponent: React.FunctionComponent<IProps> = ({ state }) => {
  let map: any = null;

  const onLoad = (ymaps: YMapsApi) => {
    const icon = ymaps.templateLayoutFactory.createClass(`
      <div class="ymap_icon">
        <div class="ymap_icon_caption">
          <div class="ymap_icon_distance">{{ properties.distance }}</div>
          <div class="ymap_icon_price">{{ properties.price }}</div>
        </div>
        <div class="ymap_icon_placemark">
          <svg width="2rem" height="2.5rem" viewBox="0 0 31 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.318457 6.86611C0.117173 5.05456 0.780265 3.25183 2.41944 2.45475C4.73478 1.32888 8.87156 0 15.5 0C22.1284 0 26.2652 1.32888 28.5806 2.45475C30.2197 3.25183 30.8828 5.05456 30.6815 6.86611L30.5878 7.71005C29.5539 17.0147 25.7056 25.7824 19.5569 32.8421L15.5 37.5L11.4431 32.8421C5.29438 25.7824 1.44608 17.0147 0.412227 7.71004L0.318457 6.86611Z" fill="#5364FF"/>
            <ellipse cx="15.5" cy="14.5" rx="5.5" ry="4.5" fill="white"/>
          </svg>
        </div>
      </div>
    `);

    state.items.map((item) => ({
      coordinates: [item.lon, item.lat],
      properties: {
        distance: "3 км",
        price: item.price + "₽"
      },
      options: {
        iconLayout: icon
      }
    })).forEach((item) => {
      const placemark = new ymaps.Placemark(item.coordinates, item.properties, item.options);
      map.geoObjects.add(placemark);
    });
  }

  return (
    <>
      <div className="top_gradient"></div>
      <YMaps query={{apikey: 'efe74c2e-a140-45f7-b21e-e7608b626295', load: 'package.full'}}>
        <Map 
          state={mapState}
          className="yandex_map"
          instanceRef={(ref) => map = ref}
          onLoad={onLoad}
        >
        </Map>
      </YMaps>
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  state: state.ordersReducer
});

export default connect(mapStateToProps)(MapComponent);