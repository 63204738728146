import React from 'react'
import marker from '../../../images/marker-blue.svg';

// const HereMarkerIcon =`
// <div style="background: #fff">
//     <div  class="leaflet-marker-icon leaflet-zoom-animated leaflet-interactive" style="margin-left: -12px;margin-top: -40px">
//         <div class="marker-number">{NUMBER}</div>
//         <img className="leaflet-marker-icon leaflet-zoom-animated leaflet-interactive" style="width:24px;height:40px;"    src="${marker}"/>
//
//      </div>
// </div>`;

// const HereMarkerIcon =`
// <div>
//     <div  class="leaflet-marker-icon leaflet-zoom-animated leaflet-interactive" style="margin-left: -12px;margin-top: -40px">
//         <div class="marker-number">&nbsp;</div>
//         <img className="leaflet-marker-icon leaflet-zoom-animated leaflet-interactive" style="width:24px;height:40px;"    src="${marker}"/>
//         <span class="cloud">{NUMBER}</span>
//      </div>
// </div>`;

export function HereMarkerBlue(label: string) {
     return '<div style="background: transparent">' +
                '<div class="leaflet-marker-icon leaflet-zoom-animated leaflet-interactive" style="margin-left: -12px;margin-top: -40px">' +
                    `<div class="marker-number"> <span class="cloud-blue">${label}</span></div>` +
                    `<img class="leaflet-marker-icon leaflet-zoom-animated leaflet-interactive" style="width:31px;height:38px;" src="${marker}"/>` +
                '</div>' +
            '</div>';

}
