import {
	CheckPhoneState,
	SET_PHONE_ENTERED,
	SET_CODE_SENDING,
	SET_CODE_SENT,
	SET_CODE_ENTERED,
	SET_CODE_VERIFYING,
	CheckPhoneActionTypes
} from "./@types";

let initialState: CheckPhoneState = {
  phone_entered: false,
  phone: '',
  code_sending: false,
  code_sent: false,
  code_entered: false,
  code: '',
  code_verifying: false
}

const checkPhoneReducer = (state = initialState, action: CheckPhoneActionTypes) => {
  switch (action.type) {
    case SET_PHONE_ENTERED:
      return {
        ...state,
        phone_entered: action.phone_entered,
        phone: action.phone
      }
    case SET_CODE_SENDING:
      return {
        ...state,
        code_sending: action.code_sending
      }
    case SET_CODE_SENT:
      return {
        ...state,
        code_sent: action.code_sent
      }
    case SET_CODE_ENTERED:
      return {
        ...state,
        code_entered: action.code_entered,
        code: action.code
      }
    case SET_CODE_VERIFYING:
      return {
        ...state,
        code_verifying: action.code_verifying
      }
    default:
      return state;
  }
}

export default checkPhoneReducer;
