import { 
    ActionsTypes, 
    ChatDataState, 
    SET_CHAT_USERS_FULL_INFO,
    SET_CHAT_COMMANDS,
    SET_CHAT_MESSAGES,
    SET_CHAT_MESSAGES_IS_READED,
    SET_CHAT_ALL_USERS,
    SET_CHAT_USERS_CLEAR
 } from "./@types"


let initialState: ChatDataState = {
    allUsers: [],
    users: [],
    commands: [],
    messages: []
}

const chatReducer = (state = initialState, action: ActionsTypes) => {
    switch (action.type) {
        case SET_CHAT_USERS_FULL_INFO:
            return {
                ...state,
                users: state.allUsers.map(user => ({ 
                                ...user,
                                type: action.users.filter(userInfo => userInfo.full_name === user.full_name).length ? action.users.filter(userInfo => userInfo.full_name === user.full_name)[0].type : '',
                                dialog_id: action.users.filter(userInfo => userInfo.full_name === user.full_name).length ? action.users.filter(userInfo => userInfo.full_name === user.full_name)[0].id : ''
                            }))
            }

        case SET_CHAT_ALL_USERS:
            return {
                ...state,
                allUsers: [...state.allUsers, ...action.allUsersData.filter(element => !state.allUsers.some(user => user.full_name === element.full_name))]
            }

        case SET_CHAT_COMMANDS:
            return {
                ...state,
                commands: action.commands
            }

        case SET_CHAT_MESSAGES:
            const newMessages = action.messages.filter(message => !state.messages.some(mess => mess.id === message.id)).reverse()
            if (!newMessages.length) {
                return state
            } else if (!!state.messages.length && state.messages[state.messages.length - 1].read === true && newMessages.some(message => message.in_out === 'in')) {
                newMessages.unshift({...newMessages[0], id: newMessages[0].id + '1', newMessagesFromHere: true} )
            }
            return {
                ...state,
                messages: [...state.messages, ...newMessages]
            }

        case SET_CHAT_MESSAGES_IS_READED:
            return {
                ...state,
                messages: state.messages.map(message => message.from_user_id === action.userID ? {...message, read: true} : message)
            }

        case SET_CHAT_USERS_CLEAR:
            return {
                ...state,
                users: [],
                allUsers: []
            }

        default:
            return state
    }
}

export default chatReducer