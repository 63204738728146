import React, {useEffect, useRef} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
// import H from "@here/maps-api-for-javascript";
import "./HereMap.css";
import { RootState } from "../../../redux/store";
import {hereMapApiKey} from "../../../deployment";
import {MapDataState, MapPoint} from "../../../redux/reducers/map/@types";
import {
  addRouteShapeToMap,
  calculateRoute, centerMapBox,
  clearMap,
  drawWayOnMap,
  hereMarker,
  MARKER_1, MARKER_2,
  removeMarkers
} from "./hereFunctions";
import {setOrder} from "../../../redux/reducers/orders/actions";
import {Order} from "../../../redux/reducers/orders/@types";
import {setSystemStatus} from "../../../redux/reducers/system/actions";
import {SystemStatus} from "../../../@types";
import {awaitExpression} from "@babel/types";
import {openOrderThunk} from "../../../redux/reducers/orders/reducers";

interface IProps {
  state: MapDataState,
}

const mapState = {
  center: [55.762222, 37.606765],
  zoom: 14,
  controls: []
}

export var map : H.Map;
var currentPoints : MapPoint[] = [];
var orderItems :Order[];

const MapComponent: React.FunctionComponent<IProps> = ({ state }) => {

  const mapCont = useRef<HTMLHeadingElement>(null);
  const isFirstLoad = useRef({isTrue:true}).current;
  const dispatch = useDispatch();
  let mapReducer = useSelector((state: RootState) => state.mapReducer);
  const {points} = useSelector((state: RootState)  => ({points:  state.mapReducer.points}));
  const {route} = useSelector((state: RootState)  => ({route:  state.mapReducer.route}));
  const {items} = useSelector((state: RootState)  => ({items:  state.ordersReducer.items}));
  const {user} = useSelector((state: RootState)  => ({user:  state.userReducer}));

  const prevPoints  = useRef({ points }).current;
  const prevRoutes  = useRef({ route}).current;
  currentPoints = [...points];


  useEffect(() => {
    orderItems = items;
    const platform = new H.service.Platform({
      'apikey': hereMapApiKey
    });

    if (!map || !mapCont || !mapCont.current || mapCont.current.childElementCount === 0) {
      const defaultLayers = platform.createDefaultLayers({
        lg:'ru_RU'
      });

      //center: { lat: 55.7511, lng: 37.6218 },
      map = new H.Map(
          //@ts-ignore
          mapCont.current,
          defaultLayers.vector.normal.map,
          {
            // This map is centered over Europe
            center: { lat: 55.7511, lng: 37.6218 },
            zoom: 12,
            pixelRatio: window.devicePixelRatio || 1
          }
      );

      var events = new H.mapevents.MapEvents(map);
      // // eslint-disable-next-line
      var behavior = new H.mapevents.Behavior(events);

      var ui   = H.ui.UI.createDefault(map, defaultLayers, 'ru-RU');

      map.addEventListener('tap', (event:any)=> {
        // var coord = map.screenToGeo(evt.currentPointer.viewportX, evt.currentPointer.viewportY) as H.geo.Point ;
        //
        // //let marker = hereMarker('', , currentPoints.length);
        // //let marker = hereMarker('A' , [coord.lat, coord.lng],  currentPoints.length,  {arrivalDate:'2020-10-10',departureDate:'2020-10-10', address:{string:'Улица'} } );
        // let marker = hereMarker('A' , [coord.lat, coord.lng],  currentPoints.length,  {arrivalDate:'2020-10-10',departureDate:'2020-10-10', address:{string:'Улица'} } );
        // map.addObject(marker);
        //
        // geocode([coord.lat, coord.lng], platform, dispatch, currentPoints.length);
        event.stopPropagation();
        console.log('was tap', event);
        if (event.target && event.target.data && event.target.data.name && (event.target.data.name == MARKER_2)) {
          let inx = +event.target.itemIndex ;
          if (orderItems.length > inx) {
            setTimeout(()=>{
              openOrderThunk(orderItems[inx].id, user.authenticated , orderItems[inx].type, dispatch );
            }, 0)

          }
        }
      });

      // setDraggable(map, behavior, (map) => {
      //   afterDrag(map,  currentPoints, platform, dispatch);
      // });

      // add a resize listener to make sure that the map occupies the whole container
      window.addEventListener('resize', () =>
          map.getViewPort().resize()
      );
    }

    if (mapReducer.use_data_from_1c) {
      drawWayOnMap(points, map);
    }
    else
      if (currentPoints.length > 1){
          const _waypoints = currentPoints.map((waypoint) =>   waypoint.lat.toFixed(4) + ',' +  waypoint.lng.toFixed(4));
          calculateRoute(platform, _waypoints, map);
          currentPoints.forEach((x,index)=>{
            let marker = hereMarker(x.data && x.data.street ? x.data.street : '' , [x.lat, x.lng],index + 1,{arrivalDate:'', departureDate:'',address: {value: x.data? x.data.street : ''}});
            map.addObject(marker);
          })
    }
    if (!route) clearMap(map);
    if (route && prevRoutes.route != route) {
      clearMap(map);
      if (!route.shapes || !route.points) return ;
      route.shapes.forEach( (x, index) =>   addRouteShapeToMap(x, map,index === 0) );
      //removeMarkers(map);

      route.points.forEach( (point, index)=> {
        let marker = hereMarker(  '' , [point.lat, point.lng],index+1,{arrivalDate:'', departureDate:'', address: {value: ''}});
        map.addObject(marker);
      })
      centerMapBox(map);
    } else {

      if (items && items.length) {
        items.forEach((order,index)=> {
          let marker = hereMarker(  order.point_count + ' точек' , [order.lat, order.lon],index+1,{arrivalDate:'', departureDate:'', address: {value: order.address}}, 1);
          map.addObject(marker);
        })
      }
    }



    return () => {
      prevPoints.points = points;
      prevRoutes.route = route;
      isFirstLoad.isTrue = false
    }
  }, [dispatch, points, route,items]);

  return (
    <>
       <div className="top_gradient"/>
       <div ref={mapCont} id={"here-map"} className="here_map" style={{ height: "100%" }} />
    </>
  )
};

export function swap(arr:number[][]) {
  if (!arr.length) {
    return arr;
  }

  if (Array.isArray(arr[0])) {
    return arr.map(item => [item[1], item[0]]);
  } else {
    return [arr[1], arr[0]];
  }
}

const mapStateToProps = (state: RootState) => ({
  state: state.mapReducer
});

export default connect(mapStateToProps)(MapComponent);
