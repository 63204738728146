import React, { FC, useState } from 'react'
import s from './Chat.module.scss'
import { ChatMenu } from './ChatMenu/ChatMenu'
import { ChatMessages } from './ChatMessages/ChatMessages'

export const Chat: FC<propTypes> = ({ setChatOpen, driverID = '' }) => {

    const [menuVisability, setMenuVisability] = useState(true),
        [userID, setUserID] = useState('')

    const onClose = () => {
        setChatOpen(false)
    }

    return (
        <section>
            <div className={s.chatBackground} onClick={onClose} ></div>
            <div className={s.commonContainer}>
                {menuVisability
                    ? <ChatMenu setChatOpen={setChatOpen} setVisability={setMenuVisability} setUserID={setUserID} />
                    : <ChatMessages setVisability={setMenuVisability} setUserID={setUserID} userID={userID} senderID={driverID} />
                }
            </div>
        </section>
    )
}


type propTypes = {
    setChatOpen: (value: boolean) => void
    driverID: string
}