import React, {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";

import "./WaitingWindow.css"
import {RootState} from "../../redux/store";
import {popSystemStatus, setVisibilityOverlay} from "../../redux/reducers/system/actions";
import Button from "../Elements/Button/Button";
import Loader from "react-loader-spinner";
import Overlay from "../Elements/Overlay/Overlay";

const WaitingWindow: React.FC = () => {
    const dispatch = useDispatch();

    const {system} = useSelector((state: RootState) => ({
        system: state.systemReducer
    }));

    const ref = useRef<HTMLInputElement>();

    useEffect(()=>{

        // @ts-ignore
        window.overlay_visible = system.overlay.visible;

        dispatch(setVisibilityOverlay(true))
    }, []);

    const closeWindow = () => {
        dispatch(popSystemStatus());
        // @ts-ignore
        dispatch(setVisibilityOverlay(window.overlay_visible))
    };

    return (
        <>
            <Overlay z_index={135} />
            <div className="waiting_window" >
                <div className="waiting_window_loading">
                    <Loader type="ThreeDots" color="#FF8C22" width={'8rem'} height={'5rem'} />
                </div>
                <Button type="default" size="middle" onClick={closeWindow}>Закрыть</Button>
            </div>
        </>
    )
};

export default WaitingWindow;
