export type Address = {
	address: any;
	latitude: string;
	longitude: string;
}

export type VehicleData = {
	id: string;
	car_type: string;
	body_option: string;
	characteristics: any[];
	car_pass: string;
	hydroboard: string;
	ramp: string;
	length: string;
	width: string;
	height: string;
	address: Address;
	photos: Blob[];
	photo_ids?: string[];
	medical_book: boolean;
	driver_loader: boolean;
	certificate: VehicleCertificate;
	drivers: Driver[];
}

export type VehicleCertificate = {
	license_plate: string;
	vin: string;
	brand_model: string;
	vehicle_type: string;
	category: string;
	build_year: string;
	eco_class: string;
	pts_series: string;
	pts_number: string;
	sts_series: string;
	sts_number: string;
	max_weight: string;
	load_weight: string;
	photos: Blob[];
	photo_ids?: string[];
}

export type Passport = {
	name: string;
	birthdate: Date | null;
	number: string;
	department: string;
	issue_date: Date | null;
	issued_by: string;
	registration: any;
	address: any;
	same_address: boolean;
	photos: Blob[];
	photo_ids?: string[];
}

export type License = {
	number: string;
	issue_date: Date | null;
	issued_by: string;
	country: string;
	categories: string[];
	photos: Blob[];
	photo_ids?: string[];
}

export type Driver = {
	id: string;
	is_owner: boolean;
	passport: Passport;
	license: License;
}

export type Bank = {
	account: string;
	bic: string;
	name: string;
	corr: string;
	inn: string;
	kpp: string;
	address: any;
}

export type Owner = {
	id: string;
	owner_type: string;
	tin_type: string;
	tin: string;
	agree: boolean;
	passport: Passport;
	bank: Bank;
	vehicles: VehicleData[];
	drivers: Driver[];
}

export const emptyPassport = {
	name: '',
	birthdate: null,
	number: '',
	department: '',
	issue_date: null,
	issued_by: '',
	registration: null,
	address: null,
	same_address: false,
	photos: []
}

export const emptyLicense = {
	number: '',
	issue_date: null,
	issued_by: '',
	country: '',
	categories: [],
	photos: []
}

export const emptyCertificate = {
	license_plate: '',
	vin: '',
	brand_model: '',
	vehicle_type: '',
	category: '',
	build_year: '',
	eco_class: '',
	pts_series: '',
	pts_number: '',
	sts_series: '',
	sts_number: '',
	max_weight: '',
	load_weight: '',
	photos: []
}
