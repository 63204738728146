import React, { FC } from 'react'
import s from './ChatMenu.module.scss'
import menuItemArrow from '../../../images/menuItemArrow.svg'
import newMessagesIndicator from '../../../images/newMessagesIndicator.svg'
import { user } from '../../../redux/reducers/chatReducers/@types'
import { useSelector } from 'react-redux'
import { getChatMessagesSelector } from '../../../redux/reducers/chatReducers/chatSelectors'

export const MenuItem: FC<propTypes> = ({ user, address, setVisability, setUserID }) => {

    const messages = useSelector(getChatMessagesSelector)

    const openChatWith = () => {
        setVisability(false)
        setUserID(user.id)
    }

    return (
        <div className={s.menuBadyItemWrapper}>
            <div>
                <div className={s.itemHeader}>{user.type === 'logist' ? 'Логист' : user.type === 'support' ? 'Поддержка' : user.full_name}</div>
                <div className={s.itemDetails}>{user.type === 'support' ? 'Сообщите нам о проблеме' : user.type === 'logist' ? user.full_name : address}</div>
            </div>
            <div className={s.iconWrapper}>
                {messages.filter(message => message.from_user_id === user.id && message.read === false && message.in_out === "in").length ? <img alt='В чате есть непрочтенные сообщения' src={newMessagesIndicator} /> : ''}
                <img alt='Закрыть чат' src={menuItemArrow} onClick={openChatWith} />
            </div>
        </div>
    )
}


type propTypes = {
    user: user
    address: string
    setVisability: (value: boolean) => void
    setUserID: (value: string) => void
}