import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { getChatMessagesSelector, getChatUsersSelector, getOrdersSelector } from '../../../redux/reducers/chatReducers/chatSelectors'
import s from './ChatMenu.module.scss'
import { MenuItem } from './MenuItem'

export const MenuBody: FC<propTypes> = ({ setVisability, setUserID }) => {

    const users = useSelector(getChatUsersSelector),
            orders = useSelector(getOrdersSelector),
            messages = useSelector(getChatMessagesSelector)    

    return (
        <div className={s.menuBadyWrapper}>
            {users.filter(user => user.type === 'support').slice(0, 1).map(user => <MenuItem key={user.id} user={user} setVisability={setVisability} setUserID={setUserID} address='' />)}
            {users
                .filter(user => user.type !== 'support')
                .filter(user => messages.filter(message => message.from_user_id === user.id).length !== 0 && user.type !== 'logist')
                .map(user => 
                        <MenuItem 
                            key={user.id} 
                            user={user} 
                            setVisability={setVisability} 
                            setUserID={setUserID} 
                            address={orders
                                        .filter(order => 
                                                    order.id === user.orderID).length 
                                                    ? orders.filter(order => order.id === user.orderID).map(order => ({id: order.id, address: order.address}))[0].address.split(', ').slice(-2).join(' ').toString() 
                                                    : ''
                                                }  />)}
        </div>
    )
}


type propTypes = {
    setVisability: (value: boolean) => void
    setUserID: (value: string) => void
}