import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {
  popSystemStatus,
  removeSystemStatus,
  setOverlay,
  setVisibilityOverlay
} from "../../redux/reducers/system/actions";

import "moment/locale/ru";
import "./OrderPlayWindow.css";
import {OrderFullInfo} from "../../redux/reducers/orders/@types";
import {ordersAPI} from "../../api";
import {RouteInfo} from "../../redux/reducers/map/@types";
import {setRoutes} from "../../redux/reducers/map/actions";
import SmallPlayWindow from "./SmallPlayWindow/SmallPlayWindow";
import FullPlayWindow from "./FullPlayWindow/FullPlayWindow";
import OnPlacePlayWindow from "./OnPlacePlayWindow/OnPlacePlayWindow";
import SmallOnPlacePlayWindow from "./SmallOnPlacePlayWindow/SmallOnPlacePlayWindow";
import FinishPlayWindow from "./FinishPlayWindow/FinishPlayWindow";
import {SystemStatus} from "../../@types";


interface ILocalState {
  route: RouteInfo | null,
  show_vehicle: boolean,
  show_cargo: boolean,
  show_point_index: number[],
}

enum EnumWindowMode   {
  SmallWindow, FullWindow, OnPlaceWindow, SmallOnPlaceWindow, OnPlaceWindowNext,FinishWindow
}

const OrderPlayWindow: React.FC  = ( ) => {
  const dispatch = useDispatch();

  const { user, orders, system, } = useSelector((state: RootState) => ({
    user: state.userReducer,
    orders: state.ordersReducer,
    system: state.systemReducer
  }));
  const order : OrderFullInfo = orders.current;
  const [selectedMode, setSelectedMode] = useState<EnumWindowMode>(EnumWindowMode.SmallWindow);
  const [currentPoint, setCurrentPoint] = useState<number>(0);


  const { additionalRequirements, bodyOptionCharacteristics, bodyOptionCharacteristicsValues, bodyTypes, carTypes } = orders;

  const [localState, setLocalState] = useState<ILocalState>( {
    route : null,
    show_vehicle: false,
    show_cargo: false,
    show_point_index: []
  } );



  useEffect(()=>{
    if (order && order.id) {
      ordersAPI.getRoute(order.id).then((res)=>{
        if (!res || !res.data) return;
        setLocalState((s) => ({...s, route: res.data }));

      })
    }

    dispatch(setOverlay(false));
  },[]);

  const onPlace = (point:number) =>{

  }

  let order_date = order.date.split('T')[0]+'T'+order.date.split('T')[1];
  return (
    <>
          {selectedMode === EnumWindowMode.FullWindow && <FullPlayWindow order={order} currentPoint={currentPoint}
                                          onShowRoute={()=>dispatch(setRoutes(localState.route))}
                                          onShowMap={()=>{}}
                                          setOnPlace={()=>{setSelectedMode(EnumWindowMode.OnPlaceWindow)}}
                                          onFullMode={()=>{setSelectedMode(EnumWindowMode.SmallWindow)}}
          />}
          {selectedMode === EnumWindowMode.SmallWindow && <SmallPlayWindow order={order} currentPoint={currentPoint}
                              onShowRoute={()=>dispatch(setRoutes(localState.route))}
                              onShowMap={()=>{}}
                              onFullMode={()=>{setSelectedMode(EnumWindowMode.FullWindow)}}
                          /> }
      {selectedMode === EnumWindowMode.OnPlaceWindow &&
          <OnPlacePlayWindow order={order} currentPoint={currentPoint}
                             onGood={()=>{setSelectedMode(EnumWindowMode.SmallOnPlaceWindow)}}
          /> }
     {selectedMode === EnumWindowMode.SmallOnPlaceWindow &&
              <SmallOnPlacePlayWindow order={order} currentPoint={currentPoint}
                                   onFullMode={()=>{setSelectedMode(EnumWindowMode.FullWindow)}}
                                  onTaskComplete={()=>{setSelectedMode(EnumWindowMode.OnPlaceWindowNext)}}

              /> }

      {selectedMode === EnumWindowMode.SmallOnPlaceWindow &&
      <SmallOnPlacePlayWindow order={order} currentPoint={currentPoint}
                              onFullMode={()=>{setSelectedMode(EnumWindowMode.FullWindow)}}
                              onTaskComplete={()=>{setSelectedMode(EnumWindowMode.OnPlaceWindowNext)}}

      /> }
      {selectedMode === EnumWindowMode.OnPlaceWindowNext &&
      <OnPlacePlayWindow order={order} currentPoint={currentPoint}
                              is_next={(currentPoint+1) < order.routes.length}
                              is_finish={(currentPoint+1) === order.routes.length}
                              onGood={()=>{
                                if ((currentPoint+1) < order.routes.length) {
                                  setCurrentPoint(currentPoint + 1);
                                  setSelectedMode(EnumWindowMode.SmallWindow)
                                } else {
                                  setSelectedMode(EnumWindowMode.FinishWindow)
                                }
                              }}

      /> }
      {selectedMode === EnumWindowMode.FinishWindow &&
          <FinishPlayWindow order={order} onClose={() => dispatch(popSystemStatus())}/>
      }
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  state: {
    orderState: state.ordersReducer,
  }
});

export default connect(mapStateToProps, { })(OrderPlayWindow);

