import React, { useRef, useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import InputMask from "react-input-mask";
import Select from "react-dropdown-select";
import Loader from "react-loader-spinner";
import { AddressSuggestions } from "react-dadata";
import { daDataToken } from "../../deployment";
import { RootState } from "../../redux/store";
import { RegistrationStatus, SystemStatus } from "../../@types";
import {
	Driver,
	VehicleData,
	emptyPassport,
	emptyLicense
} from "../Elements/Containers/@types";
import {
	VehicleState,
	SetCarTypeEnteredAction,
	SetBodyOptionEnteredAction,
	SetBodyOptionChsEnteredAction,
	SetCarPassEnteredAction,
	SetHydroboardEnteredAction,
	SetRampEnteredAction,
	SetLengthEnteredAction,
	SetWidthEnteredAction,
	SetHeightEnteredAction,
	SetGarageEnteredAction,
	AddVehiclePhotoAction,
	ReplaceVehiclePhotoAction,
	ClearVehiclePhotosAction,
	SetMedicalBookAction,
	SetDriverLoaderAction,
	SetLicensePlateEnteredAction,
	SetVinEnteredAction,
	SetBrandModelEnteredAction,
	SetVehicleTypeEnteredAction,
	SetVehicleCategoryEnteredAction,
	SetYearEnteredAction,
	SetEcologyEnteredAction,
	SetPtsSeriesEnteredAction,
	SetPtsNumberEnteredAction,
	SetStsSeriesEnteredAction,
	SetStsNumberEnteredAction,
	SetMaxWeightEnteredAction,
	SetLoadWeightEnteredAction,
	AddCertificatePhotoAction,
	ReplaceCertificatePhotoAction,
	ClearCertificatePhotosAction,
	AssignDriverAction,
	DismissDriverAction,
	DismissAllAction
} from "../../redux/reducers/vehicle/@types";
import {
	FinalizeState,
	SetAssigningDriverAction,
	SetIsOwnerAction,
	SetCameraAccessedAction,
	SaveDriverAction,
	SaveVehicleAction,
	UpdateVehicleAction,
	RemoveVehicleAction
} from "../../redux/reducers/finalize/@types";
import {
	OwnerSelectState,
	MULTIPLE_CARS_OWNER
} from "../../redux/reducers/owner_select/@types";
import {
	SetRegistrationStatusAction,
	RestoreRegistrationStatusAction
} from "../../redux/reducers/registration/@types";
import {
	SystemState,
	SetSystemStatusAction
} from "../../redux/reducers/system/@types";
import {
	setCarTypeEntered,
	setBodyOptionEntered,
	setBodyOptionChsEntered,
	setCarPassEntered,
	setHydroboardEntered,
	setRampEntered,
	setLengthEntered,
	setWidthEntered,
	setHeightEntered,
	setGarageEntered,
	addVehiclePhoto,
	replaceVehiclePhoto,
	clearVehiclePhotos,
	setMedicalBook,
	setDriverLoader,
	setLicensePlateEntered,
	setVinEntered,
	setBrandModelEntered,
	setVehicleTypeEntered,
	setVehicleCategoryEntered,
	setYearEntered,
	setEcologyEntered,
	setPtsSeriesEntered,
	setPtsNumberEntered,
	setStsSeriesEntered,
	setStsNumberEntered,
	setMaxWeightEntered,
	setLoadWeightEntered,
	addCertificatePhoto,
	replaceCertificatePhoto,
	clearCertificatePhotos,
	assignDriver,
	dismissDriver,
	dismissAll
} from "../../redux/reducers/vehicle/actions";
import {
	setAssigningDriver,
	setIsOwner,
	setCameraAccessed,
	saveDriver,
	saveVehicle,
	updateVehicle,
	removeVehicle
} from "../../redux/reducers/finalize/actions";
import {
	setRegistrationStatus,
	restoreRegistrationStatus
} from "../../redux/reducers/registration/actions";
import {popSystemStatus, setSystemStatus} from "../../redux/reducers/system/actions";
import { syncData } from "../../redux/reducers/user/actions";
import {
	CancelBtn,
	Checkbox,
	PhotoTaker,
	VehicleDriverContainer
} from "../Elements/elements";
import { generateId, getBoolean } from "../../api/validate";
import PhotoSVG from "../App/svg/photo.svg";
import { ReactSVG } from "react-svg";
import { editingChars } from "../../@types";
import { addressAPI, vehicleAPI } from "../../api";
import "./Vehicle.css";
import { getLastSystemStatus } from "../../redux/reducers/system/reducers";

const vehicleFrames = [
	{
		index: 0,
		title: "Кузов изнутри",
		box: false,
		facing: 'environment',
		width: '100%',
		height: '32%'
	},
	{
		index: 1,
		title: "ТС спереди",
		box: false,
		facing: 'environment',
		width: '100%',
		height: '32%'
	},
	{
		index: 2,
		title: "ТС сбоку",
		box: false,
		facing: 'environment',
		width: '100%',
		height: '32%'
	}
];

const certificateFrames = [
	{
		index: 0,
		title: "Сторона 1",
		box: true,
		facing: 'environment',
		width: '80%',
		height: '45%'
	},
	{
		index: 1,
		title: "Сторона 2",
		box: true,
		facing: 'environment',
		width: '80%',
		height: '45%'
	}
];

interface IProps {
	state: VehicleState,
	finalize: FinalizeState,
	system: SystemState,
	ownerSelect: OwnerSelectState,
	setCarTypeEntered: (car_type_entered: boolean, car_type?: string) => SetCarTypeEnteredAction,
	setBodyOptionEntered: (body_option_entered: boolean, body_option?: string) => SetBodyOptionEnteredAction,
	setBodyOptionChsEntered: (body_option_chs_entered: boolean,
		body_option_chs?: any) => SetBodyOptionChsEnteredAction,
	setCarPassEntered: (car_pass_entered: boolean, car_pass?: string) => SetCarPassEnteredAction,
	setHydroboardEntered: (hydroboard_entered: boolean, hydroboard?: string) => SetHydroboardEnteredAction,
	setRampEntered: (ramp_entered: boolean, ramp?: string) => SetRampEnteredAction,
	setLengthEntered: (length_entered: boolean, length?: string) => SetLengthEnteredAction,
	setWidthEntered: (width_entered: boolean, width?: string) => SetWidthEnteredAction,
	setHeightEntered: (height_entered: boolean, height?: string) => SetHeightEnteredAction,
	setGarageEntered: (garage_entered: boolean, address?: any, latitude?: string,
		longitude?: string) => SetGarageEnteredAction,
	addVehiclePhoto: (photo: Blob) => AddVehiclePhotoAction,
	replaceVehiclePhoto: (index: number, new_photo: Blob) => ReplaceVehiclePhotoAction,
	clearVehiclePhotos: () => ClearVehiclePhotosAction,
	setMedicalBook: (medical_book: boolean) => SetMedicalBookAction,
	setDriverLoader: (driver_loader: boolean) => SetDriverLoaderAction,
	setLicensePlateEntered: (license_plate_entered: boolean, license_plate?: string) => SetLicensePlateEnteredAction,
	setVinEntered: (vin_entered: boolean, vin?: string) => SetVinEnteredAction,
	setBrandModelEntered: (brand_model_entered: boolean, brand_model?: string) => SetBrandModelEnteredAction,
	setVehicleTypeEntered: (vehicle_type_entered: boolean, vehicle_type?: string) => SetVehicleTypeEnteredAction,
	setVehicleCategoryEntered: (vehicle_category_entered: boolean,
		vehicle_category?: string) => SetVehicleCategoryEnteredAction,
	setYearEntered: (year_entered: boolean, year?: string) => SetYearEnteredAction,
	setEcologyEntered: (ecology_entered: boolean, ecology?: string) => SetEcologyEnteredAction,
	setPtsSeriesEntered: (pts_series_entered: boolean, pts_series?: string) => SetPtsSeriesEnteredAction,
	setPtsNumberEntered: (pts_number_entered: boolean, pts_number?: string) => SetPtsNumberEnteredAction,
	setStsSeriesEntered: (sts_series_entered: boolean, sts_series?: string) => SetStsSeriesEnteredAction,
	setStsNumberEntered: (sts_number_entered: boolean, sts_number?: string) => SetStsNumberEnteredAction,
	setMaxWeightEntered: (max_weight_entered: boolean, max_weight?: string) => SetMaxWeightEnteredAction,
	setLoadWeightEntered: (load_weight_entered: boolean, load_weight?: string) => SetLoadWeightEnteredAction,
	addCertificatePhoto: (photo: Blob) => AddCertificatePhotoAction,
	replaceCertificatePhoto: (index: number, new_photo: Blob) => ReplaceCertificatePhotoAction,
	clearCertificatePhotos: () => ClearCertificatePhotosAction,
	assignDriver: (driver: Driver) => AssignDriverAction,
	dismissDriver: (driver: Driver) => DismissDriverAction,
	dismissAll: () => DismissAllAction,
	setAssigningDriver: (assigning_driver: boolean) => SetAssigningDriverAction,
	setIsOwner: (is_owner: boolean) => SetIsOwnerAction,
	setCameraAccessed: (camera_accessed: boolean) => SetCameraAccessedAction,
	saveDriver: (driver: Driver) => SaveDriverAction,
	saveVehicle: (vehicle: VehicleData) => SaveVehicleAction,
	updateVehicle: () => UpdateVehicleAction,
	removeVehicle: () => RemoveVehicleAction,
	setRegistrationStatus: (status: RegistrationStatus | null) => SetRegistrationStatusAction,
	restoreRegistrationStatus: () => RestoreRegistrationStatusAction,
	setSystemStatus: (status: SystemStatus) => SetSystemStatusAction
}

const Vehicle: React.FunctionComponent<IProps> = ({
	state,
	finalize,
	system,
	ownerSelect,
	setCarTypeEntered,
	setBodyOptionEntered,
	setBodyOptionChsEntered,
	setCarPassEntered,
	setHydroboardEntered,
	setRampEntered,
	setLengthEntered,
	setWidthEntered,
	setHeightEntered,
	setGarageEntered,
	addVehiclePhoto,
	replaceVehiclePhoto,
	clearVehiclePhotos,
	setMedicalBook,
	setDriverLoader,
	setLicensePlateEntered,
	setVinEntered,
	setBrandModelEntered,
	setVehicleTypeEntered,
	setVehicleCategoryEntered,
	setYearEntered,
	setEcologyEntered,
	setPtsSeriesEntered,
	setPtsNumberEntered,
	setStsSeriesEntered,
	setStsNumberEntered,
	setMaxWeightEntered,
	setLoadWeightEntered,
	addCertificatePhoto,
	replaceCertificatePhoto,
	clearCertificatePhotos,
	assignDriver,
	dismissDriver,
	dismissAll,
	setAssigningDriver,
	setIsOwner,
	setCameraAccessed,
	saveDriver,
	saveVehicle,
	updateVehicle,
	removeVehicle,
	setRegistrationStatus,
	restoreRegistrationStatus,
	setSystemStatus
}) => {
	const [formState, setFormState] = useState((ownerSelect.owner_type === MULTIPLE_CARS_OWNER ||
		getLastSystemStatus(system.statuses) === SystemStatus.Profile) && finalize.assigning_driver ? 'final' : 'characteristics');
	const garageAddress = useRef<AddressSuggestions>(null);
	const [cameraShown, setCameraShown] = useState(false);
	const [vehicleEditing, setVehicleEditing] = useState(-1);
	const [certificateEditing, setCertificateEditing] = useState(-1);
	const [editing, setEditing] = useState(false);
	const [debugMode, setDebugMode] = useState(false);
	const dispatch = useDispatch();
	const [error, setError] = useState(false);

	const [carTypes, setCarTypes] = useState<any>([]);
	const [bodyOptions, setBodyOptions] = useState<any>([]);
	const [bodyOptionChs, setBodyOptionChs] = useState<any>([]);
	const [bodyOptionChValues, setBodyOptionChValues] = useState<any>([]);
	const [carPasses, setCarPasses] = useState<any>([]);
	const [hydroboardTypes, setHydroboardTypes] = useState<any>([]);
	const [rampTypes, setRampTypes] = useState<any>([]);
	const [loadingCarTypes, setLoadingCarTypes] = useState(false);
	const [loadingBodyOptions, setLoadingBodyOptions] = useState(false);
	const [loadingBodyOptionChs, setLoadingBodyOptionChs] = useState(false);
	const [loadingCarPasses, setLoadingCarPasses] = useState(false);

	useEffect(() => {
		let current = finalize.current_vehicle;
		setCarTypeEntered(current?.car_type !== undefined &&
			current?.car_type !== '', current?.car_type);
		setBodyOptionEntered(current?.body_option !== undefined &&
			current?.body_option !== '', current?.body_option);
		setBodyOptionChsEntered(false, current?.characteristics);
		setCarPassEntered(current?.car_pass !== undefined &&
			current?.car_pass !== '', current?.car_pass);
		setHydroboardEntered(current?.hydroboard !== undefined &&
			current?.hydroboard !== '', current?.hydroboard);
		setRampEntered(current?.ramp !== undefined &&
			current?.ramp !== '', current?.ramp);
		setLengthEntered(current?.length !== undefined &&
			current?.length !== '', current?.length);
		setWidthEntered(current?.width !== undefined &&
			current?.width !== '', current?.width);
		setHeightEntered(current?.height !== undefined &&
			current?.height !== '', current?.height);
		setGarageEntered(current?.address?.address !== undefined &&
			current?.address?.address !== null, current?.address?.address,
			current?.address?.latitude, current?.address?.longitude);
		setMedicalBook(current?.medical_book !== undefined ? current?.medical_book : false);
		setDriverLoader(current?.driver_loader !== undefined ? current?.driver_loader : false);
		clearVehiclePhotos();
		current?.photos?.forEach((photo: Blob) => addVehiclePhoto(photo));
		setLicensePlateEntered(current?.certificate?.license_plate !== undefined &&
			current?.certificate?.license_plate !== '', current?.certificate?.license_plate);
		setVinEntered(current?.certificate?.vin !== undefined &&
			current?.certificate?.vin !== '', current?.certificate?.vin);
		setBrandModelEntered(current?.certificate?.brand_model !== undefined &&
			current?.certificate?.brand_model !== '', current?.certificate?.brand_model);
		setVehicleTypeEntered(current?.certificate?.vehicle_type !== undefined &&
			current?.certificate?.vehicle_type !== '', current?.certificate?.vehicle_type);
		setVehicleCategoryEntered(current?.certificate?.category !== undefined &&
			current?.certificate?.category !== '', current?.certificate?.category);
		setYearEntered(current?.certificate?.build_year !== undefined &&
			current?.certificate?.build_year !== '', current?.certificate?.build_year);
		setEcologyEntered(current?.certificate?.eco_class !== undefined &&
			current?.certificate?.eco_class !== '', current?.certificate?.eco_class);
		setPtsSeriesEntered(current?.certificate?.pts_series !== undefined &&
			current?.certificate?.pts_series !== '', current?.certificate?.pts_series);
		setPtsNumberEntered(current?.certificate?.pts_number !== undefined &&
			current?.certificate?.pts_number !== '', current?.certificate?.pts_number);
		setStsSeriesEntered(current?.certificate?.sts_series !== undefined &&
			current?.certificate?.sts_series !== '', current?.certificate?.sts_series);
		setStsNumberEntered(current?.certificate?.sts_number !== undefined &&
			current?.certificate?.sts_number !== '', current?.certificate?.sts_number);
		setMaxWeightEntered(current?.certificate?.max_weight !== undefined &&
			current?.certificate?.max_weight !== '', current?.certificate?.max_weight);
		setLoadWeightEntered(current?.certificate?.load_weight !== undefined &&
			current?.certificate?.load_weight !== '', current?.certificate?.load_weight);
		clearCertificatePhotos();
		current?.certificate?.photos?.forEach((photo: Blob) => addCertificatePhoto(photo));
		dismissAll();
		if (current?.drivers && current?.drivers.length > 0)
			current?.drivers?.forEach((driver: Driver) => assignDriver(driver));
		if (garageAddress.current)
			garageAddress.current.setInputValue(current?.address?.address?.value);

		setLoadingCarTypes(true);
		vehicleAPI.getCarTypes()
			.then((carTypesResponse) => {
				if (carTypesResponse && carTypesResponse.data) {
					setCarTypes(carTypesResponse.data);
					setLoadingCarTypes(false);
					if (current?.car_type !== undefined && current?.car_type !== '') {
						setLoadingBodyOptions(true);
						vehicleAPI.getBodyOptions(current?.car_type)
							.then((bodyOptionsResponse) => {
								if (bodyOptionsResponse && bodyOptionsResponse.data) {
									setBodyOptions(bodyOptionsResponse.data);
									setLoadingBodyOptions(false);
									if (current?.body_option !== undefined && current?.body_option !== '') {
										setLoadingBodyOptionChs(true);
										setHydroboardTypes([]);
										setRampTypes([]);
										vehicleAPI.getBodyOptionChs(current?.body_option,
											bodyOptionsResponse.data.find((bodyOption: any) => (
												bodyOption.id === current?.body_option)).body_type_id, current?.car_type)
											.then((characteristicsResponse) => {
												if (characteristicsResponse && characteristicsResponse.data) {
													setBodyOptionChs(characteristicsResponse.data);
													let values: any = [];
													characteristicsResponse.data.forEach((ch: any) => {
														if (ch.type === 'ref') {
															vehicleAPI.getBodyOptionChValues(ch.id, current?.car_type)
																.then((valuesResponse) => {
																	if (valuesResponse && valuesResponse.data) {
																		values.push({'id': ch.id, 'values': valuesResponse.data});
																		if (ch.name === 'Гидроборт') {
																			setHydroboardTypes(valuesResponse.data);
																		}
																		if (ch.name === 'Тип пандуса') {
																			setRampTypes(valuesResponse.data);
																		}
																	}
																});
														}
													});
													setBodyOptionChValues(values);
												}
												setLoadingBodyOptionChs(false);
											});
									}
								}
							});
					}
				}
			});
		setLoadingCarPasses(true);
		vehicleAPI.getCarPasses()
			.then((res) => {
				if (res && res.data) {
					setCarPasses(res.data);
				}
				setLoadingCarPasses(false);
			});
	}, []);

	useEffect(() => {
		if (window.location.host.indexOf('localhost') >= 0)
			setDebugMode(true);
	}, []);

	const editVehiclePhotos = (photos: Blob[]) => {
		setCameraAccessed(true);
		if (vehicleEditing >= 0) {
			replaceVehiclePhoto(vehicleEditing, photos[0]);
			setVehicleEditing(-1);
		} else {
			photos.forEach((photo, index) => {
				replaceVehiclePhoto(index, photo);
			});
		}
	}

	const editCertificatePhotos = (photos: Blob[]) => {
		setCameraAccessed(true);
		if (certificateEditing >= 0) {
			replaceCertificatePhoto(certificateEditing, photos[0]);
			setCertificateEditing(-1);
		} else {
			photos.forEach((photo, index) => {
				replaceCertificatePhoto(index, photo);
			});
		}
	}

	const toPassportData = () => {
		setRegistrationStatus(RegistrationStatus.PassportData);
	}

	const toLicenseData = () => {
		setRegistrationStatus(RegistrationStatus.LicenseData);
	}

	const toFinalize = () => {
		setRegistrationStatus(RegistrationStatus.Finalize);
	}

	const toProfile = () => {
		setRegistrationStatus(null);
	}

	const goBack = (remove = false) => {
		switch (getLastSystemStatus(system.statuses)) {
			case SystemStatus.Registration:
				if (remove) removeVehicle();
				toFinalize();
				return;
			case SystemStatus.Profile:
				if (finalize.assigning_driver) {
					setFormState('final');
				} else {
					toProfile();
				}
				return;
			default:
				return;
		}
	}

  return (
		<>
			<CancelBtn onClick={() => {
				if (cameraShown) {
					setCameraShown(false);
					return;
				}
				if (vehicleEditing >= 0) {
					setVehicleEditing(-1);
					return;
				}
				if (certificateEditing >= 0) {
					setCertificateEditing(-1);
					return;
				}
				goBack();
			}} />
			{formState === 'characteristics' &&
				<>
					{vehicleEditing >= 0 || (cameraShown && state.vehicle_photos.length < 3) ?
						<PhotoTaker
							id="vehicle_photo"
							title="Сделайте фотографии ТС"
							editing={vehicleEditing >= 0}
							ask={!finalize.camera_accessed}
							frames={vehicleEditing >= 0 ? [vehicleFrames[vehicleEditing]] : vehicleFrames}
							pictures={vehicleEditing >= 0 ? [state.vehicle_photos[vehicleEditing]] : state.vehicle_photos}
							onChange={(photos: Blob[]) => editVehiclePhotos(photos)}
							onError={() => setCameraShown(false)}
							camera={cameraShown}
						/> :
						<div className="vehicle_window">
							<div className="vehicle_container">
								<div className="vehicle_title">Характеристики ТС</div>
								<div className="vehicle_input_container">
									<div id="vehicle_car_type" className={'vehicle_select' +
										(state.car_type_entered ? ' vehicle_select_complete' : (error ? ' error' : ''))}>
										{carTypes ? null :
											<div className="vehicle_loader">
												<Loader type="Puff" color="#fff" width={'2rem'} height={'2rem'} />
											</div>
										}
										<Select
											options={carTypes || []}
											values={state.car_type !== undefined && state.car_type !== '' ?
												[state.car_type] : []}
											color="transparent"
											className="vehicle_select_color"
											searchable={false}
											labelField="weight_to"
											onChange={() => {}}
											contentRenderer={() => (
												<>
													{state.car_type === undefined || state.car_type === '' ?
														<div className="vehicle_select_placeholder"
															style={{'fontSize': '0.85rem'}}>
															{carTypes ? 'Грузоподъемность' : ''}
														</div> :
														<div className="vehicle_select_text">
															{'До ' + carTypes.find((carType: any) => (
																carType.id === state.car_type))?.weight_to  + ' кг'}
														</div>
													}
												</>
											)}
											dropdownRenderer={({props, methods}) => (
												<>
													{loadingCarTypes ?
														<div className="vehicle_select_nodata">Загрузка...</div> :
														<>
															{props.options.length === 0 ?
																<div className="vehicle_select_nodata">Нет данных</div> :
																<>
																	{props.options.map((item: any, index) => (
																		<span key={'car_type_' + index} tabIndex={1}
																			className="react-dropdown-select-item vehicle_select_item"
																			onClick={() => {
																				methods.addItem(item);
																				if (state.car_type !== item.id)
																					setBodyOptionEntered(false);
																				setCarTypeEntered(true, item.id);
																				setBodyOptionEntered(false, '');
																				setBodyOptionChsEntered(false, []);
																				setHydroboardEntered(false, '');
																				setRampEntered(false, '');
																				setBodyOptionChs([]);
																				setBodyOptionChValues([]);
																				setHydroboardTypes([]);
																				setRampTypes([]);
																				setLoadingBodyOptions(true);
																				vehicleAPI.getBodyOptions(item.id)
																					.then((res) => {
																						if (res && res.data) {
																							setBodyOptions(res.data);
																							setLoadingBodyOptions(false);
																						}
																					});
																			}}>{'До ' + item.weight_to + ' кг'}
																		</span>
																	))}
																</>
															}
														</>
													}	
												</>
											)}
										/>
									</div>
									<div id="vehicle_body_option" className={'vehicle_select' +
										(state.body_option_entered ? ' vehicle_select_complete' : (error ? ' error' : ''))}>
										{loadingBodyOptions ?
											<div className="vehicle_loader">
												<Loader type="Puff" color="#fff" width={'2rem'} height={'2rem'} />
											</div> : null
										}
										<Select
											options={bodyOptions || []}
											values={state.body_option !== undefined && state.body_option !== '' ?
												[state.body_option] : []}
											color="transparent"
											className="vehicle_select_color"
											searchable={false}
											labelField="name"
											onChange={() => {}}
											contentRenderer={() => {
												return (
													<>
														{state.body_option_entered ?
															<div className="vehicle_select_text">
																{bodyOptions.find((option: any) => (
																	option.id === state.body_option))?.name}
															</div> :
															<div className="vehicle_select_placeholder">
																{loadingBodyOptions ? '' : 'Вид кузова'}
															</div>
														}
													</>
												);
											}}
											dropdownRenderer={({props, methods}) => (
												<>
													{loadingBodyOptions ?
														<div className="vehicle_select_nodata">Загрузка...</div> :
														<>
															{props.options.length === 0 ?
																<div className="vehicle_select_nodata">Нет данных</div> :
																<>
																	{props.options.map((item: any, index) => (
																		<span key={'body_option_' + index} tabIndex={1}
																			className="react-dropdown-select-item vehicle_select_item"
																			onClick={() => {
																				methods.addItem(item);
																				setBodyOptionEntered(true, item.id);
																				setLoadingBodyOptionChs(true);
																				setHydroboardTypes([]);
																				setRampTypes([]);
																				vehicleAPI.getBodyOptionChs(item.id, item.body_type_id, state.car_type)
																					.then((res) => {
																						if (res && res.data) {
																							setBodyOptionChs(res.data);
																							setBodyOptionChsEntered(false, res.data);
																							let values: any = [];
																							res.data.forEach((ch: any) => {
																								if (ch.type === 'ref') {
																									vehicleAPI.getBodyOptionChValues(ch.id, state.car_type)
																										.then((r) => {
																											if (r && r.data) {
																												values.push({'id': ch.id, 'values': r.data});
																												if (ch.name === 'Гидроборт') {
																													setHydroboardTypes(r.data);
																												}
																												if (ch.name === 'Тип пандуса') {
																													setRampTypes(r.data);
																												}
																											}
																										});
																								}
																							});
																							setBodyOptionChValues(values);
																						}
																					});
																				setHydroboardEntered(false, '');
																				setRampEntered(false, '');
																				setLoadingBodyOptionChs(false);
																			}}>{item.name}
																		</span>
																	))}
																</>
															}
														</>
													}
												</>
											)}
										/>
									</div>
								</div>
								<div className="vehicle_input_container">
									<div id="vehicle_body_option_chs" className={'vehicle_select' +
										(state.body_option_chs_entered ? ' vehicle_select_complete' : '')}>
										{loadingBodyOptionChs ?
											<div className="vehicle_loader">
												<Loader type="Puff" color="#fff" width={'2rem'} height={'2rem'} />
											</div> : null
										}
										<Select
											options={state.car_type_entered && state.body_option_entered ?
												bodyOptionChs || [] : []}
											values={state.body_option_chs}
											color="transparent"
											className="vehicle_select_color"
											searchable={false}
											labelField="name"
											onChange={() => {}}
											contentRenderer={() => {
												let list = !state.body_option_chs || state.body_option_chs.length === 0 ? '' :
													state.body_option_chs.filter((ch: any) => ch.value !== undefined &&
													ch.value !== null && ch.value !== false && ch.name !== 'Медкнижка' &&
													ch.name !== 'Водитель грузчик' && ch.name !== 'Гидроборт' &&
													ch.name !== 'Тип пандуса').map((ch: any) => (
														ch.type === 'Boolean' ?
															ch.name
														: ch.type === 'number' ?
															ch.name + ': ' + ch.value
														: ch.type === 'ref' ?
															ch.name + ': ' + (bodyOptionChValues &&
															bodyOptionChValues.length > 0 &&
															bodyOptionChValues.find((entry: any) => entry.id === ch.id)?.values
															.find((value: any) => value.id === ch.value).name)
														: ''
													)).filter((value: any) => value && value !== '').join(', ');
													if (list === '') list = 'Характеристики';
												return (
													<div className="vehicle_select_placeholder"
														style={{'fontSize': '1rem'}}>
														{loadingBodyOptionChs ? '' : list}
													</div>
												);
											}}
											dropdownRenderer={({props}) => (
												<>
													{loadingBodyOptionChs ?
														<div className="vehicle_select_nodata">Загрузка...</div> :
														<>
															{props.options.length === 0 ?
																<div className="vehicle_select_nodata">Нет данных</div> :
																<>
																	{props.options.map((item: any, index) => (
																		item.type === 'number' ?
																			<div className="react-dropdown-select-item vehicle_chs_item"
																				key={'option_ch_' + index}>
																				<div className="vehicle_select_input_label">{item.name}:</div>
																				<input type="number" className="vehicle_select_input"
																					value={state.body_option_chs.length > 0 ?
																						state.body_option_chs[index].value : ''}
																					onChange={(e) => {
																						let chs = state.body_option_chs;
																						chs[index].value = e.target.value;
																						setBodyOptionChsEntered(true, chs);
																					}}/>
																			</div> : 
																			((item.type === 'Boolean') && (item.name !== 'Медкнижка') &&
																				(item.name !== 'Водитель грузчик') ?
																				<div className="react-dropdown-select-item vehicle_chs_item"
																					key={'option_ch_' + index}>
																					<table
																						onClick={() => {
																							let chs = state.body_option_chs;
																							chs[index].value = !getBoolean(chs[index].value);
																							setBodyOptionChsEntered(true, chs);
																						}}
																					>
																						<tbody>
																							<tr>
																								<td className="vehicle_select_checkbox_area">
																	 								<Checkbox className="vehicle_select_checkbox"
																	 									checked={state.body_option_chs.length > 0 ?
																	 										(state.body_option_chs[index].value === true ||
																	 										state.body_option_chs[index].value === 'true') : false} />
																	 							</td>
																	 							<td>
																		 							<div className="vehicle_select_label">{item.name}</div>
																		 						</td>
																		 					</tr>
															 							</tbody>
															 						</table>
																				</div> :
																				((item.type === 'ref') && (item.name !== 'Гидроборт') &&
																					(item.name !== 'Тип пандуса') ?
																					<div className="react-dropdown-select-item vehicle_chs_item"
																						key={'option_ch_' + index}>
																						<div className="vehicle_select_input_label">{item.name}:</div>
																						<table>
																							<tbody>
																								{bodyOptionChValues.find((entry: any) => (
																									entry.id === item.id)).values.map((v: any, i: number) => (
																									<tr onClick={() => {
																										let chs = state.body_option_chs;
																										chs[index].value = v.id;
																										setBodyOptionChsEntered(true, chs);
																									}}>
																										<td className="vehicle_select_checkbox_area">
																	 										<Checkbox className="vehicle_select_checkbox"
																	 											checked={state.body_option_chs.length > 0 ?
																	 												state.body_option_chs[index].value === v.id : false} />
																			 							</td>
																			 							<td>
																				 							<div className="vehicle_select_label">{v.name}</div>
																				 						</td>
																				 					</tr>
																				 				))}
																				 			</tbody>
																				 		</table>
																					</div> : null
																				)
																			)
																	))}
																</>
															}
														</>
													}
												</>
											)}
										/>
									</div>
									<div id="vehicle_car_pass" className={'vehicle_select' +
										(state.car_pass_entered ? ' vehicle_select_complete' : '')}>
										{loadingCarPasses && 
											<div className="vehicle_loader">
												<Loader type="Puff" color="#fff" width={'2rem'} height={'2rem'} />
											</div>
										}
										<Select
											options={carPasses ? carPasses.concat([{id: 'none', name: 'Нет пропуска'}]) : []}
											values={state.car_pass !== undefined && state.car_pass !== '' ?
												[state.car_pass] : []}
											color="transparent"
											className="vehicle_select_color"
											searchable={false}
											labelField="name"
											onChange={() => {}}
											contentRenderer={() => (
												<>
													{state.car_pass === undefined || state.car_pass === '' ?
														<div className="vehicle_select_placeholder">
															{carPasses ? 'Пропуск' : ''}
														</div> :
														<div className="vehicle_select_text">
															{state.car_pass === 'none' ? 'Нет пропуска' :
																carPasses.find((carPass: any) => (
																	carPass.id === state.car_pass))?.name
															}
														</div>
													}
												</>
											)}
											dropdownRenderer={({props, state, methods}) => (
												<>
													{loadingCarPasses ?
														<div className="vehicle_select_nodata">Загрузка...</div> :
														<>
															{props.options.length === 0 ?
																<div className="vehicle_select_nodata">Нет данных</div> :
																<>
																	{props.options.map((item: any, index) => (
																		<span key={'car_pass_' + index} tabIndex={1}
																			className="react-dropdown-select-item vehicle_select_item"
																			onClick={() => {
																				methods.addItem(item);
																				setCarPassEntered(true, item.id);
																			}}>{item.name}
																		</span>
																	))}
																</>
															}
														</>
													}	
												</>
											)}
										/>
									</div>
								</div>
								<div className="vehicle_input_container">
									<div id="vehicle_hydroboard" className={'vehicle_select' +
										(state.hydroboard_entered ? ' vehicle_select_complete' : '')}>
										{loadingBodyOptionChs ?
											<div className="vehicle_loader">
												<Loader type="Puff" color="#fff" width={'2rem'} height={'2rem'} />
											</div> : null
										}
										<Select
											options={hydroboardTypes.length > 0 ? hydroboardTypes : []}
											values={[state.hydroboard]}
											labelField="name"
											color="transparent"
											className="vehicle_select_color"
											searchable={false}
											onChange={() => {}}
											contentRenderer={() => (
												<>
													{hydroboardTypes.length === 0 || state.hydroboard === undefined ||
														state.hydroboard === '' ?
														<div className="vehicle_select_placeholder">Гидроборт</div> :
														<div className="vehicle_select_text">
															{'Гидроборт: ' + hydroboardTypes.find((hydroboard: any) => (
																hydroboard.id === state.hydroboard)).name + ' кг'}
														</div>
													}
												</>
											)}
											dropdownRenderer={({props, methods}) => (
												<>
													{loadingBodyOptionChs ?
														<div className="vehicle_select_nodata">Загрузка...</div> :
														<>
															{props.options.length === 0 ?
																<div className="vehicle_select_nodata">Нет данных</div> :
																<>
																	{props.options.map((item: any, index: number) => (
																		<span key={'hydroboard_' + index} tabIndex={1}
																			className="react-dropdown-select-item vehicle_select_item"
																			onClick={() => {
																				methods.addItem(item);
																				setHydroboardEntered(true, item.id);
																			}}>
																			{item.name + ' кг'}
																		</span>
																	))}
																</>
															}
														</>
													}
												</>
											)}
										/>
									</div>
									<div id="vehicle_ramp" className={'vehicle_select' +
										(state.ramp_entered ? ' vehicle_select_complete' : '')}>
										{loadingBodyOptionChs ?
											<div className="vehicle_loader">
												<Loader type="Puff" color="#fff" width={'2rem'} height={'2rem'} />
											</div> : null
										}
										<Select
											options={rampTypes.length > 0 ? rampTypes : []}
											values={[state.ramp]}
											labelField="name"
											color="transparent"
											className="vehicle_select_color"
											searchable={false}
											onChange={() => {}}
											contentRenderer={() => (
												<>
													{state.ramp === undefined || state.ramp === '' ?
														<div className="vehicle_select_placeholder">Под пандус</div> :
														<div className="vehicle_select_text">
															{'Под пандус: ' + state.ramp + ' см'}
														</div>
													}
												</>
											)}
											dropdownRenderer={({props, methods}) => (
												<>
													{state.body_option === undefined || state.body_option === '' ?
														<div className="vehicle_select_nodata">Нет данных</div> :
														<>
															{loadingBodyOptionChs ?
																<div className="vehicle_select_nodata">Загрузка...</div> :
																<>
																	{props.options.map((item: any, index: number) => (
																		<span key={'ramp_' + index} tabIndex={1}
																			className="react-dropdown-select-item vehicle_select_item"
																			onClick={() => {
																				methods.addItem(item);
																				setRampEntered(true, item.name);
																			}}>
																			{item.name + ' см'}
																		</span>
																	))}
																	<span key="ramp_custom" tabIndex={1}
																		className="react-dropdown-select-item vehicle_select_item">
																		<input type="number" className="vehicle_select_input"
																			style={{'marginLeft': '-0.5rem', 'width': '85%'}}
																			placeholder="Иное" onKeyPress={
																				(e: React.KeyboardEvent<HTMLInputElement>) => {
																				let target = e.target as HTMLInputElement;
																				if (e.key === 'Enter' && parseInt(target.value) > 0) {
																					methods.addItem(target.value);
																					setRampEntered(true, target.value);
																				}
																			}}/>
																		<span className="vehicle_select_item">&nbsp;см</span>
																	</span>
																</>
															}
														</>
													}
												</>
											)}
										/>
									</div>
								</div>
								<div className="vehicle_input_container">
									<div id="vehicle_length" className={'vehicle_input vehicle_input_tiny' +
										(state.length_entered ? ' vehicle_input_complete' : (error ? ' error' : ''))}>
										<input className="vehicle_input_text" placeholder="Длина" type="text"
											value={state.length ? state.length.replace('.', ',') : ''} onKeyPress={(e) => {
												let target = e.target as HTMLInputElement;
												if ((target.value.length === 5 && !editingChars.includes(e.key)) ||
													!(/[0-9.,]/.test(e.key))) {
													e.preventDefault();
													return;
												}
												if (['.', ','].includes(e.key)) {
													e.preventDefault();
													if (state.length.indexOf(',') === -1)
														document.execCommand('insertText', false, ',');
												}
											}}
											onChange={(e) => {
												if (parseInt(e.target.value) > 0) {
													setLengthEntered(true, e.target.value);
												} else {
													setLengthEntered(false, '');
												}
											}}
										/>
									</div>
									<div id="vehicle_width" className={'vehicle_input vehicle_input_tiny' +
										(state.width_entered ? ' vehicle_input_complete' : (error ? ' error' : ''))}>
										<input className="vehicle_input_text" placeholder="Ширина" type="text"
											value={state.width ? state.width.replace('.', ',') : ''} onKeyPress={(e) => {
												let target = e.target as HTMLInputElement;
												if ((target.value.length === 5 && !editingChars.includes(e.key)) ||
													!(/[0-9.,]/.test(e.key))) {
													e.preventDefault();
													return;
												}
												if (['.', ','].includes(e.key)) {
													e.preventDefault();
													if (state.width.indexOf(',') === -1)
														document.execCommand('insertText', false, ',');
												}
											}}
											onChange={(e) => {
												if (parseInt(e.target.value) > 0) {
													setWidthEntered(true, e.target.value);
												} else {
													setWidthEntered(false, '');
												}
											}}
										/>
									</div>
									<div id="vehicle_height" className={'vehicle_input vehicle_input_tiny' +
										(state.height_entered ? ' vehicle_input_complete' : (error ? ' error' : ''))}>
										<input className="vehicle_input_text" placeholder="Высота" type="text"
											value={state.height ? state.height.replace('.', ',') : ''} onKeyPress={(e) => {
												let target = e.target as HTMLInputElement;
												if ((target.value.length === 5 && !editingChars.includes(e.key)) ||
													!(/[0-9.,]/.test(e.key))) {
													e.preventDefault();
													return;
												}
												if (['.', ','].includes(e.key)) {
													e.preventDefault();
													if (state.height.indexOf(',') === -1)
														document.execCommand('insertText', false, ',');
												}
											}}
											onChange={(e) => {
												if (parseInt(e.target.value) > 0) {
													setHeightEntered(true, e.target.value);
												} else {
													setHeightEntered(false, '');
												}
											}}
										/>
									</div>
								</div>
								<div id="vehicle_address" className={'vehicle_input' + (state.garage_entered ?
									' vehicle_input_complete' : (error ? ' error' : ''))}>
									<AddressSuggestions
										token={daDataToken}
										ref={garageAddress}
										defaultQuery={state.address ? state.address.value : ''}
										count={5}
										onChange={(suggestion) => {
											if (suggestion && suggestion.value) {
												setGarageEntered(true, suggestion, suggestion.data?.geo_lat || '',
													suggestion.data?.geo_lon || '');
											}
										}}
										inputProps={{className: 'vehicle_input_text', placeholder: 'Адрес гаража', type: 'text',
											onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
												setGarageEntered(false, null, '', '');
											}
										}}
									/>
								</div>
								{navigator.geolocation &&
									<div className="vehicle_geo" onClick={() => {
										navigator.geolocation.getCurrentPosition(async (pos: any) => {
											let response = await addressAPI.getAddressByCoords(pos.coords.latitude,
												pos.coords.longitude);
											if (response && response.data && response.data.suggestions.length > 0) {
												setGarageEntered(true, response.data.suggestions[0],
													response.data.suggestions[0].data.geo_lat,
													response.data.suggestions[0].data.geo_lon);
											}
										});
									}}>
										Моё местоположение
									</div>
								}
								{state.vehicle_photos.length > 0 ?
									<div className="vehicle_photo_container">
										{state.vehicle_photos.map((photo, index) => (
											<img id={'vehicle_photo_' + index} key={'vehicle_photo_' + index} alt=""
												className="vehicle_photo" src={URL.createObjectURL(photo)}
												onClick={() => setVehicleEditing(index)} />
										))}
									</div> :
									<div id="vehicle_photos" className="vehicle_photo_button" onClick={() => setCameraShown(true)}>
										<div className="vehicle_photo_button_text">
											Фотографии ТС<br />
											<ReactSVG src={PhotoSVG} />
										</div>
									</div>
								}
								<table className="vehicle_table">
									<tbody>
										<tr id="vehicle_medical_book" className="vehicle_table_row"
											onClick={() => setMedicalBook(!state.medical_book)}>
											<td className="vehicle_checkbox">
												<Checkbox checked={state.medical_book} />
											</td>
											<td>Есть медкнижка</td>
										</tr>
										<tr id="vehicle_driver_loader" className="vehicle_table_row"
											onClick={() => setDriverLoader(!state.driver_loader)}>
											<td className="vehicle_checkbox">
												<Checkbox checked={state.driver_loader} />
											</td>
											<td>Водитель-грузчик (оплачивается)</td>
										</tr>
									</tbody>
								</table>
								<section className="vehicle_footer">
									<div id="vehicle_remove" className="vehicle_link" onClick={() => { goBack(true); }}>
										{getLastSystemStatus(system.statuses) === SystemStatus.Registration ? 'Удалить ТС' : 'Вернуться назад'}
									</div>
									<div id="vehicle_next" className={'vehicle_button' +
										(state.car_type_entered && state.body_option_entered && state.length_entered &&
										state.width_entered && state.height_entered && state.garage_entered ?
										'' : ' inactive')} onClick={() => {
										setError(false);
										if (!state.car_type_entered || !state.body_option_entered || !state.length_entered ||
											!state.width_entered || !state.height_entered || !state.garage_entered) {
											setError(true);
											return;
										}
										setCameraShown(false);
										setTimeout(setFormState, 500, ownerSelect.owner_type === MULTIPLE_CARS_OWNER &&
											editing && getLastSystemStatus(system.statuses) !== SystemStatus.Profile ? 'final' : 'certificate');
									}}>
						    		<div className="vehicle_button_text">{ownerSelect.owner_type === MULTIPLE_CARS_OWNER &&
						    			editing && getLastSystemStatus(system.statuses) !== SystemStatus.Profile ? 'Сохранить' : 'Далее'}
						    		</div>
						    	</div>
								</section>
							</div>
						</div>
					}
				</>
			}
			{formState === 'certificate' &&
				<>
					{(cameraShown && state.certificate_photos.length < 2) || certificateEditing >= 0 ?
						<PhotoTaker
							id="certificate_photo"
							title="Сделайте фотографии свидетельства"
							editing={certificateEditing >= 0}
							ask={!finalize.camera_accessed}
							frames={certificateEditing >= 0 ?
								[certificateFrames[certificateEditing]] : certificateFrames}
							pictures={certificateEditing >= 0 ?
								[state.certificate_photos[certificateEditing]] : state.certificate_photos}
							onChange={(photos: Blob[]) => editCertificatePhotos(photos)}
							onError={() => setCameraShown(false)}
							camera={cameraShown}
						/> :
						<div className="vehicle_window">
							<div className="vehicle_container">
								<div className="vehicle_title_big">Свидетельство о регистрации</div>
								<div id="vehicle_license_plate" className={'vehicle_input' +
									(state.license_plate_entered ? ' vehicle_input_complete' : (error ? ' error' : ''))}>
									<InputMask mask="a 999 aa 999" value={state.license_plate} onChange={(e) => {
										if (e.target.value !== '' && (e.target.value.indexOf('_') === -1 ||
											e.target.value.indexOf('_') === 11)) {
											setLicensePlateEntered(true, e.target.value);
										} else {
											setLicensePlateEntered(false);
										}
									}}>
										{(inputProps: any) => <input {...inputProps} className="vehicle_input_text"
											placeholder="Регистрационный гос. номер" type="text" onKeyPress={(e) => {
												if (!/[0-9aAbBcCeEhHkKmMoOpPtTxXyY]/.test(e.key)) {
													e.preventDefault();
													return;
												}
												if (/[a-z]/.test(e.key)) {
													e.preventDefault();
													document.execCommand('insertText', false, e.key.toUpperCase());
												}
											}} />
										}
									</InputMask>
								</div>
								<div id="vehicle_vin" className={'vehicle_input' +
									(state.vin_entered ? ' vehicle_input_complete' : (error ? ' error' : ''))}>
									<InputMask mask="*****************" value={state.vin} onChange={(e) => {
										if (e.target.value !== '' && e.target.value.indexOf('_') === -1) {
											setVinEntered(true, e.target.value);
										} else {
											setVinEntered(false);
										}
									}}>
										{(inputProps: any) => <input {...inputProps} className="vehicle_input_text"
											placeholder="Номер VIN" type="text" onKeyPress={(e) => {
												if (!/[0-9aAbBcCdDeEfFgGhHjJkKlLmMnNoOpPrRsStTuUvVwWxXyYzZ]/.test(e.key)) {
													e.preventDefault();
													return;
												}
												if (/[a-z]/.test(e.key)) {
													e.preventDefault();
													document.execCommand('insertText', false, e.key.toUpperCase());
												}
											}} />
										}
									</InputMask>
								</div>
								<div id="vehicle_brand_model" className={'vehicle_input' +
									(state.brand_model_entered ? ' vehicle_input_complete' : (error ? ' error' : ''))}>
									<input className="vehicle_input_text" placeholder="Марка и модель ТС"
										type="text" value={state.brand_model} onChange={(e) => {
										if (e.target.value.length >= 3) {
											setBrandModelEntered(true, e.target.value);
										} else {
											setBrandModelEntered(false);
										}
									}} />
								</div>
								<div className="vehicle_input_container">
									<div id="vehicle_type" className={'vehicle_input vehicle_input_tiny' +
										(state.vehicle_type_entered ? ' vehicle_input_complete' : (error ? ' error' : ''))}>
										<input className="vehicle_input_text" placeholder="Тип ТС"
											type="text" value={state.vehicle_type} onChange={(e) => {
											if (e.target.value.length >= 5) {
												setVehicleTypeEntered(true, e.target.value);
											} else {
												setVehicleTypeEntered(false);
											}
										}} />
									</div>
									<div id="vehicle_category" className={'vehicle_input vehicle_input_tiny' +
										(state.vehicle_category_entered ? ' vehicle_input_complete' : '')}>
										<input className="vehicle_input_text" placeholder="Категория"
											type="text" value={state.vehicle_category} onChange={(e) => {
											if (e.target.value.length >= 5) {
												setVehicleCategoryEntered(true, e.target.value);
											} else {
												setVehicleCategoryEntered(false);
											}
										}} />
									</div>
									<div id="vehicle_year" className={'vehicle_input vehicle_input_tiny' +
										(state.year_entered ? ' vehicle_input_complete' : (error ? ' error' : ''))}>
										<input className="vehicle_input_text small" placeholder="Год выпуска"
											type="number" value={state.year} onKeyPress={(e) => {
												let target = e.target as HTMLInputElement;
												if (target.value.length === 4 && !editingChars.includes(e.key)) {
													e.preventDefault();
												}
											}}
											onChange={(e) => {
											if (parseInt(e.target.value) >= 2000 &&
												parseInt(e.target.value) <= new Date().getFullYear()) {
												setYearEntered(true, e.target.value);
											} else {
												setYearEntered(false);
											}
										}} />
									</div>
								</div>
								<div id="vehicle_ecology" className={'vehicle_input vehicle_select_wrapper' +
									(state.ecology_entered ? ' vehicle_input_complete' : (error ? ' error' : ''))}>
									<Select
										options={["1", "2", "3", "4", "5"]}
										placeholder="Экологический класс"
										searchable={false}
										labelField="value"
										valueField="value"
										values={[state.ecology]}
										onChange={(values) => {
											if (values.length !== 0) {
												setEcologyEntered(true, values[0]);
											} else {
												setEcologyEntered(false);
											}
										}}
										contentRenderer={() => (
											<>
												{state.ecology === undefined || state.ecology === '' ?
													<div className="vehicle_select_placeholder" style={{'color': '#888e99'}}>
														Экологический класс
													</div> :
													<div className="vehicle_input_text" style={{'margin': '0'}}>{state.ecology}</div>
												}
											</>
										)}
										dropdownRenderer={({props, methods}) => (
											<>
												{props.options.map((item: any, index) => (
													<span key={'ecology_' + index} tabIndex={1}
														className="react-dropdown-select-item vehicle_select_item"
														onClick={() => {
															methods.addItem(item);
														}}
													>{item}</span>
												))}	
											</>
										)}
									/>
								</div>
								<div className="vehicle_input_container">
									<div id="vehicle_pts_series" className={'vehicle_input vehicle_input_short' +
										(state.pts_series_entered ? ' vehicle_input_complete' : '')}>
										<InputMask mask="99 XX" formatChars={{'9': '[0-9]', 'X': '[0-9A-Za-zА-Яа-я]'}}
											value={state.pts_series} onChange={(e) => {
											if (e.target.value !== '' && e.target.value.indexOf('_') === -1) {
												setPtsSeriesEntered(true, e.target.value);
											} else {
												setPtsSeriesEntered(false);
											}
										}}>
											{(inputProps: any) => <input {...inputProps} className="vehicle_input_text"
												placeholder="Серия ПТС" type="text" onKeyPress={(e) => {
													if (/[a-zа-я]/.test(e.key)) {
														e.preventDefault();
														document.execCommand('insertText', false, e.key.toUpperCase());
													}
												}} />
											}
										</InputMask>
									</div>
									<div id="vehicle_pts_number" className={'vehicle_input vehicle_input_short' +
										(state.pts_number_entered ? ' vehicle_input_complete' : '')}>
										<InputMask mask="999999" value={state.pts_number} onChange={(e) => {
											if (e.target.value !== '' && e.target.value.indexOf('_') === -1) {
												setPtsNumberEntered(true, e.target.value);
											} else {
												setPtsNumberEntered(false);
											}
										}}>
											{(inputProps: any) => <input {...inputProps} className="vehicle_input_text"
												placeholder="Номер ПТС" type="text" />
											}
										</InputMask>
									</div>
								</div>
								<div className="vehicle_input_container">
									<div id="vehicle_sts_series" className={'vehicle_input vehicle_input_short' +
										(state.sts_series_entered ? ' vehicle_input_complete' : (error ? ' error' : ''))}>
										<InputMask mask="99 XX" formatChars={{'9': '[0-9]', 'X': '[0-9A-Za-zА-Яа-я]'}}
											value={state.sts_series} onChange={(e) => {
											if (e.target.value !== '' && e.target.value.indexOf('_') === -1) {
												setStsSeriesEntered(true, e.target.value);
											} else {
												setStsSeriesEntered(false);
											}
										}}>
											{(inputProps: any) => <input {...inputProps} className="vehicle_input_text"
												placeholder="Серия СТС" type="text" onKeyPress={(e) => {
													if (/[a-zа-я]/.test(e.key)) {
														e.preventDefault();
														document.execCommand('insertText', false, e.key.toUpperCase());
													}
												}} />
											}
										</InputMask>
									</div>
									<div id="vehicle_sts_number" className={'vehicle_input vehicle_input_short' +
										(state.sts_number_entered ? ' vehicle_input_complete' : (error ? ' error' : ''))}>
										<InputMask mask="999999" value={state.sts_number} onChange={(e) => {
											if (e.target.value !== '' && e.target.value.indexOf('_') === -1) {
												setStsNumberEntered(true, e.target.value);
											} else {
												setStsNumberEntered(false);
											}
										}}>
											{(inputProps: any) => <input {...inputProps} className="vehicle_input_text"
												placeholder="Номер СТС" type="text" />
											}
										</InputMask>
									</div>
								</div>
								<div className="vehicle_input_container">
									<div id="vehicle_max_weight" className={'vehicle_input vehicle_input_short' +
										(state.max_weight_entered ? ' vehicle_input_complete' : (error ? ' error' : ''))}>
										<input className="vehicle_input_text small" placeholder="Разрешенная макс. масса"
											type="number" value={state.max_weight} onKeyPress={(e) => {
												let target = e.target as HTMLInputElement;
												if (target.value.length === 5 && !editingChars.includes(e.key)) {
													e.preventDefault();
												}
											}}
											onChange={(e) => {
											if (parseInt(e.target.value) >= 0) {
												setMaxWeightEntered(true, e.target.value);
											} else {
												setMaxWeightEntered(false);
											}
										}} />
									</div>
									<div id="vehicle_load_weight" className={'vehicle_input vehicle_input_short' +
										(state.load_weight_entered ? ' vehicle_input_complete' : (error ? ' error' : ''))}>
										<input className="vehicle_input_text small" placeholder="Снаряженная масса"
											type="number" value={state.load_weight} onKeyPress={(e) => {
												let target = e.target as HTMLInputElement;
												if (target.value.length === 5 && !editingChars.includes(e.key)) {
													e.preventDefault();
												}
											}}
											onChange={(e) => {
											if (parseInt(e.target.value) >= 0) {
												setLoadWeightEntered(true, e.target.value);
											} else {
												setLoadWeightEntered(false);
											}
										}} />
									</div>
								</div>
								{state.certificate_photos.length > 0 ?
									<div className="vehicle_photo_container">
										{state.certificate_photos.map((photo, index) => (
											<img id={'certificate_photo_' + index} key={'certificate_photo_' + index} alt=""
												className="vehicle_photo" src={URL.createObjectURL(photo)}
												onClick={() => setCertificateEditing(index)} />
										))}
									</div> :
									<div id="certificate_photos" className={'vehicle_photo_button' + (error ? ' error' : '')}
										onClick={() => setCameraShown(true)}>
										<div className="vehicle_photo_button_text">
											Фотографии свидетельства<br />
											<ReactSVG src={PhotoSVG} />
										</div>
									</div>
								}
								<section className="vehicle_footer">
									<div id="vehicle_back" className="vehicle_link" onClick={() => {
										if (getLastSystemStatus(system.statuses) === SystemStatus.Profile) {
											setFormState(finalize.assigning_driver ? 'final' : 'characteristics');
										} else {
											if (editing) removeVehicle();
											toFinalize();
										}
									}}>
										{editing && getLastSystemStatus(system.statuses) !== SystemStatus.Profile ?
											'Удалить ТС' : 'Вернуться назад'}
									</div>
									<div id="vehicle_save" className={'vehicle_button' +
										(state.license_plate_entered && state.vin_entered && state.brand_model_entered &&
										state.vehicle_type_entered && state.year_entered && state.ecology_entered &&
										state.sts_series_entered && state.sts_number_entered &&
										state.max_weight_entered && state.load_weight_entered && (debugMode ||
										state.certificate_photos.length === 2) ?
										'' : ' inactive')} onClick={() => {
											setError(false);
											if (!state.license_plate_entered || !state.vin_entered || !state.brand_model_entered ||
												!state.vehicle_type_entered || !state.year_entered || !state.ecology_entered ||
												!state.sts_series_entered || !state.sts_number_entered ||
												!state.max_weight_entered || !state.load_weight_entered ||
												(!debugMode && state.certificate_photos.length !== 2)) {
												setError(true);
												return;
											}
											saveVehicle({
												id: finalize.current_vehicle.id === '' ? generateId() :finalize.current_vehicle.id,
												car_type: state.car_type,
												body_option: state.body_option,
												characteristics: state.body_option_chs,
												car_pass: state.car_pass,
												hydroboard: state.hydroboard,
												ramp: state.ramp,
												length: state.length,
												width: state.width,
												height: state.height,
												address: {
													address: state.address,
													latitude: state.latitude,
													longitude: state.longitude
												},
												photos: state.vehicle_photos,
												medical_book: state.medical_book,
												driver_loader: state.driver_loader,
												certificate: {
													license_plate: (state.license_plate[11] === '_' ?
														state.license_plate.slice(0, 11) : state.license_plate),
													vin: state.vin,
													brand_model: state.brand_model,
													vehicle_type: state.vehicle_type,
													category: state.vehicle_category,
													build_year: state.year,
													eco_class: state.ecology,
													pts_series: state.pts_series,
													pts_number: state.pts_number,
													sts_series: state.sts_series,
													sts_number: state.sts_number,
													max_weight: state.max_weight,
													load_weight: state.load_weight,
													photos: state.certificate_photos
												},
												drivers: ownerSelect.owner_type === MULTIPLE_CARS_OWNER ?
													state.drivers : [finalize.current_driver]
											});
											updateVehicle();
											setCameraShown(false);
											setEditing(true);
											if (getLastSystemStatus(system.statuses) === SystemStatus.Profile ||
												ownerSelect.owner_type === MULTIPLE_CARS_OWNER) {
												setAssigningDriver(true);
												setTimeout(setFormState, 500, 'final');
											} else {
												setTimeout(toFinalize, 500);
											}
										}}>
						    		<div className="vehicle_button_text">Сохранить</div>
						    	</div>
								</section>
							</div>
						</div>
					}
				</>
			}
			{formState === 'final' &&
				<>
					<div className="vehicle_final_window">
						<div className="vehicle_final_container">
							<div className="vehicle_final_back" onClick={() => {
								if (getLastSystemStatus(system.statuses) === SystemStatus.Profile) {
									toProfile();
								} else {
									toFinalize();
								}
							}}></div>
							<div className="vehicle_final_title">
								<div className="vehicle_final_title_name">
									{state.brand_model + ' ' + state.license_plate.replace('_', '')
										.replace(/ /g, '')}
								</div>
								<div className="vehicle_final_title_type"
									style={error && state.drivers.length === 0 ? {color: 'red'} : {}}>
									{state.drivers.length === 0 ?
										`Не назначены водители` : `Назначено водителей: ${state.drivers.length}`}
								</div>
							</div>
							<VehicleDriverContainer
								id="assign_drivers"
								drivers={finalize.owner.drivers}
								assigned={state.drivers}
								onAddDriver={() => {
									setIsOwner(false);
									setAssigningDriver(true);
									saveDriver({
										id: generateId(),
										is_owner: false,
										passport: emptyPassport,
										license: emptyLicense
									});
									setTimeout(toPassportData, 500);
								}}
								onAssignSelf={() => {
									setIsOwner(true);
									setAssigningDriver(true);
									saveDriver({
										id: finalize.owner.id,
										is_owner: true,
										passport: finalize.owner.passport,
										license: emptyLicense
									});
									setTimeout(toLicenseData, 500);
								}}
								onToggleDriver={(index) => {
									if (state.drivers.findIndex((driver) => (
										driver.id === finalize.owner.drivers[index].id)) >= 0) {
										dismissDriver(finalize.owner.drivers[index]);
									} else {
										assignDriver(finalize.owner.drivers[index]);
									}
								}}
							/>
							<div className="vehicle_final_container_wrapper">
								<div id="final_characteristics" className="vehicle_final_container_item"
									onClick={() => {
										setEditing(true);
										setTimeout(setFormState, 1000, 'characteristics');
									}}>
									<div className="vehicle_final_container_item_text">Характеристики ТС</div>
									<div className="vehicle_final_expand"></div>
								</div>
								<div id="final_certificate" className="vehicle_final_container_item"
									onClick={() => {
										setEditing(true);
										setTimeout(setFormState, 1000, 'certificate');
									}}>
									<div className="vehicle_final_container_item_text">Свидетельство о регистрации</div>
									<div className="vehicle_final_expand"></div>
								</div>
							</div>
							<div className="vehicle_footer">
								<div id="vehicle_final_save" className={'vehicle_button' +
									(state.drivers.length > 0 ? '' : ' inactive')} onClick={() => {
										setError(false);
										if (state.drivers.length === 0) {
											setError(true);
											return;
										}
										saveVehicle({
											id: finalize.current_vehicle.id,
											car_type: state.car_type,
											body_option: state.body_option,
											characteristics: state.body_option_chs,
											car_pass: state.car_pass,
											hydroboard: state.hydroboard,
											ramp: state.ramp,
											length: state.length,
											width: state.width,
											height: state.height,
											address: {
												address: state.address,
												latitude: state.latitude,
												longitude: state.longitude
											},
											photos: state.vehicle_photos,
											medical_book: state.medical_book,
											driver_loader: state.driver_loader,
											certificate: {
												license_plate: (state.license_plate[11] === '_' ?
													state.license_plate.slice(0, 11) : state.license_plate),
												vin: state.vin,
												brand_model: state.brand_model,
												vehicle_type: state.vehicle_type,
												category: state.vehicle_category,
												build_year: state.year,
												eco_class: state.ecology,
												pts_series: state.pts_series,
												pts_number: state.pts_number,
												sts_series: state.sts_series,
												sts_number: state.sts_number,
												max_weight: state.max_weight,
												load_weight: state.load_weight,
												photos: state.certificate_photos
											},
											drivers: state.drivers
										});
										updateVehicle();
										setCameraShown(false);
										if (getLastSystemStatus(system.statuses) === SystemStatus.Profile) dispatch(syncData());
										setTimeout(getLastSystemStatus(system.statuses) === SystemStatus.Profile ?
											toProfile : toFinalize, 500);
									}}>
						    <div className="vehicle_button_text">Сохранить</div>
						   </div>
							</div>
						</div>
					</div>
				</>
			}
		</>
	)
}

const mapStateToProps = (state: RootState) => ({
	state: state.vehicleReducer,
	finalize: state.finalizeReducer,
	system: state.systemReducer,
	ownerSelect: state.ownerSelectReducer
});

export default connect(mapStateToProps, {
	setCarTypeEntered,
	setBodyOptionEntered,
	setBodyOptionChsEntered,
	setCarPassEntered,
	setHydroboardEntered,
	setRampEntered,
	setLengthEntered,
	setWidthEntered,
	setHeightEntered,
	setGarageEntered,
	addVehiclePhoto,
	replaceVehiclePhoto,
	clearVehiclePhotos,
	setMedicalBook,
	setDriverLoader,
	setLicensePlateEntered,
	setVinEntered,
	setBrandModelEntered,
	setVehicleTypeEntered,
	setVehicleCategoryEntered,
	setYearEntered,
	setEcologyEntered,
	setPtsSeriesEntered,
	setPtsNumberEntered,
	setStsSeriesEntered,
	setStsNumberEntered,
	setMaxWeightEntered,
	setLoadWeightEntered,
	addCertificatePhoto,
	replaceCertificatePhoto,
	clearCertificatePhotos,
	assignDriver,
	dismissDriver,
	dismissAll,
	setIsOwner,
	setAssigningDriver,
	setCameraAccessed,
	saveDriver,
	saveVehicle,
	updateVehicle,
	removeVehicle,
	setRegistrationStatus,
	restoreRegistrationStatus,
	setSystemStatus
})(Vehicle);
