import {
	SET_QUICK_NAME_ENTERED,
	SET_QUICK_NUMBER_ENTERED,
	SET_QUICK_ISSUE_DATE_ENTERED,
	SET_QUICK_ISSUED_BY_ENTERED,
	SET_QUICK_BRAND_MODEL_ENTERED,
	SET_QUICK_LICENSE_PLATE_ENTERED,
	SET_QUICK_AGREE,
	SetQuickNameEnteredAction,
	SetQuickNumberEnteredAction,
	SetQuickIssueDateEnteredAction,
	SetQuickIssuedByEnteredAction,
	SetQuickBrandModelEnteredAction,
	SetQuickLicensePlateEnteredAction,
	SetQuickAgreeAction
} from "./@types";

export const setQuickNameEntered = (name_entered: boolean, name?: string):
	SetQuickNameEnteredAction => ({
  type: SET_QUICK_NAME_ENTERED,
  name_entered,
  name
});

export const setQuickNumberEntered = (number_entered: boolean, number?: string):
	SetQuickNumberEnteredAction => ({
  type: SET_QUICK_NUMBER_ENTERED,
  number_entered,
  number
});

export const setQuickIssueDateEntered = (issue_date_entered: boolean, issue_date?: Date | null):
	SetQuickIssueDateEnteredAction => ({
  type: SET_QUICK_ISSUE_DATE_ENTERED,
  issue_date_entered,
  issue_date
});

export const setQuickIssuedByEntered = (issued_by_entered: boolean, issued_by?: string):
	SetQuickIssuedByEnteredAction => ({
  type: SET_QUICK_ISSUED_BY_ENTERED,
  issued_by_entered,
  issued_by
});

export const setQuickBrandModelEntered = (brand_model_entered: boolean, brand_model?: string):
	SetQuickBrandModelEnteredAction => ({
  type: SET_QUICK_BRAND_MODEL_ENTERED,
  brand_model_entered,
  brand_model
});

export const setQuickLicensePlateEntered = (license_plate_entered: boolean, license_plate?: string):
	SetQuickLicensePlateEnteredAction => ({
  type: SET_QUICK_LICENSE_PLATE_ENTERED,
  license_plate_entered,
  license_plate
});

export const setQuickAgree = (agree: boolean): SetQuickAgreeAction => ({
  type: SET_QUICK_AGREE,
  agree
});
