import React, { FC } from 'react'
import s from './ChatMessages.module.scss'
import startForOwnMessage from '../../../images/startForOwnMessage.svg'

export const OwnMessage: FC<propTypes> = ({ message, datetime }) => {
    
    const day = new Date().getDay(),
        date = datetime.split('T')[0].split('-'),
        time = datetime.split('T')[1].split(':')

    time.pop()

    return (
        <div className={s.ownMessageWrapper}>
            <div className={s.ownMessage}>
                <div>
                    <div className={s.messageText}>
                        {message}
                    </div>
                    <div className={s.messageTime}>
                        {`${(date[2] === day.toString()) ? date.slice().reverse().join('-') : ''} ${time.join(':')}`}
                    </div>
                </div>
                <img alt='' src={startForOwnMessage} />
            </div>
        </div>
    )
}


type propTypes = {
    message: string
    datetime: string
}