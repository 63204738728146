import React from "react";
import "./Response.css";

interface IProps {
	isError: boolean;
	name?: string;
}

const Response: React.FunctionComponent<IProps> = (props) => {

	return (
		<div className="response_window">
			<div className="response_container">
				{props.isError ?
					<>
						<div className="response_error"></div>
						<div className="response_error_text">Ошибка</div>
						<div className="response_error_message">Перепроверьте<br />введенные данные</div>
					</> :
					<>
						<div className="response_success"></div>
						<div className="response_success_text">Регистрация завершена!</div>
						<div className="response_success_message">Добро пожаловать,<br />
							{props.name}!
						</div>
					</>
				}
			</div>
		</div>
	);
}

export default Response;
