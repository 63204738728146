import React, { FC } from 'react'
import s from './ChatMenu.module.scss'
import closeIcon from '../../../images/closeIcon.svg'

export const MenuHeader: FC<propTypes> = ({ setChatOpen }) => {

    const closeChat = () => {
        setChatOpen(false)
    }

    return (
        <div className={s.menuHeaderWrapper}>
            <div>
                <div className={s.header}>Чаты</div>
            </div>
            <img alt='Закрыть чат' src={closeIcon} onClick={closeChat} />
        </div>
    )
}


type propTypes = {
    setChatOpen: (value: boolean) => void
}