import React, { useRef, useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import InputMask from "react-input-mask";
import DatePicker from "react-date-picker";
import { RootState } from "../../redux/store";
import { RegistrationStatus, SystemStatus } from "../../@types";
import {
	Owner,
	Driver
} from "../Elements/Containers/@types";
import {
	PassportDataState,
	SetNameEnteredAction,
	SetBirthdateEnteredAction,
	SetNumberEnteredAction,
	SetDepartmentEnteredAction,
	SetIssueDateEnteredAction,
	SetIssuedByEnteredAction,
	SetRegistrationEnteredAction,
	SetAddressEnteredAction,
	AddPhotoAction,
	ReplacePhotoAction,
	ClearPhotosAction
} from "../../redux/reducers/passport_data/@types";
import {
	FinalizeState,
	SetCameraAccessedAction,
	SaveOwnerAction,
	SaveDriverAction
} from "../../redux/reducers/finalize/@types";
import {
	OwnerSelectState,
	MULTIPLE_CARS_OWNER
} from "../../redux/reducers/owner_select/@types";
import {
	SetRegistrationStatusAction,
	RestoreRegistrationStatusAction
} from "../../redux/reducers/registration/@types";
import {
	SystemState,
	SetSystemStatusAction
} from "../../redux/reducers/system/@types";
import { UserState } from "../../redux/reducers/user/@types";
import {
	setNameEntered,
	setBirthdateEntered,
	setNumberEntered,
	setDepartmentEntered,
	setIssueDateEntered,
	setIssuedByEntered,
	setRegistrationEntered,
	setAddressEntered,
	addPhoto,
	replacePhoto,
	clearPhotos
} from "../../redux/reducers/passport_data/actions";
import {
	setCameraAccessed,
	saveOwner,
	saveDriver
} from "../../redux/reducers/finalize/actions";
import {
	setRegistrationStatus,
	restoreRegistrationStatus
} from "../../redux/reducers/registration/actions";
import { popSystemStatus, setSystemStatus } from "../../redux/reducers/system/actions";
import { setAuthenticated, syncData } from "../../redux/reducers/user/actions";
import { CancelBtn, Checkbox, PhotoTaker, DaData } from "../Elements/elements";
import PhotoSVG from "../App/svg/photo.svg";
import { ReactSVG } from "react-svg";
import { addressAPI } from "../../api";
import {
	validateName,
	validateOrg,
	validateDate,
	generateId
} from "../../api/validate";
import { editingChars } from "../../@types";
import "./PassportData.css";
import { getLastSystemStatus } from "../../redux/reducers/system/reducers";

const passportFrames = [
	{
		index: 0,
		title: "Разворот",
		box: true,
		facing: 'environment',
		width: '80%',
		height: '45%'
	},
	{
		index: 1,
		title: "Прописка",
		box: true,
		facing: 'environment',
		width: '62%',
		height: '37%'
	},
	{
		index: 2,
		title: "Фото с паспортом в руке",
		box: true,
		facing: 'user',
		width: '90%',
		height: '45%'
	}
];

interface IProps {
  state: PassportDataState,
  finalize: FinalizeState,
  system: SystemState,
  user: UserState,
  ownerSelect: OwnerSelectState,
  setNameEntered: (name_entered: boolean, name?: string) => SetNameEnteredAction,
  setBirthdateEntered: (birthdate_entered: boolean, birthdate?: Date | null) => SetBirthdateEnteredAction,
  setNumberEntered: (number_entered: boolean, number?: string) => SetNumberEnteredAction,
  setDepartmentEntered: (department_entered: boolean, department?: string) => SetDepartmentEnteredAction,
  setIssueDateEntered: (issue_date_entered: boolean, issue_date?: Date | null) => SetIssueDateEnteredAction,
  setIssuedByEntered: (issued_by_entered: boolean, issued_by?: string) => SetIssuedByEnteredAction,
  setRegistrationEntered: (registration_entered: boolean, registration?: any) => SetRegistrationEnteredAction,
  setAddressEntered: (address_entered: boolean, same_address?: boolean, address?: any) => SetAddressEnteredAction,
  addPhoto: (photo: Blob) => AddPhotoAction,
  replacePhoto: (index: number, new_photo: Blob) => ReplacePhotoAction,
  clearPhotos: () => ClearPhotosAction,
  setCameraAccessed: (camera_accessed: boolean) => SetCameraAccessedAction,
  saveOwner: (owner: Owner) => SaveOwnerAction,
	saveDriver: (driver: Driver) => SaveDriverAction,
	setRegistrationStatus: (status: RegistrationStatus | null) => SetRegistrationStatusAction,
	restoreRegistrationStatus: () => RestoreRegistrationStatusAction,
	setSystemStatus: (status: SystemStatus) => SetSystemStatusAction
}

const PassportData: React.FunctionComponent<IProps> = ({
	state,
	finalize,
	system,
	user,
	ownerSelect,
	setNameEntered,
	setBirthdateEntered,
	setNumberEntered,
	setDepartmentEntered,
	setIssueDateEntered,
	setIssuedByEntered,
	setRegistrationEntered,
	setAddressEntered,
	addPhoto,
	replacePhoto,
	clearPhotos,
	setCameraAccessed,
	saveOwner,
	saveDriver,
	setRegistrationStatus,
	restoreRegistrationStatus,
	setSystemStatus
}) => {
	const registration = useRef<HTMLInputElement>(null);
	const registrationHouse = useRef<HTMLInputElement>(null);
	const address = useRef<HTMLInputElement>(null);
	const addressHouse = useRef<HTMLInputElement>(null);
	const [cameraShown, setCameraShown] = useState(false);
	const [editing, setEditing] = useState(-1);
	const [debugMode, setDebugMode] = useState(false);
	const dispatch = useDispatch();
	const [error, setError] = useState(false);

	const handleChange = (photos: Blob[]) => {
		setCameraAccessed(true);
		if (editing >= 0) {
			replacePhoto(editing, photos[0]);
			setEditing(-1);
		} else {
			photos.forEach((photo, index) => {
				replacePhoto(index, photo);
			});
		}
	}

	useEffect(() => {
		const current = finalize.is_owner ? finalize.owner : finalize.current_driver;
		setNameEntered(current?.passport?.name !== undefined &&
			current?.passport?.name !== '', current?.passport?.name);
		setBirthdateEntered(current?.passport?.birthdate !== undefined &&
			current?.passport?.birthdate !== null, current?.passport?.birthdate);
		setNumberEntered(current?.passport?.number !== undefined &&
			current?.passport?.number !== '', current?.passport?.number);
		setDepartmentEntered(current?.passport?.department !== undefined &&
			current?.passport?.department !== '', current?.passport?.department);
		setIssueDateEntered(current?.passport?.issue_date !== undefined &&
			current?.passport?.issue_date !== null, current?.passport?.issue_date);
		setIssuedByEntered(current?.passport?.issued_by !== undefined &&
			current?.passport?.issued_by !== '', current?.passport?.issued_by);
		setRegistrationEntered(current?.passport?.registration !== undefined &&
			current?.passport?.registration !== null, current?.passport?.registration);
		setAddressEntered(current?.passport?.address !== undefined &&
			current?.passport?.address !== null, current?.passport?.same_address,
			current?.passport?.address);
		clearPhotos();
		current?.passport?.photos?.forEach((photo) => addPhoto(photo));
		let reg = current?.passport?.registration && current?.passport?.registration !== undefined &&
			current?.passport?.registration.data ? current?.passport?.registration.data.country +
			(current?.passport?.registration.data.city_fias_id ? ', ' +
			current?.passport?.registration.data.city_with_type :
			current?.passport?.registration.data.settlement_fias_id ? ', ' +
			current?.passport?.registration.data.settlement_with_type : '') +
			(current?.passport?.registration.data.street_fias_id ? ', ' +
			current?.passport?.registration.data.street_with_type : '') : '';
		let addr = current?.passport?.address && current?.passport?.address.data ?
			current?.passport?.address.data.country + (current?.passport?.address.data.city_fias_id ? ', ' +
			current?.passport?.address.data.city_with_type :
			current?.passport?.address.data.settlement_fias_id ? ', ' +
			current?.passport?.address.data.settlement_with_type : '') +
			(current?.passport?.address.data.street_fias_id ? ', ' +
			current?.passport?.address.data.street_with_type : '') : '';
		if (registration.current)
			registration.current.value = reg;
		if (address.current)
			address.current.value = current?.passport?.same_address ? reg : addr;
	}, []);

	useEffect(() => {
		if (address.current && address.current.value === 'undefined')
			address.current.value = '';
	}, [address.current, address.current?.value]);

	useEffect(() => {
		if (window.location.host.indexOf('localhost') >= 0)
			setDebugMode(true);
	}, []);

	const setBirthdate = (date: Date | null) => {
		setBirthdateEntered(validateDate(date), date);
	}

	const setIssueDate = (date: Date | null) => {
		setIssueDateEntered(validateDate(date), date);
	}

	const toFinalize = () => {
		setRegistrationStatus(RegistrationStatus.Finalize);
	}

	const toLicenseData = () => {
		setRegistrationStatus(RegistrationStatus.LicenseData);
	}

	const toProfile = () => {
		setRegistrationStatus(null);
	}

	const goBack = () => {
		switch (getLastSystemStatus(system.statuses)) {
			case SystemStatus.Registration:
				restoreRegistrationStatus();
				return;
			case SystemStatus.Profile:
				toProfile();
				return;
			default:
				return;
		}
	}

	return (
		<>
			<CancelBtn onClick={() => {
				if (cameraShown) {
					setCameraShown(false);
					return;
				}
				if (editing >= 0) {
					setEditing(-1);
					return;
				}
				goBack();
			}} />
			{(cameraShown && state.photos.length < 3) || editing >= 0 ?
				<PhotoTaker
					id="passport_photo"
					title="Сделайте фотографии паспорта"
					editing={editing >= 0}
					ask={!finalize.camera_accessed}
					frames={editing >= 0 ? [passportFrames[editing]] : passportFrames}
					pictures={editing >= 0 ? [state.photos[editing]] : state.photos}
					onChange={(photos: Blob[]) => handleChange(photos)}
					onError={() => setCameraShown(false)}
					camera={cameraShown}
				/> :
				<div className="passport_window">
					<div className="passport_container">
						<div className="passport_title">
							{finalize.is_owner && getLastSystemStatus(system.statuses) !== SystemStatus.Profile ?
								'Ваш паспорт' : 'Паспортные данные'}
						</div>
						<div id="passport_name" className={'passport_input' + (state.name_entered ?
							' passport_input_complete' : (error ? ' error' : ''))}>
							<input className="passport_input_text" placeholder="ФИО полные" type="text"
								value={state.name} onKeyDown={(e: React.KeyboardEvent) => {
									if (!/[А-Яа-я -]/.test(e.key) && !editingChars.includes(e.key)) {
										e.preventDefault();
									}
								}}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									if (validateName(e.target.value)) {
										setNameEntered(true, e.target.value);
									} else {
										setNameEntered(false);
									}
								}}
							/>
						</div>
						<div className="passport_input_container">
							<div id="passport_birthdate" className={'passport_input passport_input_short' +
								(state.birthdate_entered ? ' passport_input_complete' : (error ? ' error' : ''))}>
								<DatePicker
									value={state.birthdate}
									clearIcon={null}
									showLeadingZeros={true}
									closeCalendar={true}
									dayPlaceholder="ДД"
									monthPlaceholder="ММ"
									yearPlaceholder="ГГГГ"
									format="dd.MM.y"
									locale="ru-RU"
									maxDate={new Date()}
									onChange={setBirthdate}
								/>
							</div>
							<div id="passport_number" className={'passport_input passport_input_short' +
								(state.number_entered ? ' passport_input_complete' : (error ? ' error' : ''))}>
								<InputMask mask="99 99 999999" value={state.number} onChange={(e) => {
										if (e.target.value !== '' && e.target.value.indexOf('_') === -1) {
											setNumberEntered(true, e.target.value);
										} else {
											setNumberEntered(false);
										}
									}}>
									{(inputProps: any) => <input {...inputProps} className="passport_input_text"
										placeholder="Серия и номер" type="text" />}
								</InputMask>
							</div>
						</div>
						<div className="passport_input_container">
							<div id="passport_department" className={'passport_input passport_input_short' +
								(state.department_entered ? ' passport_input_complete' : (error ? ' error' : ''))}>
								<InputMask mask="999-999" value={state.department} onChange={(e) => {
										if (e.target.value !== '' && e.target.value.indexOf('_') === -1) {
											setDepartmentEntered(true, e.target.value);
										} else {
											setDepartmentEntered(false);
										}
									}}>
									{(inputProps: any) => <input {...inputProps} className="passport_input_text"
										placeholder="Код подразделения" type="text" />}
								</InputMask>
							</div>
							<div id="passport_issue_date" className={'passport_input passport_input_short' +
								(state.issue_date_entered ? ' passport_input_complete' : (error ? ' error' : ''))}>
								<DatePicker
									value={state.issue_date}
									clearIcon={null}
									showLeadingZeros={true}
									closeCalendar={true}
									dayPlaceholder="ДД"
									monthPlaceholder="ММ"
									yearPlaceholder="ГГГГ"
									format="dd.MM.y"
									locale="ru-RU"
									maxDate={new Date()}
									onChange={setIssueDate}
								/>
							</div>
						</div>
						<div id="passport_issued_by" className={'passport_input' + (state.issued_by_entered ?
							' passport_input_complete' : (error ? ' error' : ''))}>
							<textarea className="passport_textarea" placeholder="Кем выдан" rows={2}
								value={state.issued_by} onKeyDown={(e: React.KeyboardEvent) => {
									if (!/[-а-яА-Я0-9№.,/ ()"]/.test(e.key) && !editingChars.includes(e.key)) {
										e.preventDefault();
									}
								}}
								onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
									if (validateOrg(e.target.value)) {
										setIssuedByEntered(true, e.target.value);
									} else {
										setIssuedByEntered(false);
									}
								}}
							/>
						</div>
						<div id="passport_registration" className={'passport_input' + (state.registration_entered ?
							' passport_input_complete' : (error ? ' error' : ''))}>
							<DaData ref={registration} id="passport_registration_input"
								value={state.registration && state.registration !== undefined && state.registration.data ?
									state.registration.data.country + (state.registration.data.city_fias_id ?
									', ' + state.registration.data.city_with_type :
									state.registration.data.settlement_fias_id ?
									', ' + state.registration.data.settlement_with_type : '') +
									(state.registration.data.street_fias_id ? ', ' +
									state.registration.data.street_with_type : '') : ''}
								inputClassName="passport_input_text" placeholder="Регистрация"
								onBlur={async () => {
									if (registration.current && registration.current.value !== '') {
										let data = await addressAPI.getSuggestions(registration.current.value);
										if (data && data.data && data.data.suggestions) {
											let address = data.data.suggestions[0];
											setRegistrationEntered(address.data.house, address);
											if (state.same_address)
												setAddressEntered(state.address_entered, true, address);
											if (registrationHouse.current) registrationHouse.current.focus();
										}
									}
								}}
								onChange={async (value) => {
									setRegistrationEntered(false, null);
									let data = await addressAPI.getSuggestions(value);
									if (data && data.data && data.data.suggestions) return data.data.suggestions;
									return [];
								}}
								onSelect={(suggestion) => {
									setRegistrationEntered(suggestion?.data?.house, suggestion);
									if (state.same_address)
										setAddressEntered(state.address_entered && suggestion?.data?.house, true, suggestion);
								}}
							/>
						</div>
						<div className="passport_input_container">
							<div id="passport_registration_house" className={'passport_input passport_input_tiny' +
								(state.registration && state.registration.data && state.registration.data.house ?
								' passport_input_complete' : (error ? ' error' : ''))}>
								<input type="text" placeholder="Дом" className="passport_input_text"
									disabled={!state.registration || (!state.registration.data.city &&
										!state.registration.data.settlement) || (state.registration.data.city &&
										!state.registration.data.street)} ref={registrationHouse}
									value={state.registration &&
										state.registration.data.house ? state.registration.data.house : ''}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
										let address = state.registration;
										if (e.target.value !== '' && isNaN(parseInt(e.target.value))) return;
										address.data.house = e.target.value;
										address.data.block = '';
										address.data.flat = '';
										address.value = address.data.country + (address.data.city ?
											', ' + address.data.city_with_type : address.data.settlement ?
											', ' + address.data.settlement : '') + (address.data.street ?
											', ' + address.data.street_with_type : '') + ', д ' + e.target.value +
											(address.data.block ? ', к ' + address.data.block : '') +
											(address.data.flat ? ', кв ' + address.data.flat : '');
										setRegistrationEntered(true, address);
										if (state.same_address)
											setAddressEntered(state.address_entered, true, address);
									}}
								/>
							</div>
							<div id="passport_registration_block" className={'passport_input passport_input_tiny' +
								(state.registration && state.registration.data && state.registration.data.block ?
								' passport_input_complete' : '')}>
								<input type="text" placeholder="Корп." className="passport_input_text"
									disabled={!state.registration || !state.registration.data.house}
									value={state.registration &&
										state.registration.data.block ? state.registration.data.block : ''}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
										let address = state.registration;
										if (e.target.value !== '' && isNaN(parseInt(e.target.value))) return;
										address.data.block = e.target.value;
										address.data.flat = '';
										address.value = address.data.country + (address.data.city ?
											', ' + address.data.city_with_type : address.data.settlement ?
											', ' + address.data.settlement : '') + (address.data.street ?
											', ' + address.data.street_with_type : '') + (address.data.house ?
											', д ' + address.data.house : '') + ', к ' + e.target.value +
											(address.data.flat ? ', кв ' + address.data.flat : '');
										setRegistrationEntered(true, address);
										if (state.same_address)
											setAddressEntered(state.address_entered, true, address);
									}}
								/>
							</div>
							<div id="passport_registration_flat" className={'passport_input passport_input_tiny' +
								(state.registration && state.registration.data && state.registration.data.flat ?
								' passport_input_complete' : '')}>
								<input type="text" placeholder="Кв." className="passport_input_text"
									disabled={!state.registration || !state.registration.data.house}
									value={state.registration &&
										state.registration.data.flat ? state.registration.data.flat : ''}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
										let address = state.registration;
										if (e.target.value !== '' && isNaN(parseInt(e.target.value))) return;
										address.data.flat = e.target.value;
										address.value = address.data.country + (address.data.city ?
											', ' + address.data.city_with_type : address.data.settlement ?
											', ' + address.data.settlement : '') + (address.data.street ?
											', ' + address.data.street_with_type : '') + (address.data.house ?
											', д ' + address.data.house : '') + (address.data.block ?
											', к ' + address.data.block : '') + ', кв ' + e.target.value;
										setRegistrationEntered(true, address);
										if (state.same_address)
											setAddressEntered(state.address_entered, true, address);
									}}
								/>
							</div>
						</div>
						<div id="passport_address" className={'passport_input' + (state.address_entered ||
							(state.registration_entered && state.same_address) ?
							' passport_input_complete' : (error ? ' error' : ''))}>
							<DaData ref={address} id="passport_address_input"
								value={state.same_address ? (registration.current ? registration.current.value : '') :
									(state.address && state.address.data ?
									state.address.data.country + (state.address.data.city_fias_id ?
									', ' + state.address.data.city_with_type :
									state.address.data.settlement_fias_id ?
									', ' + state.address.data.settlement_with_type : '') +
									(state.address.data.street_fias_id ? ', ' +
									state.address.data.street_with_type : '') : '')}
								inputClassName="passport_input_text" placeholder="Адрес проживания"
								disabled={state.same_address}
								onBlur={async () => {
									if (address.current && address.current.value !== '') {
										let data = await addressAPI.getSuggestions(address.current.value);
										if (data && data.data && data.data.suggestions) {
											let address = data.data.suggestions[0];
											setAddressEntered(address.data.house, false, address);
											if (addressHouse.current) addressHouse.current.focus();
										}
									}
								}}
								onChange={async (value) => {
									setAddressEntered(false, false, null);
									let data = await addressAPI.getSuggestions(value);
									if (data && data.data && data.data.suggestions) return data.data.suggestions;
									return [];
								}}
								onSelect={(suggestion) => {
									setAddressEntered(suggestion?.data?.house, suggestion);
								}}
							/>
							<Checkbox id="passport_same_address" parentClassName="passport_check"
								checked={state.same_address} onClick={() => {
									if (address.current)
										address.current.value = state.same_address ? '' : state.registration;
									setAddressEntered(state.address_entered && state.same_address, !state.same_address,
										state.same_address ? null : state.registration);
								}}
							/>
						</div>
						<div className="passport_input_container">
							<div id="passport_address_house" className={'passport_input passport_input_tiny' +
								(state.address && state.address.data && state.address.data.house ?
								' passport_input_complete' : (error ? ' error' : ''))}>
								<input type="text" placeholder="Дом" className="passport_input_text"
									disabled={state.same_address || !state.address || (!state.address.data.city &&
										!state.address.data.settlement) || (state.address.data.city &&
										!state.address.data.street)} ref={addressHouse}
									value={state.address && state.address.data.house ? state.address.data.house : ''}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
										let address = state.address;
										if (e.target.value !== '' && isNaN(parseInt(e.target.value))) return;
										address.data.house = e.target.value;
										address.data.block = '';
										address.data.flat = '';
										address.value = address.data.country + (address.data.city ?
											', ' + address.data.city_with_type : address.data.settlement ?
											', ' + address.data.settlement : '') + (address.data.street ?
											', ' + address.data.street_with_type : '') + ', д ' + e.target.value +
											(address.data.block ? ', к ' + address.data.block : '') +
											(address.data.flat ? ', кв ' + address.data.flat : '');
										setAddressEntered(true, false, address);
									}}
								/>
							</div>
							<div id="passport_address_block" className={'passport_input passport_input_tiny' +
								(state.address && state.address.data && state.address.data.block ?
								' passport_input_complete' : '')}>
								<input type="text" placeholder="Корп." className="passport_input_text"
									disabled={state.same_address || !state.address || !state.address.data.house}
									value={state.address &&
										state.address.data.block ? state.address.data.block : ''}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
										let address = state.address;
										if (e.target.value !== '' && isNaN(parseInt(e.target.value))) return;
										address.data.block = e.target.value;
										address.data.flat = '';
										address.value = address.data.country + (address.data.city ?
											', ' + address.data.city_with_type : address.data.settlement ?
											', ' + address.data.settlement : '') + (address.data.street ?
											', ' + address.data.street_with_type : '') + (address.data.house ?
											', д ' + address.data.house : '') + ', к ' + e.target.value +
											(address.data.flat ? ', кв ' + address.data.flat : '');
										setAddressEntered(true, false, address);
									}}
								/>
							</div>
							<div id="passport_address_flat" className={'passport_input passport_input_tiny' +
								(state.address && state.address.data && state.address.data.flat ?
								' passport_input_complete' : '')}>
								<input type="text" placeholder="Кв." className="passport_input_text"
									disabled={state.same_address || !state.address || !state.address.data.house}
									value={state.address &&
										state.address.data.flat ? state.address.data.flat : ''}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
										let address = state.address;
										if (e.target.value !== '' && isNaN(parseInt(e.target.value))) return;
										address.data.flat = e.target.value;
										address.value = address.data.country + (address.data.city ?
											', ' + address.data.city_with_type : address.data.settlement ?
											', ' + address.data.settlement : '') + (address.data.street ?
											', ' + address.data.street_with_type : '') + (address.data.house ?
											', д ' + address.data.house : '') + (address.data.block ?
											', к ' + address.data.block : '') + ', кв ' + e.target.value;
										setAddressEntered(true, false, address);
									}}
								/>
							</div>
						</div>
						{navigator.geolocation && !state.same_address &&
							<div className="passport_geo" onClick={() => {
								navigator.geolocation.getCurrentPosition(async (pos: any) => {
									let response = await addressAPI.getAddressByCoords(pos.coords.latitude,
										pos.coords.longitude);
									if (response && response.data && response.data.suggestions.length > 0) {
										setAddressEntered(true, false, response.data.suggestions[0]);
									}
								});
							}}>
								Моё местоположение
							</div>
						}
						{state.photos.length > 0 ?
							<div className="passport_photo_container">
								{state.photos.map((photo, index) => (
									<img id={'passport_photo_' + index} key={'passport_photo_' + index} alt=""
										className="passport_photo" src={URL.createObjectURL(photo)}
										onClick={() => setEditing(index)} />
								))}
							</div> :
							<div id="passport_photos" className={'passport_photo_button' + (error ? ' error' : '')}
								onClick={() => setCameraShown(true)}>
								<div className="passport_photo_button_text">
									Фотографии паспорта<br />
									<ReactSVG src={PhotoSVG} />
								</div>
							</div>
						}
						<section className="passport_footer">
							<div id="passport_back" className="passport_link" onClick={goBack}>
								Вернуться назад
							</div>
							<div id="passport_next" className={'passport_button' +
								(state.name_entered && state.birthdate_entered && state.number_entered &&
								state.department_entered && state.issue_date_entered && state.issued_by_entered &&
								state.registration_entered && (state.same_address || state.address_entered) &&
								(debugMode || state.photos.length === 3) ?
								'' : ' inactive')} onClick={() => {
									setError(false);
									if (!state.name_entered || !state.birthdate_entered || !state.number_entered ||
										!state.department_entered || !state.issue_date_entered || !state.issued_by_entered ||
										!state.registration_entered || (!state.same_address && !state.address_entered) ||
										(!debugMode && state.photos.length < 3)) {
										setError(true);
										return;
									}
									setCameraShown(false);
									const newData = finalize.owner.drivers === undefined || finalize.owner.drivers.length === 0;
									if (finalize.is_owner || finalize.current_driver.is_owner) {
										let drivers = finalize.owner.drivers;
										let index = drivers.findIndex((dr: Driver) => dr.id === finalize.owner.id);
										if (index >= 0) {
											drivers[index].passport = {
												name: state.name,
												birthdate: state.birthdate,
												number: state.number,
												department: state.department,
												issue_date: state.issue_date,
												issued_by: state.issued_by,
												registration: state.registration,
												address: state.address,
												same_address: state.same_address,
												photos: state.photos
											}
											if (system.statuses[system.statuses.length-1] === SystemStatus.Profile) {
												dispatch(setAuthenticated({
													authenticated: true,
													driver_id: user.driver_id,
													full_name: state.name,
													status: user.status,
													data: user.data, current_coordinates: user.current_coordinates
												}));
											}
										}
										saveOwner({ ...(finalize.owner), passport: {
											name: state.name,
											birthdate: state.birthdate,
											number: state.number,
											department: state.department,
											issue_date: state.issue_date,
											issued_by: state.issued_by,
											registration: state.registration,
											address: state.address,
											same_address: state.same_address,
											photos: state.photos
										}});
									}
									if (!finalize.is_owner) {
										saveDriver({ ...(finalize.current_driver),
											id: finalize.current_driver.id === '' ? generateId() : finalize.current_driver.id,
											passport: {
												name: state.name,
												birthdate: state.birthdate,
												number: state.number,
												department: state.department,
												issue_date: state.issue_date,
												issued_by: state.issued_by,
												registration: state.registration,
												address: state.address,
												same_address: state.same_address,
												photos: state.photos
											}
										});
									}
									switch (getLastSystemStatus(system.statuses)) {
										case SystemStatus.Registration:
											setTimeout(finalize.is_owner && (!newData ||
												ownerSelect.owner_type === MULTIPLE_CARS_OWNER) ? toFinalize : toLicenseData, 500);
											return;
										case SystemStatus.Profile:
											dispatch(syncData());
											setTimeout(finalize.is_owner ? toProfile : toLicenseData, 500);
											return;
										default:
											return;
									}
								}}>
				    		<div className="passport_button_text">Сохранить</div>
				    	</div>
						</section>
					</div>
				</div>
			}
		</>
	)
}

const mapStateToProps = (state: RootState) => ({
	state: state.passportDataReducer,
	finalize: state.finalizeReducer,
	system: state.systemReducer,
	user: state.userReducer,
	ownerSelect: state.ownerSelectReducer
});

export default connect(mapStateToProps, {
	setNameEntered,
	setBirthdateEntered,
	setNumberEntered,
	setDepartmentEntered,
	setIssueDateEntered,
	setIssuedByEntered,
	setRegistrationEntered,
	setAddressEntered,
	addPhoto,
	replacePhoto,
	clearPhotos,
	setCameraAccessed,
	saveOwner,
	saveDriver,
	setRegistrationStatus,
	restoreRegistrationStatus,
	setSystemStatus
})(PassportData);
