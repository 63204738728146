import {
  SET_REGISTRATION_STATUS,
  RESTORE_REGISTRATION_STATUS,
  SetRegistrationStatusAction,
  RestoreRegistrationStatusAction
} from "./@types";
import { RegistrationStatus } from "../../../@types";

export const setRegistrationStatus = (status: RegistrationStatus | null): SetRegistrationStatusAction => ({
  type: SET_REGISTRATION_STATUS,
  status
});

export const restoreRegistrationStatus = (): RestoreRegistrationStatusAction => ({
  type: RESTORE_REGISTRATION_STATUS
});
