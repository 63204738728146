import React from "react";
import { Driver } from "./@types";
import { Checkbox } from "../elements";
import "./Container.css";

interface IProps {
	id?: string;
	title?: string;
	drivers: Driver[];
	assigned: Driver[];
	onAddDriver: () => void;
	onToggleDriver: (index: number) => void;
	onAssignSelf: () => void;
}

const VehicleDriverContainer: React.FunctionComponent<IProps> = (props) => {

	return (
		<div id={props.id} className="container">
			<div className="container_title">
				<div className="container_title_text">
					{props.title ? props.title : 'Кто может быть за рулем'}
				</div>
			</div>
			<div>
				{props.drivers && props.drivers !== undefined &&
					props.drivers.map((driver: Driver, index: number) => (
					<div key={'driver_' + driver.passport.name} className="container_item"
						onClick={() => props.onToggleDriver(index)}>
						<div className="container_item_description">
							<div className="container_item_name">{driver.is_owner ? 'Вы' : driver.passport.name}</div>
							<div className="container_item_value">{`Категория ${driver.license.categories.join(', ')}`}</div>
						</div>
						<Checkbox parentClassName="container_check"
							checked={props.assigned && props.assigned.findIndex((dr) => (dr.id === driver.id)) >= 0}
						/>
					</div>
				))}
				{!props.drivers || props.drivers === undefined ||
					props.drivers.findIndex((driver) => driver.is_owner) === -1 ?
					<div key="driver_assign_self" className="container_item container_item_nocircle"
						onClick={() => props.onAssignSelf()}>
						<div className="container_item_description">
							<div className="container_item_name_blue">Назначить себя</div>
							<div className="container_item_value">Потребуются ваши водительские права</div>
						</div>
						<div className="container_add">
							<div className="container_add_h"></div>
							<div className="container_add_v"></div>
						</div>
					</div> : null
				}
				<div key="driver_assign_driver" className="container_item container_item_nocircle"
					onClick={() => props.onAddDriver()}>
					{!props.drivers || props.drivers === undefined || props.drivers.length === 0 ?
						<div className="container_item_description">
							<div className="container_item_name_blue">Добавить водителя</div>
							<div className="container_item_value">Потребуются его паспорт и права</div>
						</div> :
						<div className="container_item_action">Добавить водителя</div>
					}
					<div className="container_add">
						<div className="container_add_h"></div>
						<div className="container_add_v"></div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default VehicleDriverContainer;
