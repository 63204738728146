import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {RootState} from "../../redux/store";
import {UserState} from "../../redux/reducers/user/@types";
import {userAPI} from "../../api";
import "./OrderBook.css";
import moment from "moment";
import {OrderFullInfo, OrdersState} from "../../redux/reducers/orders/@types";

import {EnumStatusResponse} from "../../api/@types";
import {
    popSystemStatus,
    setOverlay,
    setSystemStatus,
    toggleOrderBookWindow,
    toggleSystemStatus
} from "../../redux/reducers/system/actions";
import {SetOverlayAction, SystemState, ToggleOrderBookWindowAction} from "../../redux/reducers/system/@types";
import OrderSmallCard from "./OrderSmallCard/OrderSmallCard";
import Loader from "react-loader-spinner";
import OrderFullCard from "./OrderFullCard/OrderFullCard";
import {OrdersListType, SystemStatus} from "../../@types";
import {setOrder} from "../../redux/reducers/orders/actions";

interface IProps {
    state: {
        user: UserState;
        system: SystemState;
        orderState: OrdersState
    },
    toggleOrderBookWindow: ()=> ToggleOrderBookWindowAction
    setOverlay: (overlay:boolean)=> SetOverlayAction

}

enum EnumSortType{
    Today, Future, Past
}

interface ILocalState {
    orders: OrderFullInfo[];
    error: string;
    from: number;
    sort: EnumSortType;
    loading: boolean;
    current_order :OrderFullInfo | null;
}

const OrderBook: React.FunctionComponent<IProps> = ({ state,toggleOrderBookWindow, setOverlay }) => {
    const dispatch = useDispatch();
    const countInRequest = 10;

    const [localState, setLocalState] = useState<ILocalState>({
        orders: [],
        error:"",
        from:0,
        sort: EnumSortType.Today,
        loading: false,
        current_order: null
    });

    const { user } = state;

    const today =  moment(new Date());

    useEffect(() => {
        setLocalState((s) => ({...s, loading:true}));



        userAPI.getUsedOrders({driver_id: user.driver_id, start: localState.from, count: countInRequest}).then((res) => {
            console.log(' res.data ', res.data )
            if (res && res.data && res.data.status === EnumStatusResponse.success)
                setLocalState((s) => ({...s, orders: res.data.order ? res.data.order : []}));
            setLocalState((s) => ({...s, loading:false}));
        }).catch(error=>{
            setLocalState((s) => ({...s, error: error}));
            setLocalState((s) => ({...s, loading:false}));
        });
    }, []);

    let setSortMode = (mode:EnumSortType) => {
        setLocalState((s) => ({...s, sort: mode }));
    };

    let onOrderClick = (item:any  | null) => {
        dispatch(setOrder(item, localState.sort === EnumSortType.Past ? OrdersListType.Last : OrdersListType.Set));
        dispatch(toggleSystemStatus(SystemStatus.FullOrderCard));

        setLocalState((s) => ({...s, current_order: item }));
    };

    let closeWindow = () => {
        dispatch(popSystemStatus());
    };
    return (
        <>
            {
              <div className="order_book_page">
            <div className="page_back_link" onClick={closeWindow}>
                <svg width="1rem" height="1.5rem" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.02348 16.6742C8.23875 16.885 8.51272 17 8.83562 17C9.48141 17 10 16.5017 10 15.8692C10 15.553 9.86301 15.2655 9.63796 15.0451L2.78865 8.49042L9.63796 1.9549C9.86301 1.7345 10 1.43743 10 1.13078C10 0.498309 9.48141 0 8.83562 0C8.51272 0 8.23875 0.114994 8.02348 0.325817L0.410959 7.60879C0.136986 7.85795 0.00978474 8.1646 0 8.5C0 8.8354 0.136986 9.12289 0.410959 9.38162L8.02348 16.6742Z" fill="#888E99"/>
                </svg>
            </div>
            <div className="order_book_header">
                <div className="page_title">Журнал заказов</div>
                <div className="orders_tabs">
                    <div className={ "orders_tab " + (localState.sort === EnumSortType.Today ? "active" : "")}  onClick={()=>setSortMode(EnumSortType.Today)}>Сегодня</div>
                    <div className={ "orders_tab " + (localState.sort === EnumSortType.Future ? "active" : "")} onClick={()=>setSortMode(EnumSortType.Future)}>Будущие</div>
                    <div className={ "orders_tab " + (localState.sort === EnumSortType.Past ? "active" : "")} onClick={()=>setSortMode(EnumSortType.Past)}>Прошлые</div>
                </div>
            </div>
            <div className="order_book_container">
                <div className="orders_list">
                    { localState.loading?
                        <div className="loader">
                            <Loader type="Puff" color="#00BB40" width={'8rem'} height={'8rem'} />
                            <div className="loader-text">Идет загрузка данных</div>
                        </div>
                        :
                        localState.orders
                            .filter( item=> {
                                let dt = item.date.split('T');
                                let date_order =  moment(dt[0]+'T'+dt[1]);

                                return  ((localState.sort === EnumSortType.Today && today.diff(date_order, 'days') === 0) ||
                                           (localState.sort === EnumSortType.Future && today.diff(date_order, 'days') < 0) ||
                                           (localState.sort === EnumSortType.Past && today.diff(date_order, 'days') > 0) );
                            })
                            .map((item, i) => (
                        <OrderSmallCard key={'card-'+item.id + i} order={item} isLooked={true} onClick={()=>{onOrderClick(item)}} state={state.orderState} user_coordinate={user.current_coordinates}  />
                    ))}
                </div>
            </div>
        </div>

            }
            {/*{localState.current_order != null &&*/}
            {/*    <OrderFullCard order={localState.current_order} onClose={()=> onOrderClick(null)} />*/}
            {/*}*/}
       </>
    );
}

const mapStateToProps = (state: RootState) => ({
    state: {
        user: state.userReducer,
        system: state.systemReducer,
        orderState: state.ordersReducer,
    }
});

export default connect(mapStateToProps, {toggleOrderBookWindow, setOverlay })(OrderBook);
