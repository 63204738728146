import {
	SET_NAME_ENTERED,
	SET_BIRTHDATE_ENTERED,
	SET_NUMBER_ENTERED,
	SET_DEPARTMENT_ENTERED,
	SET_ISSUE_DATE_ENTERED,
	SET_ISSUED_BY_ENTERED,
	SET_REGISTRATION_ENTERED,
	SET_ADDRESS_ENTERED,
	ADD_PHOTO,
	REPLACE_PHOTO,
	CLEAR_PHOTOS,
	SetNameEnteredAction,
	SetBirthdateEnteredAction,
	SetNumberEnteredAction,
	SetDepartmentEnteredAction,
	SetIssueDateEnteredAction,
	SetIssuedByEnteredAction,
	SetRegistrationEnteredAction,
	SetAddressEnteredAction,
	AddPhotoAction,
	ReplacePhotoAction,
	ClearPhotosAction
} from "./@types";

export const setNameEntered = (name_entered: boolean, name?: string): SetNameEnteredAction => ({
  type: SET_NAME_ENTERED,
  name_entered,
  name
});

export const setBirthdateEntered = (birthdate_entered: boolean, birthdate?: Date | null):
	SetBirthdateEnteredAction => ({
  type: SET_BIRTHDATE_ENTERED,
  birthdate_entered,
  birthdate
});

export const setNumberEntered = (number_entered: boolean, number?: string): SetNumberEnteredAction => ({
  type: SET_NUMBER_ENTERED,
  number_entered,
  number
});

export const setDepartmentEntered = (department_entered: boolean, department?: string): SetDepartmentEnteredAction => ({
  type: SET_DEPARTMENT_ENTERED,
  department_entered,
  department
});

export const setIssueDateEntered = (issue_date_entered: boolean, issue_date?: Date | null):
	SetIssueDateEnteredAction => ({
  type: SET_ISSUE_DATE_ENTERED,
  issue_date_entered,
  issue_date
});

export const setIssuedByEntered = (issued_by_entered: boolean, issued_by?: string): SetIssuedByEnteredAction => ({
  type: SET_ISSUED_BY_ENTERED,
  issued_by_entered,
  issued_by
});

export const setRegistrationEntered = (registration_entered: boolean, registration?: any): SetRegistrationEnteredAction => ({
  type: SET_REGISTRATION_ENTERED,
  registration_entered,
  registration
});

export const setAddressEntered = (address_entered: boolean, same_address?: boolean, address?: any): SetAddressEnteredAction => ({
  type: SET_ADDRESS_ENTERED,
  address_entered,
  same_address,
  address
});

export const addPhoto = (photo: Blob): AddPhotoAction => ({
  type: ADD_PHOTO,
  photo
});

export const replacePhoto = (index: number, new_photo: Blob): ReplacePhotoAction => ({
  type: REPLACE_PHOTO,
  index,
  new_photo
});

export const clearPhotos = (): ClearPhotosAction => ({
	type: CLEAR_PHOTOS
});
