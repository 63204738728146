import React, {useEffect} from "react";
import {Provider, useDispatch, useSelector} from "react-redux";
import {setAuthenticated, setStatus, setUserCoordinates, setUserInfo} from "../../redux/reducers/user/actions";
import {popSystemStatus, setMessage} from "../../redux/reducers/system/actions";
import {
    setAdditionalRequirements,
    setBodyOptionCharacteristics,
    setBodyOptionCharacteristicsValues,
    setBodyOptions,
    setBodyTypes,
    setCarTypes
} from "../../redux/reducers/orders/actions";
import store, {RootState} from "../../redux/store";
import {ordersAPI, userAPI} from "../../api";
import {
    BankData,
    CheckPhone,
    Finalize,
    Help,
    LicenseData,
    MainScreen,
    OwnerSelect,
    Partnership,
    PassportData,
    Profile,
    QuickRegistration,
    Statistic,
    Vehicle
} from "../components";
import {DatePickerWindow, DriverStatusWindow, Overlay, SystemMessage} from "../Elements/elements";
import "./App.css";
import "./Registration.css";
import {RegistrationStatus, SystemStatus, UserStatus} from "../../@types";
import OrderBook from "../OrderBook/OrderBook";
import {getCookie} from "../../redux/reducers/system/cookies";
import Menu from "../Elements/Menu/Menu";
import {getStatusWorking} from "../../redux/reducers/user/reducers";
import {loadData} from "../../redux/reducers/finalize/actions";
import CloseRoute from "../Elements/HereMap/CloseRoute";
import WaitingWindow from "../WaitingWindow/WaitingWindow";
import OrderFullCard from "../OrderBook/OrderFullCard/OrderFullCard";
import MessageWindow from "../MessageWindow/MessageWindow";
import OrderPlayWindow from "../OrderPlayWindow/OrderPlayWindow";


const SYSTEM_MESSAGE_REFRESH_INTERVAL = 5000;

const App: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => ({
    user: state.userReducer,
    system: state.systemReducer,
    registration: state.registrationReducer,
    orders: state.ordersReducer
  }));

  const { user, system, registration } = state;

  //@ts-ignore
  window.state = state;
  useEffect(() => {




    // userAPI.authMe().then((res) => {
    //   dispatch(setAuthenticated({
    //     authenticated: true,
    //     ...res
    //   }));
    // }).catch((err) => {}
    //   }
    // );

    ordersAPI.getBodyOptionCharacteristics().then((res) => {
      dispatch(setBodyOptionCharacteristics(res.data));
    }).catch((err) => {});

    ordersAPI.getBodyOptionCharacteristicsValues().then((res) => {
      dispatch(setBodyOptionCharacteristicsValues(res.data));
    }).catch((err) => {});

    ordersAPI.getBodyTypes().then((res) => {
      dispatch(setBodyTypes(res.data));
    }).catch((err) => {});

    ordersAPI.getBodyOptions().then((res) => {
      dispatch(setBodyOptions(res.data));
    }).catch((err) => {});


    ordersAPI.getCarTypes().then((res) => {
      dispatch(setCarTypes(res.data));
    }).catch((err) => {});

    ordersAPI.getAdditionalRequirements().then((res) => {
      dispatch(setAdditionalRequirements(res.data));
    }).catch((err) => {});

    let id = getCookie('id');
      if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(async (pos: any) => {
              dispatch(setUserCoordinates({ lat:pos.coords.latitude, lon: pos.coords.longitude} ));
          });
      }

      if (id) {

          userAPI.getUserInfo(id).then((res) => {
              if (res.data && res.status === 200) {

                  dispatch(setAuthenticated({
                      authenticated: true,
                      driver_id: id,
                      full_name: res.data && res.data.persone && res.data.person.full_name,
                      status: UserStatus.Working,
                      data: null,
                      current_coordinates: user.current_coordinates
                  }));

                  dispatch(setUserInfo(res.data));
                  dispatch(loadData(res.data));

              }
          }).catch((err) => console.log(err));

          userAPI.getDriverStatus(id).then(res => {
              if (res.data && res.status === 200) {
                  dispatch(setStatus(getStatusWorking(res.data.status, res.data.dates)));
                  // dispatch(setStatus(UserStatus.Working));
              }
          }).catch((err) => console.log(err));
    }

  }, []);

  useEffect(() => {
    if (user.driver_id) {
      const interval = setInterval(() => {
        userAPI.getSystemMessage(user.driver_id).then((res) => {
          if (res.data[0] && system && !system.message.id) {
            dispatch(setMessage(res.data[0]));
          }
        }).catch((err) => {});
      }, SYSTEM_MESSAGE_REFRESH_INTERVAL);

      return () => clearInterval(interval);
    }
  }, [user.driver_id]);

	const registrationRouter = (status: RegistrationStatus | null) => {
		switch (status) {
			case RegistrationStatus.CheckPhone: return <CheckPhone />;
			case RegistrationStatus.OwnerSelect: return <OwnerSelect />;
			case RegistrationStatus.BankData: return <BankData />;
			case RegistrationStatus.PassportData: return <PassportData />;
			case RegistrationStatus.LicenseData: return <LicenseData />;
			case RegistrationStatus.Vehicle: return <Vehicle />;
			case RegistrationStatus.Finalize: return <Finalize />;
			case RegistrationStatus.QuickRegistration: return <QuickRegistration />;
			default: return <CheckPhone />;
		}
	}

	const router = (statuses: SystemStatus[], registration: RegistrationStatus | null) => {
	    let arr : any []= [];

        arr.push(<MainScreen  key="wnd_MainScreen"/>);

	    if (statuses)
        statuses.forEach( status=> {
            switch (status) {

                case SystemStatus.Help:
                    arr.push(<Help key="wnd_Help"/>);
                    break;
                case SystemStatus.Statistic:
                    arr.push(<Statistic key="wnd_Statistic"/>);
                    break;
                case SystemStatus.Profile:
                    arr.push(<Profile key="wnd_Profile"/>);
                    break;
                case SystemStatus.Partnership:
                    arr.push(<Partnership key="wnd_Partnership"/>);
                    break;
                case SystemStatus.OrderBook:
                    arr.push(<OrderBook key="wnd_OrderBook"/>);
                    break;
                case SystemStatus.Registration:
                    arr.push(registrationRouter(registration));
                    break;
                case SystemStatus.Overlay:
                    arr.push(<Overlay key="wnd_Overlay"/>);
                    break;
                case SystemStatus.Menu:
                    arr.push(<Menu key="wnd_Menu"/>);
                    break;
                case SystemStatus.DriverStatusWindow:
                    arr.push(<DriverStatusWindow key="wnd_DriverStatusWindow"/>);
                    break;
                case SystemStatus.CloseRoute:
                    arr.push(<CloseRoute key="wnd_CloseRoute"/>);
                    break;
                case SystemStatus.WaitingWindow:
                    arr.push(<WaitingWindow key="wnd_WaitingWindow"/>);
                    break;
                case SystemStatus.MessageWindow:
                    arr.push(<MessageWindow key="wnd_MessageWindow"/>);
                    break;
                case SystemStatus.DatePickerWindow:
                    arr.push(<DatePickerWindow key="wnd_DatePickerWindow"/>);
                    break;
                case SystemStatus.PlayWindow:
                    arr.push(<OrderPlayWindow  key="wnd_OrderPlayWindow"/>);
                    break;
                case SystemStatus.FullOrderCard:
                    arr.push(<OrderFullCard order={state.orders.current} onClose={()=>{
                        dispatch(popSystemStatus())
                    }}  key="wnd_OrderFullCard"/>);
                    break
            }
        });

		return arr;
	}

  return (
    <>
      {router(system.statuses, registration.status)}
      <SystemMessage />
    </>
  )
}

const Container: React.FunctionComponent = (props) => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  )
}
export default Container;

