import {OrdersListType} from "../../../../@types";

export const SET_ORDERS = 'SET_ORDERS';
export const SET_BODY_OPTIONS_CHARACTERISTICS = 'SET_BODY_OPTIONS_CHARACTERISTICS';
export const SET_BODY_OPTIONS_CHARACTERISTICS_VALUES = 'SET_BODY_OPTIONS_CHARACTERISTICS_VALUES';
export const SET_BODY_TYPES = 'SET_BODY_TYPES';
export const SET_BODY_OPTIONS = 'SET_BODY_OPTIONS';
export const SET_CAR_TYPES = 'SET_CAR_TYPES';
export const SET_ADDITIONAL_REQUIREMENTS = 'SET_ADDITIONAL_REQUIREMENTS';
export const SET_ORDER = 'SET_ORDER';
export const SET_ASSIGNED_ORDER = 'SET_ASSIGNED_ORDER';

export type BodyOptionCharacteristic = {
  name: string;
  id: string;
  type: string;
}

export type BodyOptionCharacteristicValue = {
  name: string;
  id: string;
  type: string;
}

export type Order = {
  id: string;
  address: string;
  lon: number;
  lat: number;
  price: number;
  date: string;
  is_look: boolean;
  car_type_id: string
  car_body_option_id: string,
  full_distance: number,
  point_count: number,
  type: OrdersListType,
}

export type BodyType = {
  id: number;
  name: string;
}

export type BodyOption = {
  id: string;
  name: string;
  body_type_id: number;
}

export type CarType = {
  name: string,
  length_from: number,
  length_to: number,
  width_from: number,
  width_to: number,
  height_from: number,
  height_to: number,
  weight_from: number,
  weight_to: number,
  area_from: number,
  area_to: number,
  volume_from: number,
  volume_to: number,
  pallets_from: number,
  pallets_to: number,
  pass_sk: boolean,
  pass_ttk: boolean,
  pass_mkad: boolean,
  ramp: boolean,
  tail_lift: boolean,
  board: boolean,
  refrigerator: boolean,
  id: string,
  body_options: {
      id: string,
      name: string,
      body_type_id: number,
      body_option_characteristics: {
          id: string,
          name: string,
          type: string,
          body_option_characteristics_values: {
              id: string,
              name: string,
              body_option_characteristics_id: string
          }[]
      }[]
  }[]
}

export type AdditionalRequirement = {
  name: string;
  type: string;
  exclude: string[];
  id: string;
}
export interface SetOrdersAction {
  type: typeof SET_ORDERS;
  orders: Order[]
}

export interface SetBodyOptionsCharacteristicsAction {
  type: typeof SET_BODY_OPTIONS_CHARACTERISTICS;
  body_option_characteristics: BodyOptionCharacteristic[];
}

export interface SetBodyOptionsCharacteristicsValuesAction {
  type: typeof SET_BODY_OPTIONS_CHARACTERISTICS_VALUES;
  body_option_characteristics_values: BodyOptionCharacteristicValue[];
}

export interface SetBodyTypesAction {
  type: typeof SET_BODY_TYPES;
  body_types: BodyType[];
}
export interface SetBodyOptionsAction {
  type: typeof SET_BODY_OPTIONS;
  body_options: BodyOption[];
}

export interface SetCarTypesAction {
  type: typeof SET_CAR_TYPES;
  car_types: CarType[];
}

export interface SetAdditionalRequirementsAction {
  type: typeof SET_ADDITIONAL_REQUIREMENTS;
  additional_requirements: AdditionalRequirement[];
}

export interface SetAssignedOrderAction {
  type: typeof SET_ASSIGNED_ORDER;
  order: object;
}

export interface SetOrderAction {
  type: typeof SET_ORDER;
  order: object;
  orderType: OrdersListType;
}

export type OrdersActionTypes = 
  | SetOrdersAction 
  | SetBodyOptionsCharacteristicsAction 
  | SetBodyOptionsCharacteristicsValuesAction 
  | SetBodyTypesAction 
  | SetBodyOptionsAction
  | SetCarTypesAction
  | SetAdditionalRequirementsAction
  | SetOrderAction
  | SetAssignedOrderAction;

export interface OrdersState {
  bodyOptionCharacteristics: BodyOptionCharacteristic[];
  bodyOptionCharacteristicsValues: BodyOptionCharacteristicValue[];
  bodyTypes: BodyType[];
  bodyOptions: BodyOption[];
  carTypes: CarType[];

  additionalRequirements: AdditionalRequirement[];
  current: any;
  assigned: any;
  items: Order[];
};

export  interface OrderRoutePoint {
    id: number,
    adress: string,
    adress_comment: string,
    adress_longitude: number,
    adress_latitude: number,
    company: string,
    what_to_do: string,
    working_hours: {
        time_from: string,
        time_to: string,
        lunch_from: string,
        lunch_to:string,
        max_landing_time: string,
        no_lunch: boolean
    },
    action_forwarder: boolean,
    action_unloading: boolean,
    action_loading: boolean,
    action_documents:boolean,
    contact_persons:{
        full_name: string,
        phone: string,
        phone_ext: string
    } [],
    files_ids: string[]
}

export interface OrderFullInfo {
  id:  string,
  date: string,
  TIN:string,
  comment: string,
  body_option_id: string,
  body_type_id: number,
  tariff_type_id: string,
  car_type_id: string,
  additional_requirements: {
                              id: string,
                              value: string,
                            }[] ,
  body_option_characteristics: {
                              id: string,
                              value: string,
                            }[] ,
  routes: OrderRoutePoint[],
  cargo: {
        places: {
                    size: {
                      weight: 430,
                      height: 1,
                      length: 1.5,
                      width: 1
                    }
                  }[],
        pallets: {
          pallet_type_id:  string,
          quantity: number,
          size: {
            length: number,
            width: number,
            height: number,
            weight: number,
          }
        }[],
        packages: {
                    package_type_id: string,
                    quantity: number,
                    size: {
                      length: number,
                      width: number,
                      height: number,
                      weight: number,
                    }
                  }[]
  },
  vehicle: {
    model: string,
    number: string,
    car_type_id: string,
    body_option_id: string,
    length: number,
    width: number,
    height: number,
    volume: number,
    max_weight: number,
  },
  driver: {
    full_name: string,
    phone: string,
    license: {
      series: string,
      number: string,
      issue_date: string,
      issued_by: string,
    }
  },
  number: string,
  status: string,
  status_id: number,
  type?:OrdersListType,
}
