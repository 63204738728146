import React, { FC } from "react"
import s from './ChatMessages.module.scss'

export const CommandMessage: FC<propTypes> = ({ message }) => {
    return (
        <div className={s.commandMessageWrapper}>
            <div className={s.commandMessage}>
                {message}
            </div>
        </div>
    )
}


type propTypes = {
    message: string
}