import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {setOrder} from "../../../redux/reducers/orders/actions";
import {
  clearSystemStatus,
  popSystemStatus,
  removeSystemStatus,
  setOverlay,
  setSystemStatus,
  showMessageTrunk,
  toggleOrdersWindow
} from "../../../redux/reducers/system/actions";

import moment from "moment";
import "moment/locale/ru";
import "./OrderFullCard.css";
import BottomWindow from "../../Elements/BottomWindow/BottomWindow";
import {getDistance, numWord} from "../../OrderInfoWindow/OrderInfoWindow";
import Button from "../../Elements/Button/Button";
import CargoCard, {CargoTypes} from "../../OrderInfoWindow/CargoCard/CargoCard";
import VehicleRequirements from "../../OrderInfoWindow/VehicleRequirements/VehicleRequirements";
import {OrderFullInfo, OrderRoutePoint, OrdersState} from "../../../redux/reducers/orders/@types";
import {ordersAPI, userAPI} from "../../../api";
import {RouteInfo} from "../../../redux/reducers/map/@types";
import {setRoutes} from "../../../redux/reducers/map/actions";
import {OrdersListType, SystemStatus} from "../../../@types";
import {EnumWindowMessageType} from "../../../redux/reducers/system/@types";

interface IProps {
  state: {
    orderState: OrdersState,
  };
  order: OrderFullInfo;
  onClose: Function;
}

interface ILocalState {
  route: RouteInfo | null,
  show_vehicle: boolean,
  show_cargo: boolean,
  show_point_index: number[],
}

const OrderFullCard: React.FC<IProps> = ({ state,onClose, order }) => {
  const dispatch = useDispatch();
  const   orders  = state.orderState;
  const { user,system } = useSelector((state: RootState) => ({

    user: state.userReducer,
    system: state.systemReducer
  }));


  const { additionalRequirements, bodyOptionCharacteristics, bodyOptionCharacteristicsValues, bodyTypes, carTypes } = orders;

  const [localState, setLocalState] = useState<ILocalState>( {
    route : null,
    show_vehicle: false,
    show_cargo: false,
    show_point_index: []
  } );


  const getDistanceToStartOrder = () =>{
    if (user.current_coordinates)
      return getDistance(order.routes[0].adress_latitude , order.routes[0].adress_longitude,
                         user.current_coordinates.lat, user.current_coordinates.lon);
    return '00';
  };

  // Возвращает время в минутах до начала заказа
  const getTimeToStartOrder = (): number => {
    const now = moment();
    let order_date = order.date.split('T')[0]+'T'+order.date.split('T')[1];
    const startTime = moment(order_date);

    return startTime.diff(now, "minutes");
  };

  const handleClose = () => {
    dispatch(toggleOrdersWindow(true));
    dispatch(setOrder({}, OrdersListType.All));
    dispatch(setOverlay(false));
  };

  const startPlay = () =>{
    dispatch(clearSystemStatus());
    dispatch(setSystemStatus(SystemStatus.PlayWindow));
  };

  const showMap = () => {
    if (localState.route)  dispatch(setRoutes(localState.route));
    else return;
    dispatch(clearSystemStatus());
    dispatch(setOrder(order, order.type ? order.type: OrdersListType.All));
    dispatch(setOverlay(false));
  }


  useEffect(()=>{
    if (order && order.id) {
      ordersAPI.getRoute(order.id).then((res)=>{
        if (!res || !res.data) return;
        setLocalState((s) => ({...s, route: res.data }))
      })
    }
  },[]);
  const  toggleShowPointInfo = (i:number) => {
    if (localState.show_point_index.indexOf(i)>=0)
      setLocalState((s)=> ({...s, show_point_index: localState.show_point_index.filter(x=> x!= i )}));
    else
      setLocalState((s)=> ({...s, show_point_index: [...localState.show_point_index, i]}))
  };

  const takeOrder = () =>{
    dispatch(setSystemStatus(SystemStatus.WaitingWindow));
    userAPI.reserveOrder(user.driver_id, order.id)
        .then((d)=>{
          dispatch(removeSystemStatus(SystemStatus.WaitingWindow));
          showMessageTrunk({type: EnumWindowMessageType.Info, text:`Заказ №${order.number} успешно Вам назначен!`}, dispatch)
        }).catch((e)=>{
          showMessageTrunk({type: EnumWindowMessageType.Error, text:"Ошибка выбора заказа"}, dispatch)
        })
  };

  let order_date = order.date ? order.date.split('T')[0]+'T'+order.date.split('T')[1] : '';
  console.log('OrderFullCard order=', order);
  return (
    <>

        <BottomWindow
          title={`Заказ №${order.number}`}
          description={`${moment(order_date).format("dd DD.MM.YYYY").toUpperCase()} к ${moment(order_date).format("HH:mm")}`}
          resizable={true}
          onClose={()=> { dispatch(setRoutes(null)); onClose(); }}
          visible={true}
          style={{ zIndex: 36, top: 30}}
          isFullMode={true}
        >
          {/*<div className="bottom_window_content short_content" style={{ maxHeight: "150px", overflow: "hidden" }}>*/}
          {/*  <div className="order_info_section" style={{ marginTop: 0 }}>*/}
          {/*    <div className="order_info_roadmap_title">{order.routes.length} {numWord(order.routes.length, ["точка", "точки", "точек"])} | {100} км</div>*/}
          {/*    <ul className="order_info_roadmap">*/}
          {/*      {order.routes.map((item: any, i: number) => {*/}
          {/*        return (*/}
          {/*          <li key={item.id}>*/}
          {/*            <div className="order_info_roadmap_address">{item.adress}</div>*/}
          {/*          </li>*/}
          {/*        );*/}
          {/*      })}*/}
          {/*    </ul>*/}
          {/*  </div>*/}
          {/*  <Button type="grey" style={{ color: "#5364ff" }}>На карте 11</Button>*/}
          {/*  <VehicleRequirements*/}
          {/*    additionalRequirements={additionalRequirements}*/}
          {/*    bodyTypes={bodyTypes}*/}
          {/*    bodyOptionCharacteristics={bodyOptionCharacteristics}*/}
          {/*    bodyOptionCharacteristicsValues={bodyOptionCharacteristicsValues}*/}
          {/*    carTypes={carTypes}*/}
          {/*    orderData={order}*/}
          {/*  />*/}
          {/*  <div className="order_info_section">*/}
          {/*    <div className="order_info_section_name">Груз</div>*/}
          {/*    {Boolean(order.cargo.packages.length) && <CargoCard type={CargoTypes.Packages} data={order.cargo} />}*/}
          {/*    {Boolean(order.cargo.pallets.length) && <CargoCard type={CargoTypes.Pallets} data={order.cargo} />}*/}
          {/*    {Boolean(order.cargo.places.length) && <CargoCard type={CargoTypes.Places} data={order.cargo} />}*/}
          {/*  </div>*/}

          {/*</div>*/}

          <div className="bottom_window_content full_content">
            <div className="order_info_section" style={{ marginTop: 0 }}>
              <div className="order_info_roadmap_title">
                {order.routes.length} {numWord(order.routes.length, ["точка", "точки", "точек"])}
                | { localState.route ? Math.round(localState.route.distance/1000) : '?'} км
              </div>
              <ul className="order_info_roadmap aaa">
                {order.routes.map((item: OrderRoutePoint, i: number) => {
                  return (
                    <li key={item.id} className={ 'number' + (i+1)}>
                      <div className="order_info_roadmap_address">{item.adress}</div>
                      {item.adress_comment &&  <div className="order_info_comment">{item.adress_comment}</div>}
                      { localState.show_point_index.indexOf(i)>=0 &&
                        <div className="order_info_point_detail">
                          <div className="order_info_persons">
                            <div className="order_info_persons_title">Контактные лица:</div>
                            {item.contact_persons.map( (x,i)=> <div key={i+'cop'} className="order_info_one_person">{x.full_name ? x.full_name : 'ФИО не указано' }</div> )}
                            {item.contact_persons.length == 0 ? <div className="no_persons">Неуказаны</div> : ''}
                          </div>
                          {item.company  && <div className="order_info_from">От кого: <span className="contact_name">{item.company }</span></div> }
                          <div className="order_info_task">
                            <div className="order_info_task_header">Задача</div>
                            <div className="order_info_task_comment">на точке быть вежливым</div>
                            <div className="order_info_task_what_to_do">{item.what_to_do}</div>
                            {item.action_loading ? <div className="order_info_action"> Загрузка </div> : ''}
                            {item.action_unloading ? <div className="order_info_action"> Разгрузка </div> : ''}
                            {item.action_documents ? <div className="order_info_action"> Получение документов </div> : ''}
                            {item.action_forwarder ? <div className="order_info_action"> Экспедирование груза </div> : ''}
                          </div>
                        </div>
                      }
                      <div className="link_show_point" onClick={()=> { toggleShowPointInfo(i) }}>
                        {user.authenticated && <span>{ localState.show_point_index.indexOf(i)>=0 ? 'Скрыть детали' : 'Показать детали' }</span>}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>

            <Button type="grey" style={{ color: "#5364ff" }} onClick={showMap} disabled={!localState.route}>На карте</Button>
            <div className="space" />
            { localState.show_vehicle && <VehicleRequirements
              additionalRequirements={additionalRequirements}
              bodyTypes={bodyTypes}
              bodyOptionCharacteristics={bodyOptionCharacteristics}
              bodyOptionCharacteristicsValues={bodyOptionCharacteristicsValues}
              carTypes={carTypes}
              orderData={order}
            /> }

            <Button type="white"  onClick={()=> setLocalState( (s) => ({...s, show_vehicle: !localState.show_vehicle}))} >
              { localState.show_vehicle ? 'Скрыть требования к ТС' : 'Показать требования к ТС' }
            </Button>

            {localState.show_cargo &&
              <div className="order_info_section">
                <div className="order_info_section_name">Груз</div>
                {Boolean(order.cargo.packages.length) && <CargoCard type={CargoTypes.Packages} data={order.cargo}/>}
                {Boolean(order.cargo.pallets.length) && <CargoCard type={CargoTypes.Pallets} data={order.cargo}/>}
                {Boolean(order.cargo.places.length) && <CargoCard type={CargoTypes.Places} data={order.cargo}/>}
                {!order.cargo.packages.length && !order.cargo.pallets.length && !order.cargo.places.length && <div className="noCargo">Информация о грузе отсутсвует</div>}
              </div>
            }
            <div className="space" />
            <Button type="white"  onClick={()=> setLocalState( (s) => ({...s, show_cargo: !localState.show_cargo}))} >
              { localState.show_cargo ? 'Скрыть информацию о грузе' : 'Показать  информацию о грузе' }
            </Button>
            {(order.type === OrdersListType.Offer || order.type === OrdersListType.Free) && user.authenticated &&
              <div className="order_info_control_section">
                <div className="order_info_control_info">До начала заказа осталось {getTimeToStartOrder()} минут<br />До первой точки: {'00'} км</div>
                <Button type="primary" size="large" onClick={takeOrder}>ПРИНЯТЬ ЗАКАЗ</Button>
              </div>
            }

            {order.type === OrdersListType.Set && user.authenticated &&
            <div className="order_info_control_section">
              <div className="order_info_control_info">До начала заказа осталось {getTimeToStartOrder()} минут<br />До первой точки: {getDistanceToStartOrder()} км</div>
              <Button type="green" size="large" onClick={startPlay}>НАЧАТЬ ВЫПОЛНЕНИЕ</Button>
            </div>
            }

            {!user.authenticated &&
              <div className="order_info_control_section">
                <Button type="primary" size="large" onClick={()=>{
                  dispatch(popSystemStatus());
                  dispatch(setSystemStatus(SystemStatus.Registration))
                }}>ПРИНЯТЬ ЗАКАЗ</Button>
              </div>
            }
          </div>
        </BottomWindow>

    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  state: {
    orderState: state.ordersReducer,
  }
});

export default connect(mapStateToProps, { })(OrderFullCard);

