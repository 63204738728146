export const SET_STATISTIC = 'SET_STATISTIC';

export interface SetStatisticAction {
  type: typeof SET_STATISTIC;
  debit: number;
  credit: number;
  price: number;
  fuel_rate: number;
  distance: number;
}

export type StatiscticActionTypes = SetStatisticAction;

export interface StatisticState {
  debit: number;
  credit: number;
  price: number;
  fuel_rate: number;
  distance: number;
}