import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {Menu, OrdersWindow, StatusBtn} from "../Elements/elements";
import HereMap from "../Elements/HereMap/HereMap";
import {isSystemStatusActive} from "../../redux/reducers/system/reducers";
import {SystemStatus} from "../../@types";

const MainScreen: React.FC = () => {
  const state = useSelector((state: RootState) => ({
        user: state.userReducer,
      system: state.systemReducer,
  }));

  const { user, system } = state;

  return (
    <>
      <Menu />
      <StatusBtn />
      <HereMap />
      {/*{user.authenticated &&*/}
      {/*  <>*/}
      {/*    <OrderInfoWindow />*/}
      {/*    <AcceptingOrderWindow />*/}
      {/*  </>*/}
      {/*}*/}
      {!isSystemStatusActive(SystemStatus.PlayWindow, state.system.statuses ) && <OrdersWindow /> }
    </>
  )
}

export default MainScreen;
