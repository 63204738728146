import {OrderFullInfo} from "../../../../redux/reducers/orders/@types";
import {connect, useDispatch} from "react-redux";
import Button from "../../../Elements/Button/Button";
import {RootState} from "../../../../redux/store";
import "./PointCard.css";
import React, {useState} from "react";

interface ILocalProps {
    order: OrderFullInfo,
    orderPoint: number,
    currentPoint: number,
    onShowRoute:Function,
    onShowMap:Function,
    setOnPlace:Function,
}

const PointCard: React.FC<ILocalProps> = ({order,orderPoint, currentPoint,onShowRoute,onShowMap,  setOnPlace}) => {
    const dispatch = useDispatch();
    const [isFullMode, setFullMode] = useState<boolean>(false);

    const toggleFullMode = () =>{
       setFullMode(!isFullMode)
    };

    let class_line = "play_window_row play_window_row_line";
    const item = order.routes[orderPoint];
    if (orderPoint === order.routes.length-1)  class_line = "play_window_row play_window_row_line no_border";
    return (
        <>

            <div className="point_card_block">
                <div className="play_window_row">
                    <div className="point_number">{orderPoint + 1}</div>
                    <div className="point_address">{item.adress}</div>
                </div>
                <div className={class_line}>
                    <Button type="grey" size="small" className="Button45"
                            onClick={()=> { if (onShowRoute) onShowRoute() }}>На карте
                    </Button>
                    <Button type="darkgrey" size="small" className="Button45"
                            onClick={()=> { if (onShowMap) onShowMap() }}>Маршрут
                    </Button>
                </div>
                <div className={class_line+ " center"}>
                    {item.adress_comment &&  <div className="order_info_comment">{item.adress_comment}</div>}
                    <div className="order_info_point_detail">
                    { isFullMode &&
                        <>
                        <div className="order_info_persons">
                            <div className="order_info_persons_title">Контактные лица:</div>
                            {item.contact_persons.map( (x,i)=> <div key={i+'cop'} className="order_info_one_person">{x.full_name ? x.full_name : 'ФИО не указано' }</div> )}
                            {item.contact_persons.length == 0 ? <div className="no_persons">Неуказаны</div> : ''}
                        </div>
                        {item.company  && <div className="order_info_from">От кого: <span className="contact_name">{item.company }</span></div> }
                        <div className="order_info_task">
                            <div className="order_info_task_header">Задача</div>
                            <div className="order_info_task_comment">на точке быть вежливым</div>
                            <div className="order_info_task_what_to_do">{item.what_to_do}</div>
                            {item.action_loading ? <div className="order_info_action"> Загрузка </div> : ''}
                            {item.action_unloading ? <div className="order_info_action"> Разгрузка </div> : ''}
                            {item.action_documents ? <div className="order_info_action"> Получение документов </div> : ''}
                            {item.action_forwarder ? <div className="order_info_action"> Экспедирование груза </div> : ''}
                        </div>
                        </>
                    }
                        {orderPoint == currentPoint &&
                            <div >
                                <Button type={"primary"} size={"large"}
                                    onClick={() => {if (setOnPlace) setOnPlace()}}
                                >Я прибыл на место</Button>
                            </div>
                        }
                    <div className="link_show_point" onClick={()=> { toggleFullMode() }}>
                        { <span>{ isFullMode ? 'Скрыть детали' : 'Показать детали' }</span>}
                    </div>
                    </div>
                </div>
                <div className={class_line}>
                    <div>&nbsp;</div>
                </div>
            </div>
        </>
    );
};


const mapStateToProps = (state: RootState) => ({
    state: {
        orderState: state.ordersReducer,
    }
});

export default connect(mapStateToProps, { })(PointCard);
