import { 
    AllUsersType,
    command,
    message,
    reciveUser,
    SetAllUsersActionType,
    setChatMessagesIsReaded,
    setChatUsersClearActionType,
    setCommandsActionType,
    setMessagesActionType,
    SetUsersActionType, 
    SET_CHAT_ALL_USERS, 
    SET_CHAT_COMMANDS, 
    SET_CHAT_MESSAGES, 
    SET_CHAT_MESSAGES_IS_READED,
    SET_CHAT_USERS_CLEAR,
    SET_CHAT_USERS_FULL_INFO, 
    user
} from "./@types"

export const setChatUsersFullInfoAction = (users: reciveUser[]): SetUsersActionType => ({
    type: SET_CHAT_USERS_FULL_INFO,
    users
})

export const setChatAllUsersAction = (allUsersData: AllUsersType[]): SetAllUsersActionType => ({
    type: SET_CHAT_ALL_USERS,
    allUsersData
})

export const setChatCommandsAction = (commands: command[]): setCommandsActionType => ({
    type: SET_CHAT_COMMANDS,
    commands
})

export const setChatMessagesAction = (messages: message[]): setMessagesActionType => ({
    type: SET_CHAT_MESSAGES,
    messages
})

export const setChatMessagesIsReadedAction = (userID: string): setChatMessagesIsReaded => ({
    type: SET_CHAT_MESSAGES_IS_READED,
    userID
})

export const setChatUsersCleareAction = (): setChatUsersClearActionType => ({
    type: SET_CHAT_USERS_CLEAR
})