import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {RootState} from "../../../redux/store";
import {SetStatusAction, UserState} from "../../../redux/reducers/user/@types";
import {
  SystemState,
  ToggleDatepickerWindowAction,
  ToggleOrdersWindowAction
} from "../../../redux/reducers/system/@types";
import {SystemStatus, UserStatus} from "../../../@types";
import {setStatus} from "../../../redux/reducers/user/actions";
import {
  popSystemStatus,
  setMessage,
  toggleDatepickerWindow,
  toggleOrdersWindow
} from "../../../redux/reducers/system/actions";
import DayPicker, {DayModifiers} from "react-day-picker";
import CloseSVG from "../../App/svg/close.svg";
import ChevronLeftSVG from "../../App/svg/chevron_left.svg";
import ChevronRightSVG from "../../App/svg/chevron_right.svg";
import {ReactSVG} from "react-svg";
import moment from "moment";
import {userAPI} from "../../../api";
import "react-day-picker/lib/style.css";
import "./DatePickerWindow.css";
import {isSystemStatusActive} from "../../../redux/reducers/system/reducers";
import {getStatusWorking} from "../../../redux/reducers/user/reducers";
import Button from "../Button/Button";
import {EnumStatusResponse} from "../../../api/@types";

interface IProps {
  state: {
    system: SystemState;
    user: UserState;
  },
  toggleDatepickerWindow: () => ToggleDatepickerWindowAction,
  setStatus: (status: UserStatus) => SetStatusAction,
  toggleOrdersWindow: (value:boolean) => ToggleOrdersWindowAction
}

interface IState {
  days: {date:string,status:string}[];
}

const MONTHS = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];
const WEEKDAYS_LONG = [
  'Воскресенье',
  'Понедельник',
  'Вторник',
  'Среда',
  'Четверг',
  'Пятница',
  'Суббота',
];
const WEEKDAYS_SHORT = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];

const NavBar = ({ onPreviousClick, onNextClick }: any) => {
  return (
    <div className="datepicker_navbar">
      <div className="datepicker_navbar_item" onClick={() => onPreviousClick()}><ReactSVG src={ChevronLeftSVG} /></div>
      <div className="datepicker_navbar_item" onClick={() => onNextClick()}><ReactSVG src={ChevronRightSVG} /></div>
    </div>
  )
}

const DatePickerWindow: React.FunctionComponent<IProps> = ({ state, toggleDatepickerWindow, setStatus, toggleOrdersWindow }) => {
  const { user } = state;
  const { datepickerWindow } = state.system;
  const [localState, setLocalState] = useState<IState>({ days: [] });
  const [wasChange, setWasChange] = useState<{date: Date, modifiers: DayModifiers} | null>(null);


  const dispatch : any = useDispatch();

  const setDayOff = () => {
    if (!wasChange) return;

    userAPI.setDriverStatus({
        id: user.driver_id,
        status: user.status,
        dates: localState.days
    }).then((res) => {
        if (res.status !== 200) {
          console.log('Ошибка обновления данных')
          return;
        }
        if (res.data.status == EnumStatusResponse.success) {
          hideDatepickerWindow();
        }
      }).catch();

  };

  const renderDay = (date: Date, modifiers: DayModifiers) => {
    const days =  localState.days.findIndex(x => x.status === UserStatus.DayOff && x.date.indexOf(moment(date).format("YYYY-MM-DD")) === 0) !== -1 ;

    return (
      <div className={days ? "datepicker_day datepicker_day_off" : "datepicker_day"}><div>{moment(date).format("D")}</div></div>
    );
  };

  useEffect(() => {
    userAPI.getDriverStatus(user.driver_id).then((res) => {
      if (!res.data || res.status!==200) return;
      setStatus(getStatusWorking(res.data.status, res.data.dates));

      setLocalState((s) => ({
        ...s,
        days: res.data.dates
      }));
    }).catch( e=> console.log(e));

  }, []);

  const dayChanging = (date: Date, modifiers: DayModifiers) =>{
    setWasChange({date, modifiers});
    let wday = {date: moment(date).format("YYYY-MM-DD"), status: UserStatus.DayOff};
    let dateFound = false;
    let days = localState.days.map( x=> {
      if (moment(x.date) <= moment() ) return x;
      if (moment(x.date) == moment(date)) {
        dateFound = true;
        x.status = UserStatus.DayOff;
      }
      x.status = UserStatus.Working;
      return x;
    });
    if (!dateFound) days.push(wday);
    setLocalState((s) => ({
      ...s,
      days: days
    }));
  };

  const hideDatepickerWindow = () =>{
    // toggleOrdersWindow(true) ;
    // toggleDatepickerWindow();
    dispatch(popSystemStatus());
  };

  return (
    <>
      {isSystemStatusActive(SystemStatus.DatePickerWindow, state.system.statuses)  &&
        <div className="datepicker_window_container">
          <div className="datepicker_window">
            <div className="window_close_btn" onClick={hideDatepickerWindow}><ReactSVG src={CloseSVG} /></div>

            <DayPicker 
              className="datepicker"
              locale="it"
              navbarElement={<NavBar />}
              months={MONTHS}
              weekdaysLong={WEEKDAYS_LONG}
              weekdaysShort={WEEKDAYS_SHORT}
              firstDayOfWeek={1}
              showOutsideDays={false}
              enableOutsideDaysClick={false}
              modifiers={{
                disabled: {
                  before: moment().add(3, "days").toDate()
                }
              }}
              onDayClick={dayChanging}
              renderDay={renderDay}
            />

            <div className="datepicker_info">
              <div className="datepicker_info_item">Вы можете взять выходной на один день через 3 дня после сегодняшнего дня</div>
              <div className="datepicker_info_item">У вас может быть 2 выходных в месяц</div>
            </div>
            <div className="datepicker_save">
              <Button type={wasChange ? "primary" : "grey"} onClick={setDayOff} >
                СОХРАНИТЬ
              </Button>
            </div>
          </div>
        </div>
      } 
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  state: {
    system: state.systemReducer,
    user: state.userReducer
  }
});

export default connect(mapStateToProps, { toggleDatepickerWindow, setStatus, toggleOrdersWindow })(DatePickerWindow);
