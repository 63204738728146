import {SystemStatus} from "../../../@types"
import {
  CLEAR_MESSAGE,
  CLEAR_SYSTEM_STATUS,
  ClearSystemStatusAction,
  CLOSE_ALL_WINDOWS,
  CloseAllWindowsAction,
  EnumWindowMessageType,
  POP_SYSTEM_STATUS,
  PopSystemStatusAction,
  REMOVE_SYSTEM_STATUS,
  RemoveSystemStatusAction,
  SET_CURRENT_ID,
  SET_MESSAGE,
  SET_OVERLAY,
  SET_SYSTEM_STATUS,
  SET_VISIBILITY_OVERLAY,
  SET_WINDOW_MESSAGE,
  SetCurrentIdAction,
  SetOverlayAction,
  SetSystemStatusAction,
  SetVisibilityOverlayAction,
  ShowWindowMessageAction,
  TOGGLE_DATEPICKER_WINDOW,
  TOGGLE_DRIVER_STATUS_WINDOW,
  TOGGLE_ORDER_BOOK_WINDOW,
  TOGGLE_ORDERS_WINDOW,
  TOGGLE_PARTNERSHIP_WINDOW,
  TOGGLE_PROFILE_WINDOW,
  TOGGLE_SYSTEM_STATUS,
  ToggleDatepickerWindowAction,
  ToggleDriverStatusWindowAction,
  ToggleOrderBookWindowAction,
  ToggleOrdersWindowAction,
  TogglePartnershipWindowAction,
  ToggleProfileWindowAction,
  ToggleSystemStatusAction
} from "./@types";
import {SystemMessage} from "../../../api/@types";


export const setOverlay = (overlay: boolean, zIndex?: number): SetOverlayAction => ({
  type: SET_OVERLAY,
  overlay,
  zIndex
});

export const toggleOrdersWindow = (visible: boolean): ToggleOrdersWindowAction => ({
  type: TOGGLE_ORDERS_WINDOW,
  visible,
});



export const toggleDatepickerWindow = (): ToggleDatepickerWindowAction => ({
  type: TOGGLE_DATEPICKER_WINDOW,
});

export const toggleDriverStatusWindow = (): ToggleDriverStatusWindowAction => ({
  type: TOGGLE_DRIVER_STATUS_WINDOW,
});

export const toggleOrderBookWindow = (): ToggleOrderBookWindowAction => ({
  type: TOGGLE_ORDER_BOOK_WINDOW
});

export const toggleProfileWindow = (): ToggleProfileWindowAction  => ({
  type: TOGGLE_PROFILE_WINDOW
});

export const togglePartnershipWindow = (): TogglePartnershipWindowAction  => ({
  type: TOGGLE_PARTNERSHIP_WINDOW
});

export const closeAllWindows = (): CloseAllWindowsAction => ({
  type: CLOSE_ALL_WINDOWS
});

export const setMessage = (message: SystemMessage) => ({
  type: SET_MESSAGE,
  ...message,
});

export const clearMessage = () => ({
  type: CLEAR_MESSAGE,
  id: "",
  statuses: [],
  message: "",
  order_id: "",
});

export const setSystemStatus = (status: SystemStatus): SetSystemStatusAction => ({
	type: SET_SYSTEM_STATUS,
	status
});

export const popSystemStatus = (): PopSystemStatusAction => ({
  type: POP_SYSTEM_STATUS,

});

export const removeSystemStatus = (status: SystemStatus): RemoveSystemStatusAction => ({
  type: REMOVE_SYSTEM_STATUS,
  status
});

export const clearSystemStatus = (): ClearSystemStatusAction => ({
  type: CLEAR_SYSTEM_STATUS,
});

export const setVisibilityOverlay = (visible:boolean): SetVisibilityOverlayAction => ({
  type: SET_VISIBILITY_OVERLAY,
  visible
});

export const toggleSystemStatus = (status: SystemStatus): ToggleSystemStatusAction => ({
  type: TOGGLE_SYSTEM_STATUS,
  status
});

export const setCurrentId = (id: string): SetCurrentIdAction => ({
	type: SET_CURRENT_ID,
	id
});

export const showWindowMessage = ( msg : {type: EnumWindowMessageType, text:string}): ShowWindowMessageAction => ({
	type: SET_WINDOW_MESSAGE,
    message_type:msg.type,
    message_text:msg.text
});

export const  showMessageTrunk = ( msg : {type: EnumWindowMessageType, text:string}, dispatch:any) =>{
  dispatch(showWindowMessage(msg));
  dispatch(setSystemStatus(SystemStatus.MessageWindow));
};
