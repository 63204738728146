import axios, { AxiosResponse } from "axios";
import { apiOrderUrl, apiDriverUrl, daDataToken } from "../deployment";
import { UserStatus, OrdersListType } from "../@types";
import { Moment } from "moment";
import { sendUsersDataType, SystemMessage } from "./@types";

const instanceOrder = axios.create({
	baseURL: apiOrderUrl,
});

const instanceDriver = axios.create({
  baseURL: apiDriverUrl
});

const instanceLocal = axios.create({
  baseURL: 'http://localhost:3009'
});

const dadata = axios.create({
	baseURL: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs',
	headers: {
		Authorization: "Token " + daDataToken,
		ContentType: "application/json",
		Accept: "application/json"
	}
});

export const userAPI = {
	authMe() {
		return new Promise<{ driver_id: string; full_name: string; status: UserStatus; error_code?: number; error_message?: string }>((resolve, reject) => {
			// resolve({
			//   driver_id: "734c6dbb-973a-4e00-8c72-2c8c4b4d395f",
			//   full_name: "Шпагин А.В.",
			//   status: UserStatus.Working
			// });
			// reject({
			//   error_code: 1,
			//   error_message: "Unathorized"
			// });
		});
	},

	reserveOrder(driver_id:string, order_id:string) {
		const body = {
			"driver_id": driver_id,
			"order_id": order_id,
			"confirmed": true,
			"bad_orders": false,
			"busy": false
		};
		return instanceDriver.post('/reserve-driver', body )
			.then(response => response)
			.catch(error => error);
	},

	auth(phone: string, code: string) {
		let requestData = {phone_number: phone, sms_code: code};
		return instanceDriver.post('/auth', requestData)
			.then(response => response)
			.catch(error => error);
	},

	getDriverStatus(driver_id: string) {
		return instanceDriver.get('/driver-status?id='+driver_id )
			.then(response => response)
			.catch(error => error);
	},

	setDriverStatus({id, status, dates}: { id: string, status: UserStatus, dates: {date:string, status:string}[] }) {
		return instanceDriver.put('/driver-status', {id, dates})
			.then(response => response)
		  	.catch(error => error);
		// return new Promise<{ id: string; status: UserStatus; error_message?: string; }>((resolve, reject) => {
		// 	resolve({
		// 		id,
		// 		status
		// 	});
		// });
	},

	getUserInfo(id:string){
		return instanceDriver.get(`/drivers?driver_id=${id}`);
	},

	statistic({driver_id, date_from, date_to}: { driver_id: string, date_from: string, date_to: string }) {
		return instanceDriver.get(`/statistic?driver_id=${driver_id}&date_from=${date_from}&date_to=${date_to}`);
		return new Promise<{ debit: number; credit: number; price: number; fuel_rate: number; distance: number; }>((resolve, reject) => {
			resolve({
				debit: 15590,
				credit: 3223,
				price: 723,
				fuel_rate: 8,
				distance: 154
			});
		});
	},

	getArticles() {
		return instanceDriver.get("/articales");
	},

	getSystemMessage(driver_id: string): Promise<AxiosResponse<SystemMessage[]>> {
		return instanceDriver.get(`/system-message?driver_id=${driver_id}`);
	},

	getUsedOrders(data : {driver_id: string, start: number, count: number}): Promise<any> {
		return instanceDriver.get(`/orders?driver_id=${data.driver_id}&start=${data.start}&count=${data.count}`);
	},

};

export const ordersAPI = {
	// Получение информации о заказе
	getOrder(id: string,is_demo:boolean = false) {
		return instanceOrder.get("/orders?id=" + id+ (is_demo ? '&demo=true' : ''));
	},

	getRoute(order_id:string) {
		return instanceOrder.get(`/routes?order_id=${order_id}`);
	},

	// Получение списка доступных заказов
	getList(driver_id: string, type: OrdersListType): Promise<any> {
		if (driver_id) {
			 return instanceDriver.get(`/list-orders?driver_id=${driver_id}&type=${type}`);
			// return instanceLocal.get(`/list-orders?driver_id=${driver_id}&type=${type}`);
		}
		else return instanceDriver.get(`/list-orders?&type=reserve&driver_id=a04ffdba-d45a-11eb-8ea7-00155d010f14`);

	},

	getBodyOptionCharacteristics() {
		return instanceOrder.get("/body-option-characteristics");
	},

	getBodyOptionCharacteristicsValues() {
		return instanceOrder.get("/body-option-characteristics-values");
	},

	getBodyTypes() {
		return instanceOrder.get("/body-types");
	},

	getBodyOptions() {
		return instanceOrder.get("/body-options");
	},

	getCarTypes() {
		return instanceOrder.get("/car-types-tree");
	},

	getAdditionalRequirements() {
		return instanceOrder.get("/additional-requirements");
	}
}

export const phoneAPI = {
	sendSms(phone: string) {
		let requestData = {phone};
		return instanceOrder.post('/send-sms-code', requestData)
			.then(response => response)
			.catch(error => error);
	},

	checkSms(phone: string, code: string) {
		return instanceOrder.get('/check-sms-code?phone=' + phone + '&code=' + code)
			.then(response => response)
			.catch(error => error);
	},

	checkPhone(phone: string) {
		return instanceOrder.get('/check-tin-phone?phone=' + phone)
			.then(response => response)
			.catch(error => error);
	}
};

export const tinAPI = {
	checkTin(tin: string) {
		return instanceOrder.get('/check-tin?bik=' + tin)
			.then(response => response)
			.catch(error => error);
	}
};

export const vehicleAPI = {
	getCarTypes() {
		return instanceDriver.get('/car-types')
			.then(response => response)
			.catch(error => error);
	},

	getBodyOptions(carTypeId: string) {
		return instanceDriver.get('/body-options?car_type_id=' + carTypeId)
			.then(response => response)
			.catch(error => error);
	},

	getBodyOptionChs(bodyOptionId: string, bodyTypeId: string, carTypeId: string) {
		return instanceDriver.get('/body-option-characteristics?body_option_id=' +
			bodyOptionId + '&body_type_id=' + bodyTypeId +
			'&car_type_id=' + carTypeId)
			.then(response => response)
			.catch(error => error);
	},

	getBodyOptionChValues(bodyOptionChId: string, carTypeId: string) {
		return instanceDriver.get('/body-option-characteristics-values' +
			'?body_option_characteristics_id=' + bodyOptionChId +
			'&car_type_id=' + carTypeId)
			.then(response => response)
			.catch(error => error);
	},

	getCarPasses() {
		return instanceDriver.get('/car-pass')
			.then(response => response)
			.catch(error => error);
	}
};

export const registrationAPI = {
	postData(data: any) {
		return instanceDriver.post('/drivers', data)
			.then(response => response)
			.catch(error => error);
	},

	postFile(data: any) {
		return instanceDriver.post('/driver-files', data)
			.then(response => response)
			.catch(error => error);
	},

	getFile(id: string) {
		return instanceDriver.get('/driver-files?id=' + id)
			.then(response => {
				if (!response.data.file) {
					console.log('Error fetching file');
					return null;
				}
				return response.data.file;
			})
			.catch(error => error);
	}
}

export const referralsAPI = {
	getData(driverId: string) {
		return instanceOrder.get('/referals?driver_id=' + driverId)
			.then(response => response)
			.catch(error => error);
	},

	sendDriver(driverId: string, phone: string) {
		let data = {
			driver_id: driverId,
			phone: phone,
			type: 'driver'
		}
		return instanceOrder.post('/referals', data)
			.then(response => response)
			.catch(error => error);
	},

	sendClient(driverId: string, phone: string, name: string, email: string) {
		let data = {
			driver_id: driverId,
			phone: phone,
			full_name: name,
			email: email,
			type: 'client'
		}
		return instanceOrder.post('/referals', data)
			.then(response => response)
			.catch(error => error);
	}
}

export const toBase64 = (blob: Blob) => new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.readAsDataURL(blob);
	reader.onload = () => resolve(reader.result);
	reader.onerror = error => reject(error);
});

export const addressAPI = {
	getAddressByCoords(lat: string, lon: string) {
		let requestData = { lat, lon };
		return dadata.post('/geolocate/address', requestData)
			.then(response => response)
			.catch(error => error);
	},

	getCountries(query: string) {
		let requestData = { query, count: 5 };
		return dadata.post('/suggest/country', requestData)
			.then(response => response)
			.catch(error => error);
	},

	getSuggestions(query: string) {
		let requestData = {
			query,
			count: 5,
			locations: [{ country: "*" }],
			from_bound: { value: "country" },
			to_bound: { value: "street" }
		};
		return dadata.post('/suggest/address', requestData)
			.then(response => response)
			.catch(error => error);
	},

	splitAddress(query: string) {
		let requestData = {
			query,
			count: 1,
			locations: [{ country: "*" }]
		};
		return dadata.post('/suggest/address', requestData)
			.then(response => response)
			.catch(error => error);
	}
}

export const sendPhoto = async (blob: Blob, name: string) => {
	if (!blob) return null;
	const data = await toBase64(blob) as string;
	let response = await registrationAPI.postFile({
		name: name,
		data: data.split(',')[1]
	});
	if (response.status === 200 && response.data) {
		return response.data;
	} else {
		return null;
	}
}

export const toBlob = (base64: string, contentType = 'image/jpg', sliceSize = 512) => {
	const byteCharacters = atob(base64);
	const byteArrays = [];
	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);
		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}
  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

export const getPhoto = async (id: string) => {
	let data = await registrationAPI.getFile(id);
	if (data) {
		return toBlob(data);
	} else {
		return null;
	}	
}

export const loadPhotos = (ids: string[]) => {
	let photos: Blob[] = [];
	let blob: Blob | null;
	ids.forEach(async (id) => {
		blob = await getPhoto(id);
		if (blob) photos.push(blob);
	});
	return photos;
}

export const getAddress = async (value: string) => {
	let response: any = await addressAPI.splitAddress(value);
	if (response && response.data && response.data.suggestions && response.data.suggestions.length > 0) {
		return response.data.suggestions[0];
	} else {
		return null;
	}
}

export const chatAPI = {
    getMessages(driver_id: string, group_id: string) {
        return instanceDriver.get(`/chat-messages?driver_id=${driver_id}&group_id=${group_id}`)
                                .then(response => response)
                                .catch(error => error)
    },
    getComands(driver_id: string, order_id: string) {
        return instanceDriver.get(`/chat-commands?driver_id=${driver_id}&order_id=${order_id}`)
                                .then(response => response)
                                .catch(error => error)
    },
    getAllUsers(driver_id: string, order_id: string) {
        return instanceDriver.get(`/chat-all-users?driver_id=${driver_id}&order_id=${order_id}`)
                                .then(response => response)
                                .catch(error => error)
    },
    getUsers(driver_id: string, order_id: string) {
        return instanceDriver.get(`/chat-users?driver_id=${driver_id}&order_id=${order_id}`)
                                .then(response => response)
                                .catch(error => error)
    },
	getGroups(driver_id: string) {
		return instanceDriver.get(`/chat-groups?driver_id=${driver_id}`)
								.then(response => response)
								.catch(error => error)
	},


    
    sendUsers(data: sendUsersDataType) {
        return instanceDriver.post(`/chat-users`, data)
                                .then(response => response)
                                .catch(error => error)
    },
    sendMessages(driver_id: string, dialog_id: string, message: string) {
        let requestData = {driver_id, message, dialog_id}
        return instanceDriver.post(`/chat-messages`, requestData)
                                .then(response => response)
                                .catch(error => error)
    }
}
