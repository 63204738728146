import {
	SET_REGISTRATION_STATUS,
	RESTORE_REGISTRATION_STATUS,
	RegistrationActionTypes,
	RegistrationState
} from "./@types";

let initialState: RegistrationState = {
  status: null,
  previous_status: null
}

const registrationReducer = (state = initialState, action: RegistrationActionTypes): RegistrationState => {
  switch (action.type) {
    case SET_REGISTRATION_STATUS:
      return {
        ...state,
        status: action.status,
        previous_status: state.status
      }
    case RESTORE_REGISTRATION_STATUS:
      return {
        ...state,
        status: state.previous_status,
        previous_status: null
      }
    default:
      return state;
  }
}

export default registrationReducer;
