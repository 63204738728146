export enum UserStatus {
  Working = "Готов",
  DayOff = "Отказался",
  Unauthenticated = "unauthenticated"
}

export enum OrdersListType {
  Free = "free",
  Set = "set",
  Offer = "offer",
  All = "all",
  Last= "last"
}

export enum RegistrationStatus {
	CheckPhone = 'check_phone',
	OwnerSelect = 'owner_select',
	BankData = 'bank_data',
	PassportData = 'passport_data',
	LicenseData = 'license_data',
	Vehicle = 'vehicle',
	Finalize = 'finalize',
	QuickRegistration = 'quick_registration'
}

export enum SystemStatus {
	Main = 'main_screen',
	Registration = 'registration',
	Profile = 'profile',
	Help = 'help',
	Statistic = 'statistic',
	Partnership = 'partnership',
	OrderBook = 'order_book',
	Menu = 'show_menu',
	Overlay = 'overlay',
	FullOrderCard = 'full_order_card',
	DriverStatusWindow = 'driver_status_window',
	DatePickerWindow = 'date_picker_window',
	OrderInfoWindow = 'order_info_window',
	CloseRoute = 'close_route',
	WaitingWindow = 'waiting_window',
	MessageWindow = 'message_window',
	PlayWindow = 'play_window',
}

export enum EnumOrderStatus{
	SET = 'set',
	OFFER = 'offer'
}
export const editingChars = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'Tab', 'Home', 'End'];
