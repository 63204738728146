import React, { FC } from 'react'
import { user } from '../../../redux/reducers/chatReducers/@types'
import s from './ChatMessages.module.scss'
import userChatBackArrow from '../../../images/userChatBackArrow.svg'
import newMessagesIndicator from '../../../images/newMessagesIndicator.svg'
import { useSelector } from 'react-redux'
import { getChatMessagesSelector } from '../../../redux/reducers/chatReducers/chatSelectors'

export const ChatHeader: FC<propTypes> = ({ user, setVisability, setUserID, logistID }) => {

    const messages = useSelector(getChatMessagesSelector)

    const closeChat = () => {
        setVisability(true)
    }

    const onSpanClick = () => {
        setUserID(logistID)
    }

    return (
        <div className={s.chatHeaderWrapper}>
            <div className={s.chatIconWrapper}>
                <img alt={`Выход из чата с ${user.full_name}`} src={userChatBackArrow} onClick={closeChat} />
                {messages.filter(message => message.read === false).length 
                                                                                ? <img alt={`В одном из чатов есть непрочитанные сообщения`} src={newMessagesIndicator} /> 
                                                                                : ''}
            </div>
            <div className={s.chatHeader}>{user.type === 'logist' ? 'Логист' : user.full_name}</div>
            <div>{user.type !== 'logist' && logistID && <span onClick={onSpanClick}>Перейти в чат с логистом</span>}</div>
        </div>
    )
}


type propTypes = {
    user: user
    setVisability: (value: boolean) => void
    setUserID: (value: string) => void
    logistID: string
}