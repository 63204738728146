import React from "react";
import {connect, useDispatch} from "react-redux";
import {RootState} from "../../../redux/store";
import {UserState} from "../../../redux/reducers/user/@types";
import {SystemState,} from "../../../redux/reducers/system/@types";
import {popSystemStatus, setSystemStatus,} from "../../../redux/reducers/system/actions";
import {ReactSVG} from "react-svg";
import CloseSVG from "../../App/svg/close.svg";
import {Button} from "../elements";
import "./DriverStatusWindow.css";
import {SystemStatus, UserStatus} from "../../../@types";

interface IProps {
  state: {
    user: UserState;
    system: SystemState;
  }
}

const DriverStatusWindow: React.FunctionComponent<IProps> = ({ state  }) => {

  const { status } = state.user;
  const dispatch : any = useDispatch();

  const hideDriverStatusWindow = () =>{
    dispatch(popSystemStatus())
  };

  const showDatepickerWindow = () =>{
    dispatch(setSystemStatus(SystemStatus.DatePickerWindow))
  };

  return (
    <>
      {status === UserStatus.Working &&
        <div className="driver_status_window">
          <div className="window_close_btn" onClick={hideDriverStatusWindow}><ReactSVG src={CloseSVG} /></div>
          <div className="driver_status_window_title">Вы работаете</div>
          <div className="driver_status_window_content">
            <Button type="grey" onClick={showDatepickerWindow} style={{ width: "100%" }}>Выбрать выходной</Button>
            <div className="driver_status_window_problem">Сообщить о проблеме</div>
            <div className="driver_status_window_info">Вы можете прервать работу при<br />возникновении поломок автомобиля или других непреодолимых обстоятельствах</div>
          </div>
        </div>
      }

      {  status === UserStatus.DayOff &&
        <div className="driver_status_window">
          <div className="window_close_btn" onClick={hideDriverStatusWindow}><ReactSVG src={CloseSVG} /></div>
          <div className="driver_status_window_title">У вас выходной</div>
          <div className="driver_status_window_content">
            <div className="driver_status_window_info">Сегодняшний день Вы указали как выходной</div>
            <div className="driver_status_window_info">В выходные дни Вы не можете выполнять или брать заказы и получать предложенные или назначенные заказы</div>
            <Button type="grey" onClick={showDatepickerWindow} style={{ width: "100%" }}>Изменить выходные</Button>
          </div>
        </div>
      }
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  state: {
    user: state.userReducer,
    system: state.systemReducer
  }
});

export default connect(mapStateToProps, {})(DriverStatusWindow);
