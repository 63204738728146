export type SystemMessage = {
  id: string;
  statuses: { status: string; status_id: string; }[];
  order_id: string;
  message: string;
}

export enum EnumStatusResponse {
  success= "success"
}

export type sendUsersDataType = {
  driver_id: string
  order_id: string
  group_id: string
  id: string
}