import { Driver } from "../../../../components/Elements/Containers/@types";

export const SET_CAR_TYPE_ENTERED = 'SET_CAR_TYPE_ENTERED';
export const SET_BODY_OPTION_ENTERED = 'SET_BODY_OPTION_ENTERED';
export const SET_BODY_OPTION_CHS_ENTERED = 'SET_BODY_OPTION_CHS_ENTERED';
export const SET_CAR_PASS_ENTERED = 'SET_CAR_PASS_ENTERED';
export const SET_HYDROBOARD_ENTERED = 'SET_HYDROBOARD_ENTERED';
export const SET_RAMP_ENTERED = 'SET_RAMP_ENTERED';
export const SET_LENGTH_ENTERED = 'SET_LENGTH_ENTERED';
export const SET_WIDTH_ENTERED = 'SET_WIDTH_ENTERED';
export const SET_HEIGHT_ENTERED = 'SET_HEIGHT_ENTERED';
export const SET_GARAGE_ENTERED = 'SET_GARAGE_ENTERED';
export const ADD_VEHICLE_PHOTO = 'ADD_VEHICLE_PHOTO';
export const REPLACE_VEHICLE_PHOTO = 'REPLACE_VEHICLE_PHOTO';
export const CLEAR_VEHICLE_PHOTOS = 'CLEAR_VEHICLE_PHOTOS';
export const SET_MEDICAL_BOOK = 'SET_MEDICAL_BOOK';
export const SET_DRIVER_LOADER = 'SET_DRIVER_LOADER';
export const SET_LICENSE_PLATE_ENTERED = 'SET_LICENSE_PLATE_ENTERED';
export const SET_VIN_ENTERED = 'SET_VIN_ENTERED';
export const SET_BRAND_MODEL_ENTERED = 'SET_BRAND_MODEL_ENTERED';
export const SET_VEHICLE_TYPE_ENTERED = 'SET_VEHICLE_TYPE_ENTERED';
export const SET_VEHICLE_CATEGORY_ENTERED = 'SET_VEHICLE_CATEGORY_ENTERED';
export const SET_YEAR_ENTERED = 'SET_YEAR_ENTERED';
export const SET_ECOLOGY_ENTERED = 'SET_ECOLOGY_ENTERED';
export const SET_PTS_SERIES_ENTERED = 'SET_PTS_SERIES_ENTERED';
export const SET_PTS_NUMBER_ENTERED = 'SET_PTS_NUMBER_ENTERED';
export const SET_STS_SERIES_ENTERED = 'SET_STS_SERIES_ENTERED';
export const SET_STS_NUMBER_ENTERED = 'SET_STS_NUMBER_ENTERED';
export const SET_MAX_WEIGHT_ENTERED = 'SET_MAX_WEIGHT_ENTERED';
export const SET_LOAD_WEIGHT_ENTERED = 'SET_LOAD_WEIGHT_ENTERED';
export const ADD_CERTIFICATE_PHOTO = 'ADD_CERTIFICATE_PHOTO';
export const REPLACE_CERTIFICATE_PHOTO = 'REPLACE_CERTIFICATE_PHOTO';
export const CLEAR_CERTIFICATE_PHOTOS = 'CLEAR_CERTIFICATE_PHOTOS';
export const ASSIGN_DRIVER = 'ASSIGN_DRIVER';
export const DISMISS_DRIVER = 'DISMISS_DRIVER';
export const DISMISS_ALL = 'DISMISS_ALL';

export interface SetCarTypeEnteredAction {
  type: typeof SET_CAR_TYPE_ENTERED;
  car_type_entered: boolean;
  car_type?: string;
}

export interface SetBodyOptionEnteredAction {
  type: typeof SET_BODY_OPTION_ENTERED;
  body_option_entered: boolean;
  body_option?: string;
}

export interface SetBodyOptionChsEnteredAction {
  type: typeof SET_BODY_OPTION_CHS_ENTERED;
  body_option_chs_entered: boolean;
  body_option_chs?: any;
}

export interface SetCarPassEnteredAction {
  type: typeof SET_CAR_PASS_ENTERED;
  car_pass_entered: boolean;
  car_pass?: string;
}

export interface SetHydroboardEnteredAction {
  type: typeof SET_HYDROBOARD_ENTERED;
  hydroboard_entered: boolean;
  hydroboard?: string;
}

export interface SetRampEnteredAction {
  type: typeof SET_RAMP_ENTERED;
  ramp_entered: boolean;
  ramp?: string;
}

export interface SetLengthEnteredAction {
  type: typeof SET_LENGTH_ENTERED;
  length_entered: boolean;
  length?: string;
}

export interface SetWidthEnteredAction {
  type: typeof SET_WIDTH_ENTERED;
  width_entered: boolean;
  width?: string;
}

export interface SetHeightEnteredAction {
  type: typeof SET_HEIGHT_ENTERED;
  height_entered: boolean;
  height?: string;
}

export interface SetGarageEnteredAction {
  type: typeof SET_GARAGE_ENTERED;
  garage_entered: boolean;
  address?: any;
  latitude?: string;
  longitude?: string;
}

export interface AddVehiclePhotoAction {
  type: typeof ADD_VEHICLE_PHOTO;
  photo: Blob;
}

export interface ReplaceVehiclePhotoAction {
  type: typeof REPLACE_VEHICLE_PHOTO;
  index: number;
  new_photo: Blob;
}

export interface ClearVehiclePhotosAction {
	type: typeof CLEAR_VEHICLE_PHOTOS;
}

export interface SetMedicalBookAction {
  type: typeof SET_MEDICAL_BOOK;
  medical_book: boolean;
}

export interface SetDriverLoaderAction {
  type: typeof SET_DRIVER_LOADER;
  driver_loader: boolean;
}

export interface SetLicensePlateEnteredAction {
  type: typeof SET_LICENSE_PLATE_ENTERED;
  license_plate_entered: boolean;
  license_plate?: string;
}

export interface SetVinEnteredAction {
  type: typeof SET_VIN_ENTERED;
  vin_entered: boolean;
  vin?: string;
}

export interface SetBrandModelEnteredAction {
  type: typeof SET_BRAND_MODEL_ENTERED;
  brand_model_entered: boolean;
  brand_model?: string;
}

export interface SetVehicleTypeEnteredAction {
  type: typeof SET_VEHICLE_TYPE_ENTERED;
  vehicle_type_entered: boolean;
  vehicle_type?: string;
}

export interface SetVehicleCategoryEnteredAction {
  type: typeof SET_VEHICLE_CATEGORY_ENTERED;
  vehicle_category_entered: boolean;
  vehicle_category?: string;
}

export interface SetYearEnteredAction {
  type: typeof SET_YEAR_ENTERED;
  year_entered: boolean;
  year?: string;
}

export interface SetEcologyEnteredAction {
  type: typeof SET_ECOLOGY_ENTERED;
  ecology_entered: boolean;
  ecology?: string;
}

export interface SetPtsSeriesEnteredAction {
  type: typeof SET_PTS_SERIES_ENTERED;
  pts_series_entered: boolean;
  pts_series?: string;
}

export interface SetPtsNumberEnteredAction {
  type: typeof SET_PTS_NUMBER_ENTERED;
  pts_number_entered: boolean;
  pts_number?: string;
}

export interface SetStsSeriesEnteredAction {
  type: typeof SET_STS_SERIES_ENTERED;
  sts_series_entered: boolean;
  sts_series?: string;
}

export interface SetStsNumberEnteredAction {
  type: typeof SET_STS_NUMBER_ENTERED;
  sts_number_entered: boolean;
  sts_number?: string;
}

export interface SetMaxWeightEnteredAction {
  type: typeof SET_MAX_WEIGHT_ENTERED;
  max_weight_entered: boolean;
  max_weight?: string;
}

export interface SetLoadWeightEnteredAction {
  type: typeof SET_LOAD_WEIGHT_ENTERED;
  load_weight_entered: boolean;
  load_weight?: string;
}

export interface AddCertificatePhotoAction {
  type: typeof ADD_CERTIFICATE_PHOTO;
  photo: Blob;
}

export interface ReplaceCertificatePhotoAction {
  type: typeof REPLACE_CERTIFICATE_PHOTO;
  index: number;
  new_photo: Blob;
}

export interface ClearCertificatePhotosAction {
	type: typeof CLEAR_CERTIFICATE_PHOTOS;
}

export interface AssignDriverAction {
  type: typeof ASSIGN_DRIVER;
  driver: Driver;
}

export interface DismissDriverAction {
  type: typeof DISMISS_DRIVER;
  driver: Driver;
}

export interface DismissAllAction {
	type: typeof DISMISS_ALL;
}

export type VehicleActionTypes = SetCarTypeEnteredAction
	| SetBodyOptionEnteredAction
	| SetBodyOptionChsEnteredAction
	| SetCarPassEnteredAction
	| SetHydroboardEnteredAction
	| SetRampEnteredAction
	| SetLengthEnteredAction
	| SetWidthEnteredAction
	| SetHeightEnteredAction
	| SetGarageEnteredAction
	| AddVehiclePhotoAction
	| ReplaceVehiclePhotoAction
	| ClearVehiclePhotosAction
	| SetMedicalBookAction
	| SetDriverLoaderAction
	| SetLicensePlateEnteredAction
	| SetVinEnteredAction
	| SetBrandModelEnteredAction
	| SetVehicleTypeEnteredAction
	| SetVehicleCategoryEnteredAction
	| SetYearEnteredAction
	| SetEcologyEnteredAction
	| SetPtsSeriesEnteredAction
	| SetPtsNumberEnteredAction
	| SetStsSeriesEnteredAction
	| SetStsNumberEnteredAction
	| SetMaxWeightEnteredAction
	| SetLoadWeightEnteredAction
	| AddCertificatePhotoAction
	| ReplaceCertificatePhotoAction
	| ClearCertificatePhotosAction
	| AssignDriverAction
	| DismissDriverAction
	| DismissAllAction;

export interface VehicleState {
	car_type_entered: boolean;
	car_type: string;
	body_option_entered: boolean;
	body_option: string;
	body_option_chs_entered: boolean;
	body_option_chs: any;
	car_pass_entered: boolean;
	car_pass: string;
	hydroboard_entered: boolean;
	hydroboard: string;
	ramp_entered: boolean;
	ramp: string;
	length_entered: boolean;
	length: string;
	width_entered: boolean;
	width: string;
	height_entered: boolean;
	height: string;
	garage_entered: boolean;
	address: any;
	latitude: string;
	longitude: string;
	vehicle_photos: Blob[];
	medical_book: boolean;
	driver_loader: boolean;
	license_plate_entered: boolean;
	license_plate: string;
	vin_entered: boolean;
	vin: string;
	brand_model_entered: boolean;
	brand_model: string;
	vehicle_type_entered: boolean;
	vehicle_type: string;
	vehicle_category_entered: boolean;
	vehicle_category: string;
	year_entered: boolean;
	year: string;
	ecology_entered: boolean;
	ecology: string;
	pts_series_entered: boolean;
	pts_series: string;
	pts_number_entered: boolean;
	pts_number: string;
	sts_series_entered: boolean;
	sts_series: string;
	sts_number_entered: boolean;
	sts_number: string;
	max_weight_entered: boolean;
	max_weight: string;
	load_weight_entered: boolean;
	load_weight: string;
	certificate_photos: Blob[];
	drivers: Driver[];
}
