import React, { FC } from 'react'
import { MenuBody } from './MenuBody'
import { MenuHeader } from '../ChatMenu/MenuHeader'

export const ChatMenu: FC<propTypes> = ({ setChatOpen, setVisability, setUserID }) => {

    return (
        <>
            <MenuHeader setChatOpen={setChatOpen} />
            <MenuBody setVisability={setVisability} setUserID={setUserID} />
        </>
    )
}


type propTypes = {
    setChatOpen: (value: boolean) => void
    setVisability: (value: boolean) => void
    setUserID: (value: string) => void
}