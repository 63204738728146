import React, { FC, useRef, useEffect } from "react"
import { useSelector } from "react-redux"
import { getChatMessagesSelector, getChatUsersSelector } from "../../../redux/reducers/chatReducers/chatSelectors"
import { ChatBody } from "../ChatMessages/ChatBody"
import { ChatFooter } from "../ChatMessages/ChatFooter"
import { ChatHeader } from "../ChatMessages/ChatHeader"

export const ChatMessages: FC<propTypes> = ({ setVisability, setUserID, userID, senderID }) => {

    const scrollRef = useRef(null)

    const users = useSelector(getChatUsersSelector),
            user = users.filter(user => user.id === userID)[0],
            logistID = users.filter(us => us.type === 'logist' && us.orderID === user.orderID).length ? users.filter(user => user.type === 'logist')[0].id : '',
            messages = useSelector(getChatMessagesSelector)

    useEffect(() => {

        // при получении нового сообщения и при стартовой загрузке прокручиваем к началу
        const block = document.getElementById('chatMessagesBlock')
        block && (block.scrollTop = block.scrollHeight)
        //@ts-ignore
        scrollRef.current !== null && scrollRef.current.scrollIntoView(false)
    }, [messages])    

    return (
        <>
            <ChatHeader user={user} setVisability={setVisability} setUserID={setUserID} logistID={logistID} />
            <ChatBody userID={userID} scrollRef={scrollRef} messages={messages} userType={user.type} />
            <ChatFooter senderID={senderID} dialogID={user.dialog_id} />
        </>
    )
}


type propTypes = {
    setVisability: (value: boolean) => void
    setUserID: (value: string) => void
    userID: string
    senderID: string
}