import React from "react";
import {connect, useDispatch} from "react-redux";
import {RootState} from "../../../redux/store";
import {UserState} from "../../../redux/reducers/user/@types";
import {ToggleDriverStatusWindowAction, ToggleOrdersWindowAction} from "../../../redux/reducers/system/@types";
import {setSystemStatus, toggleDriverStatusWindow, toggleOrdersWindow} from "../../../redux/reducers/system/actions";
import {setRegistrationStatus} from "../../../redux/reducers/registration/actions";
import {SystemStatus, UserStatus} from "../../../@types";
import CheckSVG from "../../App/svg/check.svg";
import {ReactSVG} from "react-svg";
import "./StatusBtn.css";

interface IProps {
  state: UserState,
  toggleDriverStatusWindow: () => ToggleDriverStatusWindowAction,
  toggleOrdersWindow: (value:boolean) => ToggleOrdersWindowAction
}

const StatusBtn: React.FunctionComponent<IProps> = ({ state, toggleDriverStatusWindow, toggleOrdersWindow }) => {
  const { authenticated, status } = state;
  const dispatch = useDispatch();

  const showDriverStatusWindow = () =>{
     dispatch(setSystemStatus(SystemStatus.DriverStatusWindow))
  }

	const toRegistration = () => {
		dispatch(setSystemStatus(SystemStatus.Registration));
		dispatch(setRegistrationStatus(null));
	}

  if (!authenticated) {
    return (
      <div className="status_btn status_btn_guest">
        <div className="status_btn_text" onClick={toRegistration}>Начать работу</div>
      </div>
    );
  } else {
    switch (status) {
      case UserStatus.Working:
        return (
          <div className="status_btn status_btn_work" onClick={showDriverStatusWindow}>
            <div className="status_btn_text">Работаю</div>
            <div className="status_btn_icon"><ReactSVG src={CheckSVG} /></div>
          </div>
        );
      case UserStatus.DayOff:
        return (
          <div className="status_btn status_btn_dayoff" onClick={showDriverStatusWindow}>
            <div className="status_btn_text">Не работаю</div>
          </div>
        );
      default: 
          return <></>
    }
  }
}

const mapStateToProps = (state: RootState) => ({
  state: state.userReducer,
  system: state.systemReducer
});

export default connect(mapStateToProps, { toggleDriverStatusWindow , toggleOrdersWindow})(StatusBtn);
