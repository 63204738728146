import React, { useState } from "react";
import "./FoldableBlock.css";

interface IProps {
  title: string;
  style?: object;
}

interface ILocalState {
  opened: boolean;
}

const FoldableBlock: React.FunctionComponent<IProps> = ({ title, style, children }) => {
  const [localState, setLocalState] = useState<ILocalState>({
    opened: true
  }); 

  const { opened } = localState;

  const toggle = () => {
    setLocalState((s) => ({
      ...s,
      opened: !opened
    }));
  }

  return (
    <div className="foldable_block" style={style}>
      <div className="foldable_block_header" onClick={toggle}>
        <div className="foldable_block_title">{ title }</div>
        <div className="foldable_block_icon">
          {opened ?
            <svg width="1rem" height="0.6rem" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 8L7 2L1 8" stroke="white" strokeWidth="2" strokeLinecap="round"/>
            </svg>
          :
            <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path transform="scale(1, -1) translate(0, -9)" d="M13 8L7 2L1 8" stroke="white" strokeWidth="2" strokeLinecap="round"/>
            </svg>
          }
        </div>
      </div>
      <div className={opened ? "foldable_block_content_opened" : "foldable_block_content_closed"}>{children}</div>
    </div>
  );
}

export default FoldableBlock;