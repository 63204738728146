export const SET_NAME_ENTERED = 'SET_NAME_ENTERED';
export const SET_BIRTHDATE_ENTERED = 'SET_BIRTHDATE_ENTERED';
export const SET_NUMBER_ENTERED = 'SET_NUMBER_ENTERED';
export const SET_DEPARTMENT_ENTERED = 'SET_DEPARTMENT_ENTERED';
export const SET_ISSUE_DATE_ENTERED = 'SET_ISSUE_DATE_ENTERED';
export const SET_ISSUED_BY_ENTERED = 'SET_ISSUED_BY_ENTERED';
export const SET_REGISTRATION_ENTERED = 'SET_REGISTRATION_ENTERED';
export const SET_ADDRESS_ENTERED = 'SET_ADDRESS_ENTERED';
export const ADD_PHOTO = 'ADD_PHOTO';
export const REPLACE_PHOTO = 'REPLACE_PHOTO';
export const CLEAR_PHOTOS = 'CLEAR_PHOTOS';

export interface SetNameEnteredAction {
  type: typeof SET_NAME_ENTERED;
  name_entered: boolean;
  name?: string;
}

export interface SetBirthdateEnteredAction {
  type: typeof SET_BIRTHDATE_ENTERED;
  birthdate_entered: boolean;
  birthdate?: Date | null;
}

export interface SetNumberEnteredAction {
  type: typeof SET_NUMBER_ENTERED;
  number_entered: boolean;
  number?: string;
}

export interface SetDepartmentEnteredAction {
  type: typeof SET_DEPARTMENT_ENTERED;
  department_entered: boolean;
  department?: string;
}

export interface SetIssueDateEnteredAction {
  type: typeof SET_ISSUE_DATE_ENTERED;
  issue_date_entered: boolean;
  issue_date?: Date | null;
}

export interface SetIssuedByEnteredAction {
  type: typeof SET_ISSUED_BY_ENTERED;
  issued_by_entered: boolean;
  issued_by?: string;
}

export interface SetRegistrationEnteredAction {
  type: typeof SET_REGISTRATION_ENTERED;
  registration_entered: boolean;
  registration?: any;
}

export interface SetAddressEnteredAction {
  type: typeof SET_ADDRESS_ENTERED;
  address_entered: boolean;
  same_address?: boolean;
  address?: any;
}

export interface AddPhotoAction {
  type: typeof ADD_PHOTO;
  photo: Blob;
}

export interface ReplacePhotoAction {
  type: typeof REPLACE_PHOTO;
  index: number;
  new_photo: Blob;
}

export interface ClearPhotosAction {
	type: typeof CLEAR_PHOTOS;
}

export type PassportDataActionTypes = SetNameEnteredAction
	| SetBirthdateEnteredAction
	| SetNumberEnteredAction
	| SetDepartmentEnteredAction
	| SetIssueDateEnteredAction
	| SetIssuedByEnteredAction
	| SetRegistrationEnteredAction
	| SetAddressEnteredAction
	| AddPhotoAction
	| ReplacePhotoAction
	| ClearPhotosAction;

export interface PassportDataState {
	name_entered: boolean;
	name: string;
	birthdate_entered: boolean;
	birthdate: Date | null;
	number_entered: boolean;
	number: string;
	department_entered: boolean;
	department: string;
	issue_date_entered: boolean;
	issue_date: Date | null;
	issued_by_entered: boolean;
	issued_by: string;
	registration_entered: boolean;
	registration: any;
	address_entered: boolean;
	same_address: boolean;
	address: any;
	photos: Blob[];
}
