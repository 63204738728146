import Button from "../../Elements/Button/Button";
import React from "react";
import {connect, useDispatch} from "react-redux";
import {RootState} from "../../../redux/store";
import "./FullPlayWindow.css"

import {OrderFullInfo} from "../../../redux/reducers/orders/@types";
import Overlay from "../../Elements/Overlay/Overlay";
import PointCard from "./PointCard/PointCard";

interface ILocalProps {
    order: OrderFullInfo,
    currentPoint: number,
    onShowRoute:Function,
    onShowMap:Function,
    onFullMode:Function,
    setOnPlace:Function,
}

const FullPlayWindow: React.FC<ILocalProps> = ({order, currentPoint,onShowRoute,onShowMap,onFullMode,setOnPlace}) => {
    const dispatch = useDispatch();
    return (
        <>
            <div className="play_window full_window">
                {order.routes.map((p,i)=>
                    <PointCard  order={order} currentPoint={currentPoint} orderPoint={i}
                                onShowRoute={onShowRoute} onShowMap={onShowMap}
                                setOnPlace={()=> {if (setOnPlace) setOnPlace(currentPoint)}}
                                key={"point_card_"+i} />)}

                <div className="order_play_info">
                    <div className="order_play_info_number">Заказ №{order.number}</div>
                    <div className="order_play_info_time">{order.date}</div>
                    <div  className="order_play_info_price_title">Стоимость</div>
                    <div  className="order_play_info_price_value">СТОИМОСТЬ НЕ ПРИхОДИТ</div>
                    <div><Button type="transparent" onClick={()=> {if (onFullMode) onFullMode()} }
                                 className="Button100">Скрыть подробности заказа</Button> </div>
                    <div className="bottom_window_problem_link">Сообщить о проблеме</div>
                    <div className="driver_status_window_info">Вы можете прервать работу при<br />возникновении поломок автомобиля или других непреодолимых обстоятельствах</div>
                    <div>&nbsp;</div>
                </div>
            </div>
        </>
    );
};


const mapStateToProps = (state: RootState) => ({
    state: {
        orderState: state.ordersReducer,
    }
});

export default connect(mapStateToProps, { })(FullPlayWindow);
