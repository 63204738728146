import {
  OrdersActionTypes,
  OrdersState,
  SET_ADDITIONAL_REQUIREMENTS,
  SET_ASSIGNED_ORDER,
  SET_BODY_OPTIONS,
  SET_BODY_OPTIONS_CHARACTERISTICS,
  SET_BODY_OPTIONS_CHARACTERISTICS_VALUES,
  SET_BODY_TYPES,
  SET_CAR_TYPES,
  SET_ORDER,
  SET_ORDERS
} from "./@types";
import {removeSystemStatus, setSystemStatus, setVisibilityOverlay, showMessageTrunk} from "../system/actions";
import {OrdersListType, SystemStatus} from "../../../@types";
import {ordersAPI} from "../../../api";
import {setOrder} from "./actions";
import {EnumWindowMessageType} from "../system/@types";

const initialState: OrdersState = {
  bodyOptionCharacteristics: [],
  bodyOptionCharacteristicsValues: [],
  bodyTypes: [],
  bodyOptions: [],
  carTypes: [],
  additionalRequirements: [],
  current: {},
  assigned: {},
  items: [],
}

const ordersReducer = (state = initialState, action:  OrdersActionTypes): OrdersState => {
  switch (action.type) {
    case SET_ORDERS:
      return {
        ...state,
        items: action.orders
      }
    case SET_BODY_OPTIONS_CHARACTERISTICS:
      return {
        ...state,
        bodyOptionCharacteristics: action.body_option_characteristics
      }
    case SET_BODY_OPTIONS_CHARACTERISTICS_VALUES:
      return {
        ...state,
        bodyOptionCharacteristicsValues: action.body_option_characteristics_values
      }
    case SET_BODY_TYPES:
      return {
        ...state,
        bodyTypes: action.body_types
      }
    case SET_CAR_TYPES:
      return {
        ...state,
        carTypes: action.car_types
      }
      case SET_BODY_OPTIONS:
      return {
        ...state,
        bodyOptions: action.body_options
      }
    case SET_ADDITIONAL_REQUIREMENTS:
      return {
        ...state,
        additionalRequirements: action.additional_requirements
      }
    case SET_ORDER:

      return {
        ...state,
        current: {...action.order,  type: action.orderType}
      }
    case SET_ASSIGNED_ORDER:
      return {
        ...state,
        assigned: action.order
      }
    default: 
    return state;
  }
};


export const openOrderThunk = (order_id:string, isAuth:boolean, orderType:OrdersListType, dispatch: any) => {

  dispatch(setSystemStatus(SystemStatus.WaitingWindow));

  return ordersAPI.getOrder(order_id, !isAuth).then((res) => {
    dispatch(removeSystemStatus(SystemStatus.WaitingWindow));
    dispatch(setVisibilityOverlay(false));

    if (typeof res.data == "string") {
       showMessageTrunk({type:EnumWindowMessageType.Error, text: res.data}, dispatch);
       return
    } else {
      dispatch(setOrder(res.data, orderType));
      dispatch(setSystemStatus(SystemStatus.FullOrderCard));
    }
  }).catch( e=> {
    showMessageTrunk({type:EnumWindowMessageType.Error, text: e.message}, dispatch);
  });
};
export default ordersReducer;
