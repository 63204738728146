import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { SystemStatus, RegistrationStatus } from "../../@types";
import {
	Passport,
	License,
	Driver,
	VehicleData,
	VehicleCertificate,
	Address,
	emptyPassport,
	emptyLicense,
	emptyCertificate
} from "../Elements/Containers/@types";
import {
	FinalizeState,
	SetFinalizingAction,
	SetAssigningDriverAction,
	SetIsOwnerAction,
	SaveDriverAction,
	UpdateDriverAction,
	SaveVehicleAction
} from "../../redux/reducers/finalize/@types";
import { PassportDataState } from "../../redux/reducers/passport_data/@types";
import {
	OwnerSelectState,
	MULTIPLE_CARS_OWNER,
	SINGLE_CAR_OWNER,
	TIN_IP
} from "../../redux/reducers/owner_select/@types";
import { CheckPhoneState } from "../../redux/reducers/check_phone/@types";
import { SetRegistrationStatusAction } from "../../redux/reducers/registration/@types";
import {
	setFinalizing,
	setAssigningDriver,
	setIsOwner,
	saveDriver,
	updateDriver,
	saveVehicle
} from "../../redux/reducers/finalize/actions";
import {popSystemStatus, setSystemStatus} from "../../redux/reducers/system/actions";
import { setRegistrationStatus } from "../../redux/reducers/registration/actions";
import {
	CancelBtn,
	VehicleContainer,
	DriverContainer,
	Response
} from "../Elements/elements";
import { registrationAPI, sendPhoto } from "../../api";
import { convertDate } from "../../api/validate";
import "./Finalize.css";

interface IProps {
  state: FinalizeState,
  passportData: PassportDataState,
  ownerSelect: OwnerSelectState,
  checkPhone: CheckPhoneState,
  setFinalizing: (finalizing: boolean) => SetFinalizingAction,
  setAssigningDriver: (assigning_driver: boolean) => SetAssigningDriverAction,
  setIsOwner: (is_owner: boolean) => SetIsOwnerAction,
  saveDriver: (driver: Driver) => SaveDriverAction,
  updateDriver: () => UpdateDriverAction,
  saveVehicle: (vehicle: VehicleData) => SaveVehicleAction,
	setRegistrationStatus: (status: RegistrationStatus | null) => SetRegistrationStatusAction
}

const Finalize: React.FunctionComponent<IProps> = ({
	state,
	passportData,
	ownerSelect,
	checkPhone,
	setFinalizing,
	setAssigningDriver,
	setIsOwner,
	saveDriver,
	updateDriver,
	saveVehicle,
	setRegistrationStatus
}) => {
	const dispatch = useDispatch();
	const [sendingData, setSendingData] = useState(false);
	const [response, setResponse] = useState('');
	const [error, setError] = useState('');

	if (!state.finalizing)
		setFinalizing(true);

	const toBankData = () => {
		setError('');
		setRegistrationStatus(RegistrationStatus.BankData);
	}

	const toPassportData = () => {
		setError('');
		setRegistrationStatus(RegistrationStatus.PassportData);
	}

	const toLicenseData = () => {
		setError('');
		setRegistrationStatus(RegistrationStatus.LicenseData);
	}

	const toVehicle = () => {
		setError('');
		setRegistrationStatus(RegistrationStatus.Vehicle);
	}

	const toMainScreen = () => {
		setError('');
		dispatch(popSystemStatus( ));
		dispatch(setRegistrationStatus(null));
	}

	const addDriver = () => {
		setIsOwner(false);
		saveDriver({
			id: '',
			is_owner: false,
			passport: emptyPassport,
			license: emptyLicense
		});
		setTimeout(toPassportData, 500);
	}

	const editDriver = (index: number) => {
		setIsOwner(false);
		setAssigningDriver(false);
		let driver = state.owner.drivers[index];
		saveDriver(driver);
		setTimeout(toPassportData, 500);
	}

	const addVehicle = () => {
		saveVehicle({
			id: '',
			car_type: '',
			body_option: '',
			characteristics: [],
			car_pass: '',
			hydroboard: '',
			ramp: '',
			length: '',
			width: '',
			height: '',
			address: {
				address: null,
				latitude: '',
				longitude: ''
			},
			photos: [],
			photo_ids: [],
			medical_book: false,
			driver_loader: false,
			certificate: emptyCertificate,
			drivers: []
		});
		setTimeout(toVehicle, 500);
	}

	const editVehicle = (index: number) => {
		let vehicle = state.owner.vehicles[index];
		saveVehicle(vehicle);
		setTimeout(toVehicle, 500);
	}

	const sendData = async () => {
		setSendingData(true);
		let owner = state.owner;
		let response;

		if (owner.passport?.photos && owner.passport?.photos.length > 0) {
			response = await sendPhoto(owner.passport.photos[0],
				checkPhone.phone + '-passport-front.jpg');
			if (response) {
				owner.passport.photo_ids?.push(response.id);
			}
			response = await sendPhoto(owner.passport.photos[1],
				checkPhone.phone + '-passport-registration.jpg');
			if (response) {
				owner.passport.photo_ids?.push(response.id);
			}
			response = await sendPhoto(owner.passport.photos[2],
				checkPhone.phone + '-passport-selfie.jpg');
			if (response) {
				owner.passport.photo_ids?.push(response.id);
			}
		}

		owner.drivers.forEach(async (driver) => {
			let name = driver.passport.name.replace(/ /g, '-');
			if (driver.passport.photos && driver.passport.photos.length > 0) {
				response = await sendPhoto(driver.passport.photos[0],
					checkPhone.phone + '-' + name + '-front.jpg');
				if (response) {
					driver.passport.photo_ids?.push(response.id);
				}
				response = await sendPhoto(driver.passport.photos[1],
					checkPhone.phone + '-' + name + '-registration.jpg');
				if (response) {
					driver.passport.photo_ids?.push(response.id);
				}
				response = await sendPhoto(driver.passport.photos[2],
					checkPhone.phone + '-' + name + '-selfie.jpg');
				if (response) {
					driver.passport.photo_ids?.push(response.id);
				}
			}
			if (driver.license.photos && driver.license.photos.length > 0) {
				response = await sendPhoto(driver.license.photos[0],
					checkPhone.phone + '-' + name + '-license-front.jpg');
				if (response) {
					driver.license.photo_ids?.push(response.id);
				}
				response = await sendPhoto(driver.license.photos[1],
					checkPhone.phone + '-' + name + '-license-rear.jpg');
				if (response) {
					driver.license.photo_ids?.push(response.id);
				}
			}
		});

		owner.vehicles.forEach(async (vehicle) => {
			let license_plate = vehicle.certificate.license_plate
				.replace(/ /g, '')
				.replace('_', '');
			if (vehicle.photos && vehicle.photos.length > 0) {
				response = await sendPhoto(vehicle.photos[0],
					checkPhone.phone + '-' + license_plate + '-inside.jpg');
				if (response) {
					vehicle.photo_ids?.push(response.id);
				}
				response = await sendPhoto(vehicle.photos[1],
					checkPhone.phone + '-' + license_plate + '-front.jpg');
				if (response) {
					vehicle.photo_ids?.push(response.id);
				}
				response = await sendPhoto(vehicle.photos[2],
					checkPhone.phone + '-' + license_plate + '-side.jpg');
				if (response) {
					vehicle.photo_ids?.push(response.id);
				}
			}
			if (vehicle.certificate.photos && vehicle.certificate.photos.length > 0) {
				response = await sendPhoto(vehicle.certificate.photos[0],
					checkPhone.phone + '-' + license_plate + '-certificate-front.jpg');
				if (response) {
					vehicle.certificate.photo_ids?.push(response.id);
				}
				response = await sendPhoto(vehicle.certificate.photos[1],
					checkPhone.phone + '-' + license_plate + '-certificate-rear.jpg');
				if (response) {
					vehicle.certificate.photo_ids?.push(response.id);
				}
			}

			vehicle.characteristics.forEach((characteristic) => {
				switch (characteristic.name) {
					case 'Медкнижка':
						characteristic.value = vehicle.medical_book;
						break;
					case 'Водитель грузчик':
						characteristic.value = vehicle.driver_loader;
						break;
					case 'Гидроборт':
						characteristic.value = vehicle.hydroboard;
						break;
					case 'Тип пандуса':
						characteristic.value = vehicle.ramp;
						break;
					default:
						if (characteristic.type === 'Boolean' &&
							(!characteristic.value || characteristic.value === undefined))
							characteristic.value = false;
						if (characteristic.type === 'number' &&
							(!characteristic.value || characteristic.value === undefined))
							characteristic.value = '0';
						break;
				}
			});
		});

		let car: any;
		let driver: any;
		let vehicles: any = [];
		owner.vehicles.forEach((vehicle: any) => {
			car = {
				car_type_id: vehicle.car_type,
				body_option_id: vehicle.body_option,
				body_option_characteristics: vehicle.characteristics,
				pass: vehicle.car_pass === 'none' ? '' : vehicle.car_pass,
				size: {
					length: vehicle.length.replace(',', '.'),
					width: vehicle.width.replace(',', '.'),
					height: vehicle.height.replace(',', '.')
				},
				adress: {
					adress: vehicle.address.address.value,
					longitude: vehicle.address.longitude,
					latitude: vehicle.address.latitude
				},
				files: [
					vehicle.photo_ids ? vehicle.photo_ids[0] : '',
					vehicle.photo_ids ? vehicle.photo_ids[1] : '',
					vehicle.photo_ids ? vehicle.photo_ids[2] : ''
				],
				certificate: {
					number: vehicle.certificate.license_plate.replace('_', '').replace(/ /g, ''),
					VIN: vehicle.certificate.vin,
					brand: vehicle.certificate.brand_model.split(' ')[0],
					model: vehicle.certificate.brand_model.slice(
						vehicle.certificate.brand_model.indexOf(' ') + 1),
					type: vehicle.certificate.vehicle_type,
					category: vehicle.certificate.category,
					date: vehicle.certificate.build_year,
					class: vehicle.certificate.eco_class,
					passport_serial: vehicle.certificate.pts_series,
					passport_number: vehicle.certificate.pts_number,
					certificate_serial: vehicle.certificate.sts_series,
					certificate_number: vehicle.certificate.sts_number,
					max_weight: vehicle.certificate.max_weight,
					free_weight: vehicle.certificate.load_weight,
					files: [
						vehicle.certificate.photo_ids ? vehicle.certificate.photo_ids[0] : '',
						vehicle.certificate.photo_ids ? vehicle.certificate.photo_ids[1] : ''
					]
				},
				drivers: []
			};

			vehicle.drivers.forEach((dr: any) => {
				driver = {
					phone_number: dr.is_owner ? checkPhone.phone : '',
					passport: {
						full_name: dr.passport.name,
						birth_date: convertDate(dr.passport.birthdate),
						series: dr.passport.number.slice(0, 5).replace(' ', ''),
						number: dr.passport.number.slice(6),
						issued_by: dr.passport.issued_by,
						department_code: dr.passport.department,
						issue_date: convertDate(dr.passport.issue_date),
						adress: dr.passport.registration.value,
						real_adress: (dr.passport.same_address ?
							dr.passport.registration.value : dr.passport.address.value),
						files: [
							dr.passport.photo_ids ? dr.passport.photo_ids[0] : '',
							dr.passport.photo_ids ? dr.passport.photo_ids[1] : '',
							dr.passport.photo_ids ? dr.passport.photo_ids[2] : ''
						]
					},
					driver_certificate: {
						full_name: dr.passport.name,
						serial: dr.license.number.slice(0, 5).replace(' ', ''),
						number: dr.license.number.slice(6),
						issue_date: convertDate(dr.license.issue_date),
						validity: '',
						issued_by: dr.license.issued_by,
						country: dr.license.country,
						category: dr.license.categories,
						files: [
							dr.license.photo_ids ? dr.license.photo_ids[0] : '',
							dr.license.photo_ids ? dr.license.photo_ids[1] : ''
						]
					}
				}
				car.drivers.push(driver);
			});

			vehicles.push(car);
		});

		let form = {
			TIN: ownerSelect.tin,
			banking: {
				account_number: owner.bank?.account || '',
				BIK: owner.bank?.bic || '',
				correction_number: owner.bank?.corr || '',
				bank: {
					BIK: owner.bank?.bic || '',
					name: owner.bank?.name || '',
					correction_number: owner.bank?.corr || '',
					TIN: owner.bank?.inn || '',
					KPP: owner.bank?.kpp || '',
					OGRN: '',
					adress: owner.bank?.address?.value || ''
				}
			},
			person: {
				full_name: owner.passport?.name?.trim(),
				phone_number: checkPhone.phone
			},
			passport: {
				full_name: owner.passport?.name?.trim(),
				birth_date: convertDate(owner.passport?.birthdate || null),
				series: owner.passport?.number?.slice(0, 5).replace(' ', ''),
				number: owner.passport?.number?.slice(6),
				issued_by: owner.passport?.issued_by,
				department_code: owner.passport?.department,
				issue_date: convertDate(owner.passport?.issue_date || null),
				adress: owner.passport?.registration.value,
				real_adress: (owner.passport?.same_address ?
					owner.passport?.registration.value : owner.passport?.address.value),
				files: [
					owner.passport?.photo_ids ? owner.passport?.photo_ids[0] : '',
					owner.passport?.photo_ids ? owner.passport?.photo_ids[1] : '',
					owner.passport?.photo_ids ? owner.passport?.photo_ids[2] : ''
				]
			},
			cars: vehicles,
			recommendations: [],
			accept_agreement: true
		}

		response = await registrationAPI.postData([form]);
		setSendingData(false);
		if ((response.status === 200) && response.data) {
			if (response.data.status === 'error') {
				setResponse('error');
				console.log(response.data.errormessage);
				setTimeout(setResponse, 5000, '');
			} else {
				setResponse('success');
				console.log('Success');
				setTimeout(toMainScreen, 5000);
			}
		} else {
			setResponse('error');
			console.log('Error');
			setTimeout(setResponse, 5000, '');
		}
	}

	useEffect(() => {
		if (ownerSelect.owner_type === SINGLE_CAR_OWNER) {
			saveDriver({
				id: state.owner.id,
				is_owner: true,
				passport: state.owner.passport,
				license: state.owner.drivers && state.owner.drivers.length > 0 ?
					state.owner.drivers[0].license : emptyLicense
			});
			updateDriver();
		}
	}, []);

  return (
		<>
			{response === 'success' &&
				<Response
					isError={false}
					name={state.owner.passport?.name?.slice(
						state.owner.passport?.name?.indexOf(' ') + 1)}
				/>
			}
			{response === 'error' &&
				<Response
					isError={true}
				/>
			}
			<CancelBtn onClick={toMainScreen} />
			<div className="finalize_window">
				<div className="finalize_container">
					<div className="finalize_title">
						<div className="finalize_title_name">{state.owner?.passport?.name}</div>
						<div className="finalize_title_type">
							{ownerSelect.owner_type === SINGLE_CAR_OWNER ? 'Водитель' : 'Владелец'}
						</div>
					</div>
					<VehicleContainer
						id="finalize_vehicles"
						title={ownerSelect.owner_type === SINGLE_CAR_OWNER ? 'Ваше ТС' : 'Ваши ТС'}
						single={ownerSelect.owner_type === SINGLE_CAR_OWNER}
						vehicles={state.owner.vehicles}
						onAddVehicle={addVehicle}
						onEditVehicle={(index) => editVehicle(index)}
					/>
					{ownerSelect.owner_type === MULTIPLE_CARS_OWNER &&
						<DriverContainer
							id="finalize_drivers"
							drivers={state.owner.drivers}
							onAddDriver={addDriver}
							onEditDriver={(index) => editDriver(index)}
						/>
					}
					<div className="finalize_container_wrapper">
						<div id="finalize_passport" className="finalize_container_item"
							onClick={() => {
								setIsOwner(true);
								setAssigningDriver(false);
								setTimeout(toPassportData, 500);
							}}>
							<div className="finalize_container_item_text">Паспортные данные</div>
							<div className="finalize_expand"></div>
						</div>
						{ownerSelect.owner_type === SINGLE_CAR_OWNER &&
							<div id="finalize_license" className="finalize_container_item"
								onClick={() => {
									setIsOwner(true);
									setTimeout(toLicenseData, 500);
								}}>
								<div className="finalize_container_item_text">Водительское удостоверение</div>
								<div className="finalize_expand"></div>
							</div>
						}
						{ownerSelect.tin_type === TIN_IP &&
							<div id="finalize_bank" className="finalize_container_item"
								onClick={() => {setTimeout(toBankData, 500)}}>
								<div className="finalize_container_item_text">Реквизиты ИП</div>
								<div className="finalize_expand"></div>
							</div>
						}
					</div>
					<section className="finalize_footer">
						{state.owner.vehicles === undefined || state.owner.vehicles.length === 0 ||
							state.owner.drivers === undefined || state.owner.drivers.length === 0 ||
							state.owner.vehicles.reduce((totalDrivers, vehicle) => (
								totalDrivers + vehicle.drivers.length), 0) === 0 ?
							<div className="finalize_warning" style={error === '' ? {} : {color: 'red'}}>
								{error === '' ? 'Необходимо заполнить все поля' : error}
							</div> : null
						}
						{sendingData ?
							<div className="finalize_sending">Проверяем ваши данные...</div> :
							<div id="finalize_finish" className={'finalize_button' +
								(state.owner.vehicles === undefined || state.owner.vehicles.length === 0 ||
								state.owner.drivers === undefined || state.owner.drivers.length === 0 ||
								state.owner.vehicles.reduce((totalDrivers, vehicle) => (
									totalDrivers + vehicle.drivers.length), 0) === 0 ?
								' inactive' : '')} onClick={() => {
									setError('');
									if (state.owner.vehicles === undefined || state.owner.vehicles.length === 0) {
										setError('Не задано ни одно транспортное средство');
										return;
									}
									if (state.owner.drivers === undefined || state.owner.drivers.length === 0) {
										setError('Не задан ни один водитель');
										return;
									}
									if (state.owner.vehicles.reduce((totalDrivers, vehicle) => (
										totalDrivers + vehicle.drivers.length), 0) === 0) {
										setError('Транспортному средству не назначен водитель');
										return;
									}
									setTimeout(sendData, 500);
							}}>
				    		<div className="finalize_button_text">Закончить регистрацию</div>
				    	</div>
				    }
					</section>
				</div>
			</div>
		</>
	)
}

const mapStateToProps = (state: RootState) => ({
	state: state.finalizeReducer,
	passportData: state.passportDataReducer,
	ownerSelect: state.ownerSelectReducer,
	checkPhone: state.checkPhoneReducer
});

export default connect(mapStateToProps, {
	setFinalizing,
	setAssigningDriver,
	setIsOwner,
	saveDriver,
	updateDriver,
	saveVehicle,
	setRegistrationStatus
})(Finalize);
