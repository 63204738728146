export const SET_CHAT_USERS_FULL_INFO = 'SET_CHAT_USERS_FULL_INFO'
export const SET_CHAT_ALL_USERS = 'SET_CHAT_ALL_USERS'
export const SET_CHAT_COMMANDS = 'SET_CHAT_COMMANDS'
export const SET_CHAT_MESSAGES = 'SET_CHAT_MESSAGES'
export const SET_CHAT_MESSAGES_IS_READED = 'SET_CHAT_MESSAGES_IS_READED'
export const SET_CHAT_USERS_CLEAR = 'SET_CHAT_USERS_CLEAR'



export type AllUsersType = {
    id: string
    full_name: string
    group_id: string
    orderID: string

}

export type reciveUser = {
    id: string
    full_name: string
    type: string
}

export type user = {
    id: string
    full_name: string
    type: string
    dialog_id: string
    group_id: string
    orderID: string
}

export type command = {
    id: string
    name: string
}

export type message = {
    date: string
    from_user_id: string
    id: string
    in_out: string
    message: string
    read: boolean
    newMessagesFromHere?: boolean
}


export interface ChatDataState {
    allUsers: AllUsersType[]
    users: user[]
    commands: command[]
    messages: message[]
}

export interface SetUsersActionType {
    type: typeof SET_CHAT_USERS_FULL_INFO
    users: reciveUser[]
}

export interface SetAllUsersActionType {
    type: typeof SET_CHAT_ALL_USERS
    allUsersData: AllUsersType[]
}

export interface setCommandsActionType {
    type: typeof SET_CHAT_COMMANDS
    commands: command[]
}

export interface setMessagesActionType {
    type: typeof SET_CHAT_MESSAGES
    messages: message[]
}

export interface setChatMessagesIsReaded {
    type: typeof SET_CHAT_MESSAGES_IS_READED
    userID: string
}

export interface setChatUsersClearActionType {
    type: typeof SET_CHAT_USERS_CLEAR
}

export interface InitialStateActionType {}

export type ActionsTypes = SetUsersActionType | setCommandsActionType | setMessagesActionType | setChatMessagesIsReaded | SetAllUsersActionType | setChatUsersClearActionType

