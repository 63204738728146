import React, { useState, FC } from "react"
import s from './ChatMessages.module.scss'

export const TemporaryMessage: FC<propTypes> = () => {

    const [isHide, setIsHide] = useState(false)

    setTimeout(() => setIsHide(true), 5000)

    return (
        <div className={isHide ? s.temporaryHideMessageWrapper : s.temporaryMessageWrapper}>
            Непрочитанные сообщения
        </div>
    )
}


type propTypes = {}