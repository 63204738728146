import React from "react";
import "./RowScreenTab.css";

interface IProps {
  url?: string;
  title: string | React.ReactElement;
  icon: "next" | "plus",
  warning?: string;
  description?: string;
  onClick?: Function;
}

const RowScreenTab: React.FunctionComponent<IProps> = ({ url, title, icon, warning, description ,onClick}) => {
  const renderIcon = () => {
    switch (icon) {
      case "next":
        return (
          <svg width="0.65rem" height="1.14rem" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.97652 0.325817C1.76125 0.114994 1.48728 0 1.16438 0C0.518591 0 0 0.49831 0 1.13078C0 1.44701 0.136987 1.7345 0.362036 1.9549L7.21135 8.50958L0.362036 15.0451C0.136987 15.2655 0 15.5626 0 15.8692C0 16.5017 0.518591 17 1.16438 17C1.48728 17 1.76125 16.885 1.97652 16.6742L9.58904 9.39121C9.86301 9.14205 9.99022 8.8354 10 8.5C10 8.1646 9.86301 7.87711 9.58904 7.61838L1.97652 0.325817Z" fill="#c6cad2"/>
          </svg>
        );
      case "plus":
        return (
          <svg width="1rem" height="1rem" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="1" y1="8" x2="15" y2="8" stroke="#C6CAD2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8 15V1" stroke="#C6CAD2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        );
      default:
        break;
    }
  }

  return (
    <div className="screen_tab_container">
      {onClick ?

          <div className="screen_tab" onClick={()=> onClick()}>
            <div className="screen_tab_icon">{renderIcon()}</div>
            <div className="screen_tab_title">{ title }</div>
            { warning && <div className="screen_tab_warning">{ warning }</div> }
            { description && <div className="screen_tab_description">{ description }</div> }
          </div>
      :
        <div className="screen_tab">
          <div className="screen_tab_icon">{renderIcon()}</div>
          <div className="screen_tab_title">{ title }</div>
          { warning && <div className="screen_tab_warning">{ warning }</div> }
          { description && <div className="screen_tab_description">{ description }</div> }
        </div>
      }
    </div>
  );
}

export default RowScreenTab;
