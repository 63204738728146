import {UserStatus} from "../../../@types";
import {
  LOG_OUT,
  SET_AUTHENTICATED,
  SET_CURRENT_COORDINATE,
  SET_STATUS,
  SET_USER_INFO,
  UserActionTypes,
  UserState
} from "./@types";
import {setCookie} from "../system/cookies";
import moment from "moment";

let initialState: UserState = {
  authenticated: false,
  driver_id: "",
  full_name: "",
  status: UserStatus.Unauthenticated,
  data: null,
  current_coordinates: null,
}

const userReducer = (state = initialState, action: UserActionTypes): UserState => {
  switch (action.type) {
    case SET_AUTHENTICATED:
      action.authenticated ? setCookie("id", action.driver_id, 100) : setCookie("id", "", 0);
      return {
        ...state,
        authenticated: action.authenticated,
        driver_id: action.driver_id,
        full_name: action.full_name,
      }
    case SET_STATUS:
      return {
        ...state,
        status: action.status
      }
    case SET_USER_INFO:

      return {
        ...state,
        data: action.data
      }

    case LOG_OUT:
      return {
        ...state,
        authenticated: false,
        driver_id: '',
        full_name: '',
        status: UserStatus.Unauthenticated
      }

    case SET_CURRENT_COORDINATE:
      return {
        ...state,
        current_coordinates: action.current_coordinates
      }
    default:
      return state;
  }
}


export  const getStatusWorking = (status:UserStatus, dates: {date:string, status:string} []) => {
  if ( !dates || dates.length === 0 ) return status ? status :  UserStatus.Working;
  let st = UserStatus.Working;
  dates.forEach( x=> {
    if (moment().diff(moment(x.date), 'days') === 0 && x.status === UserStatus.DayOff) st = UserStatus.DayOff;
  });
  return  st;
}

export default userReducer;
