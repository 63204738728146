export const deleteCookie = (name:string) => {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
}


export const setCookie = (name:string, value:string, days:number) => {
    let date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    document.cookie = name + '=' + value + '; expires=' + date.toUTCString() + '; path=/';
}

export const getCookie = (name:string) => {
    let n = name + '=';
    let ca = document.cookie.split(';');
    let c;
    for (let i = 0; i < ca.length; i++) {
        c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(n) === 0) {
            return c.substring(n.length, c.length);
        }
    }
    return '';
};
