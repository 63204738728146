import { SystemStatus } from "../../../../@types"

export const SET_OVERLAY = 'SET_OVERLAY';
export const TOGGLE_ORDERS_WINDOW = 'TOGGLE_ORDERS_WINDOW';

export const TOGGLE_DATEPICKER_WINDOW = 'TOGGLE_DATEPICKER_WINDOW';
export const TOGGLE_DRIVER_STATUS_WINDOW = 'TOGGLE_DRIVER_STATUS_WINDOW';
export const TOGGLE_ORDER_BOOK_WINDOW = 'TOGGLE_ORDER_BOOK_WINDOW';
export const TOGGLE_PROFILE_WINDOW = 'TOGGLE_PROFILE_WINDOW';
export const TOGGLE_PARTNERSHIP_WINDOW = 'TOGGLE_PARTNERSHIP_WINDOW';
export const CLOSE_ALL_WINDOWS = 'CLOSE_ALL_WINDOWS';
export const SET_MESSAGE = 'SET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export const SET_SYSTEM_STATUS = 'SET_SYSTEM_STATUS';
export const POP_SYSTEM_STATUS = 'POP_SYSTEM_STATUS';
export const REMOVE_SYSTEM_STATUS = 'REMOVE_SYSTEM_STATUS';
export const CLEAR_SYSTEM_STATUS = 'CLEAR_SYSTEM_STATUS';
export const SET_VISIBILITY_OVERLAY = 'SET_VISIBILITY_OVERLAY';
export const TOGGLE_SYSTEM_STATUS = 'TOGGLE_SYSTEM_STATUS';
export const SET_CURRENT_ID = 'SET_CURRENT_ID';
export const SET_WINDOW_MESSAGE = 'SET_WINDOW_MESSAGE';

export interface SetOverlayAction {
  type: typeof SET_OVERLAY;
  overlay: boolean;
  zIndex?: number;
}

export interface ToggleOrdersWindowAction {
  type: typeof TOGGLE_ORDERS_WINDOW;
  visible: boolean;
}



export interface ToggleDatepickerWindowAction {
  type: typeof TOGGLE_DATEPICKER_WINDOW;
}

export interface ToggleDriverStatusWindowAction {
  type: typeof TOGGLE_DRIVER_STATUS_WINDOW;
}

export interface ToggleOrderBookWindowAction {
  type: typeof TOGGLE_ORDER_BOOK_WINDOW;
}

export interface ToggleProfileWindowAction {
  type: typeof TOGGLE_PROFILE_WINDOW;
}

export interface TogglePartnershipWindowAction {
    type: typeof TOGGLE_PARTNERSHIP_WINDOW;
}

export interface CloseAllWindowsAction {
  type: typeof CLOSE_ALL_WINDOWS;
}

export interface SetMessageAction {
  type: typeof SET_MESSAGE,
  id: string;
  statuses: { status: string; status_id: string; }[];
  message: string;
  order_id: string;
}

export interface ClearMessageAction {
  type: typeof CLEAR_MESSAGE;
  id: string;
  statuses: { status: string; status_id: string; }[];
  message: string;
  order_id: string;
}

export interface SetSystemStatusAction {
	type: typeof SET_SYSTEM_STATUS;
	status: SystemStatus;
}

export interface PopSystemStatusAction {
    type: typeof POP_SYSTEM_STATUS;
}

export interface RemoveSystemStatusAction {
    type: typeof REMOVE_SYSTEM_STATUS;
    status: SystemStatus;
}

export interface ClearSystemStatusAction {
    type: typeof CLEAR_SYSTEM_STATUS;
}

export interface SetVisibilityOverlayAction {
    type: typeof SET_VISIBILITY_OVERLAY;
    visible:boolean;
};

export interface ToggleSystemStatusAction {
    type: typeof TOGGLE_SYSTEM_STATUS;
    status: SystemStatus;
}

export interface SetCurrentIdAction {
	type: typeof SET_CURRENT_ID;
	id: string;
}
export interface ShowWindowMessageAction {
	type: typeof SET_WINDOW_MESSAGE;
    message_type:EnumWindowMessageType,
    message_text: string
}


export type SystemActionTypes = SetOverlayAction
	| ToggleOrdersWindowAction
	| ToggleSystemStatusAction
	| ToggleDatepickerWindowAction
	| ToggleDriverStatusWindowAction
	| CloseAllWindowsAction
	| SetMessageAction
	| ClearMessageAction
	| ToggleOrderBookWindowAction
	| ToggleProfileWindowAction
	| SetSystemStatusAction
    | PopSystemStatusAction
    | ClearSystemStatusAction
    | RemoveSystemStatusAction
    | SetVisibilityOverlayAction
	| SetCurrentIdAction
    | TogglePartnershipWindowAction
    | ShowWindowMessageAction;

export interface SystemState {
  overlay: {
    visible: boolean;
    zIndex: number;
  },
  menuWindow: boolean;
  ordersWindow: boolean;
  datepickerWindow: boolean;
  driverStatusWindow: boolean;
  profileWindow: boolean;
  partnershipWindow: boolean;
  message: {
    id: string;
    statuses: { status: string; status_id: string; }[];
    message: string;
    order_id: string;
  },
  statuses: SystemStatus[],
  currentId: string;
  window_message:{type:EnumWindowMessageType, text :string}
}

export enum EnumWindowMessageType {
    None,Error,Warning,Info
}
export enum EnumOrderBookView {
  Today, Future, Past
}
