export const SET_QUICK_NAME_ENTERED = 'SET_QUICK_NAME_ENTERED';
export const SET_QUICK_NUMBER_ENTERED = 'SET_QUICK_NUMBER_ENTERED';
export const SET_QUICK_ISSUE_DATE_ENTERED = 'SET_QUICK_ISSUE_DATE_ENTERED';
export const SET_QUICK_ISSUED_BY_ENTERED = 'SET_QUICK_ISSUED_BY_ENTERED';
export const SET_QUICK_BRAND_MODEL_ENTERED = 'SET_QUICK_BRAND_MODEL_ENTERED';
export const SET_QUICK_LICENSE_PLATE_ENTERED = 'SET_QUICK_LICENSE_PLATE_ENTERED';
export const SET_QUICK_AGREE = 'SET_QUICK_AGREE';

export interface SetQuickNameEnteredAction {
  type: typeof SET_QUICK_NAME_ENTERED;
  name_entered: boolean;
  name?: string;
}

export interface SetQuickNumberEnteredAction {
  type: typeof SET_QUICK_NUMBER_ENTERED;
  number_entered: boolean;
  number?: string;
}

export interface SetQuickIssueDateEnteredAction {
  type: typeof SET_QUICK_ISSUE_DATE_ENTERED;
  issue_date_entered: boolean;
  issue_date?: Date | null;
}

export interface SetQuickIssuedByEnteredAction {
  type: typeof SET_QUICK_ISSUED_BY_ENTERED;
  issued_by_entered: boolean;
  issued_by?: string;
}

export interface SetQuickBrandModelEnteredAction {
  type: typeof SET_QUICK_BRAND_MODEL_ENTERED;
  brand_model_entered: boolean;
  brand_model?: string;
}

export interface SetQuickLicensePlateEnteredAction {
  type: typeof SET_QUICK_LICENSE_PLATE_ENTERED;
  license_plate_entered: boolean;
  license_plate?: string;
}

export interface SetQuickAgreeAction {
  type: typeof SET_QUICK_AGREE;
  agree: boolean;
}

export type QuickRegistrationActionTypes = SetQuickNameEnteredAction
	| SetQuickNumberEnteredAction
	| SetQuickIssueDateEnteredAction
	| SetQuickIssuedByEnteredAction
	| SetQuickBrandModelEnteredAction
	| SetQuickLicensePlateEnteredAction
	| SetQuickAgreeAction;

export interface QuickRegistrationState {
	name_entered: boolean;
	name: string;
	number_entered: boolean;
	number: string;
	issue_date_entered: boolean;
	issue_date: Date | null;
	issued_by_entered: boolean;
	issued_by: string;
	brand_model_entered: boolean;
	brand_model: string;
	license_plate_entered: boolean;
	license_plate: string;
	agree: boolean;
}
