import Button from "../../Elements/Button/Button";
import React from "react";
import {OrderFullInfo} from "../../../redux/reducers/orders/@types";
import "./SmallOnPlacePlayWindow.css"

interface ILocalProps {
    order: OrderFullInfo,
    currentPoint: number,
    onTaskComplete:Function,
    onFullMode:Function,
}

const SmallOnPlacePlayWindow: React.FC<ILocalProps> = ({order, currentPoint,onTaskComplete,onFullMode}) => {

    return (
        <>
            <div className="play_window small_window">
                <div className="play_window_row">
                    <div className="point_number">{currentPoint + 1}</div>
                    <div className="point_address">{order.routes[currentPoint].adress}</div>
                </div>
                <div className="play_window_row play_window_row_line">
                    <Button type="primary" size="large" className="Button100"
                            onClick={()=> { if (onTaskComplete) onTaskComplete() }}>Задача выполнена
                    </Button>
                </div>
                <div className="play_window_row play_window_row_line">
                    <Button type="transparent" size="large" className="Button100"
                            onClick={()=> { if (onFullMode) onFullMode() }}>Детали маршрута
                    </Button>
                </div>
                <div className="play_window_row play_window_row_line">
                    <div>&nbsp;</div>
                </div>
            </div>
        </>
    );
};


export default SmallOnPlacePlayWindow;
