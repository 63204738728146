import {
	QuickRegistrationState,
	SET_QUICK_NAME_ENTERED,
	SET_QUICK_NUMBER_ENTERED,
	SET_QUICK_ISSUE_DATE_ENTERED,
	SET_QUICK_ISSUED_BY_ENTERED,
	SET_QUICK_BRAND_MODEL_ENTERED,
	SET_QUICK_LICENSE_PLATE_ENTERED,
	SET_QUICK_AGREE,
	QuickRegistrationActionTypes
} from "./@types";

let initialState: QuickRegistrationState = {
	name_entered: false,
	name: '',
	number_entered: false,
	number: '',
	issue_date_entered: false,
	issue_date: null,
	issued_by_entered: false,
	issued_by: '',
	brand_model_entered: false,
	brand_model: '',
	license_plate_entered: false,
	license_plate: '',
	agree: false
}

const quickRegistrationReducer = (state = initialState, action: QuickRegistrationActionTypes) => {
	switch (action.type) {
		case SET_QUICK_NAME_ENTERED:
			return {
				...state,
				name_entered: action.name_entered,
				name: action.name
			}
		case SET_QUICK_NUMBER_ENTERED:
			return {
				...state,
				number_entered: action.number_entered,
				number: action.number
			}
		case SET_QUICK_ISSUE_DATE_ENTERED:
			return {
				...state,
				issue_date_entered: action.issue_date_entered,
				issue_date: action.issue_date
			}
		case SET_QUICK_ISSUED_BY_ENTERED:
			return {
				...state,
				issued_by_entered: action.issued_by_entered,
				issued_by: action.issued_by
			}
		case SET_QUICK_BRAND_MODEL_ENTERED:
			return {
				...state,
				brand_model_entered: action.brand_model_entered,
				brand_model: action.brand_model
			}
		case SET_QUICK_LICENSE_PLATE_ENTERED:
			return {
				...state,
				license_plate_entered: action.license_plate_entered,
				license_plate: action.license_plate
			}
		case SET_QUICK_AGREE:
			return {
				...state,
				agree: action.agree
			}
		default:
			return state;
	}
}

export default quickRegistrationReducer;
