import Button from "../../Elements/Button/Button";
import React from "react";
import {connect, useDispatch} from "react-redux";
import {RootState} from "../../../redux/store";
import "./OnPlacePlayWindow.css"
import CheckSVG from "../../App/svg/round-check.svg"
import {OrderFullInfo} from "../../../redux/reducers/orders/@types";


interface ILocalProps {
    order: OrderFullInfo,
    currentPoint: number,
    onGood?:Function,
    is_finish?:boolean
    is_next?:boolean
}

const OnPlacePlayWindow: React.FC<ILocalProps> = ({order, currentPoint, onGood,is_finish, is_next}) => {
    const dispatch = useDispatch();
    const item = order.routes[currentPoint];
    return (
        <>
            <div className="play_window full_window on_place_window">
                <div className="order_play_info" style={ {borderTop: "unset"} }>
                    <div className="order_play_info_on_place_title">На точке</div>
                    <div className="order_play_info_on_place_time">Время не приходит &nbsp;&nbsp;&nbsp; Прибытие - убытие</div>
                    {item.adress_comment &&  <div className="order_info_comment">{item.adress_comment}</div>}
                    <div className="order_info_point_detail">
                            <div className="order_info_persons">
                                <div className="order_info_persons_title">Контактные лица:</div>
                                {item.contact_persons.map( (x,i)=> <div key={i+'cop'} className="order_info_one_person">{x.full_name ? x.full_name : 'ФИО не указано' }</div> )}
                                {item.contact_persons.length == 0 ? <div className="no_persons">Неуказаны</div> : ''}
                            </div>
                            {item.company  && <div className="order_info_from">От кого: <span className="contact_name">{item.company }</span></div> }
                            <div className="order_info_task">
                                <div className="order_info_task_header">Задача</div>
                                <div className="order_info_task_comment">на точке быть вежливым</div>
                                <div className="order_info_task_what_to_do">{item.what_to_do}</div>
                                {item.action_loading ? <div className="order_info_action"> Загрузка </div> : ''}
                                {item.action_unloading ? <div className="order_info_action"> Разгрузка </div> : ''}
                                {item.action_documents ? <div className="order_info_action"> Получение документов </div> : ''}
                                {item.action_forwarder ? <div className="order_info_action"> Экспедирование груза </div> : ''}
                            </div>
                    </div>
                    {!is_next && !is_finish &&
                        <div>
                            <Button type={"grey"} size={"large"} onClick={() => { if (onGood) onGood() }} >Хорошо</Button>
                        </div>
                    }
                    { (is_next || is_finish) &&
                        <div>
                            <Button type={"primary"} size={"large"} icon={CheckSVG}
                                    onClick={() => { if (onGood) onGood() }} >
                                {is_next ? 'СЛЕДУЮЩАЯ ТОЧКА' : 'ЗАВЕРШИТЬ ЗАКАЗ'}</Button>
                            <div className="driver_status_window_problem">Сообщить о проблеме</div>
                        </div>
                    }


                </div>
            </div>
        </>
    );
};


export default OnPlacePlayWindow ;
