import React, {useEffect, useState} from "react";
import { useDispatch } from "react-redux";
import "./OrderSmallCard.css";
import {OrderFullInfo, OrdersState} from "../../../redux/reducers/orders/@types";
import moment from "moment";
import {getDistance} from "../../OrderInfoWindow/OrderInfoWindow";


interface IProps {

  order: OrderFullInfo;
  isLooked: boolean;
  isFull?: boolean;
  state: OrdersState;
  onClick?: () => void;
  user_coordinate:{lon:number, lat:number} | null;

}

const OrderSmallCard: React.FC<IProps> = ({
                                            order,
                                            state,
                                            onClick,
                                            user_coordinate,
                                            isLooked= true,
                                            isFull = false,


}) => {



  const   today =  moment(new Date());
  const  street =  order.routes.length ? order.routes[0].adress.trim() : '';

  let date_order =  moment(order.date);
  let is_today =  today.diff(date_order, 'days') === 0;
  //let body_type :{id :string,name:string} | undefined = state.bodyOptions.find(x=> x.id === order.body_option_id) ;
  let body_type : any = state.additionalRequirements.filter(st=>
      order.additional_requirements.filter(x=>x.value != "false").some( x=> st.id == x.id)
  ).map(x=> <li>{x.name}</li>) ;

  let bo = state.bodyOptionCharacteristics;
  let order_bo = order.body_option_characteristics.filter(x=>x.value !== "false");

    return (
    <div className={`order_block ${!isLooked && `unviewed`} ${isFull ? `full_order_block` : `short_order_block`}`} onClick={() => onClick ? onClick() : null}>
      <div className="order_block_info">
        <div className="order_block_info_left">
          <div className="order_block_title">
            <div className="order_block_street">{street}</div>

          </div>
          <div className="order_block_points">{order.routes.length} точки.</div>
        </div>
        <div className="order_block_info_right">
          <div className={is_today ? "order_block_datetime" :  "order_block_price"}>{is_today ? 'Сегодня ' + date_order.format("HH:mm") : '3501 ₽' }</div>
            {is_today ?  <div className="order_block_distantion">~ {user_coordinate ? getDistance(order.routes[0].adress_latitude, order.routes[0].adress_longitude, user_coordinate.lat, user_coordinate.lon) : '0'} км от вас</div> : <div className="order_block_distantion">№ {order.number}</div>}
        </div>
      </div>
      <div className="order_block_footer">
        <div className="order_block_conditions">
          <ul>
            {body_type.length ? body_type : <li>доп. требования отсутсвуют</li>  }
            {  bo.filter(st=> order_bo.some( (x )=> st.id == x.id))
                 .map((x,i)=> <li key={'li-name-'+x.id}>{x.name}</li>)}
          </ul>
        </div>
          {is_today ? <div className="order_block_id">№ {order.number}</div> : <div className="order_block_status">{order.status}</div>}
      </div>
    </div>
  );
}

export default OrderSmallCard;
