import { RegistrationStatus } from "../../../../@types";

export const SET_REGISTRATION_STATUS = 'SET_REGISTRATION_STATUS';
export const RESTORE_REGISTRATION_STATUS = 'RESTORE_REGISTRATION_STATUS';

export interface SetRegistrationStatusAction {
  type: typeof SET_REGISTRATION_STATUS;
  status: RegistrationStatus | null
}

export interface RestoreRegistrationStatusAction {
  type: typeof RESTORE_REGISTRATION_STATUS
}

export type RegistrationActionTypes = SetRegistrationStatusAction
	| RestoreRegistrationStatusAction;

export interface RegistrationState {
  status: RegistrationStatus | null;
  previous_status: RegistrationStatus | null
}
