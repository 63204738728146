export function HereMarkerIconFixed(labels, pointNumber) {
  let margin = labels.length;
  margin = margin === 1 ? 12 : margin * 4.5;
  let marginStr = margin.toString();

  let color = pointNumber === 1 ? '#FF9900' : '#5364FF';
  let markerStyle = pointNumber === 1 ? 'marker-time-orange' : 'marker-time-blue';

  let t =  '<div>' +
    '<div class="leaflet-marker-icon leaflet-zoom-animated leaflet-interactive" style="margin-left: 0px;margin-top: 0px">';
  //t += labels.length ? '<div class="'+markerStyle+'" style="margin-left: -'+marginStr+'px; margin-top: -30px;">' + labels + '</div>' : '';
  t += labels.length ? '<div class="'+markerStyle+'" style="max-width: 200px; margin-top: -30px;">' + labels + '</div>' : '';

  t += '<svg width="30" height="20"><circle cx="10" cy="8" r="6" stroke="'+color+'" fill="#FFFF" style="stroke-width: 4px" /></svg> </div> </div>';
  return t;
}
