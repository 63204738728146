import React from "react";
import CloseSVG from "../../App/svg/close.svg";
import { ReactSVG } from "react-svg";
import { SystemStatus } from "../../../@types";
import {popSystemStatus, setSystemStatus} from "../../../redux/reducers/system/actions";
import "./CancelBtn.css";
import {useDispatch} from "react-redux";

interface IProps  {
	onClick?: Function
}
const CancelBtn: React.FunctionComponent<IProps> = ( {onClick = null }) => {
	const dispatch = useDispatch();
	return (
		<div className="cancel_btn" onClick={() => {
			if (onClick) onClick();
			else {
				dispatch(popSystemStatus());
			}
		}}>
			<ReactSVG src={CloseSVG} />
		</div>
	);
}

export default CancelBtn;
