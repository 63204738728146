import { RootState } from "../../store"

export const getChatUsersSelector = (state: RootState) => {
    return state.chatReducer.users
}

export const getChatCommandsSelector = (state: RootState) => {
    return state.chatReducer.commands
}

export const getChatMessagesSelector = (state: RootState) => {
    return state.chatReducer.messages
}

export const getDriverIDSelector = (state: RootState) => {
    return state.userReducer.driver_id
}

export const getOrdersSelector = (state: RootState) => {
    return state.ordersReducer.items
}