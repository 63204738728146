import {
	VehicleData,
	Driver,
	Owner,
} from "../../../../components/Elements/Containers/@types";

export const SET_FINALIZING = 'SET_FINALIZING';
export const SET_ASSIGNING_DRIVER = 'SET_ASSIGNING_DRIVER';
export const SET_IS_OWNER = 'SET_IS_OWNER';
export const SET_CAMERA_ACCESSED = 'SET_CAMERA_ACCESSED';
export const SAVE_OWNER = 'SAVE_OWNER';
export const SAVE_DRIVER = 'SAVE_DRIVER';
export const UPDATE_DRIVER = 'UPDATE_DRIVER';
export const SAVE_VEHICLE = 'SAVE_VEHICLE';
export const UPDATE_VEHICLE = 'UPDATE_VEHICLE';
export const REMOVE_VEHICLE = 'REMOVE_VEHICLE';

export interface SetFinalizingAction {
  type: typeof SET_FINALIZING;
  finalizing: boolean;
}

export interface SetAssigningDriverAction {
  type: typeof SET_ASSIGNING_DRIVER;
  assigning_driver: boolean;
}

export interface SetIsOwnerAction {
	type: typeof SET_IS_OWNER;
	is_owner: boolean;
}

export interface SetCameraAccessedAction {
	type: typeof SET_CAMERA_ACCESSED;
	camera_accessed: boolean;
}

export interface SaveOwnerAction {
	type: typeof SAVE_OWNER;
	owner: Owner;
}

export interface SaveDriverAction {
	type: typeof SAVE_DRIVER;
	driver: Driver;
}

export interface UpdateDriverAction {
	type: typeof UPDATE_DRIVER;
}

export interface SaveVehicleAction {
	type: typeof SAVE_VEHICLE;
	vehicle: VehicleData;
}

export interface UpdateVehicleAction {
	type: typeof UPDATE_VEHICLE;
}

export interface RemoveVehicleAction {
	type: typeof REMOVE_VEHICLE;
}

export type FinalizeActionTypes = SetFinalizingAction
	| SetAssigningDriverAction
	| SetIsOwnerAction
	| SaveOwnerAction
	| SetCameraAccessedAction
	| SaveDriverAction
	| UpdateDriverAction
	| SaveVehicleAction
	| UpdateVehicleAction
	| RemoveVehicleAction;

export interface FinalizeState {
	finalizing: boolean;
	assigning_driver: boolean;
	is_owner: boolean;
	camera_accessed: boolean;
	owner: Owner;
	current_driver: Driver;
	current_vehicle: VehicleData;
}
