import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import OrdersMenu from "./OrdersMenu/OrdersMenu";
import OrderCard from "./OrderCard/OrderCard";
import "./OrdersWindow.css";
import { setChatAllUsersAction,
         setChatCommandsAction,
         setChatMessagesAction,
         setChatUsersCleareAction,
         setChatUsersFullInfoAction } from "../../redux/reducers/chatReducers/actions";
import {getChatUsersSelector} from "../../redux/reducers/chatReducers/chatSelectors";
import {chatAPI, ordersAPI} from "../../api";
import {OrdersListType, SystemStatus} from "../../@types";
import {RootState} from "../../redux/store";
import {setOrders} from "../../redux/reducers/orders/actions";
import {isSystemStatusActive} from "../../redux/reducers/system/reducers";
import BottomWindow from "../Elements/BottomWindow/BottomWindow";
import {Chat} from "../Chat/Chat";

interface ILocalState {
  type: OrdersListType,
}

export function numberWithCommas(x: number) {

  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const OrdersWindow: React.FC = () => {
  const dispatch = useDispatch();

  const { user, orders, system } = useSelector((state: RootState) => ({
    user: state.userReducer,
    orders: state.ordersReducer,
    system: state.systemReducer
  }));

  const [localState, setLocalState] = useState<ILocalState>({
    type: OrdersListType.All
  }),

    [openChat, setChatOpen] = useState(false);

  const { type } = localState;

  const setType = (type: OrdersListType) => {
    setLocalState((s) => ({
      ...s,
      type
    }));
  }

  // ------------------------------------------------------------------------------------------------
  //
  // блок инициализации чата
  //
  //-------------------------------------------------------------------------------------------------

  const
    driverID = user.driver_id,
    // driverID = useSelector(getDriverIDSelector),
    ordersID =  [],
    //ordersID = orders.map(order => order.id)
    users = useSelector(getChatUsersSelector);

  let messageRequesLoop: ReturnType<typeof setInterval>,
      groupeRequestLoop: ReturnType<typeof setInterval>,
      usersGroupes: any = [];

  useEffect(() => {
    return ;
    // Получение групп пользователей
    groupeRequestLoop =
      setInterval(async function () {
        !usersGroupes.length && chatAPI.getGroups(user.driver_id).then(response => {
          response.status === 200 && (usersGroupes = response.data)
        })
      }, 10000);

    // опрос новых сообщений
    messageRequesLoop =
      setInterval(async () => {
        for (const userGroup of usersGroupes) {
          await chatAPI.getMessages(user.driver_id, userGroup.id).then(response => {
            if (response.status === 200) {
              dispatch(setChatMessagesAction(response.data))
            }
          }).catch(error => console.log(error))
        }
      }, 10000);



    return () => {
      clearInterval(messageRequesLoop);
      clearInterval(groupeRequestLoop);
    }

  }, [user]);

  // запрос пользователей по условию авторизации и пустого массива пользователей
  useEffect(() => {
    return ;
    if (driverID && !users.length) {

      // обход всех заказов
      for (const orderID of ordersID) {

        // получаем первую часть информации о пользователях в чатах
        chatAPI.getAllUsers(driverID, orderID).then(response => {
          if (response.status === 200 && response.data.length) {
            dispatch(setChatAllUsersAction(response.data.map((element: any) => ({ ...element, orderID }))));
            // обход для инициации чатов с заказчиками
            for (const user of response.data) {
                chatAPI.sendUsers({ driver_id: driverID, order_id: orderID, group_id: user.group_id, id: user.id }).catch(error => console.log(error))
            }
          }
        })
          .then(() => {

            // получаем вторую часть информации о пользователях в чатах
            chatAPI.getUsers(driverID, orderID).then(response => {

              if (response.status === 200) {
                dispatch(setChatUsersFullInfoAction(response.data))
              }
            });

            // получаем доступный список команд для быстрого ввода
            chatAPI.getComands(driverID, orderID).then(response => {
              if (response.status === 200) {
                dispatch(setChatCommandsAction(response.data))
              }
            }).catch(error => console.log(error))
          })
          .catch(error => console.log(error))
      }
    }
  }, [users])

  // в случае изменения в заказах стирает пользователей
  useEffect(() => {
    dispatch(setChatUsersCleareAction())
  }, [orders.items])

  //--------------------------------------------------------------------------------------------
  //--------------------------------------------------------------------------------------------

  useEffect(() => {

    ordersAPI.getList(user.driver_id, type, ).then((res) => {
      dispatch(setOrders(res.data));
    });
  }, [type, user.driver_id]);


  const orders_sum : number = orders.items.reduce((sum, current) => (sum + current.price), 0);



  if (isSystemStatusActive(SystemStatus.FullOrderCard, system.statuses))
    return (<></>);

  if (!user.authenticated) {
    return (
      <>
        <BottomWindow
          title={`${orders.items.length} доступных заказов`}
          description={`Стоимостью до ${numberWithCommas(Math.round(orders_sum))} ₽`}
          resizable={true}
          visible={system.ordersWindow}
          style={{ background: "#ecedef" }}
        >
          <div className="short_content">
            <div className="orders_list">
              {orders.items.map((item, i) => (
                <OrderCard key={'ff' + item.id} order={item} isAuth={false} isLooked={true}
                           user_coordinate={user.current_coordinates}  orderType={localState.type}/>
              ))}
            </div>
            <OrdersMenu setChatOpen={setChatOpen} />
          </div>

          <div className="full_content">
            <div className="orders_list">
              {orders.items.map((item, i) => (
                <OrderCard key={'ful_card' + item.id} order={item} isAuth={false} isFull={true} isLooked={false}
                           orderType={localState.type} user_coordinate={user.current_coordinates} />
              ))}
            </div>
          </div>
        </BottomWindow>
        {openChat && <Chat driverID={driverID} setChatOpen={setChatOpen} />}
      </>

  );
  } else {
    return (
      <>
        <BottomWindow title="Список заказов" resizable={true} visible={system.ordersWindow} style={{ background: "#ecedef" }}>
          <div className="orders_tabs">
            <div className={type === "all" ? "orders_tab active" : "orders_tab"} onClick={() => setType(OrdersListType.All)}>Все</div>
            <div className={type === "set" ? "orders_tab active" : "orders_tab"} onClick={() => setType(OrdersListType.Set)}>Назначенные</div>
            <div className={type === "offer" ? "orders_tab active" : "orders_tab"} onClick={() => setType(OrdersListType.Offer)}>Предложенные</div>
          </div>

          <div className="short_content">
            <div className="orders_list">
              {orders.items.map((item, i) => (
                <OrderCard key={'card' + item.id + i} order={item} isLooked={item.is_look}
                           orderType={localState.type} user_coordinate={user.current_coordinates} />
              ))}
            </div>
            <OrdersMenu setChatOpen={setChatOpen} />
          </div>

          <div className="full_content">
            <div className="orders_list">
              {orders.items.map((item, i) => (
                <OrderCard key={'full_card' + item.id} order={item} isFull={true} isLooked={item.is_look}
                           orderType={localState.type} user_coordinate={user.current_coordinates} />
              ))}
            </div>
          </div>
        </BottomWindow>
        {openChat && <Chat driverID={driverID} setChatOpen={setChatOpen} />}
      </>
    );
  }
}

export default OrdersWindow;
