import {
	SET_PHONE_ENTERED,
	SET_CODE_SENDING,
	SET_CODE_SENT,
	SET_CODE_ENTERED,
	SET_CODE_VERIFYING,
	SetPhoneEnteredAction,
	SetCodeSendingAction,
	SetCodeSentAction,
	SetCodeEnteredAction,
	SetCodeVerifyingAction
} from "./@types";

export const setPhoneEntered = (phone_entered: boolean, phone?: string): SetPhoneEnteredAction => ({ 
  type: SET_PHONE_ENTERED, 
  phone_entered,
  phone
});

export const setCodeSending = (code_sending: boolean): SetCodeSendingAction => ({ 
  type: SET_CODE_SENDING, 
  code_sending
});

export const setCodeSent = (code_sent: boolean): SetCodeSentAction => ({ 
  type: SET_CODE_SENT, 
  code_sent
});

export const setCodeEntered = (code_entered: boolean, code?: string): SetCodeEnteredAction => ({ 
  type: SET_CODE_ENTERED, 
  code_entered,
  code
});

export const setCodeVerifying = (code_verifying: boolean): SetCodeVerifyingAction => ({ 
  type: SET_CODE_VERIFYING, 
  code_verifying
});
