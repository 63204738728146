export const ADD_POINT_ON_MAP = 'ADD_POINT_ON_MAP';
export const SET_POINTS_ON_MAP = 'SET_POINTS_ON_MAP';
export const UPDATE_POINT_ON_MAP = 'UPDATE_POINT_ON_MAP';
export const REMOVE_POINT_FROM_MAP = 'REMOVE_POINT_FROM_MAP';
export const SET_MAP_CENTER = 'SET_MAP_CENTER';
export const SET_ROUTE = 'SET_ROUTES';
export const REMOVE_ROUTE = 'REMOVE_ROUTES';

export type MapPoint = {
    lng:number,
    lat:number,
    data?:{
        region_type?: string,
        region?: string,
        street_type?: string,
        street?: string,
        house?: string,
        label?: string
    }
}

export type CoordinatePoint = [number,number];

export interface AddPointOnMap {
    type: typeof ADD_POINT_ON_MAP;
    point: MapPoint;
    index: number;
}

export interface UpdatePointOnMap {
    type: typeof UPDATE_POINT_ON_MAP;
    point: MapPoint;
    index: number;
}

export interface setPointOnMap {
    type: typeof SET_POINTS_ON_MAP;
    points: MapPoint[];
}

export interface setRouteOnMap  {
    type: typeof SET_ROUTE;
    route: RouteInfo | null;
}

export interface removeRouteOnMap  {
    type: typeof REMOVE_ROUTE;
}

export interface RemovePointFromMap {
    type: typeof REMOVE_POINT_FROM_MAP;
    index: number;
}

export interface setMapCenter {
    type: typeof SET_MAP_CENTER;
    point: number[];
}


export type MapDataActionTypes = AddPointOnMap | setPointOnMap | UpdatePointOnMap | RemovePointFromMap | setMapCenter | setRouteOnMap | removeRouteOnMap;

export interface MapDataState {
    points: MapPoint[],
    use_data_from_1c:boolean,
    center: number[],
    route: RouteInfo | null
}


export  interface RouteInfo {
    status: number,
    error_code: number,
    error_message: string,
    base_time: number,
    traffic_time: number,
    distance: number,
    shapes: any[]  ,
    points:
        {
            order_id: string,
            lng: number,
            lat: number,
            time_waiting: number,
            distance: number,
            base_time: number,
            traffic_time: number,
            shape: string[],
            region: string,
            arrival_base_time: string,
            departure_base_time: string,
            arrival_traffic_time: string,
            departure_traffic_time: string
        }[],
    mkad_points_info:
        {
            from: string,
            to: string,
            fromReg: string,
            toReg: string,
            distance: number,
            insideMKAD: boolean
        }[],
    mkad_summary: {
        outside_count: number,
        inside_distance: number ,
        outside_distance: number ,
        outside_distance_minus_30: number
    },
    points_to_draw:
        {
            mkad_start: boolean,
            time_waiting: number,
            order_id: string,
            lng: number,
            lat: number,
            arrival_base_time: string,
            departure_base_time: string,
            arrival_traffic_time: string,
            departure_traffic_time: string,
            distance: number,
            base_time: number,
            traffic_time: number,
            region: string,
            shape: string[]
        } []
}
