import React, { useRef, useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import InputMask from "react-input-mask";
import DatePicker from "react-date-picker";
import Select from "react-dropdown-select";
import { RootState } from "../../redux/store";
import { RegistrationStatus, SystemStatus } from "../../@types";
import { Driver } from "../Elements/Containers/@types";
import {
	LicenseDataState,
	SetLicenseNumberEnteredAction,
	SetLicenseIssueDateEnteredAction,
	SetLicenseIssuedByEnteredAction,
	SetCountryEnteredAction,
	SetCategoryEnteredAction,
	AddLicensePhotoAction,
	ReplaceLicensePhotoAction,
	ClearLicensePhotosAction
} from "../../redux/reducers/license_data/@types";
import {
	FinalizeState,
	SetCameraAccessedAction,
	SaveDriverAction,
	UpdateDriverAction
} from "../../redux/reducers/finalize/@types";
import {
	SetRegistrationStatusAction,
	RestoreRegistrationStatusAction
} from "../../redux/reducers/registration/@types";
import {
	SystemState,
	SetSystemStatusAction
} from "../../redux/reducers/system/@types";
import {
	setLicenseNumberEntered,
	setLicenseIssueDateEntered,
	setLicenseIssuedByEntered,
	setCountryEntered,
	setCategoryEntered,
	addLicensePhoto,
	replaceLicensePhoto,
	clearLicensePhotos
} from "../../redux/reducers/license_data/actions";
import {
	setCameraAccessed,
	saveDriver,
	updateDriver
} from "../../redux/reducers/finalize/actions";
import {
	setRegistrationStatus,
	restoreRegistrationStatus
} from "../../redux/reducers/registration/actions";
import {popSystemStatus, setSystemStatus} from "../../redux/reducers/system/actions";
import { syncData } from "../../redux/reducers/user/actions";
import { CancelBtn, PhotoTaker, DaData } from "../Elements/elements";
import PhotoSVG from "../App/svg/photo.svg";
import { ReactSVG } from "react-svg";
import { validateOrg, validateDate } from "../../api/validate";
import { addressAPI } from "../../api";
import { editingChars } from "../../@types";
import "./LicenseData.css";
import { getLastSystemStatus } from "../../redux/reducers/system/reducers";

const licenseFrames = [
	{
		index: 0,
		title: "Сторона с фотографией",
		box: true,
		facing: 'environment',
		width: '80%',
		height: '25%'
	},
	{
		index: 1,
		title: "Обратная сторона",
		box: true,
		facing: 'environment',
		width: '80%',
		height: '25%'
	}
];

interface IProps {
  state: LicenseDataState,
  finalize: FinalizeState,
  system: SystemState,
  setLicenseNumberEntered: (number_entered: boolean, number?: string) => SetLicenseNumberEnteredAction,
  setLicenseIssueDateEntered: (issue_date_entered: boolean,
  	issue_date?: Date | null) => SetLicenseIssueDateEnteredAction,
  setLicenseIssuedByEntered: (issued_by_entered: boolean, issued_by?: string) => SetLicenseIssuedByEnteredAction,
  setCountryEntered: (country_entered: boolean, country?: string) => SetCountryEnteredAction,
  setCategoryEntered: (category_entered: boolean, categories?: string[]) => SetCategoryEnteredAction,
  addLicensePhoto: (photo: Blob) => AddLicensePhotoAction,
  replaceLicensePhoto: (index: number, new_photo: Blob) => ReplaceLicensePhotoAction,
  clearLicensePhotos: () => ClearLicensePhotosAction,
  setCameraAccessed: (camera_accessed: boolean) => SetCameraAccessedAction,
	saveDriver: (driver: Driver) => SaveDriverAction,
	updateDriver: () => UpdateDriverAction,
	setRegistrationStatus: (status: RegistrationStatus | null) => SetRegistrationStatusAction,
	restoreRegistrationStatus: () => RestoreRegistrationStatusAction,
	setSystemStatus: (status: SystemStatus) => SetSystemStatusAction
}

const LicenseData: React.FunctionComponent<IProps> = ({
	state,
	finalize,
	system,
	setLicenseNumberEntered,
	setLicenseIssueDateEntered,
	setLicenseIssuedByEntered,
	setCountryEntered,
	setCategoryEntered,
	addLicensePhoto,
	replaceLicensePhoto,
	clearLicensePhotos,
	setCameraAccessed,
	saveDriver,
	updateDriver,
	setRegistrationStatus,
	restoreRegistrationStatus,
	setSystemStatus
}) => {
	const [cameraShown, setCameraShown] = useState(false);
	const [editing, setEditing] = useState(-1);
	const licenseCountry = useRef<HTMLInputElement>(null);
	const [debugMode, setDebugMode] = useState(false);
	const dispatch = useDispatch();
	const [error, setError] = useState(false);

	const handleChange = (photos: Blob[]) => {
		setCameraAccessed(true);
		if (editing >= 0) {
			replaceLicensePhoto(editing, photos[0]);
			setEditing(-1);
		} else {
			photos.forEach((photo, index) => {
				replaceLicensePhoto(index, photo);
			});
		}
	}

	useEffect(() => {
		if (finalize.assigning_driver) {
			setLicenseNumberEntered(false, '');
			setLicenseIssueDateEntered(false, null);
			setLicenseIssuedByEntered(false, '');
			setCountryEntered(false, '');
			setCategoryEntered(false, []);
			clearLicensePhotos();
		} else {
			const current = finalize.current_driver;
			setLicenseNumberEntered(current?.license?.number !== undefined &&
				current?.license?.number !== '', current?.license?.number);
			setLicenseIssueDateEntered(current?.license?.issue_date !== undefined &&
				current?.license?.issue_date !== null, current?.license?.issue_date);
			setLicenseIssuedByEntered(current?.license?.issued_by !== undefined &&
				current?.license?.issued_by !== '', current?.license?.issued_by);
			setCountryEntered(current?.license?.country !== undefined &&
				current?.license?.country !== '', current?.license?.country);
			setCategoryEntered(current?.license?.categories !== undefined &&
				current?.license?.categories.length !== 0, current?.license?.categories);
			clearLicensePhotos();
			current?.license?.photos?.forEach((photo) => addLicensePhoto(photo));
		}
	}, []);

	useEffect(() => {
		if (window.location.host.indexOf('localhost') >= 0)
			setDebugMode(true);
	}, []);

	const setIssueDate = (date: Date | null) => {
		setLicenseIssueDateEntered(validateDate(date), date);
	}

	const toVehicle = () => {
		setRegistrationStatus(RegistrationStatus.Vehicle);
	}

	const toFinalize = () => {
		setRegistrationStatus(RegistrationStatus.Finalize);
	}

	const toProfile = () => {
		setRegistrationStatus(null);
	}

  return (
		<>
			<CancelBtn onClick={() => {
				if (cameraShown) {
					setCameraShown(false);
					return;
				}
				if (editing >= 0) {
					setEditing(-1);
					return;
				}
				restoreRegistrationStatus();
			}} />
			{(cameraShown && state.photos.length < 2) || editing >= 0 ?
				<PhotoTaker
					id="license_photo"
					title="Сделайте фотографии водительских прав"
					editing={editing >= 0}
					ask={!finalize.camera_accessed}
					frames={editing >= 0 ? [licenseFrames[editing]] : licenseFrames}
					pictures={editing >= 0 ? [state.photos[editing]] : state.photos}
					onChange={(photos: Blob[]) => handleChange(photos)}
					onError={() => setCameraShown(false)}
					camera={cameraShown}
				/> :
				<div className="license_window">
					<div className="license_container">
						<div className="license_title">{finalize.is_owner ?
							'Ваше водительское удостоверение' : 'Водительское удостоверение'}</div>
						<div id="license_name"
							className={'license_input' + ((finalize.is_owner && finalize.owner?.passport?.name === '') ||
								(!finalize.is_owner && finalize.current_driver?.passport?.name === '') ?
								'' : ' license_input_complete license_autofill')}>
							<input className="license_input_text" placeholder="ФИО полностью" type="text"
								value={finalize.is_owner ? finalize.owner?.passport?.name :
									finalize.current_driver?.passport?.name} readOnly
							/>
						</div>
						<div className="license_input_container">
							<div id="license_number" className={'license_input license_input_short' +
								(state.number_entered ? ' license_input_complete' : (error ? ' error' : ''))}>
								<InputMask mask="99 99 999999" value={state.number} onChange={(e) => {
										if (e.target.value !== '' && e.target.value.indexOf('_') === -1) {
											setLicenseNumberEntered(true, e.target.value);
										} else {
											setLicenseNumberEntered(false);
										}
									}}>
									{(inputProps: any) => <input {...inputProps} className="license_input_text"
										placeholder="Серия и номер" type="text" />}
								</InputMask>
							</div>
							<div id="license_issue_date" className={'license_input license_input_short' +
								(state.issue_date_entered ? ' license_input_complete' : (error ? ' error' : ''))}>
								<DatePicker
									value={state.issue_date}
									clearIcon={null}
									showLeadingZeros={true}
									closeCalendar={true}
									dayPlaceholder="ДД"
									monthPlaceholder="ММ"
									yearPlaceholder="ГГГГ"
									format="dd.MM.y"
									locale="ru-RU"
									maxDate={new Date()}
									onChange={setIssueDate}
								/>
							</div>
						</div>
						<div id="license_issued_by" className={'license_input' + (state.issued_by_entered ?
							' license_input_complete' : (error ? ' error' : ''))}>
							<textarea className="license_textarea" placeholder="Кем выдано" rows={2}
								value={state.issued_by} onKeyDown={(e: React.KeyboardEvent) => {
									if (!/[-а-яА-Я0-9№.,/ ()"]/.test(e.key) && !editingChars.includes(e.key)) {
										e.preventDefault();
									}
								}}
								onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
									if (validateOrg(e.target.value)) {
										setLicenseIssuedByEntered(true, e.target.value);
									} else {
										setLicenseIssuedByEntered(false);
									}
								}}
							/>
						</div>
						<div className="license_input_container">
							<div id="license_country" className={'license_input license_input_short' +
								(state.country_entered ? ' license_input_complete' : (error ? ' error' : ''))}>
								<DaData
									value={state.country}
									inputClassName="license_input_text"
									ref={licenseCountry}
									placeholder="Страна"
									onBlur={async () => {
										if (licenseCountry.current && licenseCountry.current.value !== '') {
											let data = await addressAPI.getCountries(licenseCountry.current.value);
											if (data && data.data && data.data.suggestions) {
												setCountryEntered(true, data.data.suggestions[0].value);
											}
										}
									}}
									onChange={async (value: string) => {
										setCountryEntered(false);
										let data = await addressAPI.getCountries(value);
										if (data && data.data && data.data.suggestions) return data.data.suggestions;
										return [];
									}}
									onSelect={(suggestion: any) => {
										setCountryEntered(suggestion.value);
									}}
								/>
							</div>
							<div id="license_category" className={'license_input license_input_short' +
								' license_select_wrapper' + (state.category_entered ? ' license_input_complete' :
								(error ? ' error' : ''))}>
								<Select
									multi={true}
									options={["A", "B", "C", "B1", "C1", "C1E", "D1", "D1E", "BE", "CE", "DE"]}
									searchable={false}
									values={state.categories}
									onChange={() => {}}
									contentRenderer={({ props, state }) => (
										<div className="react-dropdown-select-content">
											{state.values.length === 0 ?
												<span className="license_select_placeholder">Категория</span> :
												state.values.join(', ')
											}
										</div>
									)}
									dropdownRenderer={({props}) => (
										<>
											{props.options.map((item: string, index) => (
												<span key={'category_' + index}
													className={'react-dropdown-select-item license_select_item' +
														(state.categories && state.categories !== undefined &&
														state.categories.find((cat: string) => item === cat) ?
														' license_select_item_selected' : '')}
													onClick={() => {
														let categories = state.categories;
														if (categories && categories !== undefined) {
															let index = categories.findIndex((cat: string) => item === cat);
															if (index >= 0) {
																categories.splice(index, 1);
															} else {
																categories.push(item);
															}
														} else {
															categories = [item];
														}
														if (categories && categories.length > 0) {
															setCategoryEntered(true, categories);
														} else {
															setCategoryEntered(false);
														}
													}}
												>{item}</span>
											))}	
										</>
									)}
								/>
							</div>
						</div>
						{state.photos.length > 0 ?
							<div className="license_photo_container">
								{state.photos.map((photo, index) => (
									<img id={'license_photo_' + index} key={'license_photo_' + index} alt=""
										className="license_photo" src={URL.createObjectURL(photo)}
										onClick={() => setEditing(index)} />
								))}
							</div> :
							<div id="license_photos" className={'license_photo_button' + (error ? ' error' : '')}
								onClick={() => setCameraShown(true)}>
								<div className="license_photo_button_text">
									Фотографии удостоверения<br />
									<ReactSVG src={PhotoSVG} />
								</div>
							</div>
						}
						<section className="license_footer">
							<div id="license_back" className="license_link" onClick={restoreRegistrationStatus}>
								{finalize.is_owner ? 'Отменить' : 'Вернуться назад'}</div>
							<div id="license_next" className={'license_button' +
								(state.number_entered && state.issue_date_entered && state.issued_by_entered &&
								state.country_entered && state.category_entered &&
								(debugMode || state.photos.length === 2) ?
								'' : ' inactive')} onClick={() => {
								setError(false);
								if (!state.number_entered || !state.issue_date_entered || !state.issued_by_entered ||
									!state.country_entered || !state.category_entered ||
									(!debugMode && state.photos.length !== 2)) {
									setError(true);
									return;
								}
								setCameraShown(false);
								saveDriver({ ...(finalize.current_driver), license: {
									number: state.number,
									issue_date: state.issue_date,
									issued_by: state.issued_by,
									country: state.country,
									categories: state.categories,
									photos: state.photos
								}});
								updateDriver();
								switch (getLastSystemStatus(system.statuses)) {
									case SystemStatus.Registration:
										setTimeout(finalize.assigning_driver ? toVehicle : toFinalize, 500);
										return;
									case SystemStatus.Profile:
										dispatch(syncData());
										setTimeout(finalize.assigning_driver ? toVehicle : toProfile, 500);
										return;
									default:
										return;
								}
							}}>
				    		<div className="license_button_text">Сохранить</div>
				    	</div>
						</section>
					</div>
				</div>
			}
		</>
	)
}

const mapStateToProps = (state: RootState) => ({
	state: state.licenseDataReducer,
	finalize: state.finalizeReducer,
	system: state.systemReducer
});

export default connect(mapStateToProps, {
	setLicenseNumberEntered,
	setLicenseIssueDateEntered,
	setLicenseIssuedByEntered,
	setCountryEntered,
	setCategoryEntered,
	addLicensePhoto,
	replaceLicensePhoto,
	clearLicensePhotos,
	setCameraAccessed,
	saveDriver,
	updateDriver,
	setRegistrationStatus,
	restoreRegistrationStatus,
	setSystemStatus
})(LicenseData);
