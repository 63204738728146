// import H from "@here/maps-api-for-javascript";
import {CoordinatePoint, MapPoint} from "../../../redux/reducers/map/@types";
import {HereMarkerIconFixed} from "./HereMarkerIconFixed";
import {map} from "./HereMap";
import {HereMarkerBlue} from "./HereMarkerIcon";

const  ROUTE_LINE = 'route_line';
export const  MARKER_1 = 'MARKER_1';
export const  MARKER_2 = 'MARKER_2';

export  const  setMapCenter= ( point:{lat:number,lng:number}, map : H.Map) => {
    map.setCenter({lat: point.lat, lng: point.lng});
    map.setZoom(14);
};

export function hereMarker(label:string , coordination:number[], index :number|string = 1, point : {arrivalDate:string,departureDate:string, address:{value:string|undefined} }, type = 0) {
    // let icon = (type === 0) ? HereMarkerIcon(label) : HereMarkerIconFixed(label, number);
     let icon =  HereMarkerIconFixed(label, index);
     if (type === 1)
        icon =  HereMarkerBlue(label);
    let labelParts = label.split('<br>');

    labelParts[1] = labelParts[1] === undefined ? '' : labelParts[1];


    let fontColor = labelParts[1].indexOf('локация') >= 0 ? '#888E99' : '#000000';

    icon = (type === 0) ?
        icon.replace('{NUMBER}', '<div style="color: #888E99;">'+labelParts[0] + ' ' + index +
            '</div><div style="font-weight: 500; color: '+fontColor+'">' + labelParts[1]+'</div>') :
        icon.replace('{NUMBER}', labelParts[0]); // уберем номер из описания


    let marker = new H.map.DomMarker({lat: coordination[0], lng: coordination[1]}
        , {
            // @ts-ignore
            volatility: true,
            icon: new H.map.DomIcon(
                icon.replace('{NUMBER}',
                    labelParts[0] + ' ' + index + '<br>' + labelParts[1])
            ),
            data: {name: type ==0 ? MARKER_1 : MARKER_2, text :  getPointText(point, label)}
        });
    // @ts-ignore
    marker.draggable = true;
    // @ts-ignore
    marker.itemIndex = +index - 1;

    return marker;
}

export const removeMarkers = (map: H.Map) => {
    removeObjectFromMap(map, MARKER_1);
};

export const clearMap = (map: H.Map) => {
    removeObjectFromMap(map, MARKER_1);
    removeObjectFromMap(map, MARKER_2);
    removeObjectFromMap(map, ROUTE_LINE);
};

export function getPointText(point:{arrivalDate:string,departureDate:string, address:{value:string | undefined} }, label:string) {
    let arrivalDate =  new Date(point.arrivalDate);
    let departureDate = new Date(point.departureDate);

    let arrivalTime = arrivalDate.getDate() ?
        arrivalDate.getHours().toString().padStart(2, '0') + ':'
        + arrivalDate.getMinutes().toString().padStart(2, '0')
        : '00:00';

    let departureTime = departureDate.getDate() ?
        departureDate.getHours().toString().padStart(2, '0') + ':'
        + departureDate.getMinutes().toString().padStart(2, '0')
        : '00:00';

    let address = point.address && point.address.value ? point.address.value : '';

    return '<b>' + label + '</b><br/>' + address
        + '<br/><br/>планируемое время<br/>прибытие ' + arrivalTime
        + '<br/>убытие ' + departureTime;
}

export  const  calculateRoute = (platform: H.service.Platform, waypoints: string[], map:H.Map  ) => {

    if (waypoints.length<2) return ;

    let router = platform.getRoutingService();

    var routeRequestParams : any = {
        representation: 'display',
        routeattributes : 'waypoints,summary,shape,legs',
        mode: "balanced;truck;traffic:enabled;",
        language:'ru-RU',
        truckRestrictionPenalty:'strict',
        trailersCount:1,
    };

    waypoints.forEach(
        (point,index)=> routeRequestParams['waypoint'+ index] = point
    );

    console.log(routeRequestParams);

    let wPoint :CoordinatePoint[]=[];
    waypoints.forEach( x=> {
        let arr = x.split(',').map(x=> +x);
        if (arr.length)
            wPoint.push( [arr[1], arr[0]]);
    });

    return new Promise( (resolve, reject) => {
        router.calculateRoute(
            routeRequestParams,
            (result :any) => {

                if (result && result.response && result.response.route ) {
                    let route = result.response.route[0];
                    let lineLonLat = addRouteShapeToMap(route, map);
                }

                resolve(result);
            },
            (d) => {   resolve(null); }
        );
    } )
};


export function addRouteShapeToMap(shape: string[], map:H.Map, removeOldObjects = true,  lineWidth:number=3, color:string = 'rgba(72, 148, 224, 0.8)'){
    if (removeOldObjects)
        removeObjectFromMap(map, ROUTE_LINE);
    if (!shape || !shape.length) return;
    var lineString = new H.geo.LineString();
    shape.forEach( (x:any) => {
        let points : any[] = x.split(',');
        lineString.pushPoint({lat: +points[0], lng: +points[1]})
    });

    let p =  new H.map.Polyline( lineString, { data:{name: ROUTE_LINE}, style: { lineWidth: lineWidth, strokeColor: color}});
    map.addObject(p);
    // map.getViewModel().setLookAtData({
    //     bounds: p.getBoundingBox()
    // });


}

export function centerMapBox(map: H.Map) {
    setTimeout(()=>{
        // let rect = new H.geo.Rect();
        let listObj : H.map.Object[] = map.getObjects() as H.map.Object[];
        let rect = {top:0, left:0, right:0, bottom:0}
        listObj.forEach((x:any)=> {
            if (x.data && x.data.name === ROUTE_LINE ) {
                let r: H.geo.Rect = x.getBoundingBox();
                if (rect.top == 0 || rect.top > r.getTop()) rect.top = r.getTop();
                if (rect.left == 0 || rect.left > r.getLeft()) rect.left = r.getLeft();
                if (rect.right == 0 || rect.right < r.getRight()) rect.right = r.getRight();
                if (rect.bottom == 0 || rect.bottom < r.getBottom()) rect.bottom = r.getBottom();
            }
        });

        map.getViewModel().setLookAtData({
            bounds: new H.geo.Rect(rect.top, rect.left, rect.bottom, rect.right)
        });
        let zoom = map.getZoom();
        map.setZoom(zoom - zoom*0.05);
    }, 500)

}

export function removeObjectFromMap( map: H.Map, nameObject:string) {

    let listObj : H.map.Object[] = map.getObjects() as H.map.Object[];

    let objects: H.map.Object[] = listObj.filter( (obj : H.map.Object) => {

        // @ts-ignore
        if (obj.data && obj.data.name === nameObject) return true;
        return  false;
    });
    if (objects && objects.length)
        map.removeObjects(objects);
}


export function drawWayOnMap(points: MapPoint[], map: H.Map) {

    var lineString = new H.geo.LineString();

    points.forEach( point => lineString.pushPoint({lat:point.lat, lng:point.lng}));

    map.addObject(new H.map.Polyline(
        lineString, { data:null, style: { lineWidth: 5, strokeColor: 'rgba(224, 148, 72, 0.8)' }}
    ));
}
