import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {setOrder} from "../../redux/reducers/orders/actions";
import {
  popSystemStatus,
  removeSystemStatus, setMessage,
  setSystemStatus,
  setVisibilityOverlay
} from "../../redux/reducers/system/actions";
import {BottomWindow, Button} from "../Elements/elements";
import VehicleRequirements from "./VehicleRequirements/VehicleRequirements";
import CargoCard, {CargoTypes} from "./CargoCard/CargoCard";
import moment from "moment";
import "moment/locale/ru";
import "./OrderInfoWindow.css";
import {RouteInfo} from "../../redux/reducers/map/@types";
import {setRoutes} from "../../redux/reducers/map/actions";
import {EnumOrderStatus, OrdersListType, SystemStatus} from "../../@types";
import {getLastSystemStatus} from "../../redux/reducers/system/reducers";
import {ordersAPI, userAPI} from "../../api";


export const numWord = (value: number, words: string[]) => {
  value = Math.abs(value) % 100; 
  var num = value % 10;
  if(value > 10 && value < 20) return words[2]; 
  if(num > 1 && num < 5) return words[1];
  if(num === 1) return words[0];
  return words[2];
};

export const getDistance = (latFrom: number, lonFrom: number, latTo: number, lonTo: number): number => {
  const R = 6371;
  const dLat = (latTo - latFrom) * (Math.PI/180);
  const dLon = (lonTo - lonFrom) * (Math.PI/180);
  const a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.cos(latFrom * (Math.PI/180)) * Math.cos(latTo * (Math.PI/180)) * Math.sin(dLon/2) * Math.sin(dLon/2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  const d = R * c;

  return Math.round(d);
};


interface ILocalState {
  route: RouteInfo | null
}


const OrderInfoWindow: React.FC = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => ({
    orders: state.ordersReducer,
    user: state.userReducer,
    system: state.systemReducer
  }));

  const { orders } = state;
  const { additionalRequirements, bodyOptionCharacteristics, bodyOptionCharacteristicsValues, bodyTypes, carTypes, current } = orders;

   // Возвращает дистанцию в км между двумя координатами

  const getDistanceToFirstRoute = (): number => {
    if (!current.routes || current.routes.length<2) return 0;
    const latFrom = current.routes[0].adress_latitude;
    const lonFrom = current.routes[0].adress_longitude;
    const latTo = current.routes[1].adress_latitude;
    const lonTo = current.routes[1].adress_longitude;

    return getDistance(latFrom, lonFrom, latTo, lonTo);
  };

  const getTotalDistance = (): number => {
    if (current.routes.length<2) return 0;
    const latFrom = current.routes[0].adress_latitude;
    const lonFrom = current.routes[0].adress_longitude;
    const latTo = current.routes[current.routes.length - 1].adress_latitude;
    const lonTo = current.routes[current.routes.length - 1].adress_longitude;

    return getDistance(latFrom, lonFrom, latTo, lonTo);
  };

  // Возвращает время в минутах до начала заказа
  const getTimeToStartOrder = (): number => {
    const now = moment();
    const startTime = moment(current.date);

    return startTime.diff(now, "minutes");
  };

  const handleClose = () => {
    dispatch(popSystemStatus());
    dispatch(setOrder({}, OrdersListType.All));
     dispatch(setRoutes(null));
  };

  const showMap = () => {
    if (localState.route)  dispatch(setRoutes(localState.route));
    else return;
    dispatch(setSystemStatus(SystemStatus.CloseRoute));
    dispatch(setVisibilityOverlay(false));
    // dispatch(clearSystemStatus());
    // dispatch(setOrder(current, true));
    // dispatch(setOverlay(false));
  };

  const takeOrder = () =>{
     dispatch(setSystemStatus(SystemStatus.WaitingWindow));
     userAPI.reserveOrder(state.user.driver_id, current.id)
         .then((d)=>{
           dispatch(removeSystemStatus(SystemStatus.WaitingWindow));
           dispatch(setVisibilityOverlay(false));
           dispatch(setMessage({
              id:state.user.driver_id,
              statuses: [{ status: "fff", status_id: "0" }],
              order_id: current.id,
              message: "Заказ успешно назначен!"
             }))
         }).catch((e)=>{

         })
  };

  const [localState, setLocalState] = useState<ILocalState>( {
    route : null
  } );

  useEffect(()=>{
    if (current && current.id) {
      ordersAPI.getRoute(current.id).then((res:any)=>{
        if (!res || !res.data) return;
        setLocalState((s) => ({...s, route: res.data }));
        dispatch(setRoutes(res.data));
      })
    }
  },[current]);

  let dateFrom = new Date();
  let dateTo = new Date();
  if (current && current.date) {
    let arrayDate = current.date.split('T');
    dateFrom = new Date(arrayDate[0] + 'T' + arrayDate[1]);
    if (arrayDate.length === 3) {
      dateTo = new Date(arrayDate[0] + 'T' + arrayDate[2]);
    } else {
      dateTo = new Date(dateFrom);
      dateTo.setMinutes(dateTo.getMinutes() + 15);
    }
  }

  return (
    <>
      {current && current.id && (getLastSystemStatus( state.system.statuses) === SystemStatus.FullOrderCard) &&
        <BottomWindow
          title={`Заказ №${current.number}`}
          description={`${moment(dateFrom).format("dd DD.MM.YYYY").toUpperCase()} к ${moment(dateTo).format("HH:mm")}`}
          resizable={true}
          onClose={state.orders ? handleClose : undefined}
          visible={true}
          style={{ zIndex: 36 }}
        >
          {state.orders && !state.orders  &&
            <div className="accepting_info">Ждет принятия...</div>
          }
          <div className="bottom_window_content" >
            <div className="order_info_section" style={{ marginTop: 0 }}>
              <div className="order_info_roadmap_title">
                {current.routes ? current.routes.length : current.point_count}
                {numWord(current.routes ? current.routes.length : current.point_count, [" точка", " точки", " точек"])}
              </div>
              <ul className="order_info_roadmap">
                {current.routes && current.routes.map((item: any, i: number) => {
                  return (
                    <li key={item.id}  className={ 'number' + (i+1)}>
                      <div className="order_info_roadmap_address">{item.adress}</div>
                    </li>
                  );
                })}
                {!current.routes && <li key={'itemAddress1'}>
                  <div className="order_info_roadmap_address">{current.address}</div>
                </li>}
              </ul>
            </div>
            <Button type="grey" style={{ color: "#5364ff" }}  onClick={showMap} disabled={!localState.route}>На карте</Button>
            <VehicleRequirements 
              additionalRequirements={additionalRequirements}
              bodyTypes={bodyTypes}
              bodyOptionCharacteristics={bodyOptionCharacteristics}
              bodyOptionCharacteristicsValues={bodyOptionCharacteristicsValues}
              carTypes={carTypes}
              orderData={current}
            />

            <div className="order_info_section">
              <div className="order_info_section_name">Груз</div>
              {current.cargo && Boolean(current.cargo.packages.length) && <CargoCard type={CargoTypes.Packages} data={current.cargo} />}
              {current.cargo && Boolean(current.cargo.pallets.length) && <CargoCard type={CargoTypes.Pallets} data={current.cargo} />}
              {current.cargo && Boolean(current.cargo.places.length) && <CargoCard type={CargoTypes.Places} data={current.cargo} />}
            </div>
            {current.type === EnumOrderStatus.SET &&
              <div className="order_info_control_section">
                <div className="order_info_control_info">До начала заказа осталось {getTimeToStartOrder()} минут<br />До первой точки: {getDistanceToFirstRoute()} км</div>
                <Button type="primary" size="large" onClick={takeOrder} >ПРИНЯТЬ ЗАКАЗ 1</Button>
              </div>
            }
          </div>

          {/*<div className="bottom_window_content full_content">*/}
          {/*  <div className="order_info_section" style={{ marginTop: 0 }}>*/}
          {/*    <div className="order_info_roadmap_title">*/}
          {/*      {current.routes ? current.routes.length : current.point_count}*/}
          {/*      {numWord(current.routes ? current.routes.length : current.point_count, ["точка", "точки", "точек"])}  </div>*/}
          {/*    <ul className="order_info_roadmap">*/}
          {/*      {current.routes  && current.routes.map((item: any, i: number) => {*/}
          {/*        return (*/}
          {/*          <li key={item.id}  className={ 'number' + (i+1)}>*/}
          {/*            <div className="order_info_roadmap_address">{item.adress}</div>*/}
          {/*          </li>*/}
          {/*        );*/}
          {/*      })}*/}

          {/*      {!current.routes && <li key={'itemAddress1'}>*/}
          {/*        <div className="order_info_roadmap_address">{current.address}</div>*/}
          {/*      </li>}*/}
          {/*    </ul>*/}
          {/*  </div>*/}
          {/*  <Button type="grey" style={{ color: "#5364ff" }} onClick={showMap} disabled={!localState.route}>На карте</Button>*/}
          {/*  <VehicleRequirements */}
          {/*    additionalRequirements={additionalRequirements}*/}
          {/*    bodyTypes={bodyTypes}*/}
          {/*    bodyOptionCharacteristics={bodyOptionCharacteristics}*/}
          {/*    bodyOptionCharacteristicsValues={bodyOptionCharacteristicsValues}*/}
          {/*    carTypes={carTypes}*/}
          {/*    orderData={current}*/}
          {/*  />*/}
          {/*  <div className="order_info_section">*/}
          {/*    <div className="order_info_section_name">Груз</div>*/}
          {/*    {current.cargo && Boolean(current.cargo.packages.length) && <CargoCard type={CargoTypes.Packages} data={current.cargo} />}*/}
          {/*    {current.cargo && Boolean(current.cargo.pallets.length) && <CargoCard type={CargoTypes.Pallets} data={current.cargo} />}*/}
          {/*    {current.cargo && Boolean(current.cargo.places.length) && <CargoCard type={CargoTypes.Places} data={current.cargo} />}*/}
          {/*  </div>*/}
          {/*  {current.type === EnumOrderStatus.SET &&*/}
          {/*    <div className="order_info_control_section">*/}
          {/*      <div className="order_info_control_info">До начала заказа осталось {getTimeToStartOrder()} минут<br />До первой точки: {getDistanceToFirstRoute()} км</div>*/}
          {/*      <Button type="primary" size="large">ПРИНЯТЬ ЗАКАЗ</Button>*/}
          {/*    </div>*/}
          {/*  }*/}
          {/*</div>*/}

        </BottomWindow>
      }
    </>
  );
};

export default OrderInfoWindow;
