import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ReactSVG } from "react-svg";
import { setRegistrationStatus } from "../../redux/reducers/registration/actions";
import {popSystemStatus, setSystemStatus} from "../../redux/reducers/system/actions";
import CloseSVG from "../App/svg/close.svg";
import { IState } from "./@types";
import { SystemStatus } from "../../@types";
import { userAPI } from "../../api";
import "./Help.css";

const Help: React.FC = () => {
  const [state, setState] = useState<IState>({
    articles: [],
    article: null,
    loading: false
  });

  const { articles, article } = state;

  const dispatch = useDispatch();

  const openArticle = (id: string) => {
    const selectedArticle = articles.find((item) => item.id === id);
    if (selectedArticle) {
      setState((s) => ({
        ...s,
        article: selectedArticle
      }));
    }
  }

  const closeArticle = () => {
    setState((s) => ({
      ...s,
      article: null
    }));    
  }

  const getArticles = () => {
    setState((s) => ({
      ...s,
      loading: true
    }));

    userAPI.getArticles().then((res) => {
      setState((s) => ({
        ...s,
        loading: false,
        articles: [res.data]
      }));
    });
  }

  useEffect(() => {
    getArticles();
  }, []);

  const toMainScreen = () => {
      dispatch(popSystemStatus());
      dispatch(setRegistrationStatus(null));
  }

  return (
    <div className="screen_help">
      <div className="page_back_link" onClick={toMainScreen}>
        <svg width="1rem" height="1.5rem" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.02348 16.6742C8.23875 16.885 8.51272 17 8.83562 17C9.48141 17 10 16.5017 10 15.8692C10 15.553 9.86301 15.2655 9.63796 15.0451L2.78865 8.49042L9.63796 1.9549C9.86301 1.7345 10 1.43743 10 1.13078C10 0.498309 9.48141 0 8.83562 0C8.51272 0 8.23875 0.114994 8.02348 0.325817L0.410959 7.60879C0.136986 7.85795 0.00978474 8.1646 0 8.5C0 8.8354 0.136986 9.12289 0.410959 9.38162L8.02348 16.6742Z" fill="#888E99"/>
        </svg>
      </div>
      <div className="screen_help_header">Помощь</div>
      <div className="screen_help_list">
        {articles.map((item) => {
          return (
            <div key={item.id} className="screen_help_article" onClick={() => openArticle(item.id)}>
              <div className="screen_help_article_title">{item.title}</div>
              <div className="screen_help_article_description">{item.description}</div>
              <div className="screen_help_article_footer"><span className="screen_help_article_btn">Подробнее</span></div>
            </div>
          );
        })}
      </div>
      {article && 
        <div className="article_window">
          <div className="window_close_btn" onClick={closeArticle}><ReactSVG src={CloseSVG} /></div>
          <div className="article_window_header">{article.title}</div>
          <div className="article_window_content">{article.articale}</div>
        </div>
      }
    </div>
  );
}

export default Help;
