import {
	VehicleState,
	SET_CAR_TYPE_ENTERED,
	SET_BODY_OPTION_ENTERED,
	SET_BODY_OPTION_CHS_ENTERED,
	SET_CAR_PASS_ENTERED,
	SET_HYDROBOARD_ENTERED,
	SET_RAMP_ENTERED,
	SET_LENGTH_ENTERED,
	SET_WIDTH_ENTERED,
	SET_HEIGHT_ENTERED,
	SET_GARAGE_ENTERED,
	ADD_VEHICLE_PHOTO,
	REPLACE_VEHICLE_PHOTO,
	CLEAR_VEHICLE_PHOTOS,
	SET_MEDICAL_BOOK,
	SET_DRIVER_LOADER,
	SET_LICENSE_PLATE_ENTERED,
	SET_VIN_ENTERED,
	SET_BRAND_MODEL_ENTERED,
	SET_VEHICLE_TYPE_ENTERED,
	SET_VEHICLE_CATEGORY_ENTERED,
	SET_YEAR_ENTERED,
	SET_ECOLOGY_ENTERED,
	SET_PTS_SERIES_ENTERED,
	SET_PTS_NUMBER_ENTERED,
	SET_STS_SERIES_ENTERED,
	SET_STS_NUMBER_ENTERED,
	SET_MAX_WEIGHT_ENTERED,
	SET_LOAD_WEIGHT_ENTERED,
	ADD_CERTIFICATE_PHOTO,
	REPLACE_CERTIFICATE_PHOTO,
	CLEAR_CERTIFICATE_PHOTOS,
	ASSIGN_DRIVER,
	DISMISS_DRIVER,
	DISMISS_ALL,
	VehicleActionTypes
} from "./@types";

let initialState: VehicleState = {
	car_type_entered: false,
	car_type: '',
	body_option_entered: false,
	body_option: '',
	body_option_chs_entered: false,
	body_option_chs: [],
	car_pass_entered: false,
	car_pass: '',
	hydroboard_entered: false,
	hydroboard: '',
	ramp_entered: false,
	ramp: '',
	length_entered: false,
	length: '',
	width_entered: false,
	width: '',
	height_entered: false,
	height: '',
	garage_entered: false,
	address: null,
	latitude: '',
	longitude: '',
	vehicle_photos: [],
	medical_book: false,
	driver_loader: false,
	license_plate_entered: false,
	license_plate: '',
	vin_entered: false,
	vin: '',
	brand_model_entered: false,
	brand_model: '',
	vehicle_type_entered: false,
	vehicle_type: '',
	vehicle_category_entered: false,
	vehicle_category: '',
	year_entered: false,
	year: '',
	ecology_entered: false,
	ecology: '',
	pts_series_entered: false,
	pts_series: '',
	pts_number_entered: false,
	pts_number: '',
	sts_series_entered: false,
	sts_series: '',
	sts_number_entered: false,
	sts_number: '',
	max_weight_entered: false,
	max_weight: '',
	load_weight_entered: false,
	load_weight: '',
	certificate_photos: [],
	drivers: []
}

const vehicleReducer = (state = initialState, action: VehicleActionTypes) => {
	switch (action.type) {
		case SET_CAR_TYPE_ENTERED:
			return {
				...state,
				car_type_entered: action.car_type_entered,
				car_type: action.car_type
			}
		case SET_BODY_OPTION_ENTERED:
			return {
				...state,
				body_option_entered: action.body_option_entered,
				body_option: action.body_option
			}
		case SET_BODY_OPTION_CHS_ENTERED:
			return {
				...state,
				body_option_chs_entered: action.body_option_chs_entered,
				body_option_chs: action.body_option_chs
			}
		case SET_CAR_PASS_ENTERED:
			return {
				...state,
				car_pass_entered: action.car_pass_entered,
				car_pass: action.car_pass
			}
		case SET_HYDROBOARD_ENTERED:
			return {
				...state,
				hydroboard_entered: action.hydroboard_entered,
				hydroboard: action.hydroboard
			}
		case SET_RAMP_ENTERED:
			return {
				...state,
				ramp_entered: action.ramp_entered,
				ramp: action.ramp
			}
		case SET_LENGTH_ENTERED:
			return {
				...state,
				length_entered: action.length_entered,
				length: action.length
			}
		case SET_WIDTH_ENTERED:
			return {
				...state,
				width_entered: action.width_entered,
				width: action.width
			}
		case SET_HEIGHT_ENTERED:
			return {
				...state,
				height_entered: action.height_entered,
				height: action.height
			}
		case SET_GARAGE_ENTERED:
			return {
				...state,
				garage_entered: action.garage_entered,
				address: action.address,
				latitude: action.latitude,
				longitude: action.longitude
			}
		case ADD_VEHICLE_PHOTO:
			let add_vehicle_photos = state.vehicle_photos;
			add_vehicle_photos.push(action.photo);
			return {
				...state,
				vehicle_photos: add_vehicle_photos
			}
		case REPLACE_VEHICLE_PHOTO:
			let replace_vehicle_photos = state.vehicle_photos;
			if (action.index < replace_vehicle_photos.length) {
				replace_vehicle_photos[action.index] = action.new_photo;
			}
			return {
				...state,
				vehicle_photos: replace_vehicle_photos
			}
		case CLEAR_VEHICLE_PHOTOS:
			return {
				...state,
				vehicle_photos: []
			}
		case SET_MEDICAL_BOOK:
			return {
				...state,
				medical_book: action.medical_book
			}
		case SET_DRIVER_LOADER:
			return {
				...state,
				driver_loader: action.driver_loader
			}
		case SET_LICENSE_PLATE_ENTERED:
			return {
				...state,
				license_plate_entered: action.license_plate_entered,
				license_plate: action.license_plate
			}
		case SET_VIN_ENTERED:
			return {
				...state,
				vin_entered: action.vin_entered,
				vin: action.vin
			}
		case SET_BRAND_MODEL_ENTERED:
			return {
				...state,
				brand_model_entered: action.brand_model_entered,
				brand_model: action.brand_model
			}
		case SET_VEHICLE_TYPE_ENTERED:
			return {
				...state,
				vehicle_type_entered: action.vehicle_type_entered,
				vehicle_type: action.vehicle_type
			}
		case SET_VEHICLE_CATEGORY_ENTERED:
			return {
				...state,
				vehicle_category_entered: action.vehicle_category_entered,
				vehicle_category: action.vehicle_category
			}
		case SET_YEAR_ENTERED:
			return {
				...state,
				year_entered: action.year_entered,
				year: action.year
			}
		case SET_ECOLOGY_ENTERED:
			return {
				...state,
				ecology_entered: action.ecology_entered,
				ecology: action.ecology
			}
		case SET_PTS_SERIES_ENTERED:
			return {
				...state,
				pts_series_entered: action.pts_series_entered,
				pts_series: action.pts_series
			}
		case SET_PTS_NUMBER_ENTERED:
			return {
				...state,
				pts_number_entered: action.pts_number_entered,
				pts_number: action.pts_number
			}
		case SET_STS_SERIES_ENTERED:
			return {
				...state,
				sts_series_entered: action.sts_series_entered,
				sts_series: action.sts_series
			}
		case SET_STS_NUMBER_ENTERED:
			return {
				...state,
				sts_number_entered: action.sts_number_entered,
				sts_number: action.sts_number
			}
		case SET_MAX_WEIGHT_ENTERED:
			return {
				...state,
				max_weight_entered: action.max_weight_entered,
				max_weight: action.max_weight
			}
		case SET_LOAD_WEIGHT_ENTERED:
			return {
				...state,
				load_weight_entered: action.load_weight_entered,
				load_weight: action.load_weight
			}
		case ADD_CERTIFICATE_PHOTO:
			let add_certificate_photos = state.certificate_photos;
			add_certificate_photos.push(action.photo);
			return {
				...state,
				certificate_photos: add_certificate_photos
			}
		case REPLACE_CERTIFICATE_PHOTO:
			let replace_certificate_photos = state.certificate_photos;
			if (action.index < replace_certificate_photos.length) {
				replace_certificate_photos[action.index] = action.new_photo;
			}
			return {
				...state,
				certificate_photos: replace_certificate_photos
			}
		case CLEAR_CERTIFICATE_PHOTOS:
			return {
				...state,
				certificate_photos: []
			}
		case ASSIGN_DRIVER:
			let assign_drivers = state.drivers;
			let assign_index = assign_drivers.findIndex((driver) => (
				driver.id === action.driver.id));
			if (assign_index === -1) {
				assign_drivers.push(action.driver);
			}
			return {
				...state,
				drivers: assign_drivers
			}
		case DISMISS_DRIVER:
			let dismiss_drivers = state.drivers;
			let dismiss_index = dismiss_drivers.findIndex((driver) => (
				driver.id === action.driver.id));
			if (dismiss_index >= 0) {
				dismiss_drivers.splice(dismiss_index, 1);
			}
			return {
				...state,
				drivers: dismiss_drivers
			}
		case DISMISS_ALL:
			return {
				...state,
				drivers: []
			}
		default:
			return state;
	}
}

export default vehicleReducer;
