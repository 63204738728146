import React from "react";
import { VehicleData } from "./@types";
import "./Container.css";

interface IProps {
	id?: string;
	title?: string;
	single?: boolean;
	vehicles: VehicleData[];
	onAddVehicle: () => void;
	onEditVehicle: (index: number) => void;
}

const VehicleContainer: React.FunctionComponent<IProps> = (props) => {

	return (
		<div id={props.id} className="container">
			<div className="container_title">
				<div className="container_title_text">{props.title ? props.title : 'Ваши ТС'}</div>
			</div>
			<div>
				{props.vehicles && props.vehicles.map((vehicle: VehicleData, index: number) => (
					<div key={'vehicle_' + vehicle.certificate.license_plate} className="container_item"
						onClick={() => props.onEditVehicle(index)}>
						<div className="container_item_description">
							<div className="container_item_name">
								{`${vehicle.certificate.brand_model} ${vehicle.certificate.license_plate
									.replace(/ /g, '')}`}
							</div>
							{!props.single &&
								<div className="container_item_value">{vehicle.drivers.length === 0 ?
									`Не назначены водители` : `Назначено водителей: ${vehicle.drivers.length}`}
								</div>
							}
						</div>
						<div className="container_expand"></div>
					</div>
				))}
				{props.single && props.vehicles && props.vehicles.length > 0 ? null :
					<div key="vehicle_add" className="container_item" onClick={() => props.onAddVehicle()}>
						<div className="container_item_action">Добавить транспортное средство</div>
						<div className="container_add">
							<div className="container_add_h"></div>
							<div className="container_add_v"></div>
						</div>
					</div>
				}
			</div>
		</div>
	);
}

export default VehicleContainer;
