import React from "react";
import {Button} from "../../Elements/elements";
import ChatsSVG from "../../App/svg/chats.svg";
import "./OrdersMenu.css";
import {useDispatch, useSelector} from "react-redux";
import {SystemStatus} from "../../../@types";
import {setSystemStatus} from "../../../redux/reducers/system/actions";
import {RootState} from "../../../redux/store";
 import newMessagesIndicator from '../../../images/newMessagesIndicator.svg'
import { getChatMessagesSelector } from "../../../redux/reducers/chatReducers/chatSelectors";

interface IProps {
    setChatOpen: (value: boolean) => void
}

const OrdersMenu: React.FunctionComponent<IProps> = ({ setChatOpen }) =>  {
    const { user  } = useSelector((state: RootState) => ({
        user: state.userReducer,
    }));
    const dispatch = useDispatch();

    const clickPartnership =() => {
        dispatch(setSystemStatus(SystemStatus.Partnership))
    };

    const messages = useSelector(getChatMessagesSelector),
            hasNewMessages = messages.length ? messages.filter(message => message.in_out === "in" && message.read === false) : []


    const onChatButtonClick = () => {
        setChatOpen(true)
    }

    return (
    <div className="order_window_links">
      {hasNewMessages.length ? <img src={newMessagesIndicator} className="order_window_btn_chats_new_messages" /> : ''}
      <Button type="referal" className="order_window_btn order_window_btn_referals" onClick={()=> clickPartnership()}>Партнерская программа</Button>
      <Button type="default" className="order_window_btn order_window_btn_chats" icon={ChatsSVG} onClick={onChatButtonClick}>Чаты</Button>
    </div>
    )
}

export default OrdersMenu;
