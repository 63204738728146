import {
    ADD_POINT_ON_MAP,
    MapDataActionTypes,
    MapDataState,
    MapPoint, REMOVE_POINT_FROM_MAP, REMOVE_ROUTE, SET_MAP_CENTER,
    SET_POINTS_ON_MAP, SET_ROUTE,
    UPDATE_POINT_ON_MAP
} from "./@types";
import OrderFullCard from "../../../components/OrderBook/OrderFullCard/OrderFullCard";
import {OrderFullInfo} from "../orders/@types";

let initialState: MapDataState = {
    points: [],
    use_data_from_1c: false,
    center:[],
    route: null
};

const MapDataReducer = (state = initialState, action: MapDataActionTypes) => {
    switch (action.type) {
        case SET_POINTS_ON_MAP:

            return {
                ...state,
                points: [...action.points ]
            };
        case ADD_POINT_ON_MAP:
            return {
                ...state,
                points: [...state.points, action.point]
            };
        case UPDATE_POINT_ON_MAP:

            let updPoints : MapPoint[] = [...state.points];
            if (action.index < state.points.length)
                updPoints[action.index] = {...action.point};
            return {
                ...state,
                points: updPoints
            };
        case REMOVE_POINT_FROM_MAP:
            return {
                ...state,
                points: action.index < state.points.length ? [...state.points].slice(action.index, 1) :  state.points
            };

        case SET_ROUTE:
            return {
                ...state,
                route: action.route
            };

        case REMOVE_ROUTE:
            return {
                ...state,
                route: null
            };

        case SET_MAP_CENTER:
            return {
                ...state,
                center: action.point
            };

        default:
            return state;
    }
};


export default MapDataReducer;
