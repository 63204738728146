import {
	Owner,
	VehicleData,
	Driver,
	Passport,
	License,
	Bank,
	VehicleCertificate
} from "../../../components/Elements/Containers/@types";
import {
	SET_FINALIZING,
	SET_ASSIGNING_DRIVER,
	SET_IS_OWNER,
	SET_CAMERA_ACCESSED,
	SAVE_OWNER,
	SAVE_DRIVER,
	UPDATE_DRIVER,
	SAVE_VEHICLE,
	UPDATE_VEHICLE,
	REMOVE_VEHICLE,
	SetFinalizingAction,
	SetAssigningDriverAction,
	SetIsOwnerAction,
	SetCameraAccessedAction,
	SaveOwnerAction,
	SaveDriverAction,
	UpdateDriverAction,
	SaveVehicleAction,
	UpdateVehicleAction,
	RemoveVehicleAction
} from "./@types";
import {
	loadPhotos,
	getAddress
} from "../../../api";
import { generateId, getBoolean } from "../../../api/validate";
import { MULTIPLE_CARS_OWNER, TIN_IP } from "../owner_select/@types";

const MEDICAL_BOOK = '9cebde1b-25e2-11ea-a9ad-00155d8e4e05';
const DRIVER_LOADER = '9cebde1a-25e2-11ea-a9ad-00155d8e4e05';
const HYDROBOARD = '9cebde15-25e2-11ea-a9ad-00155d8e4e05';
const RAMP = 'fc3de6d0-40dd-11ea-a9c6-00155d8e4e05';

export const setFinalizing = (finalizing: boolean): SetFinalizingAction => ({
  type: SET_FINALIZING,
  finalizing
});

export const setAssigningDriver = (assigning_driver: boolean): SetAssigningDriverAction => ({
  type: SET_ASSIGNING_DRIVER,
  assigning_driver
});

export const setIsOwner = (is_owner: boolean): SetIsOwnerAction => ({
  type: SET_IS_OWNER,
  is_owner
});

export const setCameraAccessed = (camera_accessed: boolean): SetCameraAccessedAction => ({
  type: SET_CAMERA_ACCESSED,
  camera_accessed
});

export const saveOwner = (owner: Owner): SaveOwnerAction => ({
	type: SAVE_OWNER,
	owner
});

export const saveDriver = (driver: Driver): SaveDriverAction => ({
	type: SAVE_DRIVER,
	driver
});

export const updateDriver = (): UpdateDriverAction => ({
	type: UPDATE_DRIVER
});

export const saveVehicle = (vehicle: VehicleData): SaveVehicleAction => ({
	type: SAVE_VEHICLE,
	vehicle
});

export const updateVehicle = (): UpdateVehicleAction => ({
	type: UPDATE_VEHICLE
});

export const removeVehicle = (): RemoveVehicleAction => ({
	type: REMOVE_VEHICLE
});

export const loadData = (data: any) => async (dispatch: any) => {
	let bank: Bank;
	let passport: Passport;
	let license: License;
	let certificate: VehicleCertificate;
	let driver: Driver;
	let vehicle: VehicleData;
	let drivers: Driver[] = [];
	let vehicles: VehicleData[] = [];
	let index: number;
	let registration: any;
	let address: any;
	let passportPhotos: Blob[];
	let licensePhotos: Blob[];
	let vehicleAddress: any;
	let vehiclePhotos: Blob[];
	let certificatePhotos: Blob[];
	let id = generateId();
	if (data.cars) {
		data.cars.forEach(async (car: any) => {
			vehicleAddress = car.adress ? await getAddress(car.adress) : null;
			vehiclePhotos = car.files ? await loadPhotos(car.files) : [];
			certificatePhotos = car.certificate?.files ?
				await loadPhotos(car.certificate.files) : [];
			certificate = {
				license_plate: car.certificate?.number || '',
				vin: car.certificate?.VIN || '',
				brand_model: car.certificate?.brand ? (car.certificate.brand +
					(car.certificate?.model ? ' ' + car.certificate.model : '')) :
					(car.certificate?.model ? car.certificate.model : ''),
				vehicle_type: car.certificate?.type || '',
				category: car.certificate?.category || '',
				build_year: car.certificate?.date || '',
				eco_class: car.certificate?.class || '',
				pts_series: car.certificate?.passport_serial || '',
				pts_number: car.certificate?.passport_number || '',
				sts_series: car.certificate?.certificate_serial || '',
				sts_number: car.certificate?.certificate_number || '',
				max_weight: car.certificate?.max_weight || '',
				load_weight: car.certificate?.free_weight || '',
				photos: certificatePhotos,
				photo_ids: car.certificate?.files || []
			}
			let hydroboard = '';
			let ramp = '';
			let medical = false;
			let loader = false;
			if (car.body_option_characteristics) {
				car.body_option_characteristics.forEach((ch: any) => {
					if (ch.value === '00000000-0000-0000-0000-000000000000') return;
					switch (ch.id) {
						case HYDROBOARD:
							hydroboard = ch.value;
							break;
						case RAMP:
							ramp = ch.value;
							break;
						case MEDICAL_BOOK:
							medical = getBoolean(ch.value);
							break;
						case DRIVER_LOADER:
							loader = getBoolean(ch.value);
							break;
						default:
							break;
					}
				});
			}
			vehicle = {
				id: generateId(),
				car_type: car.car_type_id || '',
				body_option: car.body_option_id || '',
				characteristics: car.body_option_characteristics || [],
				car_pass: car.pass || '',
				hydroboard,
				ramp,
				length: car.size?.length || '',
				width: car.size?.width || '',
				height: car.size?.height || '',
				address: {
					address: vehicleAddress,
					latitude: vehicleAddress?.data?.geo_lat || '',
					longitude: vehicleAddress?.data?.geo_lon || ''
				},
				photos: vehiclePhotos,
				photo_ids: car.files || [],
				medical_book: medical,
				driver_loader: loader,
				certificate,
				drivers: []
			}
			if (car.drivers) {
				car.drivers.forEach(async (dr: any) => {
					index = drivers.findIndex((d: Driver) => d.passport.name === dr.passport?.full_name);
					if (index >= 0) {
						vehicle.drivers.push(drivers[index]);
					} else {
						registration = dr.passport?.adress ? await getAddress(dr.passport.adress) : null;
						address = dr.passport?.real_adress ? await getAddress(dr.passport.real_adress) : null;
						passportPhotos = dr.passport?.files ? await loadPhotos(dr.passport.files) : [];
						licensePhotos = dr.driver_certificate?.files ?
							await loadPhotos(dr.driver_certificate.files) : [];
						passport = {
							name: dr.passport?.full_name || '',
							birthdate: dr.passport?.birth_date ? new Date(
								dr.passport.birth_date.slice(0, 4),
								dr.passport.birth_date.slice(5, 7) - 1,
								dr.passport.birth_date.slice(8, 10)
							) : null,
							number: dr.passport?.series && dr.passport?.number ?
								dr.passport.series + ' ' + dr.passport.number : '',
							department: dr.passport?.department_code || '',
							issue_date: dr.passport?.issue_date ? new Date(
								dr.passport.issue_date.slice(0, 4),
								dr.passport.issue_date.slice(5, 7) - 1,
								dr.passport.issue_date.slice(8, 10)
							) : null,
							issued_by: dr.passport?.issued_by || '',
							registration,
							address,
							same_address: registration && address && registration.value === address.value,
							photos: passportPhotos,
							photo_ids: dr.passport?.files || []
						}
						license = {
							number: dr.driver_certificate?.serial && dr.driver_certificate?.number ?
								dr.driver_certificate.serial + ' ' + dr.driver_certificate.number : '',
							issue_date: dr.driver_certificate?.issue_date ? new Date(
								dr.driver_certificate.issue_date.slice(0, 4),
								dr.driver_certificate.issue_date.slice(5, 7) - 1,
								dr.driver_certificate.issue_date.slice(8, 10)
							) : null,
							issued_by: dr.driver_certificate?.issued_by || '',
							country: dr.driver_certificate?.country || '',
							categories: dr.driver_certificate?.category || [],
							photos: licensePhotos,
							photo_ids: dr.driver_certificate?.files || []
						}
						driver = {
							id: dr.passport?.full_name === data.person?.full_name ? id : generateId(),
							is_owner: dr.passport?.full_name === data.person?.full_name,
							passport,
							license
						}
						vehicle.drivers.push(driver);
						drivers.push(driver);
					}
				});
			}
			vehicles.push(vehicle);
		});
	}
	let bankAddress = data.banking?.bank?.adress ? await getAddress(data.banking.bank.adress) : null;
	bank = {
		account: data.banking?.account_number || '',
		bic: data.banking?.BIK || '',
		name: data.banking?.bank?.name || '',
		corr: data.banking?.bank?.correction_number || '',
		inn: data.banking?.bank?.TIN || '',
		kpp: data.banking?.bank?.KPP || '',
		address: bankAddress
	}
	registration = data.passport?.adress ? await getAddress(data.passport.adress) : null;
	address = data.passport?.real_adress ? await getAddress(data.passport.real_adress) : null;
	passportPhotos = data.passport?.files ? await loadPhotos(data.passport.files) : [];
	passport = {
		name: data.passport?.full_name || '',
		birthdate: data.passport?.birth_date ? new Date(
			data.passport.birth_date.slice(0, 4),
			data.passport.birth_date.slice(5, 7) - 1,
			data.passport.birth_date.slice(8, 10)
		) : null,
		number: data.passport?.series && data.passport?.number ?
			data.passport.series + ' ' + data.passport.number : '',
		department: data.passport?.department_code || '',
		issue_date: data.passport?.issue_date ? new Date(
			data.passport.issue_date.slice(0, 4),
			data.passport.issue_date.slice(5, 7) - 1,
			data.passport.issue_date.slice(8, 10)
		) : null,
		issued_by: data.passport?.issued_by || '',
		registration,
		address,
		same_address: registration && address && registration.value === address.value,
		photos: passportPhotos,
		photo_ids: data.passport?.files || []
	}
	let owner: Owner = {
		id,
		owner_type: MULTIPLE_CARS_OWNER,
		tin_type: TIN_IP,
		tin: data.TIN || '',
		agree: getBoolean(data.accept_agreement),
		passport,
		bank,
		vehicles,
		drivers
	}
	dispatch(saveOwner(owner));
}
