import { UserStatus } from "../../../../@types";
import {SET_CURRENT_ID} from "../../system/@types";

export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_STATUS = 'SET_STATUS';
export const SET_USER_INFO = 'SET_USER_INFO';
export const LOG_OUT = 'LOG_OUT';
export const SET_CURRENT_COORDINATE = 'SET_CURRENT_COORDINATE';

export interface SetAuthenticatedAction {
  type: typeof SET_AUTHENTICATED;
  authenticated: boolean;
  driver_id: string;
  full_name: string;
  status: UserStatus
}

export interface SetStatusAction {
  type: typeof SET_STATUS;
  status: UserStatus
}

export interface SetUserInfoAction {
  type: typeof SET_USER_INFO;
  data: UserInfo
}

export interface LogOutAction {
  type: typeof LOG_OUT;
}

export interface SetCurrentCoordinateAction {
  type: typeof SET_CURRENT_COORDINATE;
  current_coordinates: {lon:number, lat: number};
}

export type UserActionTypes = SetAuthenticatedAction | SetStatusAction | SetUserInfoAction | LogOutAction | SetCurrentCoordinateAction;

export interface UserState {
  authenticated: boolean;
  driver_id: string;
  full_name: string;
  status: UserStatus,
  data: UserInfo | null
  current_coordinates : {lon:number,lat:number} | null
}

export interface UserInfo {
  "TIN": string,
  "person": {
    "full_name": string,
    "phone_number":string,
    "email": string
  },
  "banking": {
    "account_number": string,
    "BIK": string,
    "correction_number": string,
    "bank": {
      "BIK": string,
      "name": string,
      "correction_number": string,
      "TIN": string,
      "KPP": string,
      "OGRN": string,
      "adress": string
    }
  },
  "passport": {
    "full_name": string,
    "birth_date": string,
    "series": string,
    "number": string,
    "issued_by": string,
    "department_code": string,
    "issue_date": string,
    "adress": string,
    "real_adress": string,
    "files": string[]
  },
  "cars": [
    {
      "car_type_id": string,
      "body_option_id": string,
      "body_option_characteristics":{
          "id": string,
          "value": string
        }[],
      "size": {
        "length": string,
        "width": string,
        "height": string
      },
      "certificate": {
        "number": string,
        "VIN": string,
        "brand": string,
        "model": string,
        "date": string,
        "type": string,
        "category": string,
        "class": string,
        "passport_serial": string,
        "passport_number": string,
        "certificate_serial": string,
        "certificate_number": string,
        "free_weight": string,
        "max_weight": 0,
        "files":  string[]
      },
      "files": string[],
      "adress": {
        "adress": string,
        "longitude": string,
        "latitude": string,
      },
      "drivers":{
          "driver_certificate": {
            "full_name": string,
            "serial": string,
            "number": string,
            "issue_date": string,
            "validity": string,
            "issued_by": string,
            "country": string,
            "category":string[],
            "files": string[]
          },
          "phone_number": string,
          "passport": {
            "full_name": string,
            "birth_date": string,
            "series": string,
            "number": string,
            "issued_by": string,
            "department_code": string,
            "issue_date": string,
            "adress": string,
            "real_adress": string,
            "files": string[]
          }
        }[]
    }
  ],
  "recommendations": any[],
  "accept_agreement": boolean
}
