import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {Button} from "../elements";
import {popSystemStatus, setVisibilityOverlay} from "../../../redux/reducers/system/actions";
import "./CloseRoute.css"

const CloseRoute: React.FC = () => {
    const dispatch = useDispatch();

    const {system} = useSelector((state: RootState) => ({
        system: state.systemReducer
    }));

    useEffect(()=>{

    }, []);

    const closeWindow = () => {
        dispatch(popSystemStatus());
        dispatch(setVisibilityOverlay(true))
    }

    return (
        <div className="close_route_window">
            <Button type="primary" size="large" onClick={closeWindow}>ВЕРНУТЬСЯ НАЗАД</Button>
       </div>)
}

export default CloseRoute;
