import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import InputMask from "react-input-mask";
import {
	CheckPhoneState,
	SetPhoneEnteredAction,
	SetCodeSendingAction,
	SetCodeSentAction,
	SetCodeEnteredAction,
	SetCodeVerifyingAction
} from "../../redux/reducers/check_phone/@types";
import {
	UserState,
	SetAuthenticatedAction,
	UserInfo,
	SetUserInfoAction
} from "../../redux/reducers/user/@types";
import { SetRegistrationStatusAction } from "../../redux/reducers/registration/@types";
import {
	SystemState,
	SetSystemStatusAction
} from "../../redux/reducers/system/@types";
import {
	setPhoneEntered,
	setCodeSending,
	setCodeSent,
	setCodeEntered,
	setCodeVerifying
} from "../../redux/reducers/check_phone/actions";
import {
	setAuthenticated,
	setStatus,
	setUserInfo
} from "../../redux/reducers/user/actions";
import { setRegistrationStatus } from "../../redux/reducers/registration/actions";
import {
	popSystemStatus,
	setSystemStatus
} from "../../redux/reducers/system/actions";
import { loadData } from "../../redux/reducers/finalize/actions";
import BulletSVG from "../App/svg/bullet.svg";
import CheckSVG from "../App/svg/check.svg";
import { ReactSVG } from "react-svg";
import { CancelBtn } from "../Elements/elements";
import {
	userAPI,
	ordersAPI,
	phoneAPI
} from "../../api";
import { trimPhone } from "../../api/validate";
import {
	OrdersListType,
	UserStatus,
	RegistrationStatus,
	SystemStatus
} from "../../@types";
import "./CheckPhone.css";
import {getStatusWorking} from "../../redux/reducers/user/reducers";
import {OrdersState} from "../../redux/reducers/orders/@types";

interface IProps {
  state: CheckPhoneState,
  user: UserState,
  system: SystemState,
  orders: OrdersState,
  setPhoneEntered: (phone_entered: boolean, phone?: string) => SetPhoneEnteredAction,
  setCodeSending: (code_sending: boolean) => SetCodeSendingAction,
  setCodeSent: (code_sent: boolean) => SetCodeSentAction,
  setCodeEntered: (code_entered: boolean, code?: string) => SetCodeEnteredAction,
  setCodeVerifying: (code_verifying: boolean) => SetCodeVerifyingAction,
  setAuthenticated: (state: UserState) => SetAuthenticatedAction,
	setUserInfo: (state: UserInfo) => SetUserInfoAction,
	setRegistrationStatus: (status: RegistrationStatus | null) => SetRegistrationStatusAction,
	setSystemStatus: (status: SystemStatus) => SetSystemStatusAction
}

const CheckPhone: React.FunctionComponent<IProps> = ({
	state,
	user,
	system,
	orders,
	setPhoneEntered,
	setCodeSending,
	setCodeSent,
	setCodeEntered,
	setCodeVerifying,
	setAuthenticated,
	setUserInfo,
	setRegistrationStatus,
	setSystemStatus
}) => {

  const [numOrders, setNumOrders] = useState(0);
  const [sendCodeError, setSendCodeError] = useState(false);
  const [verifyCodeError, setVerifyCodeError] = useState(false);
  const [checkPhoneError, setCheckPhoneError] = useState(false);
  const [time, setTime] = useState(60);
  const timeLeft = useRef(0);
	const dispatch = useDispatch();
	const [registrationStarted, setRegistrationStarted] = useState(false);
	const [error, setError] = useState(false);

  useEffect(() => {
    // ordersAPI.getList(user.driver_id, OrdersListType.All).then((res) => {
    //   setNumOrders(res.data.length);
    // }).catch((err) => {})
	  setNumOrders(orders.items.length)
  }, []);

	const toOwnerSelect = () => {
		dispatch(setRegistrationStatus(RegistrationStatus.OwnerSelect));
	}

	const toMainScreen = () => {
		dispatch(popSystemStatus());
		dispatch(setRegistrationStatus(null));
	}
	let smsInterval : any= null;
  return (
		<>
			<CancelBtn onClick={toMainScreen} />
			{state.code_sent ?
				<>
					<div className="check_phone_window">
						<div className="check_phone_container">
							<label className="check_phone_label">
								<div className="check_phone_label_text">На ваш номер придет SMS-сообщение с кодом, который нужно ввести ниже</div>
								<div className="check_phone_input_container">
									<InputMask mask="9 9 9 9" id="check_phone_code"
										className={'check_phone_input' + (!state.code_entered && error ? ' error' : '')}
										type="tel" value={state.code} onChange={(e) => {
										let code = e.target.value;
										if (code.indexOf('_') === -1) {
											code = code.replace(/ /g, '');
											setCodeEntered(true, code);
										} else {
											setCodeEntered(false);
										}
									}}>
										{(inputProps: any) => <input {...inputProps} />}
									</InputMask>
									{state.code_entered &&
										<ReactSVG src={CheckSVG} className="check_phone_check" style={{"right": "33%"}}/>
									}
									{verifyCodeError && <div className="check_phone_error">Введенный код некорректен.</div>}
									{checkPhoneError && <div className="check_phone_error">Ошибка. Попробуйте еще раз.</div>}
									{timeLeft.current > 0 && !verifyCodeError &&
										<div className="check_phone_text_grey">Отправить новый код можно через {time} сек...</div>
									}
								</div>
							</label>
							<div id="check_phone_verify_code" className={'check_phone_next_button' +
								(state.code_entered ? '' : ' inactive')}
  			    		onClick={async () => {
									setError(false);
									if (state.code_verifying) return;
									if (!state.code_entered) {
										setError(true);
										return;
									}
									setCodeVerifying(true);
									if (state.code === '6291') {
										// setCodeVerifying(false);
										// setTimeout(toOwnerSelect, 500);
										// return;
									}
									if (state.code === '8888') {
										// setCodeVerifying(false);
										setAuthenticated({
											authenticated: true,
											driver_id: "5ee898f7-3157-4036-bb50-9f46e6914797 ",
											full_name: "Игнатов Владислав Петрович",
											status: UserStatus.Working,
											data: null, current_coordinates: user.current_coordinates
										});
										let res = await userAPI.getUserInfo( "5ee898f7-3157-4036-bb50-9f46e6914797 " );
										if (res.data && res.status === 200) {
											setUserInfo({ ...(res.data) });
											dispatch(loadData(res.data));
										}
										if (smsInterval) clearInterval(smsInterval);
										setTimeout(toMainScreen, 500);

										return;
									}

									let res = await userAPI.auth(state.phone, state.code);
									setCodeVerifying(false);
									if (res.data && res.data.status === 'error' && res.data.error_code === 1) {
										setVerifyCodeError(true);
										setTimeout(setVerifyCodeError, 5000, false);
										return;
									}
									if (res.data && res.data.status === 'error' && res.data.error_code === 2) {
										setTimeout(toOwnerSelect, 500);
										return;
									}
									if (res.data && (res.data.status === 'success' ||  res.data.status === 0)) {
										setAuthenticated({ authenticated: true, ...(res.data) });
										let driver_id = res.data.driver_id;
										res = await userAPI.getUserInfo( driver_id );
										if (res.data &&  res.status === 200) {
											setUserInfo({ ...(res.data)});
											dispatch(loadData(res.data));
										}
										res = await userAPI.getDriverStatus(driver_id);
										if (res.data && res.status === 200)
											dispatch(setStatus(getStatusWorking(res.data.status, res.data.dates)));

										setTimeout(toMainScreen, 500);
										return;
									}
									setCheckPhoneError(true);
									setTimeout(setCheckPhoneError, 5000, false);
  				   		}}>
  			    		<div className="check_phone_start_text">Далее</div>
  			    	</div>
						</div>
					</div>
				</> :
				<>
					{registrationStarted ?
						<div className="check_phone_window">
							<div className="check_phone_container">
								<label className="check_phone_label">
									<div className="check_phone_label_text">Введите свой номер телефона</div>
									<div className="check_phone_input_container">
										<InputMask mask="+7(999) 999-99-99" id="check_phone_phone"
											className={'check_phone_input' + (!state.phone_entered && error ? ' error' : '')}
											type="tel" value={state.phone} onChange={(e) => {
											let phone = e.target.value;
											if (phone.indexOf('_') === -1) {
												phone = trimPhone(phone);
												setPhoneEntered(true, phone);
											} else {
												setPhoneEntered(false);
											}
										}}>
											{(inputProps: any) => <input {...inputProps} />}
										</InputMask>
										{state.phone_entered && <ReactSVG src={CheckSVG} className="check_phone_check" />}
										{sendCodeError && <div className="check_phone_error">Ошибка отправки кода. Попробуйте еще раз.</div>}
									</div>
								</label>
								<div id="check_phone_send_code" className={'check_phone_next_button' +
									(state.phone_entered ? '' : ' inactive')}
  				    		onClick={async () => {
  				    			setError(false);
	  			    			if (state.code_sending) return;
  				    			if (!state.phone_entered) {
  				    				setError(true);
  				    				return;
  				    			}
  				    			setCodeSending(true);
  				    			timeLeft.current = 60;
  				    			setTime(60);
  				    			smsInterval = setInterval(() => {
  				    				timeLeft.current -= 1;
  				    				setTime(timeLeft.current);
  				    				if (timeLeft.current === 0) {
  				    					clearInterval(smsInterval);
  				    					setCodeSent(false);
  				    					setCodeEntered(false, '');
  				    					setError(false);
  				    				}
  				    			}, 1000);
  				    			let res = await phoneAPI.sendSms(state.phone);
  				    			setCodeSending(false);
  				    			if (res.data && res.data.status === 'success') {
  				    				setCodeSent(true);
  				    			} else {
  				    				setSendCodeError(true);
  				    				setTimeout(setSendCodeError, 5000, false);
  				    			}
  				    		}}>
  				    		<div className="check_phone_start_text">Далее</div>
  				    	</div>
							</div>
						</div> :
			    	<div className="check_phone_window">
				      <div className="check_phone_container">
  				    	<div className="check_phone_title">Получите доступ ко всем {numOrders} заказам в Москве</div>
  				    	<div className="check_phone_text_bold">а также</div>
  				    	<table className="check_phone_list">
  				    		<tbody>
				  	    		<tr>
					  	    		<td className="check_phone_list_bullet"><ReactSVG src={BulletSVG} /></td>
					  	    		<td className="check_phone_list_item">Получайте заказы, подобранные специально для вас</td>
					  	    	</tr>
					  	    	<tr>
					  	    		<td className="check_phone_list_bullet"><ReactSVG src={BulletSVG} /></td>
  					    			<td className="check_phone_list_item">Зарабатывайте каждый день</td>
  					    		</tr>
  					    		<tr>
					  	    		<td className="check_phone_list_bullet"><ReactSVG src={BulletSVG} /></td>
  					    			<td className="check_phone_list_item">Удобно и просто общайтесь с клиентом, не покидая сайта</td>
  					    		</tr>
  					    	</tbody>
  				    	</table>
  				    	<div id="check_phone_start" className="check_phone_start_button"
  				    		onClick={() => setTimeout(setRegistrationStarted, 500, true)}>
  				    		<div className="check_phone_start_text">Начать работу</div>
  				    	</div>
  				    </div>
						</div>
					}
				</>
			}
		</>
	)
}

const mapStateToProps = (state: RootState) => ({
	state: state.checkPhoneReducer,
	user: state.userReducer,
	system: state.systemReducer,
	orders: state.ordersReducer
});

export default connect(mapStateToProps, {
	setPhoneEntered,
	setCodeSending,
	setCodeSent,
	setCodeEntered,
	setCodeVerifying,
	setAuthenticated,
	setUserInfo,
	setRegistrationStatus,
	setSystemStatus
})(CheckPhone);
