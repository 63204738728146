import React from "react";
import {connect, useDispatch} from "react-redux";
import {RootState} from "../../../redux/store";
import {LogOutAction, UserState} from "../../../redux/reducers/user/@types";
import {SystemState} from "../../../redux/reducers/system/@types";
import {SystemStatus} from "../../../@types";
import {
  clearSystemStatus,
  popSystemStatus,
  setSystemStatus,
  toggleSystemStatus
} from "../../../redux/reducers/system/actions";
import {setRegistrationStatus} from "../../../redux/reducers/registration/actions";
import BarsSVG from "../../App/svg/bars.svg";
import HelpSVG from "../../App/svg/help.svg";
import LogOutSVG from "../../App/svg/logout.svg";
import PartnerSVG from "../../App/svg/partner.svg";
import JournalSVG from "../../App/svg/journal.svg";
import UserSVG from "../../App/svg/user.svg";
import StatisticSVG from "../../App/svg/statistic.svg";
import {ReactSVG} from "react-svg";
import "./Menu.css";
import {deleteCookie} from "../../../redux/reducers/system/cookies";
import {setLogOut} from "../../../redux/reducers/user/actions";
import {isSystemStatusActive} from "../../../redux/reducers/system/reducers";


interface IProps {
  state: {
    user: UserState,
    system: SystemState
  },

  setLogOut: () => LogOutAction,
}

const Menu: React.FunctionComponent<IProps> = ({ state, setLogOut}) => {
  const { user } = state;

  const dispatch = useDispatch();

  const showOrderBook = () =>{
    dispatch(setSystemStatus(SystemStatus.OrderBook));
  };

  const showStatisticInfo = () =>{
    dispatch(setSystemStatus(SystemStatus.Statistic));
  };

  const showHelp = () =>{
    dispatch(setSystemStatus(SystemStatus.Help));
  };

  const showPartnership = () =>{

    dispatch(setSystemStatus(SystemStatus.Partnership));

  };

  const showRegistration = () => {
      dispatch(setRegistrationStatus(null));
      dispatch(setSystemStatus(SystemStatus.Registration));
  }

  const showProfile = () =>{
    dispatch(setRegistrationStatus(null));
    dispatch(setSystemStatus(SystemStatus.Profile));
  };

  const logout = ()=> {
    deleteCookie("id");
    setLogOut();
    dispatch(clearSystemStatus());

  }

  const toggleMenuWindow = () => {
    //dispatch(toggleSystemStatus(SystemStatus.Menu));
    if (isSystemStatusActive(SystemStatus.Menu, state.system.statuses))
      dispatch(popSystemStatus());
    else
      dispatch(setSystemStatus(SystemStatus.Menu));
  }

  if (!user.authenticated) {
    return (
      <>
        <div className="menu_btn" onClick={toggleMenuWindow}><ReactSVG src={BarsSVG} /></div>
        {isSystemStatusActive(SystemStatus.Menu, state.system.statuses) &&
          <div className="menu_window">
            <div className="menu_items">
              <div className="menu_item">
                <div className="menu_item_info">
                  <div className="menu_item_title"
										onClick={showRegistration}>Вход / регистрация</div>
                </div>
                <div className="menu_item_icon"><ReactSVG src={UserSVG} /></div>
              </div>
              {/*<div className="menu_item">*/}
              {/*  <div className="menu_item_info">*/}
              {/*    <div className="menu_item_title">Партнерская программа</div>*/}
              {/*    <div className="menu_item_description">Взаимовыгодное сотрудничество</div>*/}
              {/*  </div>*/}
              {/*  <div className="menu_item_icon"><ReactSVG src={PartnerSVG} /></div>*/}
              {/*</div>*/}
              {/*<div className="menu_item">*/}
              {/*  <div className="menu_item_info">*/}
              {/*    <div className="menu_item_title">Свободные заказы</div>*/}
              {/*  </div>*/}
              {/*  <div className="menu_item_icon"><ReactSVG src={StopWatchSVG} /></div>*/}
              {/*</div>*/}

                <div className="menu_item" onClick={showHelp}>
                  <div className="menu_item_info">
                    <div className="menu_item_title" onClick={()=>console.log('fff')} >Помощь</div>
                  </div>
                  <div className="menu_item_icon"><ReactSVG src={HelpSVG} /></div>
                </div>



            </div>
            <div className="version_info">Версия сайта: 0.01</div>
          </div>
        }
      </>
    )
  } else {
    return (
      <>
        <div className="menu_btn" onClick={toggleMenuWindow}><ReactSVG src={BarsSVG} /></div>
        {isSystemStatusActive(SystemStatus.Menu, state.system.statuses) &&
          <div className="menu_window">
            <div className="menu_window_header" onClick={showProfile}>
              <div className="user_avatar"/>
              <div className="user_info">
                <div className="user_fullname">{user.full_name}</div>
              </div>
            </div>
            <div className="menu_items">

              <div className="menu_item" onClick={showProfile}>
                <div className="menu_item_info">
                  <div className="menu_item_title">Профиль</div>
                </div>
                <div className="menu_item_icon"><ReactSVG src={UserSVG} /></div>
              </div>

              <div className="menu_item" onClick={showPartnership}>
                <div className="menu_item_info">
                  <div className="menu_item_title">Партнерская программа</div>
                  <div className="menu_item_description">Взаимовыгодное сотрудничество</div>
                </div>
                <div className="menu_item_icon"><ReactSVG src={PartnerSVG} /></div>
              </div>
              {/*<div className="menu_item">*/}
              {/*  <div className="menu_item_info" onClick={()=>showExecutionForm()}>*/}
              {/*    <div className="menu_item_title">Выполнение заказа</div>*/}
              {/*  </div>*/}
              {/*  <div className="menu_item_icon"><ReactSVG src={CargoTruckSVG} /></div>*/}
              {/*</div>*/}
              {/*<div className="menu_item" onClick={showFreeOrders}>*/}
              {/*  <div className="menu_item_info">*/}
              {/*    <div className="menu_item_title">Свободные заказы</div>*/}
              {/*  </div>*/}
              {/*  <div className="menu_item_icon"><ReactSVG src={StopWatchSVG} /></div>*/}
              {/*</div>*/}
              <div className="menu_item"  onClick={()=>showOrderBook()}>
                <div className="menu_item_info">
                  <div className="menu_item_title">Журнал заказов</div>
                </div>
                <div className="menu_item_icon"><ReactSVG src={JournalSVG} /></div>
              </div>

              <div className="menu_item" onClick={showStatisticInfo}>
                <div className="menu_item_info">
                  <div className="menu_item_title">Статистика</div>
                </div>
                <div className="menu_item_icon"><ReactSVG src={StatisticSVG} /></div>
              </div>


              <div className="menu_item" onClick={showHelp}>
                <div className="menu_item_info">
                  <div className="menu_item_title">Помощь</div>
                </div>
                <div className="menu_item_icon"><ReactSVG src={HelpSVG} /></div>
              </div>

              <div className="menu_item">
                <div className="menu_item_info">
                  <div className="menu_item_title" onClick={logout}>Выход</div>
                </div>
                <div className="menu_item_icon"><ReactSVG src={LogOutSVG} /></div>
              </div>

            </div>
            <div className="version_info">Версия сайта: 0.01</div>
          </div>
        }
      </>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  state: {
    user: state.userReducer,
    system: state.systemReducer
  }
});

export default connect(mapStateToProps, {  setLogOut})(Menu);
