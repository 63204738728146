import React, { useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { BankSuggestions } from "react-dadata";
import { daDataToken } from "../../deployment";
import { RootState } from "../../redux/store";
import { RegistrationStatus, SystemStatus } from "../../@types";
import { Owner } from "../Elements/Containers/@types";
import {
	BankDataState,
	SetAccountEnteredAction,
	SetBankEnteredAction,
	SetBicEnteredAction,
	SetCorrEnteredAction,
	SetInnEnteredAction,
	SetKppEnteredAction,
	SetBankAddressEnteredAction
} from "../../redux/reducers/bank_data/@types";
import {
	FinalizeState,
	SaveOwnerAction
} from "../../redux/reducers/finalize/@types";
import {
	SetRegistrationStatusAction,
	RestoreRegistrationStatusAction
} from "../../redux/reducers/registration/@types";
import {
	SystemState,
	SetSystemStatusAction
} from "../../redux/reducers/system/@types";
import {
	setAccountEntered,
	setBankEntered,
	setBicEntered,
	setCorrEntered,
	setInnEntered,
	setKppEntered,
	setBankAddressEntered
} from "../../redux/reducers/bank_data/actions";
import { saveOwner } from "../../redux/reducers/finalize/actions";
import {
	setRegistrationStatus,
	restoreRegistrationStatus
} from "../../redux/reducers/registration/actions";
import {popSystemStatus, setSystemStatus} from "../../redux/reducers/system/actions";
import { syncData } from "../../redux/reducers/user/actions";
import { CancelBtn } from "../Elements/elements";
import { editingChars } from "../../@types";
import "./BankData.css";
import { getLastSystemStatus } from "../../redux/reducers/system/reducers";

interface IProps {
  state: BankDataState,
  finalize: FinalizeState,
  system: SystemState,
  setAccountEntered: (account_entered: boolean, account?: string) => SetAccountEnteredAction,
  setBankEntered: (bank_entered: boolean, bank?: string) => SetBankEnteredAction,
  setBicEntered: (bic_entered: boolean, bic?: string) => SetBicEnteredAction,
  setCorrEntered: (corr_entered: boolean, corr?: string) => SetCorrEnteredAction,
  setInnEntered: (inn_entered: boolean, inn?: string) => SetInnEnteredAction,
  setKppEntered: (kpp_entered: boolean, kpp?: string) => SetKppEnteredAction,
  setBankAddressEntered: (bank_address_entered: boolean, bank_address?: any) => SetBankAddressEnteredAction,
  saveOwner: (owner: Owner) => SaveOwnerAction,
	setRegistrationStatus: (status: RegistrationStatus | null) => SetRegistrationStatusAction,
	restoreRegistrationStatus: () => RestoreRegistrationStatusAction,
	setSystemStatus: (status: SystemStatus) => SetSystemStatusAction
}

const BankData: React.FunctionComponent<IProps> = ({
	state,
	finalize,
	system,
	setAccountEntered,
	setBankEntered,
	setBicEntered,
	setCorrEntered,
	setInnEntered,
	setKppEntered,
	setBankAddressEntered,
	saveOwner,
	setRegistrationStatus,
	restoreRegistrationStatus,
	setSystemStatus
}) => {
	const bic = useRef<BankSuggestions>(null);
	const dispatch = useDispatch();
	const [error, setError] = useState(false);

	const toPassportData = () => {
		dispatch(setRegistrationStatus(RegistrationStatus.PassportData));
	}

	const toFinalize = () => {
		dispatch(setRegistrationStatus(RegistrationStatus.Finalize));
	}

	const toProfile = () => {
		dispatch(setRegistrationStatus(null));
	}

	const goBack = () => {
		switch (getLastSystemStatus(system.statuses)) {
			case SystemStatus.Registration:
				restoreRegistrationStatus();
				return;
			case SystemStatus.Profile:
				toProfile();
				return;
			default:
				return;
		}
	}

  return (
		<>
			<CancelBtn onClick={goBack} />
			<div className="bank_window">
				<div className="bank_container">
					<div className="bank_title">Реквизиты ИП</div>
					<div id="bank_bic" className={'bank_input' + (state.bic_entered ?
						' bank_input_complete' : (error ? ' error': ''))}>
						<BankSuggestions
							token={daDataToken}
							ref={bic}
							defaultQuery={state.bic}
							count={5}
							onChange={(suggestion) => {
								if (suggestion && suggestion.data && suggestion.data.state &&
									suggestion.data.state.status === 'ACTIVE') {
									setBicEntered(true, suggestion.data.bic);
									setBankEntered(true, suggestion.value);
									setCorrEntered(true, suggestion.data.correspondent_account);
									setInnEntered(true, suggestion.data.inn);
									setKppEntered(true, suggestion.data.kpp);
									setBankAddressEntered(true, suggestion.data.address);
									if (bic.current) bic.current.setInputValue(suggestion.data.bic);
								}
							}}
							inputProps={{className: 'bank_input_text', placeholder: 'БИК банка', type: 'number',
								onKeyDown: (e: React.KeyboardEvent) => {
									if ((!/[0-9]/.test(e.key) && !editingChars.includes(e.key)) ||
										(/[0-9]/.test(e.key) && (e.target as HTMLInputElement).value.length === 9)) {
										e.preventDefault();
									}
								},
								onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
									if (e.target.value.length === 9) {
										setBicEntered(true, e.target.value);
									} else {
										setBicEntered(false);
										setBankEntered(false, '');
										setCorrEntered(false, '');
										setInnEntered(false, '');
										setKppEntered(false, '');
										setBankAddressEntered(false, null);
									}
								},
							}}
						/>
					</div>
					<div id="bank_name" className={'bank_input bank_autofill' + (state.bank_entered ?
						' bank_input_complete' : '')}>
						<input className="bank_input_text" placeholder="Наименование банка" type="text"
							value={state.bank} readOnly
						/>
					</div>
					<div id="bank_corr" className={'bank_input bank_autofill' + (state.corr_entered ?
						' bank_input_complete' : '')}>
						<input className="bank_input_text" placeholder="Корр. счет банка" type="number"
							value={state.corr} readOnly
						/>
					</div>
					<div id="bank_inn" className={'bank_input bank_autofill' + (state.inn_entered ?
						' bank_input_complete' : '')}>
						<input className="bank_input_text" placeholder="ИНН банка" type="number"
							value={state.inn} readOnly
						/>
					</div>
					<div id="bank_kpp" className={'bank_input bank_autofill' + (state.kpp_entered ?
						' bank_input_complete' : '')}>
						<input className="bank_input_text" placeholder="КПП банка" type="number"
							value={state.kpp} readOnly
						/>
					</div>
					<div id="bank_address" className={'bank_input bank_autofill' + (state.bank_address_entered ?
						' bank_input_complete' : '')}>
						<input className="bank_input_text" placeholder="Юридический адрес банка" type="text"
							value={state.bank_address?.value || ''} readOnly
						/>
					</div>
					<div id="bank_account" className={'bank_input' + (state.account_entered ?
						' bank_input_complete' : (error ? ' error' : ''))}>
						<input className="bank_input_text" placeholder="Рассчетный счет в банке" type="number"
							value={state.account} onKeyDown={(e: React.KeyboardEvent) => {
								if ((!/[0-9]/.test(e.key) && !editingChars.includes(e.key)) ||
									(/[0-9]/.test(e.key) && (e.target as HTMLInputElement).value.length === 20)) {
									e.preventDefault();
								}
							}}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								if (e.target.value.length === 20) {
									setAccountEntered(true, e.target.value);
								} else {
									setAccountEntered(false);
								}
							}}
						/>
					</div>
					<section className="bank_footer">
						<div id="bank_back" className="bank_link" onClick={goBack}>Вернуться назад</div>
						<div id="bank_next" className={'bank_button' +
							(state.account_entered && state.bic_entered ? '' : ' inactive')}
								onClick={() => {
									setError(false);
									if (!state.account_entered || !state.bic_entered) {
										setError(true);
										return;
									}
									saveOwner({ ...(finalize.owner), bank: {
										account: state.account,
										bic: state.bic,
										name: state.bank,
										corr: state.corr,
										inn: state.inn,
										kpp: state.kpp,
										address: state.bank_address
									}});
									switch (getLastSystemStatus(system.statuses)) {
										case SystemStatus.Registration:
											setTimeout(finalize.finalizing ? toFinalize : toPassportData, 500);
											return;
										case SystemStatus.Profile:
											dispatch(syncData());
											setTimeout(toProfile, 500);
											return;
										default:
											return;
									}
								}}>
			    		<div className="bank_button_text">
			    			{finalize.finalizing || getLastSystemStatus(system.statuses) === SystemStatus.Profile ?
			    				'Сохранить' : 'Далее'}
			    		</div>
			    	</div>
					</section>
				</div>
			</div>
		</>
	)
}

const mapStateToProps = (state: RootState) => ({
	state: state.bankDataReducer,
	finalize: state.finalizeReducer,
	system: state.systemReducer
});

export default connect(mapStateToProps, {
	setAccountEntered,
	setBankEntered,
	setBicEntered,
	setCorrEntered,
	setInnEntered,
	setKppEntered,
	setBankAddressEntered,
	saveOwner,
	setRegistrationStatus,
	restoreRegistrationStatus,
	setSystemStatus
})(BankData);
