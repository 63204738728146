import Button from "../../Elements/Button/Button";
import React from "react";
import {connect, useDispatch} from "react-redux";
import {RootState} from "../../../redux/store";
import {RouteInfo} from "../../../redux/reducers/map/@types";
import {OrderFullInfo} from "../../../redux/reducers/orders/@types";

interface ILocalProps {
    order: OrderFullInfo,
    currentPoint: number,
    onShowRoute:Function,
    onShowMap:Function,
    onFullMode:Function,
}

const SmallPlayWindow: React.FC<ILocalProps> = ({order, currentPoint,onShowRoute,onShowMap,onFullMode}) => {
    const dispatch = useDispatch();
    return (
        <>
            <div className="play_window small_window">
                <div className="play_window_row">
                    <div className="point_number">{currentPoint + 1}</div>
                    <div className="point_address">{order.routes[currentPoint].adress}</div>
                </div>
                <div className="play_window_row play_window_row_line">
                    <Button type="white2" size="small" className="Button45"
                            onClick={()=> { if (onShowRoute) onShowRoute() }}>На карте
                    </Button>
                    <Button type="darkgrey" size="small" className="Button45"
                            onClick={()=> { if (onShowMap) onShowMap() }}>Маршрут
                    </Button>
                </div>
                <div className="play_window_row play_window_row_line">
                    <Button type="transparent" size="large" className="Button100"
                            onClick={()=> { if (onFullMode) onFullMode() }}>Детали маршрута
                    </Button>
                </div>
                <div className="play_window_row play_window_row_line">
                    <div>&nbsp;</div>
                </div>
            </div>
        </>
    );
};


const mapStateToProps = (state: RootState) => ({
    state: {
        orderState: state.ordersReducer,
    }
});

export default connect(mapStateToProps, { })(SmallPlayWindow);
