import React, { CSSProperties } from "react";
import { ReactSVG } from "react-svg";
import "./Button.css";

interface IProps {
  type: "default" | "referal" | "grey" | "primary" | "white" | "green" | "transparent" | "darkgrey" | "white2" | "error" ;

  className?: string;
  onClick?: () => void,
  size?: "large" | "middle" | "small";
  icon?: string;
  style?: CSSProperties;
  disabled?:boolean;
}

const Button: React.FunctionComponent<IProps> = (props) => {
  const { type, size = "middle" } = props;

  return (
    <div 
      className={`btn btn_${size} ${type && 'btn_' + type}${props.className ? ' '+props.className : ''}${  props.disabled ? ' btn_disable' : ''}`}
      onClick={ () => props.disabled || !props.onClick ?  null : props.onClick()}
      style={props.style}

    >{props.children} {(props.icon) && <ReactSVG src={props.icon} />}</div>
  );
}

export default Button;
